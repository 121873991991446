export const styles = {
  container: {
    flexGrow: 1,
    ml: '20px',
  },

  list: {
    pt: '20px',
    pl: '16px',
    pr: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    alignItems: 'flex-end',
    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',
    zIndex: 9,
  },

  warning: {
    mt: '10px',
    textAlign: 'center',
  },

  iconButton: {
    p: 0,
  },

  icon: {
    fontSize: '20px',
    color: 'primary.main',
  },

  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100% - 50px)',
  },
};
