import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  item: {
    pt: 0,
    pb: 0,
    pl: '62px',
    cursor: 'pointer',

    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },

    '&:before': {
      content: '""',
      position: 'absolute',
      left: '34px',
      top: '50%',
      bottom: '50%',
      width: '40px',

      borderTop: '1px dashed',
      borderColor: 'custom.greyLight',
    },
  },

  selected: {
    bgcolor: 'custom.blueLight',
    '&:hover': {
      bgcolor: 'custom.blueLight',
    },
  },

  content: {
    ml: '10px',
    p: '10px 0',
    borderTop: '1px solid',
    borderColor: 'custom.greyLight',
  },

  editButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  moveButton: {
    position: 'absolute',
    right: '16px',
    top: 0,
  },
  popup: {
    borderColor: 'grey',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  },
};
