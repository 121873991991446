import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useDrop } from 'react-dnd';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Box, IconButton, ListItem, Typography } from '@mui/material';
import { ReactComponent as TwoCarsIcon } from 'assets/icons/twoCars.svg';
import { GROUP_SECTION_SCREEN } from 'constants/common';
import { GroupSettingContext } from 'context/GroupSetting/GroupSettingProvider';
import { useAddResourcesToGroupMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  addResourceToGroupDetailResourcesById,
  setToast,
} from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import {
  GroupApiResponse,
  ResourceForSettings,
  WrappedErrorApi,
} from 'types/api';

import { DeleteGroupButton } from 'components/ui/DeleteGroupButton';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface DroppableGroupItemProps {
  group: GroupApiResponse;
  icon?: ReactElement;
}

const DroppableGroupItem: FC<DroppableGroupItemProps> = React.memo((props) => {
  const { group, icon = <TwoCarsIcon /> } = props;
  // @ts-ignore
  const { setCurrentScreen, setCurrentGroupId } =
    useContext(GroupSettingContext);

  const dispatch = useAppDispatch();
  const [addResourceMutation] = useAddResourcesToGroupMutation();

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'resource',
      drop: (item) => {
        dispatch(
          addResourceToGroupDetailResourcesById({
            groupId: group.id,
            resourceId: item.id,
          }),
        );
        addResourceToGroup(item.id);
      },
      canDrop: (item: ResourceForSettings) => {
        const founded = group.resources_ids.find((id) => id === item.id);

        if (founded) {
          return false;
        }

        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [group.resources_ids],
  );

  const addResourceToGroup = useCallback(
    async (id: string) => {
      const res = await addResourceMutation({
        groupId: group.id,
        resourcesIds: [id],
      });

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              t`Something wrong when adding resource!`,
            severity: 'error',
          }),
        );
      }
    },
    [group, addResourceMutation],
  );

  const backgroundStyle = useMemo(() => {
    const isActive = canDrop && isOver;
    let bc = {};

    if (isActive) {
      bc = { backgroundColor: 'custom.green2' };
    } else if (canDrop) {
      bc = { backgroundColor: 'custom.green3' };
    }

    return bc;
  }, [canDrop, isOver]);

  return (
    <ListItem
      sx={{ ...styles.item, ...backgroundStyle }}
      ref={drop}
      onClick={() => {
        setCurrentGroupId(group.id);
        setCurrentScreen(GROUP_SECTION_SCREEN.groupDetail);
      }}
    >
      <Box sx={styles.content}>
        <Box sx={styles.icon}>{icon}</Box>
        <Typography variant="subtitle1">{group.title}</Typography>
      </Box>
      <Box className="crab-icon-block" sx={styles.grabIconBlock}>
        <LightTooltip title={t`You can edit group title`} arrow placement="top">
          <span>
            <IconButton
              sx={styles.button}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentGroupId(group.id);
                setCurrentScreen(GROUP_SECTION_SCREEN.createGroup);
              }}
            >
              <BorderColorOutlinedIcon sx={{ color: 'custom.graphite' }} />
            </IconButton>
          </span>
        </LightTooltip>
        <DeleteGroupButton group={group} />
      </Box>
    </ListItem>
  );
});

export { DroppableGroupItem };
