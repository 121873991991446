import cookies from 'browser-cookies';
import { LOCALE_COOKIE } from 'constants/common';
import svLocaleObject from 'i18n/sv.po.json';
import { addLocale, LocaleData, useLocale } from 'ttag';

export type Locale = 'sv' | 'en';

export const getLocale = (): Locale => {
  return (cookies.get(LOCALE_COOKIE) as Locale) || 'en';
};

export const saveLocale = (locale: Locale) => {
  cookies.set(LOCALE_COOKIE, locale);
};

export const setLocale = (locale: Locale) => {
  saveLocale(locale);
  window.location.reload();
};

const locale = getLocale();

if (locale === 'sv') {
  const translationsObj = svLocaleObject;
  addLocale(locale, translationsObj as LocaleData);
  useLocale(locale);
}
