import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();

  const navigateWithQueryParams = useCallback(
    (route: string, state?: any) => {
      const browserHistory = createBrowserHistory();
      const { search } = browserHistory.location;
      const options: { state?: any } = {};

      if (state) {
        options.state = state;
      }

      navigate(`${route}${search}`, options);
    },
    [createBrowserHistory, navigate],
  );

  return {
    navigateWithQueryParams,
  };
};
