import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    position: 'relative',
  },

  firstFilter: {
    margin: '24px 0',
  },

  // secondFilter: {
  //   marginBottom: '24px',
  // },

  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 16px 0',
  },

  infoIcon: {
    color: 'custom.blue',
  },

  infoText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: 'custom.graphite',
    marginLeft: '8px',
  },

  filterButton: {
    margin: '16px 0',
  },
  close: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  applyButton: {
    marginBottom: '16px',
  },
};
