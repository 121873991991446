import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';

export const PickUpDataTabSenderSection: FC = () => {
  const { formik } = useChangeScreenContext();

  const { setFieldValue } = formik;
  const { businessPickType, terminalPickType } = useEditScreenErrandTypes();

  useEffect(() => {
    if (!businessPickType) {
      setFieldValue('pickCompanyName', null);
    }
  }, [businessPickType]);

  return (
    <ChangeScreenSection label={t`Sender`}>
      <Grid container spacing={2} rowGap={4}>
        {!terminalPickType && (
          <Grid item mobile>
            <Field
              component={FieldInput}
              name="pickCompanyName"
              label={t`Company name`}
              placeholder={t`Enter company name`}
              fullWidth
              autoComplete="new-password"
              // required
            />
          </Grid>
        )}

        {terminalPickType ? (
          <Grid item mobile>
            <Field
              component={FieldInput}
              name="pickTerminalName"
              label={t`Terminal name`}
              placeholder={t`Enter name of terminal`}
              fullWidth
              autoComplete="new-password"
              // required={!terminalPickType}
            />
          </Grid>
        ) : (
          <>
            <Grid item mobile>
              <Field
                component={FieldInput}
                name="pickNameOfSender"
                label={t`Name of sender`}
                placeholder={t`Enter name of sender`}
                fullWidth
                autoComplete="new-password"
                // required={!terminalPickType}
              />
            </Grid>
            <Grid item mobile>
              <Field
                component={FieldInput}
                name="pickSurnameOfSender"
                label={t`Surname of sender`}
                placeholder={t`Enter surname of sender`}
                fullWidth
                autoComplete="new-password"
                // required={!terminalPickType}
              />
            </Grid>
          </>
        )}
        <Grid item mobile>
          <Field
            component={FieldInput}
            name="pickPhoneNumber"
            label={t`Phone number`}
            placeholder={t`Enter phone number`}
            fullWidth
            autoComplete="new-password"
            maxLength={20}
            // required={!terminalPickType}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
