import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '428px',
  },
  buttonBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: '16px',
  },
  button: {
    width: '190px',
  },
  title: {
    padding: '24px 16px 0 16px',
    alignSelf: 'center',
    textAlign: 'center',
  },
  content: {
    fontWeight: 300,
    padding: '16px',
    alignSelf: 'center',
    textAlign: 'center',
  },
  separator: {
    width: '100%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.greyLight2',
  },
};
