import { FC } from 'react';
import { Typography } from '@mui/material';
import { t } from 'ttag';

import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerInstructionProps {
  instruction?: string;
  deliveryInstruction?: string;
  isPickup: boolean;
}

export const DetailsSectionInnerInstruction: FC<
  DetailsSectionInnerInstructionProps
> = ({ instruction, deliveryInstruction, isPickup = false }) => {
  return (
    <>
      {instruction && (
        <InnerSection
          sx={styles.container}
          label={isPickup ? t`Pick up instruction` : t`Delivery instruction`}
        >
          <Typography>{instruction}</Typography>
        </InnerSection>
      )}
      {deliveryInstruction && isPickup && (
        <InnerSection sx={styles.container} label={t`Delivery instruction`}>
          <Typography>{deliveryInstruction}</Typography>
        </InnerSection>
      )}
    </>
  );
};
