import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateDedicatedChatDate } from 'store/reducers/chatSlice';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { setSelectedResourceId } from 'store/reducers/resourceSlice';
import {
  setCurrentCommonDate,
  setOnlyCurrentWorkshift,
} from 'store/reducers/settingsSlice';
import { setWorkshiftId } from 'store/reducers/workshiftSlice';
import { getDedicatedChatDateSelector } from 'store/selectors/chat';

import { styles } from './style';

interface UnreadMessagesProps {
  unread: number;
  errandId: string | null;
  resourceId: string;
  workshiftId: string;
}
export const UnreadMessages = memo((props: UnreadMessagesProps) => {
  const { unread, resourceId, errandId, workshiftId } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dedicatedChatDate = useAppSelector(getDedicatedChatDateSelector);

  const circleStyle = useMemo(() => {
    if (unread > 9) {
      return {
        ...styles.circle,
        padding: '0 2px',
      };
    }

    return styles.circle;
  }, [unread]);

  const handleIconClick = useCallback(() => {
    let orderIdParam = '';
    let dateParam = '';

    dispatch(setSelectedResourceId(resourceId));
    dispatch(setWorkshiftId(workshiftId));
    dispatch(setOnlyCurrentWorkshift(false));

    if (errandId) {
      dispatch(setSelectedOrderId(errandId));
      orderIdParam = `&orderId=${errandId}`;
    }

    if (dedicatedChatDate) {
      dispatch(setCurrentCommonDate(dedicatedChatDate));
      dateParam = `&chosen_date=${dedicatedChatDate}`;
    } else {
      dispatch(setCurrentCommonDate(undefined));
    }

    dispatch(updateDedicatedChatDate(undefined));

    navigate(
      `/chat?only_current_workshift=false&workshiftId=${workshiftId}&resourceId=${resourceId}${orderIdParam}${dateParam}`,
    );
  }, [errandId, resourceId, workshiftId, dedicatedChatDate]);

  return (
    <IconButton onClick={handleIconClick} sx={styles.container}>
      <ChatOutlinedIcon
        sx={{ color: unread ? 'primary.main' : 'custom.graphite' }}
      />
      {!!unread && (
        <Box sx={circleStyle}>
          <Typography sx={styles.unread}>{unread}</Typography>
        </Box>
      )}
    </IconButton>
  );
});
