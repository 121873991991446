import { createTypedSelector } from 'store/utils';

export const getManagerIdSelector = createTypedSelector(
  (state) => state.auth.managerId,
);

export const getIsLoggedInSelector = createTypedSelector(
  (state) => state.auth.loggedIn,
);

export const getIsAdminSelector = createTypedSelector(
  (state) => state.auth.isAdmin,
);
