export const styles = {
  itemContainer: {
    width: '270px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderRadius: '24px',
    padding: '8px 16px 8px 0',
    justifyContent: 'space-between',
  },
};
