import { getMuiColor } from 'theme';
import { ResourceColorMappedToMui } from 'types/resource';

export const resourceColorsMappedToMui: ResourceColorMappedToMui = {
  green: getMuiColor('green'),
  blue: getMuiColor('blue'),
  grey: getMuiColor('graphite'),
  red: getMuiColor('red'),
  orange: getMuiColor('main', 'primary'),
  yellow: getMuiColor('yellow'),
  deepGrey: getMuiColor('grey'),
};
