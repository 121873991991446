import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    pt: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',
  },
};
