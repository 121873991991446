import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  stack: {
    overflowY: 'hidden',
    maxHeight: '100%',
  },

  container: {
    mb: '36px',
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },

  text: {
    width: '50%',
  },

  section: {
    height: '80px',
  },
};
