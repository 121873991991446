import React, { memo, useRef, useState } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Card, IconButton } from '@mui/material';
import { createUniqueId } from 'utils/uniqIdUtils';

interface VideoItemProps {
  videoUrl: string;
}

const VideoItem: React.FC<VideoItemProps> = ({ videoUrl }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else {
        // @ts-ignore
        if (videoRef.current?.webkitRequestFullscreen) {
          // @ts-ignore
          videoRef.current?.webkitRequestFullscreen?.();
          // @ts-ignore
        } else if (videoRef.current?.msRequestFullscreen) {
          // @ts-ignore
          videoRef.current?.msRequestFullscreen?.();
        }
      }
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <Card
      sx={{
        width: '126px',
        height: '108px',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
        bgcolor: 'custom.grey',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'scale-down',
          }}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Custom Controls - Show only on hover */}
        {isHovered && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            {/* Play/Pause Button */}
            <IconButton onClick={handlePlayPause} sx={{ color: '#fff' }}>
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>

            {/* Mute/Unmute Button */}
            <IconButton onClick={toggleMute} sx={{ color: '#fff' }}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>

            {/* Fullscreen Button */}
            <IconButton onClick={handleFullScreen} sx={{ color: '#fff' }}>
              <FullscreenIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  );
};

interface FeedbackVideoListProps {
  videoUrl: string[];
}
export const FeedbackVideoList = memo((props: FeedbackVideoListProps) => {
  const { videoUrl } = props;
  return (
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      {videoUrl.map((item) => (
        <VideoItem key={createUniqueId()} videoUrl={item} />
      ))}
    </Box>
  );
});
