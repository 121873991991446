import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  bottomButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    pt: '10px',
  },

  button: {
    width: '134px',
    height: '40px',
    textTransform: 'lowercase',
  },
};
