import { FC, useCallback, useMemo } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateShowResourcesSettingsFilter } from 'store/reducers/settingsSlice';
import {
  getSearchResourcesForSettingsByName,
  getShowResourcesSettingsFilter,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const ResourcesForSettingFilterButton: FC = () => {
  const showFilter = useAppSelector(getShowResourcesSettingsFilter);
  const search = useAppSelector(getSearchResourcesForSettingsByName);
  const isAnyFilterFilled = useMemo(() => !!search, [search]);
  const dispatch = useAppDispatch();

  const handlePress = useCallback(() => {
    dispatch(updateShowResourcesSettingsFilter(!showFilter));
  }, [showFilter]);

  return (
    <LightTooltip
      title={
        isAnyFilterFilled
          ? t`You can change resource name filters`
          : t`You can set resource name filters`
      }
      arrow
      placement="top"
    >
      <IconButton sx={styles.button} onClick={handlePress}>
        {isAnyFilterFilled ? (
          <FilterAltIcon sx={styles.icon} />
        ) : (
          <FilterAltOutlinedIcon sx={styles.icon} />
        )}
      </IconButton>
    </LightTooltip>
  );
};
