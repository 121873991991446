import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    fontSize: '12px',
  },

  button: {
    color: 'custom.graphite',
    fontSize: 'inherit',
    '&:hover': {
      color: 'custom.grey',
      bgcolor: 'transparent',
    },
  },

  active: {
    color: 'primary.main',
  },

  menuItem: {
    width: '160px',
    fontSize: 'inherit',
    letterSpacing: 'inherit',
    fontWeight: 'inherit',
  },
};
