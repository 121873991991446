import { FC } from 'react';
import { Language } from '@mui/icons-material';
import { Box, BoxProps, SxProps } from '@mui/material';
import { getLocale, Locale, setLocale } from 'utils/i18nInit';

import { DropdownMenu } from 'components/ui/DropdownMenu';

interface MenuOption {
  value: Locale;
  label: string;
}

const options: MenuOption[] = [
  { value: 'sv', label: 'Swedish' },
  { value: 'en', label: 'English' },
];

interface LocalizationMenuProps extends BoxProps {
  menuStyles?: SxProps;
}

export const LocalizationMenu: FC<LocalizationMenuProps> = ({ ...rest }) => {
  const locale = getLocale();

  const selectHandler = (value: string | number) => {
    setLocale(value as Locale);
  };

  return (
    <Box {...rest}>
      <DropdownMenu
        onSelect={selectHandler}
        options={options}
        selectedOptionValue={locale}
        iconButtonContent={<Language />}
      />
    </Box>
  );
};
