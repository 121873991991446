import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  packagesAmount: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    mb: '8px',
  },

  list: { p: 0 },

  divider: {
    margin: '16px 0',
  },

  fieldNameText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: 'custom.graphite',
  },

  fieldValueText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: 'custom.black',
  },
};
