import { FC } from 'react';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';

import { styles } from './styles';

interface OrdersSectionSkeletonProps {
  quantity?: number;
}

export const OrdersSectionSkeleton: FC<OrdersSectionSkeletonProps> = ({
  quantity,
}) => {
  const content = Array.from(Array(quantity)).map((_e, index) => (
    <Grid container columns={42} sx={styles.container} key={index}>
      <Skeleton sx={{ ml: '2px' }} variant="circular" width={32} height={32} />
      <Grid item mobile={20}>
        <Typography variant="subtitle1">
          <Skeleton variant="text" />
        </Typography>
        <Typography variant="h3">
          <Skeleton variant="text" />
        </Typography>
      </Grid>

      <Grid item mobile={7}>
        <Typography variant="body2">
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Typography>
      </Grid>

      <Grid item mobile={7} sx={styles.buttons}>
        <Skeleton variant="rounded" width={25} height={25} />
        <Skeleton variant="rounded" width={22} height={22} />
      </Grid>
    </Grid>
  ));
  return <Stack sx={styles.stack}>{content}</Stack>;
};
