function wrap(n: number, min: number, max: number) {
  const d = max - min;
  const w = ((((n - min) % d) + d) % d) + min;
  return w === min ? max : w;
}

// const earthRadius = 6371008.8;

class LngLat {
  lng;
  lat;

  constructor(lng: number, lat: number) {
    if (isNaN(lng) || isNaN(lat)) {
      throw new Error(`Invalid LngLat object: (${lng}, ${lat})`);
    }
    this.lng = +lng;
    this.lat = +lat;
    if (this.lat > 90 || this.lat < -90) {
      throw new Error(
        'Invalid LngLat latitude value: must be between -90 and 90',
      );
    }
  }

  wrap() {
    return new LngLat(wrap(this.lng, -180, 180), this.lat);
  }

  // toArray() {
  //   return [this.lng, this.lat];
  // }

  toString() {
    return `LngLat(${this.lng}, ${this.lat})`;
  }

  // distanceTo(lngLat) {
  //   const rad = Math.PI / 180;
  //   const lat1 = this.lat * rad;
  //   const lat2 = lngLat.lat * rad;
  //   const a =
  //     Math.sin(lat1) * Math.sin(lat2) +
  //     Math.cos(lat1) * Math.cos(lat2) * Math.cos((lngLat.lng - this.lng) * rad);
  //
  //   const maxMeters = earthRadius * Math.acos(Math.min(a, 1));
  //   return maxMeters;
  // }
  //
  // toBounds(radius) {
  //   const earthCircumferenceInMetersAtEquator = 40075017;
  //   const latAccuracy = (360 * radius) / earthCircumferenceInMetersAtEquator,
  //     lngAccuracy = latAccuracy / Math.cos((Math.PI / 180) * this.lat);
  //
  //   return new LngLatBounds(
  //     new LngLat(this.lng - lngAccuracy, this.lat - latAccuracy),
  //     new LngLat(this.lng + lngAccuracy, this.lat + latAccuracy),
  //   );
  // }

  static convert(input: any) {
    if (input instanceof LngLat) {
      return input;
    }
    if (Array.isArray(input) && (input.length === 2 || input.length === 3)) {
      return new LngLat(Number(input[0]), Number(input[1]));
    }
    if (!Array.isArray(input) && typeof input === 'object' && input !== null) {
      return new LngLat(
        // flow can't refine this to have one of lng or lat, so we have to cast to any
        Number('lng' in input ? input.lng : input.lon),
        Number(input.lat),
      );
    }
    throw new Error(
      '`LngLatLike` argument must be specified as a LngLat instance, an object {lng: <lng>, lat: <lat>}, an object {lon: <lng>, lat: <lat>}, or an array of [<lng>, <lat>]',
    );
  }
}

class LngLatBounds {
  _ne: any;
  _sw: any;

  constructor(sw?: any, ne?: any) {
    if (!sw) {
      // noop
    } else if (ne) {
      this.setSouthWest(sw).setNorthEast(ne);
    } else if (sw.length === 4) {
      this.setSouthWest([sw[0], sw[1]]).setNorthEast([sw[2], sw[3]]);
    } else {
      this.setSouthWest(sw[0]).setNorthEast(sw[1]);
    }
  }

  setNorthEast(ne: any) {
    this._ne =
      ne instanceof LngLat ? new LngLat(ne.lng, ne.lat) : LngLat.convert(ne);
    return this;
  }

  setSouthWest(sw: any) {
    this._sw =
      sw instanceof LngLat ? new LngLat(sw.lng, sw.lat) : LngLat.convert(sw);
    return this;
  }

  extend(obj: any): any {
    const sw = this._sw,
      ne = this._ne;
    let sw2, ne2;

    if (obj instanceof LngLat) {
      sw2 = obj;
      ne2 = obj;
    } else if (obj instanceof LngLatBounds) {
      sw2 = obj._sw;
      ne2 = obj._ne;

      if (!sw2 || !ne2) return this;
    } else {
      if (Array.isArray(obj)) {
        if (obj.length === 4 || obj.every(Array.isArray)) {
          return this.extend(LngLatBounds.convert(obj));
        } else {
          return this.extend(LngLat.convert(obj));
        }
      }
      return this;
    }

    if (!sw && !ne) {
      this._sw = new LngLat(sw2.lng, sw2.lat);
      this._ne = new LngLat(ne2.lng, ne2.lat);
    } else {
      sw.lng = Math.min(sw2.lng, sw.lng);
      sw.lat = Math.min(sw2.lat, sw.lat);
      ne.lng = Math.max(ne2.lng, ne.lng);
      ne.lat = Math.max(ne2.lat, ne.lat);
    }

    return this;
  }

  // getCenter() {
  //   return new LngLat(
  //     (this._sw.lng + this._ne.lng) / 2,
  //     (this._sw.lat + this._ne.lat) / 2,
  //   );
  // }
  //
  // getSouthWest() {
  //   return this._sw;
  // }
  //
  // getNorthEast() {
  //   return this._ne;
  // }

  // getNorthWest() {
  //   return new LngLat(this.getWest(), this.getNorth());
  // }

  // getSouthEast() {
  //   return new LngLat(this.getEast(), this.getSouth());
  // }
  //
  // getWest() {
  //   return this._sw.lng;
  // }
  //
  // getSouth() {
  //   return this._sw.lat;
  // }
  //
  // getEast() {
  //   return this._ne.lng;
  // }
  //
  // getNorth() {
  //   return this._ne.lat;
  // }
  //
  // toArray() {
  //   return [this._sw.toArray(), this._ne.toArray()];
  // }
  //
  toString() {
    return `LngLatBounds(${this._sw.toString()}, ${this._ne.toString()})`;
  }

  // isEmpty() {
  //   return !(this._sw && this._ne);
  // }

  // contains(lnglat: any) {
  //   const { lng, lat } = LngLat.convert(lnglat);
  //
  //   const containsLatitude = this._sw.lat <= lat && lat <= this._ne.lat;
  //   let containsLongitude = this._sw.lng <= lng && lng <= this._ne.lng;
  //   if (this._sw.lng > this._ne.lng) {
  //     // wrapped coordinates
  //     containsLongitude = this._sw.lng >= lng && lng >= this._ne.lng;
  //   }
  //
  //   return containsLatitude && containsLongitude;
  // }

  static convert(input: any) {
    if (!input || input instanceof LngLatBounds) return input;
    return new LngLatBounds(input);
  }
}

export default LngLatBounds;
