import { isEmpty } from 'lodash';

export const setCurrentResourceAsFirst = (
  settingsList: string[],
  currentResourceName: string,
) => {
  if (!settingsList || isEmpty(settingsList)) {
    return [];
  }

  const currentResource = settingsList.find(
    (resourceName) => resourceName === currentResourceName,
  );

  if (!currentResource) {
    return settingsList;
  }

  return [
    currentResourceName,
    ...settingsList.filter(
      (resourceName) => resourceName !== currentResourceName,
    ),
  ];
};
