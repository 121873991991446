import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'constants/common';
import { ONLY_CURRENT_WORKSHIFT } from 'constants/urlParams';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { removeJustSelectedOrderId } from 'store/reducers/orderSlice';
import { removeJustSelectedResourceId } from 'store/reducers/resourceSlice';
import { removeJustCurrentCommonDate } from 'store/reducers/settingsSlice';
import { removeJustSelectedWorkshiftId } from 'store/reducers/workshiftSlice';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';

interface RemoveSelectedValuesOptions {
  resource?: boolean;
  order?: boolean;
  workshift?: boolean;
  commonDate?: boolean;
}
export const useRemoveSelectedValues = (
  options: RemoveSelectedValuesOptions = {},
) => {
  const {
    resource = true,
    order = true,
    workshift = true,
    commonDate = true,
  } = options;

  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return () => {
    navigate(
      `${APP_ROUTES.root}?${ONLY_CURRENT_WORKSHIFT}=${onlyCurrentWorkshift}`,
    );
    resource && dispatch(removeJustSelectedResourceId());
    order && dispatch(removeJustSelectedOrderId());
    workshift && dispatch(removeJustSelectedWorkshiftId());
    commonDate && dispatch(removeJustCurrentCommonDate());
  };
};
