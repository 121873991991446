import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    color: 'custom.red',
  },
};
