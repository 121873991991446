export const findPageNumber = (
  currIndex: number,
  total: number,
  pageSize: number,
) => {
  if (currIndex > total || currIndex < 1 || !total || !pageSize) {
    return 0;
  }

  let maxPageIndex = 0;
  let count = 0;

  do {
    count++;
    maxPageIndex = pageSize * count;

    if (currIndex <= maxPageIndex) {
      return count;
    }
  } while (maxPageIndex <= total);

  return 0;
};
