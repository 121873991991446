import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { styles } from './style';

interface UnreadLabelProps {
  qty?: number;
}

export const UnreadLabel: FC<UnreadLabelProps> = ({ qty = 0 }) => {
  const number = qty > 99 ? '99+' : qty + '';

  const labelStyle =
    qty > 99
      ? ({ ...styles.labelCircle, ...styles.horizontalPad } as SxProps)
      : styles.labelCircle;

  return (
    <Box sx={{ ...labelStyle, animation: 'unread_blinker 2s linear infinite' }}>
      {!!qty && (
        <Typography variant="body2" color="inherit">
          {number}
        </Typography>
      )}
    </Box>
  );
};
