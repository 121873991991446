import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  repliesContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    border: '1px solid #EBEFF2',
    borderRadius: '6px',
  },
  reply: {
    padding: '8px',
    '&:not(:last-of-type)': {
      mr: '5px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  borderedReply: {
    borderRadius: '6px',
    borderColor: 'custom.greyBlue3',
    bgcolor: 'custom.greyLightBlue2',
  },
};
