import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  TextField,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeAllFilters,
  setCityFilters,
  setCountryFilters,
  setSearchFilters,
} from 'store/reducers/companySlice';
import {
  getCityFilterSelector,
  getCountryFilterSelector,
  getIsAnyFilterFilled,
  getSearchFilterSelector,
  getShowFiltersSelector,
} from 'store/selectors/company';
import { t } from 'ttag';
import { Subcontractor } from 'types/api';

import { CompanySectionListItem } from '../CompanySectionListItem';

import { styles } from './styles';

interface CompanySectionListProps {
  companiesList: Subcontractor[];
  selectedCompanyId: string | null;
  setSelectedCompanyId: (id: string) => void;
  selectAllCompanies: () => void;
  cities: string[];
  countries: string[];
}

export const CompanySectionList: FC<CompanySectionListProps> = ({
  companiesList,
  selectedCompanyId,
  setSelectedCompanyId,
  selectAllCompanies,
  cities,
  countries,
}) => {
  const showFilter = useAppSelector(getShowFiltersSelector);
  const dispatch = useAppDispatch();
  const country = useAppSelector(getCountryFilterSelector);
  const city = useAppSelector(getCityFilterSelector);
  const search = useAppSelector(getSearchFilterSelector);
  const isAnyFilterFilled = useAppSelector(getIsAnyFilterFilled);

  const handleResetPress = useCallback(() => {
    dispatch(removeAllFilters());
  }, []);

  const citiesOptions = useMemo(() => {
    return cities.map((label) => ({ label, value: label }));
  }, [cities]);

  const countriesOptions = useMemo(() => {
    return countries.map((label) => ({ label, value: label }));
  }, [countries]);

  const handleSearchCloseClick = useCallback(() => {
    dispatch(setSearchFilters(''));
  }, []);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      dispatch(setSearchFilters(e.target.value));
    },
    [],
  );

  const companiesListItems = useMemo(
    () =>
      companiesList.map((company) => (
        <CompanySectionListItem
          icon={
            <BusinessIcon
              sx={{
                color: company.is_active
                  ? 'custom.blueLight2'
                  : 'custom.greyLight',
              }}
            />
          }
          key={company.id}
          id={company.id}
          label={company.name}
          country={company.country}
          city={company.city}
          labelStyle={company.is_active ? {} : { color: 'custom.greyLight' }}
          selected={selectedCompanyId === company.id}
          onClick={setSelectedCompanyId}
        />
      )),
    [companiesList, selectedCompanyId],
  );

  return (
    <List sx={styles.list}>
      {showFilter && (
        <Box sx={styles.filtersBlock}>
          <TextField
            sx={styles.input}
            // inputRef={inputRef}
            onChange={handleSearchChange}
            autoComplete="new-password"
            value={search}
            fullWidth
            autoFocus
            label={t`Search`}
            placeholder={t`Search by company, country or city`}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ marginRight: '16px', marginLeft: '16px' }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearchCloseClick}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type="text"
          />
          <Box sx={{ height: '16px' }} />
          <Autocomplete
            sx={styles.autocomplete}
            options={countriesOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_, selectedOption) => {
              const { value } = selectedOption || {};
              dispatch(setCountryFilters(value as string));
            }}
            value={country ? { value: country, label: country } : null}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={t`Country`}
                autoComplete="new-password"
              />
            )}
          />

          <Box sx={{ height: '16px' }} />

          <Autocomplete
            sx={styles.autocomplete}
            options={citiesOptions}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_, selectedOption) => {
              const { value } = selectedOption || {};
              dispatch(setCityFilters(value as string));
            }}
            value={city ? { value: city, label: city } : null}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={t`City`}
                autoComplete="new-password"
              />
            )}
          />

          <Box sx={{ height: '16px' }} />

          <Button
            sx={styles.button}
            disabled={!isAnyFilterFilled}
            variant="contained"
            onClick={handleResetPress}
          >
            {t`Reset all`}
          </Button>
        </Box>
      )}

      <CompanySectionListItem
        key="All-Companies-first-item"
        icon={<ApartmentIcon sx={{ color: 'custom.blueLight2' }} />}
        label="All Companies"
        selected={!selectedCompanyId}
        onClick={selectAllCompanies}
      />
      {companiesListItems}
    </List>
  );
};
