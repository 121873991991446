import { FC, useEffect, useMemo } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import {
  AGE_CONTROL_OPTIONS,
  PROOF_TYPE_OPTIONS,
} from 'constants/editOrderOptions';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { useIsManualOrderSetting } from 'hooks/useIsManualOrderSetting';
import { useOrdersList } from 'hooks/useOrdersList';
import { t } from 'ttag';
import { isProofTypeSupportsAgeControl } from 'utils/changeScreenUtils';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldDatePicker } from 'components/common/FieldDatePicker';
import { FieldInput } from 'components/common/FieldInput';
import { FieldTimePicker } from 'components/common/FieldTimePicker';

export const OrderDetailsTabPickupDataSection: FC = () => {
  const { formik, isCreateScreen } = useChangeScreenContext();
  const { isManualOrderSetting } = useIsManualOrderSetting();
  const { ordersList } = useOrdersList();

  const { values, errors, setFieldValue, touched } = formik;
  const { terminalPickType, consumerPickType, businessPickType } =
    useEditScreenErrandTypes();

  const pickupProofTypeOptions = useMemo(() => {
    // if (consumerPickType || businessPickType) {
    //   return PROOF_TYPE_OPTIONS.slice(0, 8);
    // }

    return PROOF_TYPE_OPTIONS;
  }, [consumerPickType, businessPickType]);

  useEffect(() => {
    if (isCreateScreen && !isManualOrderSetting) {
      if (!ordersList || !ordersList?.length) {
        setFieldValue('pickOrderNumber', 1);
      } else {
        const arrayOrderNumbers = ordersList.map(
          (order) => order?.orderNumber || 0,
        );
        const maxOrderNumber = Math.max(...arrayOrderNumbers);
        setFieldValue('pickOrderNumber', maxOrderNumber + 1);
      }
    }
  }, [isCreateScreen, ordersList, isManualOrderSetting]);

  return (
    <ChangeScreenSection label={t`Pick up data`}>
      <Grid container columns={2} rowGap={4} spacing={2}>
        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="pickEarliest"
            label={t`Earliest date`}
            // maxDate={values.deliveryEarliest}
            required
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="pickEarliest"
            label={t`Earliest time`}
            // maxTime={values.deliveryEarliest}
            required
          />
        </Grid>

        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="pickLatest"
            label={t`Latest date`}
            // maxDate={values.deliveryEarliest}
            required
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="pickLatest"
            label={t`Latest time`}
            // maxTime={values.deliveryEarliest}
            required
          />
        </Grid>

        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="pickEta"
            label={t`ETA date`}
            // maxDate={values.deliveryEarliest}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="pickEta"
            label={t`ETA time`}
            // maxTime={values.deliveryEarliest}
          />
        </Grid>
        <Grid item mobile={2}>
          <Grid container columns={5} spacing={2}>
            <Grid item mobile={3}>
              <Autocomplete
                disabled={terminalPickType}
                options={pickupProofTypeOptions || null}
                onChange={(_, selectedOption) => {
                  const { value } = selectedOption || {};
                  setFieldValue('pickProofType', value);
                  if (
                    typeof value !== 'undefined' &&
                    !isProofTypeSupportsAgeControl(value)
                  ) {
                    setFieldValue('pickAgeControl', null);
                  }
                }}
                value={
                  PROOF_TYPE_OPTIONS.find(
                    (option) => option.value === values.pickProofType,
                  ) || null
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t`Proof of pickup method`}
                    name="pickProofMethod"
                    placeholder={t`Enter proof type name`}
                    error={touched.pickProofType && !!errors.pickProofType}
                    helperText={touched.pickProofType && errors.pickProofType}
                    required={!terminalPickType}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={2}>
              <Autocomplete
                options={AGE_CONTROL_OPTIONS || null}
                onChange={(_, selectedOption) => {
                  setFieldValue('pickAgeControl', selectedOption?.value);
                }}
                value={
                  (values.pickProofType &&
                    AGE_CONTROL_OPTIONS.find(
                      (option) => option.value === values.pickAgeControl,
                    )) ||
                  null
                }
                fullWidth
                disabled={!isProofTypeSupportsAgeControl(values.pickProofType)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t`Age control`}
                    name="pickAgeControl"
                    placeholder={t`Choose age`}
                    error={!!errors.pickAgeControl}
                    helperText={errors.pickAgeControl}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="pickOrderNumber"
            label={t`Order number`}
            placeholder={t`Enter order number`}
            type="number"
            selectOnFocus
            required
            disabled={!isManualOrderSetting}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
