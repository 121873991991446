import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    display: 'flex',
    p: 0,
  },

  listItem: {
    width: 'initial',
  },

  link: {
    fontWeight: 'bold',
    fontSize: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: 'custom.graphite',
    '&:hover': {
      color: 'custom.grey',
    },
    cursor: 'pointer',
  },

  active: {
    color: 'primary.main',
    '&:hover': {
      color: 'primary.main',
    },
  },
};
