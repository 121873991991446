import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { STATUS_TYPE_OPTIONS } from 'constants/editOrderOptions';
import { Field } from 'formik';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getResourcesListSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { SelectOption } from 'types/common';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';

export const OrderDetailsTabStatusSection: FC = () => {
  const resourcesList = useAppSelector(getResourcesListSelector);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const currentWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  const resourcesSelectOptionsList: SelectOption[] = useMemo(() => {
    return resourcesList.map((resource) => {
      if (resource.id === selectedResourceId) {
        return {
          label: resource.number,
          value: currentWorkshiftId as string,
        };
      }
      return {
        label: resource.number,
        value: resource.work_shifts[0]?.id,
      };
    });
  }, [resourcesList, selectedResourceId, currentWorkshiftId]);

  return (
    <ChangeScreenSection label={t`Status`}>
      <Grid container columns={1} rowGap={4}>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={resourcesSelectOptionsList}
            name="assignedResource"
            label={t`Assigned resource`}
            placeholder={t`Enter resource to assign`}
            required
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldAutocomplete}
            options={STATUS_TYPE_OPTIONS}
            name="status"
            label={t`Status`}
            placeholder={t`Enter status`}
            required
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
