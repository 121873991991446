import React, { FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { MapPoint } from 'hooks/useResourceRouteData';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { OrderStatuses } from 'types/orders';

import { MapUnreadMessagesByPriority } from 'components/ui/MapUnreadMessagesByPriority';

import { styles } from './styles';

interface MapPointIfoProps {
  point: MapPoint;
  closePopup: () => void;
}
export const MapPointInfo: FC<MapPointIfoProps> = (props) => {
  const { point, closePopup } = props;
  const { popupSubtitle, popupTitle, popupData, orderData } = point;
  const dispatch = useAppDispatch();

  const handleClick = useCallback(
    (id: string) => {
      closePopup();
      dispatch(setSelectedOrderId(id));
    },
    [closePopup, dispatch],
  );

  const multipleOrders = useMemo(() => {
    if (!popupData && !orderData) {
      return null;
    }

    return popupData?.map((order, idx) => {
      const getBgcolor = (status: OrderStatuses) => {
        switch (status) {
          case OrderStatuses.DONE:
            return 'custom.green';
          case OrderStatuses.FAILED:
            return 'custom.red';
          case OrderStatuses.PENDING:
          default:
            return 'custom.grey';
        }
      };

      const unreadMessages = orderData?.[idx].unreadMessages;

      return (
        <Box
          sx={styles.orderRow}
          key={order.id}
          onClick={() => handleClick(order.id)}
        >
          <Box sx={{ ...styles.circle, bgcolor: getBgcolor(order.status) }} />
          <Typography sx={styles.name}>{order.name}</Typography>
          {unreadMessages && (
            <MapUnreadMessagesByPriority
              unread={unreadMessages.count}
              priority={unreadMessages.priority}
              errandId={order.id}
            />
          )}
        </Box>
      );
    });
  }, [popupData, handleClick, orderData]);

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h5">{popupTitle}</Typography>
      <Typography sx={styles.subtitle}>{popupSubtitle}</Typography>
      <Box sx={styles.divider} />
      <Box>{multipleOrders}</Box>
    </Box>
  );
};

export default MapPointInfo;
