import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { t } from 'ttag';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const CreateCompanyrButton: FC = () => {
  return (
    <LightTooltip title={t`Create a new company`} arrow placement="top">
      <span>
        <IconButton sx={styles.button}>
          <QueryParamsLink route={APP_ROUTES.createCompany}>
            <AddIcon sx={styles.icon} color="primary" />
          </QueryParamsLink>
        </IconButton>
      </span>
    </LightTooltip>
  );
};
