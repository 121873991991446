import { FC } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'ttag';
import { OrderTime } from 'types/orders';
import { formatMillisecondsToTimestamp } from 'utils/timeUtils';

import { styles } from './styles';

const getDifferenceTimeData = (differenceTime?: number) => {
  if (typeof differenceTime !== 'number') {
    return;
  }

  const timestamp = formatMillisecondsToTimestamp(differenceTime);

  const [color, sign] =
    timestamp === '0 min'
      ? ['custom.green', '']
      : differenceTime >= 0
      ? ['custom.green', '+']
      : ['custom.red', '-'];

  return {
    color,
    label: `${sign}${timestamp}`,
  };
};

interface TimeProps {
  time?: OrderTime;
  isCollapsible?: boolean;
  isOnlyPod?: boolean;
}

export const Time: FC<TimeProps> = ({
  isCollapsible,
  time,
  isOnlyPod = false,
}) => {
  if (!time || isCollapsible) {
    return null;
  }

  const eta = time?.eta;
  const pod = time?.pod;
  const differenceTime = getDifferenceTimeData(time?.difference);

  const etaTime = eta && !isOnlyPod && (
    <Typography variant="body2" sx={styles.timeContainer}>
      <Box component="span">{t`ETA`}</Box>
      &nbsp;
      <Box component="span" sx={styles.time}>
        {eta}
      </Box>
    </Typography>
  );

  const podTime = pod && (
    <Typography variant="body2" sx={styles.timeContainer}>
      <Box component="span">{t`POD`}</Box>
      &nbsp;
      <Box component="span" sx={styles.time}>
        {pod}
      </Box>
    </Typography>
  );

  return (
    <>
      {etaTime}
      {podTime}
      {!isOnlyPod && (
        <Typography sx={{ ...styles.delayTime, color: differenceTime?.color }}>
          {differenceTime?.label}
        </Typography>
      )}
    </>
  );
};
