import React, { FC } from 'react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { IconButton } from '@mui/material';

import { styles } from './styles';

interface SendButtonProps {
  onClick(): void;
  disabled?: boolean;
}

export const SendButton: FC<SendButtonProps> = (props) => {
  return (
    <IconButton sx={styles.editButton} {...props}>
      <SendOutlinedIcon />
    </IconButton>
  );
};
