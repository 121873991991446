import { FC } from 'react';
import { Box } from '@mui/material';

import { styles } from 'components/screens/HomeScreen/styles';

export const OrdersAndDetailsWrapper: FC<{
  isWrap: boolean;
  children: any;
}> = ({ isWrap, children }) => {
  if (!isWrap) {
    return children;
  }

  return <Box sx={styles.detailsUnderOrdersBlock}>{children}</Box>;
};
