import { t } from 'ttag';
import { ResourceReportOrdering } from 'types/deviceInfoReport';

export type DeviceResourceHeadCellTitle =
  | 'resource_number'
  | 'android_version'
  | 'device_info'
  | 'login_time'
  | 'logout_time';

export type HeadCell = {
  id: DeviceResourceHeadCellTitle | ResourceReportOrdering;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  isSorted: boolean;
};

export const deviceResourceHeadCells: readonly HeadCell[] = [
  {
    id: 'resource_number',
    numeric: false,
    disablePadding: true,
    label: t`RESOURCE`,
    isSorted: true,
  },
  {
    id: 'login_time',
    numeric: false,
    disablePadding: false,
    label: t`LOG IN DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'logout_time',
    numeric: false,
    disablePadding: false,
    label: t`LOG OUT DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'device_info',
    numeric: false,
    disablePadding: false,
    label: t`DEVICE`,
    isSorted: false,
  },
  {
    id: 'android_version',
    numeric: false,
    disablePadding: true,
    label: t`ANDROID`,
    isSorted: false,
  },
];

export const strWithoutMinus = (str: string | null) => {
  if (!str) {
    return null;
  }

  if (str.startsWith('-')) {
    return str.slice(1);
  }

  return str;
};
