import { FC, MouseEvent, useCallback, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  Typography,
} from '@mui/material';
import { t } from 'ttag';
import { OrderPackage } from 'types/orders';

import { InnerSectionTextItem } from 'components/common/InnerSectionTextItem';

import { ParcelDataImagesList } from './components/ParcelDataImagesList';
import { styles } from './styles';

interface DetailsSectionInnerParcelDataItemProps extends OrderPackage {
  number: number;
}

export const DetailsSectionInnerParcelDataItem: FC<
  DetailsSectionInnerParcelDataItemProps
> = ({ data, barcode, number }) => {
  const {
    height = 0,
    length = 0,
    parcel_type: parcelType,
    photo_urls: photoUrls,
    weight,
    width = 0,
    comment,
  } = data;

  const volume = useMemo(
    () =>
      Math.floor(Number(height) * Number(width) * Number(length) * 1000) / 1000,
    [height, width, length],
  );

  const handleBarcodeClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );

  const barcodeItem = barcode ? (
    <Box sx={styles.barcode} onClick={handleBarcodeClick}>
      <Typography variant="subtitle2">{barcode}</Typography>
    </Box>
  ) : (
    <Typography variant="subtitle2">{t`Packet ${number}`}</Typography>
  );
  const parcelTypeItem = !!parcelType && (
    <Typography sx={styles.parcelType}>{parcelType}</Typography>
  );
  const volumeItem = !!volume && (
    <InnerSectionTextItem
      label={t`Volume`}
      value={t`${volume} m${'\u{000B3}'}`}
    />
  );
  const weightItem = !!weight && (
    <InnerSectionTextItem label={t`Weight`} value={t`${weight} kg`} />
  );
  const widthItem = !!width && (
    <InnerSectionTextItem label={t`Width`} value={t`${width} m`} />
  );
  const heightItem = !!height && (
    <InnerSectionTextItem label={t`Height`} value={t`${height} m`} />
  );
  const lengthItem = !!length && (
    <InnerSectionTextItem label={t`Length`} value={t`${length} m`} />
  );
  const photosItem = !!photoUrls?.length && (
    <ParcelDataImagesList imageUrls={photoUrls} />
  );
  const commentItem = comment && (
    <Typography sx={styles.commentItem}>{comment}</Typography>
  );

  const areDetailItemsExist =
    volumeItem ||
    weightItem ||
    widthItem ||
    heightItem ||
    lengthItem ||
    photosItem ||
    commentItem;

  return (
    <ListItem sx={styles.listItem}>
      <Accordion disableGutters elevation={0} sx={styles.accordion}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={
                areDetailItemsExist
                  ? { color: 'custom.graphite' }
                  : { color: 'custom.white' }
              }
            />
          }
          sx={styles.accordionSummary}
        >
          <Box sx={styles.summary}>
            {barcodeItem}
            {parcelTypeItem}
          </Box>
        </AccordionSummary>
        {areDetailItemsExist && (
          <AccordionDetails sx={styles.accordionDetails}>
            {volumeItem}
            {weightItem}
            {lengthItem}
            {widthItem}
            {heightItem}
            {photosItem}
            {commentItem}
          </AccordionDetails>
        )}
      </Accordion>
    </ListItem>
  );
};
