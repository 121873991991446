import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  input: {
    width: '519px',
  },
  list: {
    height: '100%',
    p: 0,
    overflowY: 'auto',
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    paddingBottom: '150px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    cursor: 'pointer',
    borderColor: 'custom.greyLight',
    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },
    '&:first-of-type': {
      borderRadius: '6px 6px 0 0',
    },
  },

  selected: {
    bgcolor: 'custom.blueLight',
    '&:hover': {
      bgcolor: 'custom.blueLight',
    },
  },

  icon: {
    mr: '16px',
    fontSize: '20px',
    color: 'custom.blue',
    display: 'flex',
    alignItems: 'center',
  },

  labelCircle: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    bgcolor: 'primary.main',
    margin: '3px',
  },
  headerBlock: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomColor: 'custom.greyLight',
    borderBottomStyle: 'solid',
  },

  pressableBlock: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  headerName: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    marginLeft: '8px',
    color: 'custom.black',
  },

  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  headerDescriptionBlock: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  descriptionContent: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    color: 'custom.black',
    marginTop: '16px',
  },

  noResources: {
    marginTop: '24px',
    textAlign: 'center',
  },
};
