import { useEffect, useMemo, useRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  CLOSED_BY_APP,
  UNASSIGNED_TASKS,
  WEBSOCKET_URL,
} from 'constants/common';
import { MESSAGE_EVENT_TYPE } from 'constants/messageEventTypes';
import { OPTOMIZATION_TYPE } from 'constants/optimizationTypes';
import { format } from 'date-fns';
import { useChatMessages } from 'hooks/useChatMessages';
import { useOrdersList } from 'hooks/useOrdersList';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import { store } from 'store';
import {
  apiSlice,
  useCommonOptimizationSettingsQuery,
  useResourcesSettingsQuery,
  useSubcontractorsQuery,
  useTerminalScansChartQuery,
} from 'store/api/apiSlice';
import {
  useChatByResourceIdQuery,
  useChatPriorityQuery,
  useDedicatedChatQuery,
  useUnreadMessagesByResourceQuery,
  useUnreadMessagesForMapByResourceQuery,
} from 'store/api/chatApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateQuickReplyByMessageId } from 'store/reducers/chatSlice';
import {
  updateResourceColor,
  updateResourceCoordinates,
  updateResourceGroupsNames,
} from 'store/reducers/resourceSlice';
import {
  resetCalcOptimization,
  setBlockedTimeGapButtonByResourceId,
  setToast,
  setUnblockedTimeGapButtonByResourceId,
} from 'store/reducers/settingsSlice';
import {
  getIsAdminSelector,
  getIsLoggedInSelector,
} from 'store/selectors/auth';
import {
  getDedicatedChatDateSelector,
  getDedicatedChatPriorityFilters,
  getDedicatedChatResourcesFilters,
  getDedicatedChatResourcesGroups,
} from 'store/selectors/chat';
import {
  getCityFilterSelector,
  getCountryFilterSelector,
  getSearchFilterSelector,
} from 'store/selectors/company';
import { getSelectedOrderIdSelector } from 'store/selectors/order';
import {
  getResourcesListSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getIsDedicatedChatScreen,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import {
  ChangeResourceColorEventApi,
  ChangeShipmentStatusEventApi,
  Chat,
  DriverPositionApi,
  MessageApi,
  OptimizationErrorApi,
  PackageDataUpdateEventApi,
  ProofDataUpdateEventApi,
  ResourceInGroupChangedEventApi,
  TerminalScansWebsocketApi,
  UpdateChatPriorityApi,
} from 'types/api';

const closeParams: [number, string] = [1000, CLOSED_BY_APP];

function Websocket() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(getIsLoggedInSelector);
  const isAdmin = useAppSelector(getIsAdminSelector);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedOrderId = useAppSelector(getSelectedOrderIdSelector);
  const currentClient = useRef<WebSocket | null>(null);
  const search = useAppSelector(getSearchFilterSelector);
  const country = useAppSelector(getCountryFilterSelector);
  const city = useAppSelector(getCityFilterSelector);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);
  const resources = useAppSelector(getResourcesListSelector);
  const resourceName = useMemo(() => {
    if (!selectedResourceId) {
      return null;
    }

    const foundedResource = resources?.find(
      (resource) => resource.id === selectedResourceId,
    );

    if (!foundedResource) {
      return null;
    }

    return foundedResource.number;
  }, [resources, selectedResourceId]);
  const { refetch: refetchAllRouteData } =
    useUnreadMessagesForMapByResourceQuery(
      {
        resource_name: resourceName,
      },
      { skip: !resourceName },
    );

  const isDedicatedChatScreen = useAppSelector(getIsDedicatedChatScreen);
  const dedicatedChatResourcesFilters = useAppSelector(
    getDedicatedChatResourcesFilters,
  );
  const dedicatedChatResourcesGroups = useAppSelector(
    getDedicatedChatResourcesGroups,
  );
  const dedicatedChatPriorityFilters = useAppSelector(
    getDedicatedChatPriorityFilters,
  );
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);

  const dedicatedChatDate = useAppSelector(getDedicatedChatDateSelector);
  const { selectedOrder } = useSelectedOrder();

  const { chatMessages } = useChatMessages(selectedOrder);
  const { refetch: refetchChatPriority } = useChatPriorityQuery(
    !selectedWorkshiftId ||
      (!selectedOrder && selectedOrderId) ||
      !chatMessages?.length
      ? skipToken
      : {
          workshiftId: selectedWorkshiftId as string,
          shipmentId: selectedOrder?.shipmentId,
        },
  );

  const { refetch: dedicatedChatRefetch } = useDedicatedChatQuery(
    !isDedicatedChatScreen
      ? skipToken
      : {
          group: dedicatedChatResourcesGroups,
          resource_name: dedicatedChatResourcesFilters,
          priority: dedicatedChatPriorityFilters,
          date: dedicatedChatDate,
        },
  );

  const { refetch: subcontractorsRefetch } = useSubcontractorsQuery(
    isLoggedIn && isAdmin
      ? {
          search,
          country,
          city,
        }
      : skipToken,
  );
  const { refetch: chatMessageRefetch } = useChatByResourceIdQuery(
    {
      resourceId: selectedResourceId as string,
      workshiftId: selectedWorkshiftId as string,
    },
    {
      skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
    },
  );

  const { refetch: terminalScansRefetch } = useTerminalScansChartQuery(
    {
      work_shift_id: selectedWorkshiftId as string,
    },
    {
      skip: !selectedWorkshiftId,
    },
  );

  const { refetch: orderListRefetch } = useOrdersList();

  const preparedCommonDate = useMemo(() => {
    return commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd');
  }, [commonDate]);

  const { refetch: unreadMessagesRefetch } = useUnreadMessagesByResourceQuery(
    {
      date: onlyCurrentWorkshift ? undefined : preparedCommonDate,
    },
    {
      skip: !isLoggedIn,
    },
  );

  const { refetch: resourseSettingsRefetch } = useResourcesSettingsQuery(
    isLoggedIn
      ? { current_resource_id: selectedResourceId || undefined }
      : skipToken,
  );
  const { refetch: globalSettingsRefetch } = useCommonOptimizationSettingsQuery(
    isLoggedIn ? undefined : skipToken,
  );

  const handleMessage = (e: MessageEvent<any>) => {
    const currentResource = store.getState().resource.selectedResourceId;
    const currentWorkShift = getCurrentWorkshifIdSelector(
      store.getState(),
    ) as string;
    const isAdminRole = store.getState().auth.isAdmin;
    const showUnreadInMap = store.getState().mapSettings.showUnreadInMap;
    const isDedicatedChatScreen =
      store.getState().settings.isDedicatedChatScreen;
    const isMapSectionActive = store.getState().settings.isMapSectionActive;

    const message: MessageApi = JSON.parse(e.data);
    const data:
      | DriverPositionApi
      | ChangeShipmentStatusEventApi
      | OptimizationErrorApi
      | ChangeResourceColorEventApi
      | ResourceInGroupChangedEventApi
      | ProofDataUpdateEventApi
      | PackageDataUpdateEventApi
      | Chat
      | TerminalScansWebsocketApi
      | UpdateChatPriorityApi = JSON.parse(JSON.parse(e.data)?.data);

    console.log('EVENT: ', message?.event);
    console.log('Data: ', JSON.stringify(data, null, 2));

    if (isAdminRole) {
      if (
        message?.event === MESSAGE_EVENT_TYPE.subcontractor_deleted ||
        message?.event === MESSAGE_EVENT_TYPE.subcontractor_changed ||
        message?.event === MESSAGE_EVENT_TYPE.new_subcontractor_created
      ) {
        subcontractorsRefetch();
      }

      return;
    }

    if (message?.event === MESSAGE_EVENT_TYPE.driverPositionUpdate) {
      dispatch(updateResourceCoordinates(data as DriverPositionApi));
    }

    if (message?.event === MESSAGE_EVENT_TYPE.resource_color_changed) {
      const { resource_id, color } = data as ChangeResourceColorEventApi;
      dispatch(
        updateResourceColor({ resourceId: resource_id as string, color }),
      );
    }

    if (message?.event === MESSAGE_EVENT_TYPE.block_time_gap_button) {
      const { resource_id } = data as ChangeResourceColorEventApi;
      dispatch(setBlockedTimeGapButtonByResourceId(resource_id as string));
    }

    if (message?.event === MESSAGE_EVENT_TYPE.unblock_time_gap_button) {
      const { resource_id } = data as ChangeResourceColorEventApi;
      dispatch(setUnblockedTimeGapButtonByResourceId(resource_id as string));
    }

    if (message?.event === MESSAGE_EVENT_TYPE.resources_in_group_changed) {
      const { resource_id, groups_names } =
        data as ResourceInGroupChangedEventApi;
      dispatch(
        updateResourceGroupsNames({
          resourceId: resource_id as string,
          groups_names,
        }),
      );
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.shipmentStatusChanged &&
      (data as ChangeShipmentStatusEventApi)?.resource_id === currentResource
    ) {
      orderListRefetch();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.terminal_scan &&
      (data as TerminalScansWebsocketApi)?.work_shift_id === currentWorkShift
    ) {
      terminalScansRefetch();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.proof_data_updated &&
      (data as ProofDataUpdateEventApi)?.resource_id === currentResource
    ) {
      const errandId = (data as ProofDataUpdateEventApi)?.errand_id;
      const proofData = (data as ProofDataUpdateEventApi)?.proof_data;

      dispatch(
        apiSlice.util.updateQueryData(
          'shipmentsByResourceId',
          {
            resourceId: currentResource as string,
            workshiftId: currentWorkShift,
          },
          (shipments) => {
            const pickup = shipments.find(
              ({ pickup_errand_id }) => pickup_errand_id === errandId,
            );
            const delivery = shipments.find(
              ({ delivery_errand_id }) => delivery_errand_id === errandId,
            );

            if (pickup?.pickup_errand) {
              if (pickup.pickup_errand.proof === null) {
                // @ts-ignore
                pickup.pickup_errand.proof = { data: proofData || null };
              } else {
                pickup.pickup_errand.proof.data = proofData || null;
              }
            }

            if (delivery?.delivery_errand) {
              if (delivery.delivery_errand.proof === null) {
                // @ts-ignore
                delivery.delivery_errand.proof = { data: proofData || null };
              } else {
                delivery.delivery_errand.proof.data = proofData || null;
              }
            }
          },
        ),
      );
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.packageUpdate &&
      (data as PackageDataUpdateEventApi)?.resource_id === currentResource
    ) {
      const bookingId = (data as PackageDataUpdateEventApi)?.booking_id;
      const proofData = (data as PackageDataUpdateEventApi)?.proof_data;
      const packageId = (data as PackageDataUpdateEventApi)?.package_id;

      dispatch(
        apiSlice.util.updateQueryData(
          'shipmentsByResourceId',
          {
            resourceId: currentResource as string,
            workshiftId: currentWorkShift,
          },
          (shipments) => {
            const booking = shipments.find(
              ({ booking_id }) => booking_id === bookingId,
            );

            if (booking) {
              if (booking.booking.packages.length) {
                const mappedPackages = booking.booking.packages.map((p) => {
                  if (p.id === packageId) {
                    return {
                      ...p,
                      data: proofData,
                    };
                  }

                  return p;
                });

                booking.booking.packages = mappedPackages;
              }
            }
            // Modify the draft data here
          },
        ),
      );
      // orderListRefetch();
    }

    if (message?.event === MESSAGE_EVENT_TYPE.new_quick_response) {
      const { id, quick_responses } = data as Chat;

      const serializedQuickResponses =
        quick_responses?.map(({ id }) => id) || [];

      dispatch(
        updateQuickReplyByMessageId({
          messageId: id,
          quickReplies: serializedQuickResponses,
        }),
      );
    }

    if (
      (message?.event === MESSAGE_EVENT_TYPE.shipmentUpdate ||
        message?.event === MESSAGE_EVENT_TYPE.packageDelete ||
        message?.event === MESSAGE_EVENT_TYPE.newPackage) &&
      (data as PackageDataUpdateEventApi)?.resource_id === currentResource
    ) {
      orderListRefetch();
    }

    if (message?.event === MESSAGE_EVENT_TYPE.global_settings_changed) {
      globalSettingsRefetch();
    }

    if (message?.event === MESSAGE_EVENT_TYPE.resource_settings_changed) {
      resourseSettingsRefetch();
    }

    if (message?.event === MESSAGE_EVENT_TYPE.routeOptimizationIsDone) {
      dispatch(resetCalcOptimization());

      if ((data as OptimizationErrorApi)?.error) {
        let errorMessage = 'Something wrong happen in optimization flow!';
        const optimizeError = JSON.parse((data as OptimizationErrorApi).error);

        if (optimizeError?.name === OPTOMIZATION_TYPE.cityFlow) {
          errorMessage = JSON.parse(optimizeError?.response)?.error;
        }
        if (optimizeError?.name === OPTOMIZATION_TYPE.hereSDK) {
          const issues = JSON.parse(optimizeError?.response)?.issues;
          errorMessage = issues?.[0]?.message;
        }
        dispatch(
          setToast({
            message: errorMessage,
            severity: 'error',
          }),
        );
      } else {
        dispatch(
          setToast({
            message: t`The route has been optimized.`,
            severity: 'success',
          }),
        );
        orderListRefetch();
      }
    }

    if (message?.event === MESSAGE_EVENT_TYPE.all_messages_read) {
      dedicatedChatRefetch?.();
      unreadMessagesRefetch?.();
      if (
        (data as ChangeShipmentStatusEventApi)?.resource_id ===
          currentResource &&
        isMapSectionActive &&
        showUnreadInMap
      ) {
        refetchAllRouteData?.();
      }
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.newChatMessage &&
      !(data as Chat)?.is_group_message &&
      isDedicatedChatScreen
    ) {
      dedicatedChatRefetch?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.newChatMessage &&
      (data as ChangeShipmentStatusEventApi)?.resource_id === currentResource &&
      isMapSectionActive &&
      showUnreadInMap
    ) {
      refetchAllRouteData?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.chat_priority_update &&
      isDedicatedChatScreen
    ) {
      dedicatedChatRefetch?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.chat_priority_update &&
      !isDedicatedChatScreen &&
      (data as UpdateChatPriorityApi)?.work_shift_id === currentWorkShift
    ) {
      refetchChatPriority?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.new_group_chat_message &&
      isDedicatedChatScreen
    ) {
      dedicatedChatRefetch?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.messageRead &&
      isDedicatedChatScreen
    ) {
      dedicatedChatRefetch?.();
    }

    if (
      message?.event === MESSAGE_EVENT_TYPE.messageRead ||
      message?.event === MESSAGE_EVENT_TYPE.newChatMessage
    ) {
      chatMessageRefetch?.();
      unreadMessagesRefetch?.();
    }
  };

  const connect = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const client = new WebSocket(WEBSOCKET_URL!);
    client.onopen = () => {
      console.log('I AM CONNECTED!');
    };

    client.onclose = (ev) => {
      console.log('closed connection!!!', ev);
      if (ev?.reason === CLOSED_BY_APP) {
        return;
      }
      setTimeout(() => {
        connect();
      }, 3000);
    };

    client.onmessage = handleMessage;

    client.onerror = (er) => {
      // TODO add error popup
      // console.log('trying to reconnect...');
      console.log('WEBSOCKET ERROR:', er);
    };

    return client;
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (!currentClient.current) {
        console.log('I am IN WEBSOCKET USE_EFFECT!!!');
        currentClient.current = connect();
      }
    }

    if (!isLoggedIn) {
      if (currentClient.current) {
        currentClient.current.close(...closeParams);
        currentClient.current = null;
      }
    }

    return () => {
      if (currentClient.current) {
        currentClient.current.close(...closeParams);
        currentClient.current = null;
      }
    };
  }, [isLoggedIn]);

  return null;
}

export default Websocket;
