import React from 'react';
import { Box, Typography } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';

export const EmptyDedicatedChat = () => {
  return (
    <Box sx={styles.borderedBox}>
      <Typography variant="subtitle2" sx={styles.title}>
        {t`You don't have any messages right now`}
      </Typography>
    </Box>
  );
};
