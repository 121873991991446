import { useChangeScreenContext } from './useChangeScreenContext';

export const useEditScreenErrandTypes = () => {
  const { pickType, deliveryType } = useChangeScreenContext();

  const terminalPickType = pickType === 'terminal';
  const consumerPickType = pickType === 'consumer';
  const businessPickType = pickType === 'business';

  const terminalDeliveryType = deliveryType === 'terminal';
  const consumerDeliveryType = deliveryType === 'consumer';
  const businessDeliveryType = deliveryType === 'business';

  return {
    terminalPickType,
    consumerPickType,
    businessPickType,
    terminalDeliveryType,
    consumerDeliveryType,
    businessDeliveryType,
  };
};
