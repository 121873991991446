import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, SxProps, Typography } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';

interface EmptyDnDListProps {
  wrapperStyle?: SxProps;
}
const EmptyDnDList: FC<EmptyDnDListProps> = React.memo(
  ({ wrapperStyle = {} }) => {
    return (
      <Box sx={{ ...styles.container, ...wrapperStyle } as SxProps}>
        <Box sx={styles.addIconBlock}>
          <AddIcon color="primary" />
        </Box>
        <Typography sx={styles.description}>
          {t`Drag and drop the necessary resources from the column on the left.`}
        </Typography>
      </Box>
    );
  },
);

export { EmptyDnDList };
