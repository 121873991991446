import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
} from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logoMiddle.svg';
import { APP_ROUTES, UNASSIGNED_TASKS } from 'constants/common';
import { useSearchByParamsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useRemoveSelectedValues } from 'store/hooks/useRemoveSelectedValues';
import {
  setSearchedUnassignedShipmentId,
  setSelectedOrderId,
} from 'store/reducers/orderSlice';
import { setSelectedResourceId } from 'store/reducers/resourceSlice';
import { setToast } from 'store/reducers/settingsSlice';
import { setWorkshiftId } from 'store/reducers/workshiftSlice';
import {
  getIsDedicatedChatScreen,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { CommonDate } from 'components/common/Header/components/CommonDate';
import { DedicatedChatDate } from 'components/common/Header/components/DedicatedChatDate';
import { MainMenu } from 'components/common/MainMenu';
import { ResourcesGroupsSearch } from 'components/ui/ResourcesGroupsSearch';

import { HeaderNavigation } from './components/HeaderNavigation';
import { styles } from './styles';

export interface HeaderProps {
  admin?: boolean;
}
export const Header: FC<HeaderProps> = ({ admin = false }) => {
  const dispatch = useAppDispatch();
  const isDedicatedChatScreen = useAppSelector(getIsDedicatedChatScreen);
  const removeSelectedValues = useRemoveSelectedValues();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [lastOrderId, setLastOrderId] = useState<string | null>(null);
  const [result, setResult] = useState('');
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const [previousResult, setPreviousResult] = useState('');
  const { data, error, isLoading } = useSearchByParamsQuery(result, {
    skip: !result,
    refetchOnMountOrArgChange: true,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    if (data?.delivery_errand_id !== lastOrderId) {
      setLastOrderId(data?.delivery_errand_id || null);
    }
  }, [data, lastOrderId]);

  const handleSearchPress = useCallback(() => {
    if (searchString) {
      setResult(searchString);
    }
  }, [searchString]);

  useEffect(() => {
    if (data && result) {
      if (
        data?.delivery_errand_id !== lastOrderId ||
        result === previousResult
      ) {
        const resourceId = data?.resource_id || UNASSIGNED_TASKS;
        navigate(APP_ROUTES.root);
        dispatch(setSelectedResourceId(resourceId));
        dispatch(setSelectedOrderId(data.delivery_errand_id));
        dispatch(setSearchedUnassignedShipmentId(data.shipment_id));

        if (!onlyCurrentWorkshift) {
          dispatch(setWorkshiftId(data.work_shift_id));
        }

        dispatch(
          setToast({
            message: t`Successful search`,
            severity: 'success',
          }),
        );
        setPreviousResult(result);
        setResult('');
        setSearchString('');
      }
    }
  }, [data, result, lastOrderId, previousResult, onlyCurrentWorkshift]);

  useEffect(() => {
    if (error) {
      dispatch(
        setToast({
          message: t`No matches were found`,
          severity: 'error',
        }),
      );

      setResult('');
    }
  }, [error]);

  const clickHandler = useCallback(() => {
    removeSelectedValues();
  }, [removeSelectedValues]);

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && searchString) {
        handleSearchPress();
      }
    },
    [searchString],
  );

  return (
    <AppBar sx={styles.wrapper}>
      <Toolbar disableGutters sx={styles.content}>
        <Box sx={styles.container}>
          <Box sx={styles.subContainer}>
            <ButtonBase onClick={clickHandler} disableRipple sx={styles.logo}>
              <Logo />
            </ButtonBase>
            {!admin && pathname === APP_ROUTES.editSettings && (
              <ResourcesGroupsSearch />
            )}
            {!admin && pathname !== APP_ROUTES.editSettings && (
              <Box sx={styles.searchContainer}>
                <TextField
                  id="search"
                  name="search"
                  placeholder={t`Search by Booking number or Freight bill`}
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                  onKeyDown={handleEnterPress}
                  InputProps={
                    searchString
                      ? {
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={styles.inputIcon}
                            >
                              <IconButton
                                sx={styles.button}
                                onClick={() => setSearchString('')}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                  sx={styles.input}
                  fullWidth
                />

                <Button
                  variant={'contained'}
                  sx={styles.searchButton}
                  onClick={handleSearchPress}
                  disabled={!searchString || isLoading}
                >
                  <SearchIcon />
                </Button>
              </Box>
            )}
          </Box>
          {!admin && (
            <Box>
              {isDedicatedChatScreen ? <DedicatedChatDate /> : <CommonDate />}
            </Box>
          )}
        </Box>
        <Box sx={styles.container}>
          {!admin && <HeaderNavigation />}
          <MainMenu isAdmin={admin} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
