import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { styles } from './styles';

export const LoadingScreen: FC = () => {
  return (
    <Box sx={styles.container}>
      <CircularProgress disableShrink />
    </Box>
  );
};
