import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },
  damageBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0 16px 0',
  },
  digitsBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  damageTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0em',
    color: 'rgba(0, 0, 0, 0.87)',
    ml: '8px',
  },
  commentItem: {
    mt: '20px',
  },
};
