import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import { useResourcesV2Query } from 'store/api/apiSlice';
import { useDedicatedChatQuery } from 'store/api/chatApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { removeJustSelectedOrderId } from 'store/reducers/orderSlice';
import { removeJustSelectedResourceId } from 'store/reducers/resourceSlice';
import {
  removeJustCurrentCommonDate,
  updateIsDedicatedChatScreen,
} from 'store/reducers/settingsSlice';
import { removeJustSelectedWorkshiftId } from 'store/reducers/workshiftSlice';
import {
  getDedicatedChatDateSelector,
  getDedicatedChatPriorityFilters,
  getDedicatedChatResourcesFilters,
  getDedicatedChatResourcesGroups,
} from 'store/selectors/chat';
import { t } from 'ttag';
import {
  DedicatedChat,
  HeaderFieldNames,
  sortedFields,
  SortOrder,
} from 'types/dedicatedChat';
import { mappedSortedFields } from 'utils/dedicatedChatUtils';

import { DedicatedChatHeader } from 'components/screens/DedicatedChatScreen/components/DedicatedChatHeader';
import { DedicatedChatList } from 'components/screens/DedicatedChatScreen/components/DedicatedChatList';
import { DedicatedChatGroupMessageButton } from 'components/ui/DedicatedChatGroupMessageButton';

import { styles } from './styles';

export const DedicatedChatScreen: FC = () => {
  const dispatch = useAppDispatch();
  const dedicatedChatDate = useAppSelector(getDedicatedChatDateSelector);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: resourcesList } = useResourcesV2Query(
    {
      date: dedicatedChatDate
        ? dedicatedChatDate
        : format(new Date(), 'yyyy-MM-dd'),
    },
    { refetchOnMountOrArgChange: true },
  );
  const [orderedHeaderItems, setOrderedHeaderItems] = useState<
    Partial<Record<HeaderFieldNames, SortOrder>>
  >(
    sortedFields.reduce(
      (
        acc: Partial<Record<HeaderFieldNames, SortOrder>>,
        curr,
      ): Partial<Record<HeaderFieldNames, SortOrder>> => ({
        ...acc,
        [curr]: null,
      }),
      {},
    ),
  );

  const dedicatedChatResourcesFilters = useAppSelector(
    getDedicatedChatResourcesFilters,
  );
  const dedicatedChatResourcesGroups = useAppSelector(
    getDedicatedChatResourcesGroups,
  );
  const dedicatedChatPriorityFilters = useAppSelector(
    getDedicatedChatPriorityFilters,
  );

  const { data } = useDedicatedChatQuery(
    {
      group: dedicatedChatResourcesGroups,
      resource_name: dedicatedChatResourcesFilters,
      priority: dedicatedChatPriorityFilters,
      date: dedicatedChatDate,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const currentSortedField: {
    fieldName: HeaderFieldNames;
    value: SortOrder;
  } | null = useMemo(() => {
    const foundFieldWithValue = Object.entries(orderedHeaderItems).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => !!value,
    );

    if (!foundFieldWithValue) {
      return null;
    }

    return {
      fieldName: foundFieldWithValue[0] as HeaderFieldNames,
      value: foundFieldWithValue[1],
    };
  }, [orderedHeaderItems]);

  const sortedData = useMemo(() => {
    if (!currentSortedField) {
      return data;
    }

    const dataWithLatestTime = data?.map((d) => ({
      ...d,
      latest_message_time: d?.latest_message?.created_at,
    }));

    return (
      dataWithLatestTime &&
      orderBy(
        dataWithLatestTime,
        mappedSortedFields[currentSortedField.fieldName],
        currentSortedField.value as 'asc' | 'desc',
      )
    );
  }, [currentSortedField, data]);

  useEffect(() => {
    dispatch(updateIsDedicatedChatScreen(true));

    return () => {
      dispatch(updateIsDedicatedChatScreen(false));
    };
  }, []);

  useEffect(() => {
    dispatch(removeJustSelectedResourceId());
    dispatch(removeJustSelectedOrderId());
    dispatch(removeJustSelectedWorkshiftId());
    dispatch(removeJustCurrentCommonDate());
  }, []);

  const handleOrderedItemClick = useCallback((field: HeaderFieldNames) => {
    // @ts-ignore
    setOrderedHeaderItems((prev) => {
      // eslint-disable-next-line
      const foundKey = Object.entries(prev).find(([_, val]) => !!val);

      const emptyObj = sortedFields.reduce(
        (
          acc: Partial<Record<HeaderFieldNames, SortOrder>>,
          curr,
        ): Partial<Record<HeaderFieldNames, SortOrder>> => ({
          ...acc,
          [curr]: null,
        }),
        {},
      );

      if (!foundKey) {
        return {
          ...emptyObj,
          [field]: 'asc',
        };
      }

      if (foundKey[0] === field) {
        switch (foundKey[1]) {
          case 'asc':
            return {
              ...emptyObj,
              [field]: 'desc',
            };

          case 'desc':
          default:
            return {
              ...emptyObj,
              [field]: null,
            };

          case null:
            return {
              ...emptyObj,
              [field]: 'asc',
            };
        }
      } else {
        return {
          ...emptyObj,
          [field]: 'asc',
        };
      }

      return prev;
    });
  }, []);

  return (
    <Box sx={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={styles.screenTitleBlock}
      >
        <Typography sx={styles.title} component="h1">
          {t`Dedicated chat`}
        </Typography>
        <DedicatedChatGroupMessageButton />
      </Stack>
      <DedicatedChatHeader
        onOrderClick={handleOrderedItemClick}
        orderedItems={orderedHeaderItems}
      />
      <DedicatedChatList chatList={sortedData as DedicatedChat[]} />
    </Box>
  );
};
