import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES, UNASSIGNED_TASKS } from 'constants/common';
import { format } from 'date-fns';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import { useSelectedResource } from 'hooks/useSelectedResource';
import { useSelectedShipment } from 'hooks/useSelectedShipment';
import {
  useResourcesQuery,
  useResourcesV2Query,
  useShipmentsByResourceIdQuery,
  useUpdateBookingMutation,
  useUpdateErrandMutation,
  useUpdatePackagesMutation,
  useUpdateShipmentMutation,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setSelectedResourceId } from 'store/reducers/resourceSlice';
import {
  setToast,
  updateIsPreviousScreenChat,
} from 'store/reducers/settingsSlice';
import {
  getResourceFilterSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getIsPreviousScreenChat,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { getSelectedWorkshiftIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { BookingPackages, ShipmentStatuses } from 'types/api';
import { ChangeScreenFormOnSubmitFunctionParams } from 'types/changeScreenTypes';
import { twoObjectsDifference } from 'utils/common';
import { setNewShipmentStatus } from 'utils/orderUtils';

import { ChangeScreenForms } from 'components/common/ChangeScreenForms';

import { LoadingScreen } from '../LoadingScreen';

export const EditScreen: FC = () => {
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
  const isPreviousScreenChat = useAppSelector(getIsPreviousScreenChat);

  const { selectedShipment, isFetching: isShipmentFetching } =
    useSelectedShipment();
  const { selectedResource, isFetching: isResourceFetching } =
    useSelectedResource();
  const { selectedOrder, isFetching: isOrderFetching } = useSelectedOrder();
  const { refetch } = useShipmentsByResourceIdQuery(
    {
      resourceId: selectedResource?.id as string,
      workshiftId: selectedWorkshiftId,
    },
    {
      skip:
        !selectedResource ||
        !selectedResource.id ||
        selectedResource.id === UNASSIGNED_TASKS,
    },
  );
  const dispatch = useDispatch();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const resourceFilter = useAppSelector(getResourceFilterSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);

  const { data: resourcesListV2 } = useResourcesV2Query(
    {
      resource_name: resourceFilter,
      date: commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd'),
    },
    { skip: onlyCurrentWorkshift, refetchOnMountOrArgChange: true },
  );

  const { data: resourcesListV1 } = useResourcesQuery(resourceFilter, {
    skip: !onlyCurrentWorkshift,
  });

  const resourcesList = useMemo(() => {
    if (onlyCurrentWorkshift) {
      return resourcesListV1;
    }

    return resourcesListV2;
  }, [onlyCurrentWorkshift, resourcesListV1, resourcesListV2]);

  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const {
    pickup_errand: pickErrand,
    delivery_errand: deliveryErrand,
    booking,
    status,
    work_shift_id,
    id: shipmentId,
  } = selectedShipment || {};
  const statusName = status || '';
  const { packages } = booking || {};

  const [updateErrand] = useUpdateErrandMutation();
  const [updateBooking] = useUpdateBookingMutation();
  const [updateShipment] = useUpdateShipmentMutation();
  const [updatePackages] = useUpdatePackagesMutation();

  const submitFormHandler = useCallback(
    async ({
      changedPickErrand,
      changedDeliveryErrand,
      changedBooking,
      changedPackages,
      changedShipmentStatus,
      changedWorkShiftId,
    }: ChangeScreenFormOnSubmitFunctionParams) => {
      let updated = false;
      const pickErrandDifference =
        typeof pickErrand !== 'undefined'
          ? twoObjectsDifference(pickErrand, changedPickErrand)
          : null;
      const deliveryErrandDifference =
        typeof deliveryErrand !== 'undefined'
          ? twoObjectsDifference(deliveryErrand, changedDeliveryErrand)
          : null;
      const bookingDifference =
        typeof booking !== 'undefined'
          ? twoObjectsDifference(booking, changedBooking)
          : null;

      const hasPackagesChanged =
        typeof packages !== 'undefined'
          ? !!(twoObjectsDifference(
              packages,
              changedPackages,
            ) as BookingPackages) || packages.length !== changedPackages.length
          : false;
      const hasShipmentStatusChanged = changedShipmentStatus !== statusName;
      const hasWorkShiftIdChanged = changedWorkShiftId !== work_shift_id;

      if (typeof pickErrand !== 'undefined' && pickErrandDifference) {
        updated = true;
        await updateErrand({
          ...pickErrandDifference,
          id: pickErrand.id,
        });
      }
      if (typeof deliveryErrand !== 'undefined' && deliveryErrandDifference) {
        updated = true;
        await updateErrand({
          ...deliveryErrandDifference,
          id: deliveryErrand.id,
        });
      }
      if (typeof booking !== 'undefined' && bookingDifference) {
        updated = true;
        await updateBooking({
          ...bookingDifference,
          id: booking.id,
        });
      }
      if (
        typeof packages !== 'undefined' &&
        booking?.id &&
        hasPackagesChanged
      ) {
        updated = true;
        await updatePackages({
          bookingId: booking.id,
          packages: changedPackages,
        });
      }
      if (
        typeof shipmentId !== 'undefined' &&
        (hasShipmentStatusChanged || hasWorkShiftIdChanged)
      ) {
        updated = true;
        await updateShipment({
          id: shipmentId,
          ...(changedWorkShiftId !== work_shift_id
            ? { work_shift_id: changedWorkShiftId }
            : null),
          ...(changedShipmentStatus !== statusName
            ? {
                status: setNewShipmentStatus(
                  changedShipmentStatus as ShipmentStatuses,
                ) as ShipmentStatuses,
              }
            : null),
          ...(changedShipmentStatus !== statusName &&
          changedShipmentStatus === ShipmentStatuses.UNASSIGNED
            ? { work_shift_id: null }
            : null),
        });
      }

      // TODO: Remove, when tags invalidation will be implemented
      if (updated) {
        if (hasWorkShiftIdChanged) {
          const newResource = resourcesList?.find(
            (resource) => resource.work_shifts[0].id === changedWorkShiftId,
          );
          if (typeof newResource !== 'undefined') {
            await dispatch(setSelectedResourceId(newResource.id));
          }
        }

        dispatch(
          setToast({
            message: t`You have successfully edited the order`,
            severity: 'success',
          }),
        );

        await refetch();

        if (isPreviousScreenChat) {
          navigateWithQueryParams(APP_ROUTES.chat);
        } else {
          navigateWithQueryParams(APP_ROUTES.root);
        }

        dispatch(updateIsPreviousScreenChat(false));
      }
    },
    [
      booking,
      pickErrand,
      deliveryErrand,
      work_shift_id,
      statusName,
      shipmentId,
    ],
  );

  const isUnassignedResource = selectedResourceId === UNASSIGNED_TASKS;
  const isFetching =
    isShipmentFetching || isResourceFetching || isOrderFetching;
  const shipmentNotSelected =
    !selectedShipment ||
    !(selectedResource || isUnassignedResource) ||
    !selectedOrder;

  if (shipmentNotSelected && !isFetching) {
    return <Navigate to={APP_ROUTES.notFound} />;
  }

  if (isFetching) {
    return <LoadingScreen />;
  }

  return (
    <ChangeScreenForms
      label={t`Edit screen`}
      shipment={selectedShipment}
      resource={selectedResource}
      order={selectedOrder}
      onSubmit={submitFormHandler}
      isCreateScreen={false}
    />
  );
};
