import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UNASSIGNED_TASKS } from 'constants/common';
import { omit } from 'lodash';
import {
  getSelectedResourceIdFromUrl,
  removeSelectedResourceIdFromUrl,
  setSelectedResourceIdToUrl,
} from 'store/helpers/resourceHelpers';
import {
  removeUnassignedPageNumberFromUrl,
  setUnassignedPageNumberToUrl,
} from 'store/helpers/unassignedHelpers';
import { DriverPositionApi, ResourceApi } from 'types/api';
import {
  Coordinates,
  DriverDetails,
  Resource,
  RESOURCE_COLOR,
} from 'types/resource';

interface ResourceState {
  resourcesList: Resource[] | [];
  selectedResourceId: string | null;
  resourceFilter: string;

  resourcesGroups: string[];
  resourcesFilters: string[];
  resourcesFiltersAndGroups: string[];
  resourcesColorsFilters: RESOURCE_COLOR[];
  onlyWithUnread: boolean;
}

const initialState: ResourceState = {
  resourcesList: [],
  selectedResourceId: getSelectedResourceIdFromUrl || null,
  resourceFilter: '',

  resourcesFilters: [],
  resourcesGroups: [],
  resourcesColorsFilters: [],
  resourcesFiltersAndGroups: [],
  onlyWithUnread: false,
};

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    updateResourcesGroups: (state, action: PayloadAction<string[]>) => {
      state.resourcesGroups = action.payload;
    },

    updateResourcesFiltersAndGroups: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.resourcesFiltersAndGroups = action.payload;
    },

    updateOnlyWithUnread: (state, action: PayloadAction<boolean>) => {
      state.resourcesGroups = [];
      state.resourcesFilters = [];
      state.resourcesFiltersAndGroups = [];
      state.resourcesColorsFilters = [];
      state.onlyWithUnread = action.payload;
    },

    updateResourcesFilters: (state, action: PayloadAction<string[]>) => {
      state.resourcesFilters = action.payload;
    },

    updateResourcesColorsFilters: (
      state,
      action: PayloadAction<RESOURCE_COLOR[]>,
    ) => {
      state.resourcesColorsFilters = action.payload;
    },

    removeResourcesGroupsAndFilters: (state) => {
      state.resourcesGroups = [];
      state.resourcesFilters = [];
      state.resourcesFiltersAndGroups = [];
    },

    removeResourcesColorsGroupsAndFilters: (state) => {
      state.resourcesGroups = [];
      state.resourcesFilters = [];
      state.resourcesFiltersAndGroups = [];
      state.resourcesColorsFilters = [];
    },

    setResourcesList: (state, action: PayloadAction<ResourceApi[]>) => {
      // TODO only for test purposes

      // state.resourcesList = fillMockedCoordinatesInResources(action.payload);
      state.resourcesList = action.payload;
    },
    updateResourceFilter: (state, action: PayloadAction<string>) => {
      state.resourceFilter = action.payload;
    },

    updateResourceColor: (
      state,
      action: PayloadAction<{ resourceId: string; color: RESOURCE_COLOR }>,
    ) => {
      const { resourceId, color } = action.payload;
      const foundedItem = state.resourcesList.find(
        ({ id }) => id === resourceId,
      );

      if (foundedItem) {
        foundedItem.color = color;
      }
    },

    updateResourceGroupsNames: (
      state,
      action: PayloadAction<{ resourceId: string; groups_names: string[] }>,
    ) => {
      const { resourceId, groups_names } = action.payload;
      const foundedItem = state.resourcesList.find(
        ({ id }) => id === resourceId,
      );

      if (foundedItem) {
        foundedItem.groups_names = groups_names;
      }
    },

    updateResourceCoordinates: (
      state,
      action: PayloadAction<DriverPositionApi>,
    ) => {
      const { resource_id: resourceId, lat, lng, ...rest } = action.payload;

      state.resourcesList = state.resourcesList.map((resource) => {
        if (resource.id === resourceId) {
          const coordinates = [lng, lat] as Coordinates;

          return {
            ...resource,
            coordinates,
            details: omit(rest, [
              'driver_id',
              'resource_id',
              'work_shift_id',
            ]) as DriverDetails,
          };
        }

        return resource;
      });
    },
    setSelectedResourceId: (state, action: PayloadAction<string>) => {
      state.selectedResourceId = action.payload;
      setSelectedResourceIdToUrl(action.payload);
      if (action.payload === UNASSIGNED_TASKS) {
        setUnassignedPageNumberToUrl('1');
      }
    },

    setJustSelectedResourceId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.selectedResourceId = action.payload;
    },

    removeSelectedResourceId: (state) => {
      state.selectedResourceId = null;
      removeSelectedResourceIdFromUrl();
      removeUnassignedPageNumberFromUrl();
    },

    removeJustSelectedResourceId: (state) => {
      state.selectedResourceId = null;
    },
  },
});

export const {
  setResourcesList,
  setSelectedResourceId,
  updateResourceCoordinates,
  removeSelectedResourceId,
  updateResourceFilter,
  updateResourcesFiltersAndGroups,
  removeResourcesGroupsAndFilters,
  updateResourcesColorsFilters,
  updateResourcesFilters,
  updateResourcesGroups,
  removeResourcesColorsGroupsAndFilters,
  updateResourceColor,
  updateResourceGroupsNames,
  updateOnlyWithUnread,
  removeJustSelectedResourceId,
  setJustSelectedResourceId,
} = resourceSlice.actions;

export const resourceReducer = resourceSlice.reducer;
