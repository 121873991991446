/* eslint-disable camelcase */

import { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import * as process from 'process';
import { useLoginUserMutation } from 'store/api/apiSlice';
import { t } from 'ttag';
import { LoginResponse } from 'types/api';
import * as yup from 'yup';

import { Checkbox } from 'components/ui/Checkbox';
import { PasswordInput } from 'components/ui/PasswordInput';

import { styles } from './styles';

const validationSchema = yup.object({
  email: yup
    .string()
    .email(t`Enter a valid email`)
    .max(65, t`Email should not be longer than 65 symbols`)
    .required(t`Email is required`),
  password: yup
    .string()
    .required(t`Password is required`)
    .max(65, t`Password should not be longer than 65 symbols`),
});

export interface LoginFormValues extends FormikValues {
  email: string;
  password: string;
  rememberMe: boolean;
  formErrorField: '';
}

export interface LoginScreenFormProps {
  requiredAdmin: boolean;
}

export const LoginScreenForm: FC<LoginScreenFormProps> = ({
  requiredAdmin,
}) => {
  const [loginUser, { error }] = useLoginUserMutation();
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if ((error as any)?.data?.detail) {
      setErrorMsg((error as any)?.data?.detail);
    }
  }, [(error as any)?.data?.detail]);

  const submitFormHandler = useCallback(
    async (
      { email, password, rememberMe }: LoginFormValues,
      helpers: FormikHelpers<LoginFormValues>,
    ) => {
      helpers.setSubmitting(true);
      const response = await loginUser({
        email,
        password,
        remember_me: rememberMe,
        requiredAdmin,
      });

      const { data } = response as { data: LoginResponse };

      if (data && requiredAdmin && requiredAdmin !== data?.is_admin) {
        setErrorMsg(t`User does not have an Admin role`);
      }

      await helpers.setSubmitting(false);
    },
    [requiredAdmin],
  );

  const envVal = process.env.REACT_APP_CUSTOM_NODE_ENV;

  const formik = useFormik({
    initialValues: {
      email: envVal !== 'production' ? 'traffic@manager.com' : '',
      password: envVal !== 'production' ? '123' : '',
      rememberMe: true,
      formErrorField: '',
    },
    validationSchema,
    onSubmit: submitFormHandler,
  });

  useEffect(() => {
    formik.setFieldError('formErrorField', errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    if (!formik.errors.formErrorField) {
      setErrorMsg('');
    }
  }, [formik.errors.formErrorField]);

  return (
    <FormControl
      component="form"
      onSubmit={formik.handleSubmit}
      sx={styles.form}
    >
      <TextField
        id="email"
        name="email"
        placeholder={t`Email`}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={
          formik.touched.email &&
          (Boolean(formik.errors.email) ||
            Boolean(formik.errors.formErrorField))
        }
        helperText={formik.touched.email && formik.errors.email}
        fullWidth
        sx={styles.input}
      />
      <PasswordInput
        id="password"
        name="password"
        placeholder={t`Password`}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={
          formik.touched.password &&
          (Boolean(formik.errors.password) ||
            Boolean(formik.errors.formErrorField))
        }
        helperText={
          formik.touched.password &&
          (formik.errors.password || formik.errors.formErrorField)
        }
        fullWidth
        sx={styles.input}
      />
      <Button
        variant="contained"
        sx={styles.button}
        fullWidth
        type="submit"
        disabled={formik.isSubmitting}
      >{t`Log me in`}</Button>

      <FormControlLabel
        sx={styles.label}
        id="rememberMe"
        name="rememberMe"
        checked={formik.values.rememberMe}
        value={formik.values.rememberMe}
        onChange={formik.handleChange}
        control={<Checkbox />}
        label={t`Remember Me`}
      />
    </FormControl>
  );
};
