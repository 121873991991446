import { ORDER_ID_URL_PARAM } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const getSelectedOrderIdFromUrl = getValueFromUrl(ORDER_ID_URL_PARAM);

export const removeSelectedOrderIdFromUrl = () =>
  removeValueFromUrl(ORDER_ID_URL_PARAM);

export const setSelectedOrderIdToUrl = (value: string | undefined) => {
  return value
    ? setValueToUrl(ORDER_ID_URL_PARAM, value)
    : removeValueFromUrl(ORDER_ID_URL_PARAM);
};
