import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  innerContainer: {
    mb: '16px',
    '&:last-of-type': {
      mb: 0,
    },
  },

  list: {
    p: 0,
  },
};
