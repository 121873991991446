import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { t } from 'ttag';
import { AllRoutesPointStatus } from 'types/api';
import { pointStyle } from 'utils/mapPointUtils';

import { styles } from './styles';

type ColoredMapPoints = {
  status: AllRoutesPointStatus;
  label: string;
};

const coloredMapPoints: ColoredMapPoints[] = [
  {
    status: 'done',
    label: t`Successful`,
  },
  {
    status: 'fail',
    label: t`Failed`,
  },
  {
    status: 'done-fail',
    label: t`Multiple delivery (successful and failed)`,
  },
  {
    status: 'done-fail-pending',
    label: t`Multiple delivery (successful, failed and pending)`,
  },
  {
    status: 'done-pending',
    label: t`Multiple delivery (successful and pending)`,
  },
  {
    status: 'fail-pending',
    label: t`Multiple delivery (failed and pending)`,
  },
  {
    status: 'pending',
    label: t`Pending`,
  },
];

const ColoredMapPointsTooltipContent = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>{t`Info`}</Typography>
      {coloredMapPoints.map(({ status, label }, idx) => (
        <Box key={label} sx={styles.itemBlock}>
          <Grid
            sx={{
              ...pointStyle(status),
              // transform: `scale(${
              //   point?.orderData?.some((p) => p.id === selectedOrderId)
              //     ? '1.5'
              //     : '1'
              // })`,
            }}
            // onClick={() => handleMapPointClick(point)}
          >
            <Typography
              variant="caption"
              color="inherit"
              fontSize={12}
              fontWeight={600}
              letterSpacing={1.1}
              textAlign="center"
            >
              {idx + 1}
            </Typography>
          </Grid>
          <Typography ml="8px" sx={styles.title}>
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export { ColoredMapPointsTooltipContent };
