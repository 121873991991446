import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    pt: '10px',
  },

  button: {
    width: '200px',
  },

  cancelButton: {
    bgcolor: 'custom.white',
  },
};
