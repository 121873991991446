import { FC } from 'react';
import { Box } from '@mui/material';

import { CompaniesSection } from 'components/screens/AdminHomeScreen/components/CompaniesSection';
import { CompanyDetailSection } from 'components/screens/AdminHomeScreen/components/CompanyDetailSection';

import { styles } from './styles';

export const AdminHomeScreen: FC = () => {
  return (
    <Box sx={styles.container}>
      <CompaniesSection />
      <CompanyDetailSection />
    </Box>
  );
};
