import { FC } from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';

import { styles } from './styles';

export const AdditionsDataTabSwitchSection: FC = () => {
  const { formik } = useChangeScreenContext();

  const { values, handleChange, setFieldValue } = formik;

  const changeAdditionPackageCharge = (
    name: 'smallAdditionPackageCharge' | 'bigAdditionPackageCharge',
  ) => {
    const {
      smallAdditionPackageCharge: smallChargeValue,
      bigAdditionPackageCharge: bigChargeValue,
    } = values;

    if (
      (smallChargeValue === true && name === 'smallAdditionPackageCharge') ||
      (bigChargeValue === true && name === 'bigAdditionPackageCharge')
    ) {
      setFieldValue(name, false);
    } else if (smallChargeValue === bigChargeValue) {
      setFieldValue(name, !values[name]);
    } else {
      setFieldValue('smallAdditionPackageCharge', !smallChargeValue);
      setFieldValue('bigAdditionPackageCharge', !bigChargeValue);
    }
  };

  return (
    <ChangeScreenSection variant="standard">
      <Grid container columns={1} rowGap={2}>
        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="adr"
                id="adr"
                value={!!values.adr}
                checked={!!values.adr}
                onChange={handleChange}
              />
            }
            label={t`ADR`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="smallAdditionPackageCharge"
                id="smallAdditionPackageCharge"
                value={!!values.smallAdditionPackageCharge}
                checked={!!values.smallAdditionPackageCharge}
                onChange={() => {
                  changeAdditionPackageCharge('smallAdditionPackageCharge');
                }}
              />
            }
            label={t`Small addition package charge`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="bigAdditionPackageCharge"
                id="bigAdditionPackageCharge"
                value={!!values.bigAdditionPackageCharge}
                checked={!!values.bigAdditionPackageCharge}
                onChange={() => {
                  changeAdditionPackageCharge('bigAdditionPackageCharge');
                }}
              />
            }
            label={t`Big addition package charge`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="pickNewAddress"
                id="pickNewAddress"
                value={!!values.pickNewAddress}
                checked={!!values.pickNewAddress}
                onChange={handleChange}
              />
            }
            label={t`New pickup address`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="deliveryNewAddress"
                id="deliveryNewAddress"
                value={!!values.deliveryNewAddress}
                checked={!!values.deliveryNewAddress}
                onChange={handleChange}
              />
            }
            label={t`New delivery address`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="temperedTransport"
                id="temperedTransport"
                value={!!values.temperedTransport}
                checked={!!values.temperedTransport}
                onChange={handleChange}
              />
            }
            label={t`Tempered transport`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="onCallCourier"
                id="onCallCourier"
                value={!!values.onCallCourier}
                checked={!!values.onCallCourier}
                onChange={handleChange}
              />
            }
            label={t`On call courier`}
          />
        </Grid>

        <Grid item mobile={1}>
          <FormControlLabel
            sx={styles.switchItem}
            labelPlacement="start"
            control={
              <Switch
                name="timeSlottedLorry"
                id="timeSlottedLorry"
                value={!!values.timeSlottedLorry}
                checked={!!values.timeSlottedLorry}
                onChange={handleChange}
              />
            }
            label={t`Time slotted lorry`}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
