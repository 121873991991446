import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { Box, ListItem, SxProps, Typography } from '@mui/material';

import { styles } from './style';

interface CompanySectionListItemProps {
  label: string | number;
  country?: string;
  city?: string;
  id?: string;
  selected: boolean;
  onClick: (value: any) => void;
  icon?: ReactNode;
  labelStyle?: SxProps;
}

export const CompanySectionListItem: FC<CompanySectionListItemProps> =
  React.memo(
    ({
      label,
      id,
      selected,
      onClick,
      icon,
      country = '',
      city = '',
      labelStyle = {},
    }) => {
      const itemRef = useRef<null | HTMLElement>(null);
      const isItemClick = useRef(false);

      const currentLabel = useMemo(() => {
        return [label, city, country].filter(Boolean).join(', ');
      }, [label, city, country]);

      useEffect(() => {
        if (selected && itemRef?.current && !isItemClick.current) {
          itemRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        if (isItemClick.current) {
          isItemClick.current = false;
        }
      }, [selected]);

      const clickHandler = () => {
        isItemClick.current = true;
        onClick(id);
      };

      const itemStyles = selected
        ? ({ ...styles.item, ...styles.selected } as SxProps)
        : styles.item;

      const iconContent = icon && <Box sx={styles.icon}>{icon}</Box>;

      return (
        <ListItem sx={itemStyles} onClick={clickHandler} ref={itemRef as any}>
          <Box sx={styles.content}>
            {iconContent}
            <Typography variant="subtitle1" sx={labelStyle}>
              {currentLabel}
            </Typography>
          </Box>
        </ListItem>
      );
    },
  );
