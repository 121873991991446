import { FC, useCallback } from 'react';
import {
  Box,
  Button,
  createFilterOptions,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { PARCEL_TYPE_OPTIONS } from 'constants/editOrderOptions';
import {
  Field,
  FieldArrayRenderProps,
  FormikProps,
  useFormikContext,
} from 'formik';
import { t } from 'ttag';
import { ParcelTypeOption } from 'types/changeScreenTypes';

import { FieldAutocomplete } from 'components/common/FieldAutocomplete';
import { FieldInput } from 'components/common/FieldInput';

import { ChangeScreenFormValues } from '../../types';

import { styles } from './styles';

interface ParcelDataTabParcelSectionProps {
  index: number;
  fieldArrayProps: FieldArrayRenderProps;
  formik: FormikProps<ChangeScreenFormValues>;
}

export const ParcelDataTabParcelSection: FC<
  ParcelDataTabParcelSectionProps
> = ({ index, fieldArrayProps, formik }) => {
  const {
    values: { parcels },
  } = useFormikContext<ChangeScreenFormValues>();
  const filterParcelType = createFilterOptions<ParcelTypeOption>();

  const { remove } = fieldArrayProps;
  const { setFieldValue } = formik;

  const parcel = parcels[index].data;

  // const { parcel_type: parcelType } = parcel || {};
  const heightValue = parcel?.height || 0;
  const widthValue = parcel?.width || 0;
  const lengthValue = parcel?.length || 0;
  const volumeValue = +(heightValue * widthValue * lengthValue).toFixed(5);

  const parcelNamesObject = `parcels[${index}]`;
  const barcodeName = `${parcelNamesObject}.barcode`;
  const parcelTypeName = `${parcelNamesObject}.data.parcel_type`;
  const weightName = `${parcelNamesObject}.data.weight`;
  const volumeName = `${parcelNamesObject}.data.volume`;
  const lengthName = `${parcelNamesObject}.data.length`;
  const widthName = `${parcelNamesObject}.data.width`;
  const heightName = `${parcelNamesObject}.data.height`;

  const setParcelSizeValuesHandler = (type: any) => {
    const { length, width, height } =
      PARCEL_TYPE_OPTIONS.find((option) => option.value === type) || {};

    if (length && width && height) {
      setFieldValue(lengthName, length);
      setFieldValue(widthName, width);
      setFieldValue(heightName, height);
    } else {
      setFieldValue(lengthName, 0);
      setFieldValue(widthName, 0);
      setFieldValue(heightName, 0);
    }
  };

  const removeParcelHandler = useCallback(() => {
    remove(index);
  }, [index]);

  return (
    <Box sx={styles.form}>
      <Grid container columns={5} spacing={2} rowGap={4} sx={styles.content}>
        <Grid item mobile={2} sx={styles.gridItem}>
          <Field
            component={FieldInput}
            name={barcodeName}
            label={t`Barcode`}
            placeholder={t`Enter barcode`}
            autoComplete="off"
            fullWidth
          />
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <Field
            component={FieldInput}
            name={weightName}
            label={t`Weight`}
            type="number"
            placeholder={t`Enter parcel weight`}
            fullWidth
          />
          <Typography>{t`kg`}</Typography>
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <TextField
            name={volumeName}
            label={t`Volume`}
            value={volumeValue}
            type="number"
            fullWidth
            disabled
          />
          <Typography>{t`m${'\u{000B3}'}`}</Typography>
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <Button variant="outlined" fullWidth onClick={removeParcelHandler}>
            {t`Delete package`}
          </Button>
        </Grid>
        <Grid item mobile={2} sx={styles.gridItem}>
          <Field
            component={FieldAutocomplete}
            options={PARCEL_TYPE_OPTIONS}
            name={parcelTypeName}
            label={t`General parcel type`}
            placeholder={t`Enter general parcel type`}
            creatableFilterFunction={filterParcelType}
            onSelect={setParcelSizeValuesHandler}
            fullWidth
          />
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <Field
            component={FieldInput}
            name={lengthName}
            label={t`Length`}
            placeholder={t`Enter parcel length`}
            type="number"
            fullWidth
          />
          <Typography>{t`m`}</Typography>
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <Field
            component={FieldInput}
            name={widthName}
            label={t`Width`}
            placeholder={t`Enter parcel width`}
            type="number"
            fullWidth
          />
          <Typography>{t`m`}</Typography>
        </Grid>
        <Grid item mobile={1} sx={styles.gridItem}>
          <Field
            component={FieldInput}
            name={heightName}
            label={t`Height`}
            placeholder={t`Enter parcel height`}
            type="number"
            fullWidth
          />
          <Typography>{t`m`}</Typography>
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
    </Box>
  );
};
