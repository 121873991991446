import React, { FC, useCallback, useContext, useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { GROUP_SECTION_SCREEN } from 'constants/common';
import { GroupSettingContext } from 'context/GroupSetting/GroupSettingProvider';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import {
  useAddGroupMutation,
  useEditGroupDetailMutation,
  useGetGroupByIdQuery,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  setToast,
  updateCurrentGroupDetailResources,
} from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { WrappedErrorApi } from 'types/api';
import * as yup from 'yup';

import { styles } from './styles';

interface CreateGroup extends FormikValues {
  name: string;
  description: string;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .required(t`Group name is required`)
    .min(4, t`Group name should be at least 4 symbols`)
    .max(65, t`Group name should not be longer than 65 symbols`),
  description: yup
    .string()
    .max(300, t`Group description should not be longer than 300 symbols`),
});

const CreateEditGroup: FC = () => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  const { currentGroupId, setCurrentGroupId, setCurrentScreen } =
    useContext(GroupSettingContext);

  const [editGroup] = useEditGroupDetailMutation();

  const [addGroup] = useAddGroupMutation();

  const { data } = useGetGroupByIdQuery(currentGroupId, {
    refetchOnMountOrArgChange: true,
    skip: !currentGroupId,
  });

  useEffect(() => {
    if (data) {
      if (data?.resources?.length) {
        dispatch(updateCurrentGroupDetailResources(data.resources));
      }
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: CreateGroup, helpers: FormikHelpers<CreateGroup>) => {
      helpers.setSubmitting(true);
      if (
        values.name === data?.title &&
        values.description === data?.description
      ) {
        dispatch(
          setToast({
            message: t`There aren't any changes!`,
            severity: 'error',
          }),
        );
        helpers.setSubmitting(true);
        return;
      }

      let res = {};

      if (currentGroupId) {
        res = await editGroup({
          groupId: currentGroupId,
          title: values.name,
          description: values.description,
        });
      } else {
        res = await addGroup({
          title: values.name,
          description: values.description,
        });
      }

      await helpers.setSubmitting(false);

      if ((res as WrappedErrorApi)?.error) {
        const commonError = currentGroupId
          ? t`Something wrong when editing group!`
          : t`Something wrong when adding group!`;
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              commonError,
            severity: 'error',
          }),
        );

        return;
      }

      const successMessage = currentGroupId
        ? t`You have successfully edited group`
        : t`You have successfully created group`;
      dispatch(
        setToast({
          message: successMessage,
          severity: 'success',
        }),
      );
      resetForm();

      handleHeaderClick();
    },
    [currentGroupId, data, addGroup, editGroup],
  );

  const {
    dirty,
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      name: data?.title || '',
      description: data?.description || '',
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (data) {
      if (data?.title) {
        setFieldValue('name', data.title);
      }
      if (data?.description) {
        setFieldValue('description', data.description);
      }
    }
  }, [data, setFieldValue]);

  const handleHeaderClick = useCallback(() => {
    setCurrentGroupId(null);
    setCurrentScreen(GROUP_SECTION_SCREEN.allGroups);
  }, []);

  return (
    // <Box sx={styles.headerWrapper}>
    <>
      <Box sx={styles.headerBlock}>
        <Box sx={styles.pressableBlock} onClick={handleHeaderClick}>
          <ArrowBackIcon />
          <Typography sx={styles.headerName}>
            {currentGroupId
              ? t`Editing` + ` ${data?.title}`
              : t`Creating new group`}
          </Typography>
        </Box>
      </Box>
      <FormControl
        component="form"
        onSubmit={handleSubmit}
        sx={styles.headerDescriptionBlock}
      >
        <TextField
          id="name"
          name="name"
          required
          label={t`Group name`}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!touched.name && !!errors.name}
          helperText={!!touched.name && errors.name}
          sx={styles.input}
        />
        <TextField
          id="description"
          name="description"
          multiline
          rows={4}
          label={t`Description`}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          // fullWidth
          sx={styles.textarea}
        />

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={styles.bottomButtonContainer}>
          <Button
            onClick={handleHeaderClick}
            sx={styles.cancelButton}
            variant="outlined"
          >
            {t`Cancel`}
          </Button>

          <Button
            sx={styles.button}
            variant="contained"
            type="submit"
            disabled={!dirty || !isValid}
            // onClick={handleSaveClick}
          >
            {t`Save`}
          </Button>
        </Box>
      </FormControl>
    </>

    // </Box>
  );
};
export { CreateEditGroup };
