import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { QuickReply } from 'types/api';
import { StylesProps } from 'types/common';

import { styles } from './styles';
interface QuickRepliesProps {
  allReplies: QuickReply[];
  activeReplies: number[];
  onClickReply: (replies: number[]) => void;
}
export function QuickReplies(props: QuickRepliesProps) {
  const { allReplies, activeReplies, onClickReply } = props;

  const handleReplyClick = useCallback(
    (id: number, isChoosen: boolean) => {
      let replies = activeReplies;

      if (isChoosen) {
        replies = activeReplies.filter((r) => r !== id);
      } else {
        replies = [...replies, id];
      }

      onClickReply(replies);
    },
    [activeReplies, onClickReply],
  );

  const renderReply = useCallback((reply: QuickReply) => {
    const { id, emoji } = reply;

    const isCurrentChosen = activeReplies.includes(id);

    const replyStyle = isCurrentChosen
      ? { ...styles.reply, ...styles.borderedReply }
      : styles.reply;

    return (
      <Box
        key={id}
        sx={replyStyle as StylesProps}
        onClick={() => handleReplyClick(id, isCurrentChosen)}
      >
        <Typography fontSize="24px">
          {String.fromCodePoint(Number(emoji))}
        </Typography>
      </Box>
    );
  }, []);

  return <Box sx={styles.repliesContainer}>{allReplies.map(renderReply)}</Box>;
}
