import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  form: {
    width: '360px',
    p: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  input: {
    minHeight: '70px',
    '& .MuiFormHelperText-root': {
      ml: 0,
    },
  },

  button: {
    p: '12px',
    mt: '4px',
    mb: '12px',
  },

  label: { userSelect: 'none' },

  error: {
    m: 0,
    fontSize: '12px',
    color: 'custom.red',
  },
};
