import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import NotFoundImage from 'assets/images/404.png';
import { t } from 'ttag';

import { styles } from './styles';

export const NotFoundScreen: FC = () => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(-1);
  };

  return (
    <Box sx={styles.container}>
      <img src={NotFoundImage} alt="Not found" />
      <Button
        onClick={clickHandler}
        variant="contained"
        sx={styles.button}
      >{t`Back`}</Button>
    </Box>
  );
};
