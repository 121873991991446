import { format } from 'date-fns';
import {
  useResourcesQuery,
  useResourcesSettingsQuery,
  useResourcesV2Query,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getResourceFilterSelector,
  getSelectedResourceIdSelector,
  getSelectedResourceNumberSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';

export const useIsManualOrderSetting = () => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const { data } = useResourcesSettingsQuery({
    current_resource_id: selectedResourceId || undefined,
  });

  const { results: resourceSettingsApiData = null } = data || {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const resourceFilter = useAppSelector(getResourceFilterSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: resourcesListV2 } = useResourcesV2Query(
    {
      resource_name: resourceFilter,
      date: commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd'),
    },
    { skip: onlyCurrentWorkshift, refetchOnMountOrArgChange: true },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: resourcesList } = useResourcesQuery(resourceFilter, {
    skip: !onlyCurrentWorkshift,
  });
  const currentResourceNumber = useAppSelector(
    getSelectedResourceNumberSelector,
  );
  return {
    isManualOrderSetting:
      !!resourceSettingsApiData?.[currentResourceNumber]?.errand_settings
        ?.manual_order,
    isManualOptimizationSetting:
      !!resourceSettingsApiData?.[currentResourceNumber]?.errand_settings
        ?.manual_optimization,
  };
};
