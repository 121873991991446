import { FC, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useResourcesQuery, useResourcesV2Query } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getFilteredResourcesListSelector,
  getResourceFilterSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { Section } from 'components/ui/Section';
import { SetResourceFilterButton } from 'components/ui/SetResourceFilterButton';

import { ResourcesSectionList } from '../ResourcesSectionList/ResourcesSectionList';
import { ResourcesSectionSkeleton } from '../ResourcesSectionSkeleton';

import { styles } from './styles';

interface ResourcesSectionProps {
  selectedResourceId: string | null;
  setSelectedResourceId: (id: string) => void;
  selectAllResources: () => void;
}

export const ResourcesSection: FC<ResourcesSectionProps> = ({
  selectedResourceId,
  setSelectedResourceId,
  selectAllResources,
}) => {
  const resourceFilter = useAppSelector(getResourceFilterSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);

  const { isLoading: v2Loading, refetch: refetchV2 } = useResourcesV2Query(
    {
      resource_name: resourceFilter,
      date: commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd'),
    },
    { skip: onlyCurrentWorkshift, refetchOnMountOrArgChange: true },
  );
  const { isLoading: v1Loading, refetch: refetchV1 } = useResourcesQuery(
    resourceFilter,
    { skip: !onlyCurrentWorkshift },
  );

  const resourcesList = useAppSelector(getFilteredResourcesListSelector);

  const hasResources = !!resourcesList?.length;

  const isLoading = useMemo(
    () => v1Loading || v2Loading,
    [v1Loading, v2Loading],
  );

  const content = useMemo(() => {
    if (isLoading) {
      return <ResourcesSectionSkeleton quantity={10} />;
    }

    if (hasResources) {
      return (
        <ResourcesSectionList
          resourcesList={resourcesList || []}
          selectedResourceId={selectedResourceId}
          setSelectedResourceId={setSelectedResourceId}
          selectAllResources={selectAllResources}
        />
      );
    }

    return null;
  }, [
    isLoading,
    resourcesList,
    selectedResourceId,
    selectAllResources,
    setSelectedResourceId,
    hasResources,
  ]);

  useEffect(() => {
    if (onlyCurrentWorkshift) {
      refetchV1();
    } else {
      refetchV2();
    }
  }, [refetchV1, refetchV2, onlyCurrentWorkshift]);

  return (
    <Section
      label={t`Resources`}
      emptyText={t`No resources loaded`}
      sx={styles.container}
      buttons={[
        <SetResourceFilterButton key="resurce-filter-setting-button" />,
      ]}
    >
      {content}
    </Section>
  );
};
