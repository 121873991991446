import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    mt: '10px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContainer: {
    width: '126px',
    height: '108px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '8px',

    '& > img': {
      flexShrink: 0,
      minWidth: '100%',
      minHeight: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
};
