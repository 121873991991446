import React, { memo, useCallback } from 'react';
import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { format } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeJustSelectedOrderId,
  setJustSelectedOrderId,
} from 'store/reducers/orderSlice';
import {
  removeJustSelectedResourceId,
  setJustSelectedResourceId,
} from 'store/reducers/resourceSlice';
import {
  removeJustCurrentCommonDate,
  setJustCurrentCommonDate,
} from 'store/reducers/settingsSlice';
import {
  removeJustSelectedWorkshiftId,
  setJustWorkshiftId,
} from 'store/reducers/workshiftSlice';
import { getDedicatedChatDateSelector } from 'store/selectors/chat';
import { t } from 'ttag';
import { DedicatedChat } from 'types/dedicatedChat';

import { Chat } from 'components/common/Chat';
import { ChatPrioritySelect } from 'components/common/ChatPriority';
import { ChatDetailInfo } from 'components/screens/DedicatedChatScreen/components/DedicatedChatListItem/components/ChatDetailInfo';
import { OpenDedicatedChatButton } from 'components/ui/OpenDedicatedChatButton';
import { UnreadMessages } from 'components/ui/UnreadMessages';

import { styles } from './styles';

interface DedicatedChatListItemProps {
  dedicatedChat: DedicatedChat;
  isSelected: boolean;
  setSelectedChat: (shatId: string) => void;
}
export const DedicatedChatListItem = memo(
  (props: DedicatedChatListItemProps) => {
    const { dedicatedChat, isSelected, setSelectedChat } = props;
    const dedicatedChatDate = useAppSelector(getDedicatedChatDateSelector);

    const {
      id,
      resource_number,
      start,
      end,
      latest_message,
      unread,
      work_shift_id,
      priority,
      shipment_id,
      shipment_detail,
      eta,
      pod,
      margin,
      resource_id,
      delivery_errand_id,
    } = dedicatedChat;

    const dispatch = useAppDispatch();

    const handleItemClick = useCallback(() => {
      if (isSelected) {
        setSelectedChat('');

        dispatch(removeJustSelectedWorkshiftId());
        dispatch(removeJustSelectedResourceId());
        dispatch(removeJustSelectedOrderId());
        dispatch(removeJustCurrentCommonDate());

        return;
      }

      dispatch(setJustSelectedResourceId(resource_id));
      dispatch(setJustWorkshiftId(work_shift_id));

      if (delivery_errand_id) {
        dispatch(setJustSelectedOrderId(delivery_errand_id));
      }

      if (dedicatedChatDate) {
        dispatch(setJustCurrentCommonDate(dedicatedChatDate));
      }

      setSelectedChat(id);
    }, [
      setSelectedChat,
      id,
      isSelected,
      delivery_errand_id,
      resource_id,
      work_shift_id,
      dedicatedChatDate,
    ]);

    return (
      <Grid container spacing={0} sx={styles.container}>
        <Grid
          item
          container
          columns={52}
          spacing={1}
          sx={styles.card}
          data-selected={isSelected ? 'true' : 'false'}
        >
          <Grid
            item
            mobile={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <OpenDedicatedChatButton
              open={isSelected}
              onClick={handleItemClick}
            />
          </Grid>
          <Grid
            item
            mobile={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <CarIcon />
            <Typography variant="h5" ml="8px">
              {resource_number}
            </Typography>
          </Grid>
          <Grid
            item
            mobile={8}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Stack direction="column">
              <Typography variant="body2" sx={{ lineHeight: '18px' }}>
                {t`Start`}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: '18px' }}>
                {t`End`}
              </Typography>
            </Stack>
            <Stack direction="column" ml="8px">
              <Typography sx={styles.workshifTime}>
                {format(new Date(start), 'MMM d HH:mm')}
              </Typography>
              <Typography sx={styles.workshifTime}>
                {format(new Date(end), 'MMM d HH:mm')}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            mobile={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <ChatDetailInfo
              shipmentDetail={shipment_detail}
              eta={eta}
              pod={pod}
              margin={margin}
            />
          </Grid>
          <Grid
            item
            mobile={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography sx={styles.timeContent}>
              {format(new Date(latest_message.created_at), 'HH:mm')}
            </Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <UnreadMessages
              unread={unread}
              resourceId={resource_id}
              errandId={delivery_errand_id}
              workshiftId={work_shift_id}
            />
            <Typography sx={styles.messageText} ml="8px" noWrap>
              {latest_message?.message?.content}
            </Typography>
          </Grid>
          <Grid
            item
            mobile={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <ChatPrioritySelect
              work_shift_id={work_shift_id}
              priority={priority}
              shipment_id={shipment_id ? shipment_id : undefined}
            />
          </Grid>
        </Grid>
        <Collapse
          in={isSelected}
          timeout="auto"
          unmountOnExit
          style={{ width: '100%' }}
        >
          <Box sx={styles.chatContainer} style={{ width: '100%' }}>
            <Box sx={styles.chat}>
              <Chat />
            </Box>
          </Box>
        </Collapse>
      </Grid>
    );
  },
);
