import { FC, memo, useCallback, useState } from 'react';
import { ImageListItem } from '@mui/material';

import { ImageViewer } from 'components/common/ImageViewer';

import { styles } from './styles';

interface ParcelDataImagesListItemProps {
  src: string;
  itemIndex: number;
  photoUrls: string[];
}

export const FeedbackImagesListItem: FC<ParcelDataImagesListItemProps> = memo(
  ({ src, itemIndex, photoUrls }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback(() => {
      setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
      setIsOpen(false);
    }, []);

    return (
      <>
        <ImageListItem sx={styles.imageContainer} onClick={handleClick}>
          <img src={src} alt="image" width={55} height={55} loading="eager" />
        </ImageListItem>
        <ImageViewer
          currentImageIndex={itemIndex}
          open={isOpen}
          onClose={handleClose}
          photoUrls={photoUrls}
        />
      </>
    );
  },
);
