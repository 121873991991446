import { WORKSHIFT_ID_URL_PARAM } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const getSelectedWorkshiftIdFromUrl = getValueFromUrl(
  WORKSHIFT_ID_URL_PARAM,
);

export const removeSelectedWorkshiftIdFromUrl = () =>
  removeValueFromUrl(WORKSHIFT_ID_URL_PARAM);

export const setSelectedWorkshiftIdToUrl = (value: string | undefined) => {
  return value
    ? setValueToUrl(WORKSHIFT_ID_URL_PARAM, value)
    : removeValueFromUrl(WORKSHIFT_ID_URL_PARAM);
};
