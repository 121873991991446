import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { List } from '@mui/material';
import { CHANGE_PAGE_ROUTES } from 'constants/common';
import { FormikProps } from 'formik';
import { useChangeScreenTabErrors } from 'hooks/useChangeScreenTabErrors';
import { t } from 'ttag';

import {
  ChangeScreenFormValues,
  ChangeScreenFormValuesKeys,
} from '../ChangeScreenForms/types';
import { ChangeScreenNavigationItem } from '../ChangeScreenNavigationItem';

import { styles } from './styles';

interface ChangeScreenNavigationOption {
  label: string;
  path: CHANGE_PAGE_ROUTES;
  error?: boolean;
}

interface ChangeScreenNavigationProps {
  formik: FormikProps<ChangeScreenFormValues>;
  formValuesKeys: ChangeScreenFormValuesKeys;
  pickType: string;
  state?: any;
}

export const ChangeScreenNavigation: FC<ChangeScreenNavigationProps> = ({
  formik,
  formValuesKeys,
  state,
  // pickType,
}) => {
  const { pathname } = useLocation();

  const {
    orderDetailsTabErrors,
    pickupDataTabErrors,
    deliveryDataTabErrors,
    parcelsDataTabErrors,
    additionsDataTabErrors,
  } = useChangeScreenTabErrors({
    keys: formValuesKeys,
    errors: formik.errors,
    touched: formik.touched,
  });

  // const terminalPickType = pickType === 'terminal';

  // useEffect(() => {
  //   if (terminalPickType) {
  //     formik.setValues(
  //       (prev) => ({
  //         ...prev,
  //         goodsType: null,
  //         generalParcelType: null,
  //         goodsMarking: null,
  //         palletLocation: null,
  //         adr: null,
  //         smallAdditionPackageCharge: null,
  //         bigAdditionPackageCharge: null,
  //         pickNewAddress: null,
  //         deliveryNewAddress: null,
  //         temperedTransport: null,
  //         onCallCourier: null,
  //         timeSlottedLorry: null,
  //         pickWaitingTime: null,
  //         pickLoadingTime: null,
  //         deliveryWaitingTime: null,
  //         deliveryUnloadingTime: null,
  //         extraPalletSpace: null,
  //         flatbedMeter: null,
  //         installation: null,
  //         ferryAdditionCost: null,
  //       }),
  //       false,
  //     );
  //   }
  // }, [terminalPickType]);

  const options: ChangeScreenNavigationOption[] = useMemo(
    () => [
      {
        label: t`Order Details`,
        path: CHANGE_PAGE_ROUTES.orderDetails,
        error: orderDetailsTabErrors,
      },
      {
        label: t`Pick Up`,
        path: CHANGE_PAGE_ROUTES.pickUp,
        error: pickupDataTabErrors,
      },
      {
        label: t`Delivery`,
        path: CHANGE_PAGE_ROUTES.delivery,
        error: deliveryDataTabErrors,
      },
      {
        label: t`Parcel Data`,
        path: CHANGE_PAGE_ROUTES.parcelData,
        error: parcelsDataTabErrors,
      },
      {
        label: t`Additions`,
        path: CHANGE_PAGE_ROUTES.additions,
        error: additionsDataTabErrors,
      },
    ],
    [
      additionsDataTabErrors,
      parcelsDataTabErrors,
      deliveryDataTabErrors,
      pickupDataTabErrors,
      orderDetailsTabErrors,
    ],
  );

  // const reduceOptionsByCondition = useMemo(() => {
  //   if (terminalPickType) {
  //     return options?.slice(0, options.length - 1);
  //   }
  //
  //   return options;
  // }, [options, terminalPickType]);

  const activeTabPathname = useMemo(() => pathname.split('/')[2], [pathname]);

  const content = options.map((option) => (
    <ChangeScreenNavigationItem
      key={option.path}
      to={option.path}
      label={option.label}
      active={option.path === activeTabPathname}
      error={option.error}
      state={state}
    />
  ));

  return <List sx={styles.list}>{content}</List>;
};
