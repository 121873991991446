export const styles = {
  container: {
    bgcolor: 'custom.white',
    width: '100%',
    height: '100vh',
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'transparent', // Removes border color change on hover
      },
      '&.Mui-disabled': {
        '& fieldset': {
          borderColor: '#ccc', // Custom border color for disabled state
        },
      },
    },
  },
  gpsBlock: {
    position: 'relative',
  },
  gpsIcon: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
  imageList: {
    padding: '16px',
  },
  contentBlock: {
    overflowY: 'scroll',
    height: '70%',
  },
  buttonBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
  },
  button: {
    width: '190px',
  },
};
