import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { t } from 'ttag';
import { RESOURCE_COLOR } from 'types/resource';
import { coloredTitlesForTooltip } from 'utils/coloredFilterUtils';
import { resourceColorsMappedToMui } from 'utils/resourceUtils';

import { styles } from './styles';

const ColoredResourcesTooltipContent = () => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.header}>{t`Info`}</Typography>
      {coloredTitlesForTooltip.map((item) => (
        <Box key={item.color} sx={styles.itemBlock}>
          <Box>
            <CarIcon
              fill={resourceColorsMappedToMui[item.color as RESOURCE_COLOR]}
              style={{ width: '20px', height: '20px' }}
            />
          </Box>
          <Typography ml="8px" sx={styles.title}>
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export { ColoredResourcesTooltipContent };
