import { FC } from 'react';
import { ListItem, SxProps, Typography } from '@mui/material';
import { CHANGE_PAGE_ROUTES } from 'constants/common';

import { QueryParamsLink } from 'components/common/QueryParamsLink';

import { styles } from './styles';

interface ChangeScreenNavigationItemProps {
  to: CHANGE_PAGE_ROUTES;
  label: string;
  active?: boolean;
  error?: boolean;
  state?: boolean;
}

export const ChangeScreenNavigationItem: FC<
  ChangeScreenNavigationItemProps
> = ({ to, label, active = false, error = false, state }) => {
  const activeItemStyles = active
    ? ({ ...styles.listItem, ...styles.listItemActive } as SxProps)
    : styles.listItem;

  const listItemStyles = error
    ? ({ ...activeItemStyles, ...styles.listItemError } as SxProps)
    : activeItemStyles;

  return (
    <ListItem sx={listItemStyles}>
      <QueryParamsLink route={to} sx={styles.link} data={state}>
        <Typography component="span" variant="body2" sx={styles.linkTitle}>
          {label}
        </Typography>
      </QueryParamsLink>
    </ListItem>
  );
};
