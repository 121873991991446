import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // bgcolor: 'common.white',

    '& > img': {
      width: '100%',
      maxWidth: '900px',
    },
  },

  button: {
    mt: '50px',
    width: '200px',
  },
};
