import React, { useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  IconButton,
  Switch,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  KeyOfMapSettingsState,
  MapSettings,
  setMapSettings,
  setSettingsDate,
} from 'store/reducers/mapSettingsSlice';
import {
  getDetailedMapPointsSelector,
  getMapSettingsDateSelector,
  getMapSettingsSelector,
} from 'store/selectors/mapSettings';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import { t } from 'ttag';

import { ColoredMapPointsTooltipContent } from 'components/ui/AllRoutesSettingsButton/components/ColoredMapPointsTooltipContent';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface MapSettingsSwitchersData {
  fieldName: KeyOfMapSettingsState;
  disabled: boolean;
  value: boolean;
  label: string;
}

type SwitcherTitles = Record<KeyOfMapSettingsState, string>;

const switcherTitles: SwitcherTitles = {
  showResources: t`Show resources`,
  showAllRoutes: t`Show all routes`,
  showHistoricalPath: t`Show historical path`,
  showETA: t`Show ETA`,
  showPOD: t`Show POD`,
};

const keysForDisabling = ['showHistoricalPath', 'showETA', 'showPOD'];
const keysForDetailedPointsDisabled = ['showETA', 'showPOD'];

interface MapSettingsPopoverProps {
  onClosePopover: () => void;
}

export const MapSettingsPopover = ({
  onClosePopover,
}: MapSettingsPopoverProps) => {
  const dispatch = useAppDispatch();
  const detailedMapPoints = useAppSelector(getDetailedMapPointsSelector);
  const settingsDate = useAppSelector(getMapSettingsDateSelector);
  const mapSettings = useAppSelector(getMapSettingsSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const [date, setDate] = useState<Date>(new Date(settingsDate));
  const [switchers, setSwitchers] = useState<MapSettings>(mapSettings);

  const isShowAllRoutesTurnOff = useMemo(() => {
    return !switchers.showAllRoutes;
  }, [switchers.showAllRoutes]);

  const switchersData = useMemo(() => {
    const result: MapSettingsSwitchersData[] = Object.entries(switchers).map(
      ([key, value]) => {
        return {
          fieldName: key as KeyOfMapSettingsState,
          value,
          label: switcherTitles[key as KeyOfMapSettingsState],
          disabled:
            (isShowAllRoutesTurnOff && keysForDisabling.includes(key)) ||
            (!detailedMapPoints && keysForDetailedPointsDisabled.includes(key)),
        };
      },
    );

    return result;
  }, [switchers, isShowAllRoutesTurnOff, detailedMapPoints]);

  const handleChangeDatePicker = useCallback((val: Date | null) => {
    setDate(val as Date);
  }, []);

  const handleSwitcherPress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSwitchers((prev) => {
        if (event.target.name === 'showAllRoutes' && !event.target.checked) {
          const obj = Object.entries(prev).reduce(
            (accum, [key, value]) => ({
              ...accum,
              [key]: keysForDisabling.includes(key) ? false : value,
            }),
            {},
          );

          return { ...obj, showAllRoutes: false } as MapSettings;
        }
        return {
          ...prev,
          [event.target.name]: event.target.checked,
        };
      });
    },
    [],
  );

  const handleApplyPress = useCallback(() => {
    dispatch(setMapSettings(switchers));
    dispatch(setSettingsDate(format(date, 'yyyy-MM-dd')));
    onClosePopover();
  }, [switchers, date, onClosePopover, dispatch]);

  const switchersList = useMemo(() => {
    return switchersData.map(({ fieldName, label, value, disabled }) => (
      <Box key={label} sx={styles.switcherRow}>
        <Typography sx={styles.switcherLabel}>{label}</Typography>
        <Switch
          checked={value}
          disabled={disabled}
          name={fieldName}
          onChange={handleSwitcherPress}
          value={value}
        />
      </Box>
    ));
  }, [switchersData]);

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={onClosePopover}>
        <CloseIcon />
      </IconButton>

      <Box sx={styles.title}>
        <Typography variant="h1">{t`Map settings`}</Typography>
      </Box>
      <Box sx={styles.switchersBlock}>{switchersList}</Box>
      {onlyCurrentWorkshift && (
        <Box sx={styles.dateBlock}>
          <DesktopDatePicker
            // label="Date desktop"
            inputFormat="yyyy-MM-dd"
            disabled={isShowAllRoutesTurnOff}
            // shouldDisableDate={shouldDisabledDate}
            value={date}
            onChange={handleChangeDatePicker}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
            )}
          />
        </Box>
      )}
      <Box sx={styles.infoBlock}>
        <LightTooltip
          title={<ColoredMapPointsTooltipContent />}
          TransitionComponent={Zoom}
          leaveDelay={1000}
          placement="right-start"
          sx={{
            '.MuiTooltip-tooltip': {
              maxWidth: 'none',
              width: '413px',
            },
          }}
        >
          <InfoOutlinedIcon sx={styles.infoIcon} />
        </LightTooltip>
        <Typography
          sx={styles.infoText}
        >{t`Info about order statuses`}</Typography>
      </Box>

      <Box sx={styles.divider} />
      <Box sx={styles.buttonsBlock}>
        <Button
          sx={styles.button}
          variant="contained"
          onClick={handleApplyPress}
        >
          {t`Apply`}
        </Button>
      </Box>
    </Box>
  );
};
