import React, { FC, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Button, Chip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { compareAsc, format } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeDriverFilters,
  updateAndroidFiltersDriver,
  updateDeviceFiltersDriver,
  updateDriverEndDate,
  updateDriverFilters,
  updateDriverPage,
  updateDriverSize,
  updateDriverStartDate,
} from 'store/reducers/deviceInfoReportSlice';
import { setToast } from 'store/reducers/settingsSlice';
import {
  getDriverAndroidFilter,
  getDriverDeviceFilter,
  getDriverEndTime,
  getDriverNameFilter,
  getDriverStartTime,
} from 'store/selectors/deviseInfoReports';
import { t } from 'ttag';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const driverFilter = useAppSelector(getDriverNameFilter);
  const deviceFilter = useAppSelector(getDriverDeviceFilter);
  const androidFilter = useAppSelector(getDriverAndroidFilter);
  const startDate = useAppSelector(getDriverStartTime);
  const endDate = useAppSelector(getDriverEndTime);

  const [driverFiltersInputValue, setDriverFiltersInputValue] =
    useState<string[]>(driverFilter);

  const [androidFiltersInputValue, setAndroidFiltersInputValue] =
    useState<string[]>(androidFilter);

  const [deviceFiltersInputValue, setDeviceFiltersInputValue] =
    useState<string[]>(deviceFilter);

  const [startDateInputValue, setStartDateInputValue] = useState<Date | null>(
    startDate ? new Date(startDate) : null,
  );
  const [endDateInputValue, setEndDateInputValue] = useState<Date | null>(
    endDate ? new Date(endDate) : null,
  );

  const isAnyFilters = useMemo(() => {
    return (
      driverFilter?.length ||
      deviceFilter?.length ||
      androidFilter?.length ||
      endDate ||
      startDate
    );
  }, [driverFilter, deviceFilter, androidFilter, startDate, endDate]);

  const handleChangeStartDatePicker = useCallback((val: Date | null) => {
    setStartDateInputValue(val);
  }, []);

  const handleChangeEndDatePicker = useCallback((val: Date | null) => {
    setEndDateInputValue(val);
  }, []);

  const isAnyDataInInputs = useMemo(() => {
    return (
      isAnyFilters ||
      driverFiltersInputValue.length ||
      androidFiltersInputValue.length ||
      deviceFiltersInputValue.length ||
      startDateInputValue ||
      endDateInputValue
    );
  }, [
    isAnyFilters,
    driverFiltersInputValue,
    startDateInputValue,
    endDateInputValue,
    androidFiltersInputValue,
    deviceFiltersInputValue,
  ]);

  const handleChangeDrivers = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = driverFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setDriverFiltersInputValue(updatedValues);
    },
    [driverFiltersInputValue],
  );

  const handleChangeAndroid = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = androidFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setAndroidFiltersInputValue(updatedValues);
    },
    [androidFiltersInputValue],
  );

  const handleChangeDevices = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = deviceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setDeviceFiltersInputValue(updatedValues);
    },
    [deviceFiltersInputValue],
  );

  const handleApplyPress = useCallback(() => {
    let emptyCounter = 0;

    if (!driverFiltersInputValue?.length) {
      dispatch(updateDriverFilters([]));
      emptyCounter++;
    }

    if (!deviceFiltersInputValue?.length) {
      dispatch(updateDeviceFiltersDriver([]));
      emptyCounter++;
    }

    if (!androidFiltersInputValue?.length) {
      dispatch(updateAndroidFiltersDriver([]));
      emptyCounter++;
    }

    if (!startDateInputValue) {
      dispatch(updateDriverStartDate(''));
      emptyCounter++;
    }

    if (!endDateInputValue) {
      dispatch(updateDriverEndDate(''));
      emptyCounter++;
    }

    if (emptyCounter === 5) {
      return;
    }

    dispatch(updateDriverFilters(driverFiltersInputValue));
    dispatch(updateDeviceFiltersDriver(deviceFiltersInputValue));
    dispatch(updateAndroidFiltersDriver(androidFiltersInputValue));
    dispatch(
      updateDriverStartDate(
        startDateInputValue
          ? format(startDateInputValue as Date, 'yyyy-MM-dd')
          : '',
      ),
    );
    dispatch(
      updateDriverEndDate(
        endDateInputValue
          ? format(endDateInputValue as Date, 'yyyy-MM-dd')
          : '',
      ),
    );

    dispatch(updateDriverSize(10));
    dispatch(updateDriverPage(1));

    onClosePopover();
  }, [
    onClosePopover,
    startDateInputValue,
    endDateInputValue,
    driverFiltersInputValue,
    deviceFiltersInputValue,
    androidFiltersInputValue,
  ]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeDriverFilters());
    onClosePopover();
  }, [onClosePopover]);

  const shouldDisabledDate = useCallback(
    (d: Date) => {
      return (
        !startDateInputValue || compareAsc(startDateInputValue as Date, d) === 1
      );
    },
    [startDateInputValue],
  );

  // @ts-ignore
  return (
    <Box sx={styles.container}>
      <Autocomplete
        value={driverFiltersInputValue}
        multiple
        id="drivers"
        onChange={handleChangeDrivers}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Driver's name`}
            // placeholder={t`Search by driver's name`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Autocomplete
        value={deviceFiltersInputValue}
        multiple
        id="devices"
        onChange={handleChangeDevices}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Device info`}
            // placeholder={t`Search by device info`}
          />
        )}
        // sx={styles.firstFilter}
      />

      <Autocomplete
        value={androidFiltersInputValue}
        multiple
        id="android"
        onChange={handleChangeAndroid}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Android version`}
            // placeholder={t`Search by android version`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Box sx={styles.dateBlock}>
        <Box sx={{ width: '45%' }}>
          <DesktopDatePicker
            label={t`Start date`}
            inputFormat="yyyy-MM-dd"
            value={startDateInputValue}
            onChange={handleChangeStartDatePicker}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
            )}
          />
        </Box>

        <Box sx={{ width: '45%' }}>
          <DesktopDatePicker
            label={t`End date`}
            inputFormat="yyyy-MM-dd"
            disabled={!startDateInputValue}
            shouldDisableDate={shouldDisabledDate}
            value={endDateInputValue}
            onChange={handleChangeEndDatePicker}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
            )}
          />
        </Box>
      </Box>
      <Button
        disabled={
          driverFiltersInputValue.length === 0 &&
          !startDateInputValue &&
          !endDateInputValue &&
          androidFiltersInputValue.length === 0 &&
          deviceFiltersInputValue.length === 0
        }
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>
      <Button
        disabled={!isAnyDataInInputs}
        sx={styles.button}
        variant="outlined"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
