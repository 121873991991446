import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    position: 'relative',
    width: '413px',
  },

  header: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'custom.black',
    marginBottom: '8px',
  },

  itemBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: '8px 0',
  },

  title: {
    color: 'custom.graphite',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '140%',
  },
};
