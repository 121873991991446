import React, { FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as DisabledDriverPath } from 'assets/icons/driver_historical_off.svg';
import { ReactComponent as ShowDriverPath } from 'assets/icons/driver_historical_on.svg';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setDriverHistoricalPath } from 'store/reducers/mapSettingsSlice';
import { getDriverHistoricalPathSelector } from 'store/selectors/mapSettings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface EditButtonProps {
  orderId?: string;
  disabled?: boolean;
  shipmentId?: string;
}
export const DriverHistoricalPathButton: FC<EditButtonProps> = React.memo(
  () => {
    const dispatch = useAppDispatch();
    const driverHistoricalPath = useAppSelector(
      getDriverHistoricalPathSelector,
    );

    const handleClick = useCallback(() => {
      dispatch(setDriverHistoricalPath(!driverHistoricalPath));
    }, [driverHistoricalPath]);

    return (
      <LightTooltip
        title={
          driverHistoricalPath
            ? t`Hide driver historical points`
            : t`Show driver historical points`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton sx={styles.button} onClick={handleClick}>
            {driverHistoricalPath && <ShowDriverPath />}
            {!driverHistoricalPath && <DisabledDriverPath />}
          </IconButton>
        </span>
      </LightTooltip>
    );
  },
);
