import React, { FC, useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { Box } from '@mui/system';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeAllRoutesResourcesGroupsAndFilters,
  setAllRoutesResourcesFilters,
  setAllRoutesResourcesGroups,
  setAllRoutesResourcesGroupsAndFilters,
} from 'store/reducers/mapSettingsSlice';
import { setToast } from 'store/reducers/settingsSlice';
import { getAllRoutesResourcesGroupsAndFiltersSelector } from 'store/selectors/mapSettings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const AllResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const resourcesFiltersAndGroups = useAppSelector(
    getAllRoutesResourcesGroupsAndFiltersSelector,
  );

  const [inputValues, setInputValues] = useState<string[]>(
    resourcesFiltersAndGroups,
  );

  const { data } = useGetAllGroupsQuery({});

  const allGroups = useMemo(() => {
    return data ? data.map((group) => group.title) : [];
  }, [data]);

  const handleChange = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters and groups, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = inputValues.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setInputValues(updatedValues);
    },
    [inputValues],
  );

  const handleApplyPress = useCallback(() => {
    if (!inputValues.length) {
      dispatch(removeAllRoutesResourcesGroupsAndFilters());

      return;
    }

    const filters: string[] = [];
    const groups: string[] = [];

    inputValues.forEach((value) => {
      if (allGroups.includes(value)) {
        groups.push(value);

        return;
      }

      filters.push(value);
    });

    dispatch(setAllRoutesResourcesGroups(groups));
    dispatch(setAllRoutesResourcesFilters(filters));

    dispatch(setAllRoutesResourcesGroupsAndFilters(inputValues));
    onClosePopover();
  }, [allGroups, inputValues]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeAllRoutesResourcesGroupsAndFilters());
    onClosePopover();
  }, [onClosePopover]);

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={onClosePopover}>
        <CloseIcon />
      </IconButton>

      <Box sx={styles.title}>
        <Typography variant="h1">{t`Resources and groups of resources`}</Typography>
      </Box>

      <LightTooltip
        title={t`You can set up here groups and filters for resources in the settings screen. The maximum quantity of tags is five.`}
        arrow
        TransitionComponent={Zoom}
        leaveDelay={1000}
        placement="right"
      >
        <Autocomplete
          value={inputValues}
          multiple
          id="tags-filled"
          onChange={handleChange}
          options={allGroups}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                size="small"
                sx={{ fontSize: '10px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // label={t`Groups and Filters`}
              placeholder={t`Search by resources or groups of resources`}
            />
          )}
          sx={styles.firstFilter}
        />
      </LightTooltip>

      <Button
        disabled={inputValues.length === 0}
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>

      <Button
        disabled={!resourcesFiltersAndGroups?.length}
        sx={styles.button}
        variant="contained"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { AllResourcesFiltersPopover };
