import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackOrderingParam, FeedbackStatus } from 'types/feedback';
import { SortOrder } from 'utils/tableUtils';

interface FeedbackState {
  orderBy: FeedbackOrderingParam;
  order: SortOrder;
  page: number;
  size: number;
  currentAudioId: number | null;
  feedbackResourcesFilters: string[];
  feedbackDriversFilters: string[];
  feedbackVersionsFilters: string[];
  feedbackDevicesFilters: string[];
  feedbackStatusesFilters: FeedbackStatus[];
  feedbackStartDate: string;
  feedbackEndDate: string;
}

const initialState: FeedbackState = {
  orderBy: '-created_at',
  order: 'desc',
  page: 1,
  size: 10,
  currentAudioId: null,
  feedbackResourcesFilters: [],
  feedbackDriversFilters: [],
  feedbackVersionsFilters: [],
  feedbackDevicesFilters: [],
  feedbackStatusesFilters: [],
  feedbackStartDate: '',
  feedbackEndDate: '',
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    updateFeedbackOrderBy: (
      state,
      action: PayloadAction<FeedbackOrderingParam>,
    ) => {
      state.orderBy = action.payload;
      state.order = action.payload.startsWith('-') ? 'desc' : 'asc';
    },
    updateFeedbackPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateFeedbackSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    updateCurrentAudioId: (state, action: PayloadAction<number | null>) => {
      state.currentAudioId = action.payload;
    },
    updateFeedbackResourcesFilters: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.feedbackResourcesFilters = action.payload;
    },
    updateFeedbackDriversFilters: (state, action: PayloadAction<string[]>) => {
      state.feedbackDriversFilters = action.payload;
    },
    updateFeedbackVersionsFilters: (state, action: PayloadAction<string[]>) => {
      state.feedbackVersionsFilters = action.payload;
    },
    updateFeedbackDevicesFilters: (state, action: PayloadAction<string[]>) => {
      state.feedbackDevicesFilters = action.payload;
    },
    updateFeedbackStatusesFilters: (
      state,
      action: PayloadAction<FeedbackStatus[]>,
    ) => {
      state.feedbackStatusesFilters = action.payload;
    },
    updateFeedbackStartDate: (state, action: PayloadAction<string>) => {
      state.feedbackStartDate = action.payload;
    },
    updateFeedbackEndDate: (state, action: PayloadAction<string>) => {
      state.feedbackEndDate = action.payload;
    },
    removeFeedbackFilters: (state) => {
      state.feedbackResourcesFilters = [];
      state.feedbackDriversFilters = [];
      state.feedbackVersionsFilters = [];
      state.feedbackDevicesFilters = [];
      state.feedbackStatusesFilters = [];
      state.feedbackStartDate = '';
      state.feedbackEndDate = '';
    },
  },
});

export const {
  updateFeedbackOrderBy,
  updateFeedbackPage,
  updateFeedbackSize,
  updateCurrentAudioId,
  updateFeedbackDevicesFilters,
  updateFeedbackDriversFilters,
  updateFeedbackEndDate,
  updateFeedbackResourcesFilters,
  updateFeedbackStartDate,
  updateFeedbackStatusesFilters,
  updateFeedbackVersionsFilters,
  removeFeedbackFilters,
} = feedbackSlice.actions;

export const feedbackReducer = feedbackSlice.reducer;
