import { FC, useCallback, useMemo } from 'react';
import { sortBy } from 'lodash';
import {
  useCompaniesCitiesQuery,
  useCompaniesCountriesQuery,
  useSubcontractorsQuery,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeAllFilters,
  removeSelectedCompanyId,
  setSelectedCompanyId,
} from 'store/reducers/companySlice';
import {
  getCityFilterSelector,
  getCountryFilterSelector,
  getSearchFilterSelector,
  getSelectedCompanyIdSelector,
} from 'store/selectors/company';
import { t } from 'ttag';

import { CompanySectionList } from 'components/screens/AdminHomeScreen/components/CompanySectionList';
import { ResourcesSectionSkeleton } from 'components/screens/HomeScreen/components/ResourcesSectionSkeleton';
import { CreateCompanyrButton } from 'components/ui/CreateCompanyButton';
import { OpenCompanyFilterButton } from 'components/ui/OpenCompanyFilterButton';
import { Section } from 'components/ui/Section';

import { styles } from './styles';

export const CompaniesSection: FC = () => {
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
  const search = useAppSelector(getSearchFilterSelector);
  const country = useAppSelector(getCountryFilterSelector);
  const city = useAppSelector(getCityFilterSelector);
  const dispatch = useAppDispatch();
  const { data: companiesList, isFetching } = useSubcontractorsQuery({
    search,
    country,
    city,
  });
  const { data: countriesList } = useCompaniesCountriesQuery({});
  const { data: citiesList } = useCompaniesCitiesQuery({ country });
  const hasCompanies = !!companiesList?.length;

  const selectAllCompanies = useCallback(() => {
    dispatch(removeSelectedCompanyId());
    dispatch(removeAllFilters());
  }, []);

  const sortedCompaniesList = useMemo(() => {
    return companiesList ? sortBy(companiesList, 'name') : [];
  }, [companiesList]);

  const setCurrentCompanyId = useCallback((id: string) => {
    dispatch(setSelectedCompanyId(id));
  }, []);

  const content = useMemo(() => {
    if (isFetching) {
      return <ResourcesSectionSkeleton quantity={10} />;
    }

    // if (hasCompanies) {
    return (
      <CompanySectionList
        companiesList={sortedCompaniesList}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={setCurrentCompanyId}
        selectAllCompanies={selectAllCompanies}
        cities={citiesList || []}
        countries={countriesList || []}
      />
    );
    // }
    //
    // return null;
  }, [
    hasCompanies,
    companiesList,
    selectedCompanyId,
    setCurrentCompanyId,
    selectAllCompanies,
    citiesList,
    countriesList,
  ]);

  return (
    <Section
      buttons={[
        <OpenCompanyFilterButton key="open-company-filters-button" />,
        <CreateCompanyrButton key="create-company-button" />,
      ]}
      label={t`Companies`}
      sx={styles.container}
    >
      {content}
    </Section>
  );
};
