import { FC } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { t } from 'ttag';
import { BookingPackage } from 'types/api';

import { ChangeScreenFormValues } from '../../types';
import { ParcelDataTabParcelSection as ParcelSection } from '../ParcelDataTabParcelSection';

export const ParcelDataTabForm: FC = () => {
  const { formik } = useChangeScreenContext();

  const pushParcelHandler = ({ push }: FieldArrayRenderProps) => {
    const newPackage: BookingPackage = {
      barcode: '',
      data: {
        height: null,
        length: null,
        parcel_type: '',
        weight: null,
        width: null,
      },
    };
    push(newPackage);
  };

  const renderFieldArray = (
    formikProps: FormikProps<ChangeScreenFormValues>,
    fieldArrayProps: FieldArrayRenderProps,
  ) => {
    const { parcels } = formikProps.values;
    return parcels.length ? (
      formikProps.values.parcels.map((parcel, index) => (
        <ParcelSection
          key={index}
          index={index}
          formik={formikProps}
          fieldArrayProps={fieldArrayProps}
        />
      ))
    ) : (
      // TODO: Add styles when the design will be ready
      <Typography
        variant="h3"
        sx={{ mt: '20px', mb: '32px' }}
      >{t`There are no parcels yet..`}</Typography>
    );
  };

  return (
    <Grid container columns={1} spacing={2}>
      <Grid item mobile={1}>
        <FieldArray name="parcels">
          {(fieldArrayProps) => (
            <Box>
              {renderFieldArray(formik, fieldArrayProps)}
              <Button
                type="button"
                variant="outlined"
                onClick={() => pushParcelHandler(fieldArrayProps)}
              >{t`Add new package`}</Button>
            </Box>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
};
