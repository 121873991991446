import { createTypedSelector } from 'store/utils';

export const getSelectedOrderIdSelector = createTypedSelector(
  (state) => state.order.selectedOrderId,
);
export const getUnassignedShipments = createTypedSelector((state) =>
  state.order.unassignedShipments.filter((s) => s),
);
export const getUnassignedPages = createTypedSelector(
  (state) => state.order.unassignedPages,
);
export const getUnassignedCurrentPage = createTypedSelector(
  (state) => state.order.unassignedPageNumber,
);
export const getUnassignedTotal = createTypedSelector(
  (state) => state.order.unassignedTotal,
);
export const getViewedUnassignedPages = createTypedSelector(
  (state) => state.order.viewedUnassignedPages,
);
export const getAllRoutesSelectedOrder = createTypedSelector(
  (state) => state.order.allRoutesSelectedOrder,
);
