function hslToHex(h: number, s: number, l: number): string {
  l /= 100;
  const a: number = (s * Math.min(l, 1 - l)) / 100;
  const f: (n: number) => string = (n) => {
    const k: number = (n + h / 30) % 12;
    const color: number = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // Convert to Hex and format
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function generateDistinctColors(
  n: number,
  lightnessMin = 30,
  lightnessMax = 60,
): string[] {
  const colors: string[] = [];
  for (let i = 0; i < n; i++) {
    const hue: number = (360 / n) * i;
    // Calculate lightness for this color to be within the specified range
    const lightness: number =
      lightnessMin + (i % (lightnessMax - lightnessMin));
    colors.push(hslToHex(hue, 100, lightness)); // Full saturation (100%) and controlled lightness
  }
  return colors;
}

export function getFontColorForBackground(bgColor: string) {
  // If the background color is in short form, convert it to full form
  if (bgColor.length === 4) {
    bgColor =
      '#' +
      bgColor[1] +
      bgColor[1] +
      bgColor[2] +
      bgColor[2] +
      bgColor[3] +
      bgColor[3];
  }

  // Extract the red, green, and blue components from the hex color
  const r = parseInt(bgColor.substring(1, 3), 16);
  const g = parseInt(bgColor.substring(3, 5), 16);
  const b = parseInt(bgColor.substring(5, 7), 16);

  // Calculate the luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Use luminance to determine if the font color should be black or white
  return luminance > 150 ? '#000000' : '#FFFFFF';
}
