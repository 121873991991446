import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  updateCurrentGTPage,
  updateSearchResourcesForSettingsByName,
} from 'store/reducers/settingsSlice';
import {
  getCurrentGTPage,
  getResourcesForSettings,
  getSearchResourcesForSettingsByName,
  getShowResourcesSettingsFilter,
  getTotalGRPages,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { DraggableResourceItem } from 'components/screens/EditSettingsScreen/components/DraggableResourceItem';
import { ResourcesSectionSkeleton } from 'components/screens/HomeScreen/components/ResourcesSectionSkeleton';

import { styles } from './styles';

interface ResourcesGroupSectionList {
  isFetching: boolean;
}

const ResourcesGroupSectionList: FC<ResourcesGroupSectionList> = ({
  isFetching,
}) => {
  const divRef = useRef<HTMLUListElement | null>(null);

  const showFilter = useAppSelector(getShowResourcesSettingsFilter);
  const dispatch = useAppDispatch();
  const search = useAppSelector(getSearchResourcesForSettingsByName);
  const resourcesForSettings = useAppSelector(getResourcesForSettings);
  const pages = useAppSelector(getTotalGRPages);
  const currentPage = useAppSelector(getCurrentGTPage);

  const handleScroll = useCallback(() => {
    const div = divRef.current;

    if (!div || pages === currentPage || isFetching) {
      return;
    }

    if (div?.scrollHeight - div?.scrollTop === div?.clientHeight) {
      // console.log('SCROLL_DOWN');
      dispatch(updateCurrentGTPage(currentPage + 1));
      // User has reached the bottom
    }
  }, [pages, currentPage, isFetching]);

  useEffect(() => {
    divRef.current && divRef.current.addEventListener('scroll', handleScroll);

    return () => {
      divRef.current &&
        divRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleSearchCloseClick = useCallback(() => {
    dispatch(updateSearchResourcesForSettingsByName(''));
  }, []);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      dispatch(updateSearchResourcesForSettingsByName(e.target.value));
    },
    [],
  );
  const content = useMemo(() => {
    if (isFetching && currentPage === 1) {
      return <ResourcesSectionSkeleton quantity={10} />;
    }

    if (!resourcesForSettings?.length) {
      return (
        <Typography sx={styles.noResources}>
          {t`There are no such resources.`}
        </Typography>
      );
    }
    return resourcesForSettings.map(({ id, number }) => {
      return <DraggableResourceItem key={id} id={id} number={number} />;
    });
  }, [resourcesForSettings, isFetching, currentPage]);

  return (
    <List sx={styles.list} ref={divRef}>
      {showFilter && (
        <Box sx={styles.filtersBlock}>
          <TextField
            sx={styles.input}
            // inputRef={inputRef}
            onChange={handleSearchChange}
            autoComplete="new-password"
            value={search}
            fullWidth
            autoFocus
            label={t`Search`}
            placeholder={t`Search resource by the name `}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ marginRight: '16px', marginLeft: '16px' }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleSearchCloseClick}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type="text"
          />
        </Box>
      )}
      {content}
      {isFetching && currentPage !== 1 && (
        <ResourcesSectionSkeleton quantity={10} />
      )}
    </List>
  );
};

export { ResourcesGroupSectionList };
