import { FC } from 'react';
import { Grid } from '@mui/material';

import { PickUpDataTabAddressSection as PickUpAddressSection } from '../PickUpDataTabAddressSection';
import { PickUpDataTabDetailsSection as PickUpDetailsSection } from '../PickUpDataTabDetailsSection';
import { PickUpDataTabSenderSection as PickUpSenderSection } from '../PickUpDataTabSenderSection';

export const PickUpDataTabForm: FC = () => {
  return (
    <Grid container columns={3} spacing={2}>
      <Grid item mobile={2}>
        <Grid container columns={1} rowGap={2}>
          <Grid item mobile={1}>
            <PickUpAddressSection />
          </Grid>
          <Grid item mobile={1}>
            <PickUpSenderSection />
          </Grid>
        </Grid>
      </Grid>
      <Grid item mobile={1}>
        <PickUpDetailsSection />
      </Grid>
    </Grid>
  );
};
