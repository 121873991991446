export const styles = {
  generalChat: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: 'custom.black',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
  },

  addressDescription: {
    display: 'flex',
    flexDirection: 'column',
  },

  timeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  serviceName: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    color: 'custom.black',
  },

  name: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    color: 'custom.graphite',
    margin: '4px 0',
  },

  addressContent: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: 'custom.black',
  },
  etaPodBlock: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: 'custom.graphite',
    letterSpacing: '3%',
    textTransform: 'uppercase',

    '& span': {
      color: 'custom.black',
    },
  },
  marginBlock: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: 'custom.graphite',
    letterSpacing: '3%',
  },
};
