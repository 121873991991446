import { Navigate, Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getIsAdminSelector,
  getIsLoggedInSelector,
} from 'store/selectors/auth';

import { Header } from '../Header';

import { styles } from './styles';

export const AdminProtectedLayout = () => {
  const isLoggedIn = useAppSelector(getIsLoggedInSelector);
  const isAdmin = useAppSelector(getIsAdminSelector);

  if (!isLoggedIn) {
    return <Navigate to={APP_ROUTES.auth} />;
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <Box sx={styles.layout}>
      <Header admin />
      <Container sx={styles.content}>
        <Outlet />
      </Container>
    </Box>
  );
};
