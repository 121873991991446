import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { isEmpty } from 'lodash';
import { useAddAddressMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { WrappedErrorApi } from 'types/api';
import { blockInvalidChar } from 'utils/inputUtils';
import * as yup from 'yup';

import { Section } from 'components/ui/Section';

import { styles } from './styles';

const validationSchema = yup.object({
  street: yup
    .string()
    .max(65, t`Street should not be longer than 65 symbols`)
    .min(3, t`Street should be at least 3 symbols`)
    .required(t`Street is required`),
  streetNumber: yup
    .string()
    .required(t`Street number is required`)
    .max(10, t`Street number should not be longer than 10 symbols`),
  country: yup
    .string()
    .required(t`Country is required`)
    .max(30, t`Country should not be longer than 30 symbols`),
  city: yup
    .string()
    .required(t`City is required`)
    .max(30, t`City should not be longer than 30 symbols`),
  state: yup.string().max(30, t`State should not be longer than 30 symbols`),
  postalCode: yup
    .string()
    .max(6, t`Postal code should not be longer than 6 symbols`)
    .matches(/\d/gm, t`Postal code should consist only numbers`)
    .required(t`Postal code is required`),
  latitude: yup
    .number()
    .min(-90, t`Latitude shouldn\'t be less than -90`)
    .max(90, t`Latitude shouldn\'t be more than 90`)
    .required(t`Latitude is required`)
    .test('is-zero', t`Latitude is required`, function (item) {
      return item !== 0;
    }),
  longitude: yup
    .number()
    .min(-180, t`Longitude shouldn\'t be less than -180`)
    .max(180, t`Longitude shouldn\'t be more than 180`)
    .required(t`Longitude is required`)
    .test('is-zero', t`Longitude is required`, function (item) {
      return item !== 0;
    }),
});

export interface AddAddressFormValues extends FormikValues {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
  latitude: number;
  longitude: number;
}

export const AddAddressScreen: FC = () => {
  const [addAddress, { isError }] = useAddAddressMutation();
  const dispatch = useAppDispatch();

  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const submitFormHandler = useCallback(
    async (
      {
        street,
        streetNumber: street_number,
        country,
        city,
        state,
        postalCode: postal_code,
        latitude,
        longitude,
      }: AddAddressFormValues,
      helpers: FormikHelpers<AddAddressFormValues>,
    ) => {
      helpers.setSubmitting(true);

      const res = await addAddress({
        street,
        city,
        state,
        country,
        street_number,
        postal_code,
        latitude,
        longitude,
      });

      await helpers.setSubmitting(false);

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              t`Something wrong in Add Address!`,
            severity: 'error',
          }),
        );
        return;
      }

      dispatch(
        setToast({
          message: t`You have successfully saved address`,
          severity: 'success',
        }),
      );
      formik.resetForm();
      navigateWithQueryParams(APP_ROUTES.root);
    },
    [isError, addAddress],
  );

  const formik = useFormik<AddAddressFormValues>({
    initialValues: {
      street: '',
      streetNumber: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      latitude: 0,
      longitude: 0,
    },
    validationSchema,
    onSubmit: submitFormHandler,
  });

  useEffect(() => {
    formik.resetForm();

    return () => formik.resetForm();
  }, []);

  const isValid = useMemo(
    () => formik.isValid && !isEmpty(formik.touched),
    [formik],
  );
  return (
    <FormControl
      component="form"
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <Typography sx={styles.title} component="h1">
        {t`Add New Address`}
      </Typography>
      <Section
        sx={styles.section}
        label={t`Address`}
        labelStyles={styles.subtitle}
      >
        <Grid container columns={3} spacing={2} padding={2} paddingY={3}>
          <Grid
            item
            container
            columns={3}
            spacing={1}
            mobile={1}
            paddingBottom={3}
          >
            <Grid item mobile={2}>
              <TextField
                required
                id="street"
                name="street"
                // placeholder={t`Email`}
                label="Street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.street &&
                  (Boolean(formik.errors.street) ||
                    Boolean(formik.errors.formErrorField))
                }
                helperText={formik.touched.street && formik.errors.street}
                fullWidth
                autoComplete="new-password"
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={1}>
              <TextField
                required
                id="streetNumber"
                name="streetNumber"
                // placeholder={t`Email`}
                onBlur={formik.handleBlur}
                label="Street number"
                value={formik.values.streetNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.streetNumber && !!formik.errors.streetNumber
                }
                helperText={
                  formik.touched.streetNumber && formik.errors.streetNumber
                }
                fullWidth
                autoComplete="new-password"
                sx={styles.input}
              />
            </Grid>
          </Grid>
          <Grid item container columns={3} spacing={1} mobile={1}>
            <Grid item mobile={1}>
              <TextField
                required
                id="postalCode"
                name="postalCode"
                // placeholder={t`Email`}
                label="Postal code"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postalCode &&
                  (Boolean(formik.errors.postalCode) ||
                    Boolean(formik.errors.formErrorField))
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                }
                fullWidth
                autoComplete="new-password"
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={2}>
              <TextField
                required
                id="city"
                name="city"
                // placeholder={t`Email`}
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.city &&
                  (Boolean(formik.errors.city) ||
                    Boolean(formik.errors.formErrorField))
                }
                helperText={formik.touched.city && formik.errors.city}
                fullWidth
                autoComplete="new-password"
                sx={styles.input}
              />
            </Grid>
          </Grid>
          <Grid item mobile={1}>
            <TextField
              required
              id="latitude"
              name="latitude"
              type="number"
              label="Latitude"
              onKeyDown={blockInvalidChar(['+', 'e', 'E']) as any}
              value={formik.values.latitude}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.latitude && Boolean(formik.errors.latitude)) ||
                Boolean(formik.errors.formErrorField)
              }
              helperText={formik.touched.latitude && formik.errors.latitude}
              fullWidth
              sx={styles.input}
            />
          </Grid>
        </Grid>

        <Grid container columns={3} spacing={2} paddingX={2} paddingBottom={3}>
          <Grid item mobile={1}>
            <TextField
              required
              id="country"
              name="country"
              // placeholder={t`Email`}
              label="Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.country &&
                (Boolean(formik.errors.country) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.country && formik.errors.country}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={1}>
            <TextField
              id="state"
              name="state"
              label="State"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.state &&
                (Boolean(formik.errors.state) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.state && formik.errors.state}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />

            {/* Fixed autocomplete issue*/}
            <div style={{ display: 'none' }}>
              <TextField autoComplete="on" />
            </div>
          </Grid>
          <Grid item mobile={1}>
            <TextField
              required
              id="longitude"
              name="longitude"
              label="Longitude"
              type="number"
              onKeyDown={blockInvalidChar(['+', 'e', 'E']) as any}
              value={formik.values.longitude}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.longitude &&
                  Boolean(formik.errors.longitude)) ||
                Boolean(formik.errors.formErrorField)
              }
              helperText={formik.touched.longitude && formik.errors.longitude}
              fullWidth
              sx={styles.input}
            />
          </Grid>
        </Grid>
      </Section>
      <Box sx={styles.bottomButtonContainer}>
        <Button
          onClick={() => navigateWithQueryParams(APP_ROUTES.root)}
          sx={styles.cancelButton}
          variant="outlined"
        >
          {t`Cancel`}
        </Button>
        <Button
          sx={styles.button}
          disabled={formik.isSubmitting || !isValid}
          variant="contained"
          type="submit"
        >
          {t`Save`}
        </Button>
      </Box>
    </FormControl>
  );
};
