import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DriverReportOrdering,
  ResourceReportOrdering,
} from 'types/deviceInfoReport';
import { SortOrder } from 'utils/tableUtils';

interface DeviceInfoReportState {
  orderByResource: ResourceReportOrdering;
  orderResource: SortOrder;
  pageResource: number;
  sizeResource: number;
  resourceNumberFiltersResource: string[];

  orderByDriver: DriverReportOrdering;
  orderDriver: SortOrder;
  pageDriver: number;
  sizeDriver: number;
  nameFiltersDriver: string[];
  devicesFiltersDriver: string[];
  androidFiltersDriver: string[];
  startDateDriver: string;
  endDateDriver: string;
}

const initialState: DeviceInfoReportState = {
  orderByResource: '-login_time',
  orderResource: 'desc',
  pageResource: 1,
  sizeResource: 10,
  resourceNumberFiltersResource: [],

  orderByDriver: '-login_time',
  orderDriver: 'desc',
  pageDriver: 1,
  sizeDriver: 10,
  nameFiltersDriver: [],
  devicesFiltersDriver: [],
  androidFiltersDriver: [],
  startDateDriver: '',
  endDateDriver: '',
};

export const deviceInfoReportSlice = createSlice({
  name: 'deviceInfoReport',
  initialState,
  reducers: {
    updateDriverOrderBy: (
      state,
      action: PayloadAction<DriverReportOrdering>,
    ) => {
      state.orderByDriver = action.payload;
      state.orderDriver = action.payload.startsWith('-') ? 'desc' : 'asc';
    },
    updateDriverPage: (state, action: PayloadAction<number>) => {
      state.pageDriver = action.payload;
    },
    updateDriverSize: (state, action: PayloadAction<number>) => {
      state.sizeDriver = action.payload;
    },
    updateDriverFilters: (state, action: PayloadAction<string[]>) => {
      state.nameFiltersDriver = action.payload;
    },
    updateDeviceFiltersDriver: (state, action: PayloadAction<string[]>) => {
      state.devicesFiltersDriver = action.payload;
    },
    updateAndroidFiltersDriver: (state, action: PayloadAction<string[]>) => {
      state.androidFiltersDriver = action.payload;
    },

    updateDriverStartDate: (state, action: PayloadAction<string>) => {
      state.startDateDriver = action.payload;
    },
    updateDriverEndDate: (state, action: PayloadAction<string>) => {
      state.endDateDriver = action.payload;
    },
    removeDriverFilters: (state) => {
      state.nameFiltersDriver = [];
      state.devicesFiltersDriver = [];
      state.androidFiltersDriver = [];
      state.startDateDriver = '';
      state.endDateDriver = '';
    },

    updateResourceOrderBy: (
      state,
      action: PayloadAction<ResourceReportOrdering>,
    ) => {
      state.orderByResource = action.payload;
      state.orderResource = action.payload.startsWith('-') ? 'desc' : 'asc';
    },
    updateResourcePage: (state, action: PayloadAction<number>) => {
      state.pageResource = action.payload;
    },
    updateResourceSize: (state, action: PayloadAction<number>) => {
      state.sizeResource = action.payload;
    },
    updateResourceFilters: (state, action: PayloadAction<string[]>) => {
      state.resourceNumberFiltersResource = action.payload;
    },
    removeResourceFilters: (state) => {
      state.resourceNumberFiltersResource = [];
    },
  },
});

export const {
  updateAndroidFiltersDriver,
  updateDeviceFiltersDriver,
  updateDriverEndDate,
  updateDriverFilters,
  updateDriverOrderBy,
  updateDriverPage,
  updateDriverSize,
  updateDriverStartDate,
  removeDriverFilters,
  updateResourceFilters,
  removeResourceFilters,
  updateResourceOrderBy,
  updateResourcePage,
  updateResourceSize,
} = deviceInfoReportSlice.actions;

export const deviceInfoReportReducer = deviceInfoReportSlice.reducer;
