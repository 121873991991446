import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { List } from '@mui/material';
import { UNASSIGNED_TASKS } from 'constants/common';
import { max, min } from 'lodash';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setUnassignedPageNumber } from 'store/reducers/orderSlice';
import {
  getUnassignedCurrentPage,
  getUnassignedPages,
  getViewedUnassignedPages,
} from 'store/selectors/order';
import { Order, OrderStatuses } from 'types/orders';

import { OrdersSectionSkeleton } from 'components/screens/HomeScreen/components/OrdersSectionSkeleton';

import { OrdersSectionListItem } from '../OrdersSectionListItem';

import { styles } from './styles';

interface OrdersSectionListProps {
  ordersList: Order[];
  selectedOrderId?: string | null;
  selectedResourceId?: string | null;
  showOrderNumber: boolean;
  unassignedIsFetching?: boolean;
}

export const OrdersSectionList: FC<OrdersSectionListProps> = ({
  ordersList,
  showOrderNumber,
  selectedOrderId,
  selectedResourceId,
  unassignedIsFetching,
}) => {
  const unassignedPages = useAppSelector(getUnassignedPages);
  const unassignedCurrentPage = useAppSelector(getUnassignedCurrentPage);
  const viewedUnassignedPages = useAppSelector(getViewedUnassignedPages);
  const dispatch = useAppDispatch();
  const divRef = useRef<HTMLUListElement | null>(null);
  const [scrollDirection, setScrollDirection] = useState<
    'SCROLL_DOWN' | 'SCROLL_UP' | null
  >(null);

  let ordinalNumber = 0;
  let executedNumber = 0;

  const getOrdinalNumber = (order: Order) => {
    if (
      !order.isHidden &&
      order.status !== OrderStatuses.DONE &&
      order.status !== OrderStatuses.FAILED
    ) {
      return ++ordinalNumber;
    }

    if (
      !order.isHidden &&
      (order.status === OrderStatuses.DONE ||
        order.status === OrderStatuses.FAILED)
    ) {
      return ++executedNumber;
    }
    return ordinalNumber;
  };

  useEffect(() => {
    ordinalNumber = 0;
    executedNumber = 0;
  }, []);

  const handleScroll = useCallback(() => {
    const div = divRef.current;

    if (
      selectedResourceId !== UNASSIGNED_TASKS ||
      !div ||
      unassignedIsFetching
    ) {
      return;
    }

    if (div.scrollHeight - div.scrollTop === div.clientHeight) {
      setScrollDirection('SCROLL_DOWN');
      // User has reached the bottom
      const maxNext = max(viewedUnassignedPages) || 0;
      const nextPage =
        maxNext > unassignedCurrentPage ? maxNext : unassignedCurrentPage;
      const isNextPageNew = !viewedUnassignedPages.some(
        (p) => p === nextPage + 1,
      );
      if (
        unassignedPages > 0 &&
        unassignedCurrentPage < unassignedPages &&
        isNextPageNew
      ) {
        dispatch(setUnassignedPageNumber(nextPage + 1));
      }
    }
    if (div.scrollTop === 0) {
      setScrollDirection('SCROLL_UP');

      const minNext = min(viewedUnassignedPages) || 1;
      const prevPage =
        minNext < unassignedCurrentPage && minNext > 1
          ? minNext
          : unassignedCurrentPage;
      const isPrevPageNew = !viewedUnassignedPages.some(
        (p) => p === prevPage - 1,
      );
      // User has reached the bottom
      if (unassignedCurrentPage > 1 && isPrevPageNew) {
        dispatch(setUnassignedPageNumber(prevPage - 1));
      }
    }
  }, [
    unassignedCurrentPage,
    viewedUnassignedPages,
    selectedResourceId,
    unassignedPages,
    unassignedIsFetching,
  ]);

  useEffect(() => {
    divRef.current && divRef.current.addEventListener('scroll', handleScroll);

    return () => {
      divRef.current &&
        divRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <List ref={divRef} sx={styles.list}>
      {unassignedIsFetching && scrollDirection === 'SCROLL_UP' && (
        <OrdersSectionSkeleton quantity={1} />
      )}
      {ordersList.map((order) => {
        const number = showOrderNumber
          ? order.orderNumber
          : getOrdinalNumber(order);
        return (
          <OrdersSectionListItem
            key={order.id}
            number={number}
            selectedOrderId={selectedOrderId}
            {...order}
          />
        );
      })}
      {unassignedIsFetching && scrollDirection === 'SCROLL_DOWN' && (
        <OrdersSectionSkeleton quantity={10} />
      )}
    </List>
  );
};
