import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  listItem: {
    p: '16px 0',
    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',

    '&:first-of-type': {
      pt: 0,
    },

    '&:last-of-type': {
      border: 'none',
      pb: 0,
    },
  },

  accordion: {
    width: '100%',
  },

  barcode: {
    cursor: 'text',
    pointerEvents: 'all',
    userSelect: 'all',
  },

  accordionSummary: {
    flexDirection: 'row-reverse',
    minHeight: 0,
    ml: '-5px',
    p: 0,
    '& > *': {
      m: '0 !important',
    },
  },

  summary: {
    m: 0,
    pl: '5px',
    display: 'flex',
    flexDirection: 'column',
  },

  accordionDetails: {
    p: 0,
    pt: '5px',
    // ml: '5px',
  },

  parcelType: {
    textTransform: 'capitalize',
  },

  commentItem: {
    mt: '20px',
  },
};
