import { memo, useCallback, useMemo } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useChangeFeedbackStatusOrReplyMutation } from 'store/api/adminApiSlice';
import { t } from 'ttag';
import { FeedbackStatus } from 'types/feedback';

import { styles } from './style';

interface ChatPriorityProps {
  feedbackId: number;
  status?: FeedbackStatus;
}

type PriorityOptions = { label: string; value: FeedbackStatus; color: string };

const options: PriorityOptions[] = [
  { label: t`New`, value: 'New', color: 'primary.main' },
  { label: t`In Progress`, value: 'Progress', color: 'custom.blue2' },
  { label: t`Blocked`, value: 'Blocked', color: 'custom.red' },
  { label: t`Closed`, value: 'Closed', color: 'custom.green' },
];

export const FeedbackStatusSelect = memo((props: ChatPriorityProps) => {
  const { feedbackId, status } = props;

  const [setFeedbackStatus] = useChangeFeedbackStatusOrReplyMutation();

  const currentOption = useMemo(() => {
    return options.find((option) => option.value === status);
  }, [status]);

  const menuItems = useMemo(() => {
    return options.map((option) => {
      return (
        <MenuItem value={option.value} key={option.label}>
          <Typography sx={{ ...styles.menuItemContent, color: option.color }}>
            {option.label}
          </Typography>
        </MenuItem>
      );
    });
  }, []);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      if (value === status) {
        return;
      }

      setFeedbackStatus({
        feedbackId: String(feedbackId),
        status: value as FeedbackStatus,
      });
    },
    [feedbackId, status],
  );

  return (
    <FormControl sx={{ m: 1, minWidth: 163, minHeight: 40 }} variant="outlined">
      <Select
        value={status === undefined ? '' : status}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          '&.MuiOutlinedInput-root fieldset': {
            borderColor: currentOption?.color,
          },
          '& .MuiSelect-icon': {
            color: currentOption?.color,
          },
        }}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
});
