import { FC } from 'react';
import { Checkbox as CheckboxMUI, CheckboxProps } from '@mui/material';
import { ReactComponent as CheckboxChecked } from 'assets/icons/checkboxChecked.svg';
import { ReactComponent as CheckboxCheckedGreen } from 'assets/icons/checkboxChecked2.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/icons/checkboxUnchecked.svg';
import { ReactComponent as CheckboxUncheckedGreen } from 'assets/icons/checkboxUnchecked2.svg';

type CheckBoxType = 'main' | 'green';

interface CustomCheckBoxProps extends CheckboxProps {
  type?: CheckBoxType;
}
export const Checkbox: FC<CustomCheckBoxProps> = (props) => {
  const { type = 'main', ...rest } = props;

  if (type === 'green') {
    return (
      <CheckboxMUI
        {...rest}
        icon={<CheckboxUncheckedGreen />}
        checkedIcon={<CheckboxCheckedGreen />}
      />
    );
  }
  return (
    <CheckboxMUI
      {...rest}
      icon={<CheckboxUnchecked />}
      checkedIcon={<CheckboxChecked />}
    />
  );
};
