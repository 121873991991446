import { FC, useMemo, useState } from 'react';
import { Box, List, Typography } from '@mui/material';
import { useCompanyByIdQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';
import { DriverDevice } from 'types/api';
import { SortOrder } from 'utils/tableUtils';

import { AccessCompanyDetailSection } from 'components/screens/AdminHomeScreen/components/AccessCompanyDetailSection';
import { DevicesCompanyDetailSection } from 'components/screens/AdminHomeScreen/components/DevicesCompanyDetailSection';
import { SettingsChapter } from 'components/screens/EditSettingsScreen/components/SettingsChapter';
import { DeleteCompanyButton } from 'components/ui/DeleteCompanyButton';
import { DisableCompanyButton } from 'components/ui/DisableCompanyButton';

import { styles } from './styles';

const menuOptions = [t`Access`, t`Devices`];

export const CompanyDetail: FC = () => {
  const [order, setOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<keyof DriverDevice>('created_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deviceFilter, setDeviceFilter] = useState('');
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
  const { data: companyDetail } = useCompanyByIdQuery(
    selectedCompanyId as string,
    {
      skip: !selectedCompanyId,
    },
  );

  const [currentMenuItem, setCurrentMenuItem] = useState(0);

  const menuList = useMemo(
    () =>
      menuOptions.map((option, index) => (
        <SettingsChapter
          key={`${option} ${index}`}
          isActive={currentMenuItem === index}
          label={option}
          onPress={() => setCurrentMenuItem(index)}
        />
      )),
    [currentMenuItem],
  );

  const content = useMemo(() => {
    if (!selectedCompanyId) {
      return (
        <Box sx={styles.emptyContainer}>
          <Typography variant="body1" sx={styles.empty}>
            {t`Select a company to see details`}
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={styles.mainContentWrapper}>
        <List sx={styles.list}>{menuList}</List>
        <Box>
          {currentMenuItem ? (
            <DevicesCompanyDetailSection
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              deviceFilter={deviceFilter}
              setDeviceFilter={setDeviceFilter}
              isCompanyActive={companyDetail?.is_active as boolean}
            />
          ) : (
            <AccessCompanyDetailSection company={companyDetail} />
          )}
        </Box>
      </Box>
    );
  }, [
    selectedCompanyId,
    menuList,
    companyDetail,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    deviceFilter,
    setDeviceFilter,
  ]);

  return (
    <>
      {content}
      {selectedCompanyId && (
        <Box sx={styles.bottomButtons}>
          <DisableCompanyButton />
          <DeleteCompanyButton buttonStyle={{ ml: '24px' }} />
        </Box>
      )}
    </>
  );
};
