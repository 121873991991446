/* eslint-disable camelcase */
import { MESSAGE_EVENT_TYPE } from 'constants/messageEventTypes';
import { ChatPriority } from 'types/dedicatedChat';
import { ProofData, ProofDataInnerData } from 'types/orders';
import { RESOURCE_COLOR } from 'types/resource';

export enum ShipmentStatuses {
  UNASSIGNED = 'Unassigned',
  ASSIGNED = 'Assigned',
  CONFIRMED = 'Confirmed',
  DECLINED = 'Declined',
  PICKED = 'Picked Up',
  DROPPED = 'Delivered',
  FAILED_PICK = 'Failed Pick Up',
  FAILED_DROP = 'Failed Delivery',
  NEW = 'New',
}

export type ErrandDataTitles =
  | 'two_hours_courier'
  | 'three_hours_courier'
  | 'six_hours_courier'
  | 'three_hours_truck'
  | 'six_hours_truck'
  | 'best_distribution'
  | 'best_package'
  | 'best_pall'
  | 'direct_courier'
  | 'direct_truck'
  | 'distribution'
  | 'fixed_price'
  | 'fixed_trip'
  | 'home_delivery'
  | 'home_delivery_evening'
  | 'it_logistics'
  | 'normal_4h_sll'
  | 'panic'
  | 'postal_collection'
  | 'mail_delivery'
  | 'fast_2h_sll'
  | 'terminal_return'
  | 'terminal_return_home_delivery_evening'
  | string;

export enum ErrandDataProofTypes {
  leaveOutside = 'in_front_of_door',
  signOff = 'signature',
  idControl = 'identification_control',
  ageControl = 'age_control',
  smartLockInsideTheBox = 'smartlock_inside_the_box',
  smartLockGlue = 'smartlock_glue',
  smartLockQlocx = 'smartlock_qlocx',
  smartLockIboxen = 'smartlock_iboxen',
  bankId = 'bankid',
  bankIdStrict = 'bankid_strict',
  driverSignOff = 'driver_sign_off',
  idSignature = 'ID_signature',
  idSignatureStrict = 'ID_signature_strict',
}

export type ErrandDataAgeControl = 18 | 20 | null;

export type ErrandProofTypes =
  | 'damage'
  | 'success_call'
  | 'success_chat'
  | 'can_not_find_location'
  | 'can_not_enter_the_building'
  | 'can_not_find_the_door_of_the_address'
  | 'nobody_home'
  | 'receiver_is_home_but_delivery_failed'
  | 'receiver_does_not_want_to_receive_delivery'
  | 'delivery_denied_by_driver'
  | 'another_reason'
  | 'other_reason'
  | 'no_intercom'
  | 'no_door_code'
  | 'no_phone_answer'
  | 'no_intercom_answer'
  | 'no_id_verified'
  | 'no_age_verified'
  | 'not_appropriate'
  | 'invalid_door_code'
  | 'not_home'
  | 'incorrect_address'
  | 'damaged_parcel'
  | 'skipped_call'
  | 'have_called'
  | 'skipped_chat'
  | 'in_front_of_door'
  | 'signature'
  | 'identification_control'
  | 'smart_lock'
  | 'smartlock_inside_the_box'
  | 'smartlock_glue'
  | 'smartlock_qlocx'
  | 'smartlock_iboxen'
  | 'bankid'
  | 'bankid_strict'
  | 'smartlock_home'
  | 'smartlock_boxes'
  | 'driver_sign_off'
  | 'ID_signature'
  | 'ID_signature_strict';

export type ErrandServiceTypes =
  | 'Terminal pickup'
  | 'Distribution'
  | 'Courier'
  | 'Terminal delivery';

export interface ErrandData {
  is_terminal: boolean | null;
  cage: string | null;
  name: string | null;
  phone: string | null;
  enter_code: string | null;
  intercom: string | null;
  terminal_name: string | null;
  terminal_id: string | null;
  title: ErrandDataTitles | null;
  possible_proof_types: ErrandDataProofTypes[] | null;
  age_control: ErrandDataAgeControl | null;
  instruction: string | null;
  company_name: string | null;
  knock_on_the_door: boolean | null;
  quiet_pickup?: boolean | null;
  quiet_delivery?: boolean | null;
  use_doorbell: boolean | null;
  elevator: boolean | null;
  floor: string | null;
  packaging_return: boolean | null;
  on_call_courier: boolean | null;
  time_slotted_lorry: boolean | null;
  new_address: boolean | null;
  unloading_time: number | null;
  ferry_addition_cost: number | null;
  waiting_time: number | null;
  loading_time: number | null;
  manually_created?: boolean | null;
}

export interface Shipment {
  id: string;
  status_id: string;
  status: ShipmentStatuses;
  pickup_errand_id: string;
  booking_id: string;
  delivery_errand_id: string;
  work_shift_id: string | null;
  pickup_errand: Errand;
  delivery_errand: Errand;
  booking: Booking;
}

export interface UnassignedShipments {
  items: Shipment[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface Errand {
  id: string;
  order_number: number | null;
  earliest_date: string;
  latest_date: string;
  eta: string | null;
  service: ErrandServiceTypes;
  data: ErrandData;
  address_id: string | null;
  proof_id: string | null;
  proof: ProofData | null;
  address: Address;
}

export interface TerminalErrand {
  id?: string;
  order_number?: number | null;
  order_number_opter?: number | null;
  earliest_date?: string;
  latest_date?: string;
  eta?: string | null;
  eta_opter?: string | null;
  service?: ErrandServiceTypes;
  data?: ErrandData;
  address_id: string | null;
  proof_id?: string | null;
  proof?: ProofData | null;
  address: Address;
  shipment_id?: string;
  type?: 'pickup_errand' | 'delivery_errand';
  status?: ShipmentStatuses;
  work_shift_id?: string;
  terminal_group_number?: number;
  terminal_id?: string;
  terminal_name?: string;
  _meta?: {
    terminal_group_number: number;
  };
}

export interface Proof {
  id: string;
  driver_id: string;
  data: {
    digits: number[];
    lat: number;
    lng: number;
  };
  proof_type_id: string;
  proof_type: {
    id: string;
    name: ErrandProofTypes;
  };
  date: string;
}

export interface Address {
  id: string | null;
  latitude: number;
  longitude: number;
  country: string | null;
  postal_code: string | null;
  state: string | null;
  city: string | null;
  street: string | null;
  street_number: number | null;
}

export interface Booking {
  id: string;
  freight_bill?: string;
  data?: BookingData;
  packages: BookingPackages;
}

export interface BookingData {
  sender?: string;
  receiver?: string;
  adr?: boolean | null;
  tempered_transport?: boolean | null;
  small_addition_package_charge?: boolean | null;
  big_addition_package_charge?: boolean | null;
  extra_pallet_space?: number | null;
  flatbed_meter?: number | null;
  installation?: number | null;
  goods_type?: BookingDataGoodsType | null;
  general_parcel_type?: ParcelTypes | null;
  goods_marking?: string | null;
  pallet_location?: string | null;
  number?: string | null;
  img_id?: string | null;
  img_name?: string | null;
  img_url?: string | null;
}

export type BookingDataGoodsType =
  | 'general'
  | 'alcohol'
  | 'chemical'
  | 'dangerous'
  | 'electronics'
  | 'fragile'
  | 'medical'
  | 'tempered'
  | string;

export interface BookingPackage {
  id?: string;
  barcode?: string | null;
  data: BookingPackageData;
  created_by_driver?: boolean;
  booking_id?: string;
}

export type BookingPackages = BookingPackage[] | never[];

export interface PackageVolumeSpecifications {
  length?: number | null;
  width?: number | null;
  height?: number | null;
}

export interface BookingPackageData extends PackageVolumeSpecifications {
  parcel_type?: ParcelTypes;
  weight?: number | null;
  photo_urls?: string[] | never[];
  comment?: string;
}

export type ParcelTypes =
  | 'pallet'
  | 'half pallet'
  | 'cardboard box'
  | 'box'
  | 'smallerPackage'
  | 'envelope'
  | 'mix'
  | 'other'
  | string;

export interface WorkShift extends WorkshiftHistoryItem {
  driver_id: string;
  resource_id: string;
}

export interface ResourceApi {
  id: string;
  number: string;
  work_shifts: WorkShift[];
  color: RESOURCE_COLOR;
  time_gap: number;
  groups_names: string[];
}

export type ResourceForSettings = Pick<ResourceApi, 'id' | 'number'>;

export interface GroupingResourcesWithPagination {
  items: ResourceForSettings[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface AddAddressApi {
  latitude: number;
  longitude: number;
  country: string;
  postal_code: string;
  state: string;
  city: string;
  street: string;
  street_number: string;
}

export interface AddTerminalApi {
  hub_name: string;
  hub_code: string;
  ordering?: number;
  address_id: string;
  data: {
    hub_phone: string;
  };
}

export interface OptimizationErrorApi {
  error: string;
}

export interface DriverPositionApi {
  accuracy: number;
  altitude: number;
  dir: number;
  driver_id: number;
  extra: number;
  lat: number;
  lng: number;
  resource_id: string;
  speed: number;
  work_shift_id: string;
}

export interface UpdateChatPriorityApi {
  work_shift_id: string;
  shipment_id: string;
  priority: number;
}

export interface CommonWebsocketMessageData {
  event_creator_id: string | null;
  resource_id: string | null;
  source: 'mobile' | 'web' | 'sync';
}

export interface TerminalScansWebsocketApi extends CommonWebsocketMessageData {
  shipment_id: string;
  resource_id: string;
  work_shift_id: string;
}

export interface ChangeResourceColorEventApi
  extends CommonWebsocketMessageData {
  color: RESOURCE_COLOR;
}

export interface ResourceInGroupChangedEventApi
  extends CommonWebsocketMessageData {
  group_id: string;
  groups_names: string[];
}

export interface ChangeShipmentStatusEventApi
  extends CommonWebsocketMessageData {
  shipment_ids: string[];
  status: ShipmentStatuses;
}

export interface ProofDataUpdateEventApi extends CommonWebsocketMessageData {
  errand_id: string;
  proof_data: ProofDataInnerData;
}

export interface PackageDataUpdateEventApi extends CommonWebsocketMessageData {
  package_id: string;
  booking_id: string;
  proof_data: BookingPackageData;
}

export interface MessageApi {
  data: string;
  event: MESSAGE_EVENT_TYPE;
  target: string;
}

export interface Driver {
  id: string;
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
}

export type Sender = 'driver' | 'manager';

export interface Message {
  type: string;
  content: string;
  shipment_id: string;
  sender: Sender;
  manager_id: string;
  manager_name: string;
}

type QuickResponse = { id: number };
export interface Chat {
  id: string;
  created_at: string;
  updated_at: string;
  chat_id: string;
  read: boolean;
  message: Message;
  quick_responses: QuickResponse[] | null;
  priority: ChatPriority;
  is_group_message: boolean;
}

export interface SendingMessage {
  type: string;
  content: string;
  shipment_id?: string;
  work_shift_id: string;
}

export type ReadMessagesId = Array<string>;

export interface ChatMessageStatusRequest {
  resource_id: string;
  messagesIds: ReadMessagesId;
}

export interface UnreadMessagesByResource {
  work_shift_id: string;
  resource_id: string;
  count: number;
  is_current_work_shift: boolean;
}

export interface ManagerInfoApi {
  id: string;
  email: string;
  name: string;
  is_admin: boolean;
}

export interface CompanyImage {
  id: string;
  img_id: string;
  img_name: string;
  img_url: string;
  company_name: string;
}

export type ErrorDetailArrayItem = {
  msg: string;
};

type ErrorDetail = {
  detail: string | ErrorDetailArrayItem[];
};

export interface ErrorApi {
  data: ErrorDetail;
  status: number;
}

export interface WrappedErrorApi {
  error: ErrorApi;
}

export interface TerminalData {
  off_id: number;
  hub_id: number;
  hub_cou_id: number;
  hub_phone: string;
  hub_contact_2: string;
  hub_phone_2: string;
  hub_email_2: string;
  hub_arr_line_1: string;
  hub_street: string;
  hub_street_no: string;
  hub_ZIP: string;
  hub_city: string;
  hub_changed: string;
  hub_has_mob_device: string;
  hub_has_terminal_app: string;
  country: string;
}

export interface Terminal {
  id: string;
  hub_name: string;
  hub_code: string;
  ordering: number;
  longitude: number;
  latitude: number;
  address_id: string;
  data: TerminalData;
}

export interface ResourceSetting {
  number: string;
  eta_settings: {
    ignore_opter: boolean;
    render_complete_ETA: boolean;
    render_between_gaps: boolean;
    update_by_next_ETA_only: boolean;
    update_based_at_current: boolean;
    is_strict: boolean;
    update_delivery_order_based_on_ETA: boolean;
  };
  errand_settings: {
    ignore_opter_order: boolean;
    manual_order: boolean;
    manual_optimization: boolean;
    auto_optimization: boolean;
    use_global_provider: boolean;
    use_here_sdk: boolean;
    use_cityflow: boolean;
    ignore_terminal_delivery: boolean;
    optimize_add_errand: boolean;
    optimize_del_errand: boolean;
    optimize_errand_picked_meters: boolean;
    optimize_errand_picked_number: boolean;
    use_opter_data_for_returns: boolean;
    disable_push_not_finished_shipments: boolean;
    skip_scan_barcode: boolean;
  };
}

export interface ResourceSettingsApiResponse {
  items: ResourceSetting[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export type ResourcesSettings = Record<string, ResourceSetting>;

export interface CommonOptimizationSettings {
  upd_ETA_when_older_than_60s: boolean;
  upd_ETA_when_errand_complete: boolean;
  upd_ETA_when_errand_added: boolean;
  upd_ETA_when_errand_removed: boolean;
}

export interface SetTimeGapRequest {
  resource_id: string;
  time_gap: number;
}

export interface SearchApiResponse {
  shipment_id: string;
  pickup_errand_id: string;
  delivery_errand_id: string;
  resource_id: string;
  work_shift_id: string;
}

export interface QuickReply {
  id: number;
  emoji: string;
  name: string;
}

export interface WorkshiftHistoryItem {
  id: string;
  start: string;
  end: string;
  driver_name: string;
}

export type AvailableDatesForWorkshifts = Record<string, string[]>;

export interface AvailableWorkshiftOnDate {
  date: string;
  workshifts: string[];
}

export interface LoginResponse {
  manager_id: string;
  is_admin: boolean;
}

export interface Subcontractor {
  id: string;
  name: string;
  is_active: boolean;
  city: string;
  country: string;
}

export interface SubcontractorDetail {
  id: string;
  name: string;
  qr_code: string;
  is_active: boolean;
  city: string;
  email: string;
  country: string;
  created_by: string;
  created_at: string;
}

export interface DriverDevice {
  id: string;
  device_id: string;
  is_active: boolean;
  last_login: string;
  created_at: string;
  notes: string;
}

export interface GroupingResource {
  resource_id: string;
  number: string;
  enabled: boolean;
}

interface CommonGroupApi {
  id: string;
  created_by_id: string;
  title: string;
  description: string;
}

export interface GroupApiResponse extends CommonGroupApi {
  resources_ids: string[];
}

export interface GroupDetailApiResponse extends CommonGroupApi {
  resources: GroupingResource[];
}

export type SettingsWithPaginationParams = {
  page?: number;
  size?: number;
  current_resource_id?: string;
  resource_number?: string[];
  group?: string[];
};

export type AllRoutesPointStatus =
  | 'done'
  | 'fail'
  | 'pending'
  | 'done-fail'
  | 'done-fail-pending'
  | 'done-pending'
  | 'fail-pending';

export type UnreadByShipment = {
  shipment_id: string | null;
  priority: ChatPriority;
  count: number;
};
export interface AllRoutesPoint {
  status: AllRoutesPointStatus;
  errand_ids: string[];
  eta: string | null;
  pod_time: string | null;
  latitude: number;
  longitude: number;
  unread_count: number;
  unread_by_shipments: UnreadByShipment[];
}

export interface AllRoutesApiResponse {
  work_shift_id: string;
  resource_id: string;
  points: AllRoutesPoint[];
}

export type AllRoutesMapPointAddress = {
  country: string;
  postal_code: string;
  state: string;
  city: string;
  street: string;
  street_number: string;
};

export type AllRoutesMapPointErrand = {
  id: string;
  eta: string;
  pod_time: string;
  type: 'pickup' | 'delivery';
  status: AllRoutesPointStatus;
  service: ErrandServiceTypes;
  name: string | null;
  company_name: string | null;
  title: ErrandDataTitles | null;
  latest_date: string | null;
  earliest_date: string | null;
  tempered_transport: boolean;
  proof_type: ErrandDataProofTypes | null;
};

export interface AllRoutesMapPointDetailsApi {
  resource_number: string;
  resource_id: string;
  work_shift_id: string;
  resource_color: string;
  service: string;
  is_terminal: boolean;
  terminal_name: string;
  terminal_id: string;
  address: AllRoutesMapPointAddress;
  errands: AllRoutesMapPointErrand[];
}

export interface DriverHistoricalPath {
  created_at: string;
  leaved_at: string;
  latitude: number;
  longitude: number;
  speed: number;
}
