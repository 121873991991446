import { FC, useCallback } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setShowFilters } from 'store/reducers/companySlice';
import {
  getIsAnyFilterFilled,
  getShowFiltersSelector,
} from 'store/selectors/company';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const OpenCompanyFilterButton: FC = () => {
  const showFilter = useAppSelector(getShowFiltersSelector);
  const isAnyFilterFilled = useAppSelector(getIsAnyFilterFilled);
  const dispatch = useAppDispatch();

  const handlePress = useCallback(() => {
    dispatch(setShowFilters(!showFilter));
  }, [showFilter]);

  return (
    <LightTooltip
      title={
        isAnyFilterFilled
          ? t`You can change company filters`
          : t`You can set company filters`
      }
      arrow
      placement="top"
    >
      <IconButton sx={styles.button} onClick={handlePress}>
        {isAnyFilterFilled ? (
          <FilterAltIcon sx={styles.icon} />
        ) : (
          <FilterAltOutlinedIcon sx={styles.icon} />
        )}
      </IconButton>
    </LightTooltip>
  );
};
