import React, { FC, useCallback, useMemo } from 'react';
import { Box, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format, isToday } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateDedicatedChatDate } from 'store/reducers/chatSlice';
import { getDedicatedChatDateSelector } from 'store/selectors/chat';

import { styles } from './styles';

export const DedicatedChatDate: FC = () => {
  const dedicatedChatDate = useAppSelector(getDedicatedChatDateSelector);
  const dispatch = useAppDispatch();

  const date = useMemo(() => {
    return dedicatedChatDate ? new Date(dedicatedChatDate) : new Date();
  }, [dedicatedChatDate]);

  const handleChangeDatePicker = useCallback((val: Date | null) => {
    dispatch(
      updateDedicatedChatDate(
        isToday(val as Date) ? undefined : format(val as Date, 'yyyy-MM-dd'),
      ),
    );
  }, []);

  return (
    <Box sx={styles.container}>
      <DesktopDatePicker
        inputFormat="yyyy-MM-dd"
        value={date}
        onChange={handleChangeDatePicker}
        renderInput={(params) => (
          <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
        )}
      />
    </Box>
  );
};
