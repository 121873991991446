import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },
  separator: {
    width: '100%',
    // ml: '16px',
    borderColor: 'custom.greyLight2',
    borderStyle: 'solid',
    borderWidth: '1px',
    mt: '16px',
    mb: '16px',
    mr: '-16px',
  },
  signName: {
    display: 'flex',
    flexDirection: 'row',
  },
  signPhotoBlock: {
    height: '160px',
    width: 'calc(100% - 16px)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  digits: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  digitsBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  proxy: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0em',
    color: '#666666',
    ml: '8px',
    mb: '8px',
  },
};
