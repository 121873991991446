import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { Box, ListItem, Switch, SxProps, Typography } from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import {
  useDisableResourcesInGroupMutation,
  useEnableResourcesInGroupMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  setResourceDisableById,
  setResourceEnableById,
} from 'store/reducers/settingsSlice';
import { getMuiColor } from 'theme';
import { t } from 'ttag';
import { GroupDetailApiResponse, GroupingResource } from 'types/api';

import { DeleteGroupResourceButton } from 'components/ui/DeleteGroupResourceButton';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface DroppableGroupItemProps {
  resource: GroupingResource;
  group?: GroupDetailApiResponse;
  icon?: ReactElement;
}

const DroppableGroupDetailResourceItem: FC<DroppableGroupItemProps> =
  React.memo((props) => {
    const { resource, group } = props;
    // @ts-ignore
    const [enableResource] = useEnableResourcesInGroupMutation();
    const [disableResource] = useDisableResourcesInGroupMutation();

    const dispatch = useAppDispatch();

    // @ts-ignore
    const handleSwitchChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        e.stopPropagation();

        if (!checked) {
          dispatch(setResourceDisableById(resource.resource_id));
          disableResource({
            groupId: group?.id as string,
            resourcesIds: [resource.resource_id],
          });
        } else {
          dispatch(setResourceEnableById(resource.resource_id));
          enableResource({
            groupId: group?.id as string,
            resourcesIds: [resource.resource_id],
          });
        }
      },
      [enableResource, disableResource, resource, group],
    );

    const { iconColor, textColor } = useMemo(() => {
      return {
        iconColor: resource?.enabled
          ? getMuiColor('blueLight2')
          : getMuiColor('grey'),
        textColor: {
          color: resource?.enabled ? 'custom.black' : 'custom.grey',
        },
      };
    }, [resource?.enabled]);

    return (
      <ListItem sx={styles.item}>
        <Box sx={styles.content}>
          <Box sx={styles.icon}>
            <CarIcon fill={iconColor} />
          </Box>
          <Typography variant="subtitle1" sx={textColor as SxProps}>
            {resource.number}
          </Typography>
        </Box>
        <Box className="crab-icon-block" sx={styles.grabIconBlock}>
          <LightTooltip
            title={
              resource.enabled
                ? t`You can set resource as disabled`
                : t`You can set resource as enabled`
            }
            arrow
            placement="top"
          >
            <span>
              <Switch
                checked={resource.enabled}
                onChange={handleSwitchChange}
                value={resource.enabled}
              />
            </span>
          </LightTooltip>
          <DeleteGroupResourceButton group={group} resource={resource} />
        </Box>
      </ListItem>
    );
  });

export { DroppableGroupDetailResourceItem };
