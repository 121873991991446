import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  button: {
    color: 'primary.main',
    p: '4px',
  },

  input: {
    minWidth: '370px',
    maxWidth: '1200px',
    ml: '100px',
    bgcolor: 'custom.white',
  },
};
