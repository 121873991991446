import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { UNASSIGNED_TASKS } from 'constants/common';
import { format } from 'date-fns';
import { isEqual, sortBy } from 'lodash';
import {
  useAvailableDatesForWorkshiftsQuery,
  useWorkshiftsByResourceIdQuery,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeSelectedWorkshiftId,
  setWorkshiftId,
} from 'store/reducers/workshiftSlice';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { WorkshiftHistoryItem } from 'types/api';
import { isFirstDateMoreThenSecond } from 'utils/timeUtils';
import {
  defineCurrentWorkshift,
  isCurrentWorkshift,
} from 'utils/workshiftUtil';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface ChooseWorkshiftPopoverProps {
  onClosePopover: () => void;
}

export const ChooseWorkshiftPopover = ({
  onClosePopover,
}: ChooseWorkshiftPopoverProps) => {
  const dispatch = useAppDispatch();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);
  const [datePickerValue, setDatePickerValue] = useState<Date>(
    new Date(Date.now()),
  );

  const { data: datesData } = useAvailableDatesForWorkshiftsQuery(
    { resource_id: selectedResourceId as string },
    { skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS },
  );

  const workshiftsQueryParams = useMemo(() => {
    const params = {
      resource_id: selectedResourceId as string,
    };

    if (!isEqual(datePickerValue, new Date(0))) {
      return {
        ...params,
        search_date: format(new Date(datePickerValue), 'yyyy-MM-dd'),
      };
    }

    if (selectedWorkshiftId) {
      return { ...params, work_shift_id: selectedWorkshiftId as string };
    }

    return {
      ...params,
      search_date: format(Date.now(), 'yyyy-MM-dd'),
    };
  }, [selectedWorkshiftId, selectedResourceId, datePickerValue]);

  const { data: workshiftsData } = useWorkshiftsByResourceIdQuery(
    workshiftsQueryParams,
    { skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS },
  );

  const [currentWorkshift, setCurrentWorkshift] = useState(
    selectedWorkshiftId || defineCurrentWorkshift(workshiftsData),
    // sortBy(workshiftsData as WorksheetHistoryItem[], 'start')?.at(-1)?.id,
  );

  useEffect(() => {
    if (
      !selectedWorkshiftId &&
      isFirstDateMoreThenSecond(
        format(datePickerValue, 'yyyy-MM-dd'),
        datesData?.at(-1)?.date as string,
      )
    ) {
      setCurrentWorkshift(
        sortBy(workshiftsData as WorkshiftHistoryItem[], 'start')?.at(-1)?.id,
      );
    }
  }, [workshiftsData, selectedWorkshiftId, datePickerValue, datesData]);

  const [highlightedWorkshift, setHighlightedWorkshift] = useState<
    string | null
  >(null);

  const isHighlightedCurrent = useMemo(() => {
    if (!highlightedWorkshift) {
      return false;
    }

    if (!workshiftsData || !workshiftsData?.length) {
      return false;
    }

    const foundedWorkshift = workshiftsData.find(
      ({ id }) => id === highlightedWorkshift,
    );

    if (!foundedWorkshift) {
      return false;
    }

    return isCurrentWorkshift(foundedWorkshift.start, foundedWorkshift.end);
  }, [highlightedWorkshift, workshiftsData]);

  const availaleDates = useMemo(() => {
    return datesData ? datesData?.map(({ date }) => date) : [];
  }, [datesData]);

  const shouldDisabledDate = useCallback(
    (d: Date) => {
      return !availaleDates.includes(format(d, 'yyyy-MM-dd'));
    },
    [availaleDates],
  );

  useEffect(() => {
    if (selectedWorkshiftId) {
      const currentDate = datesData?.find(({ workshifts }) =>
        workshifts.includes(selectedWorkshiftId),
      );

      if (currentDate) {
        setDatePickerValue(new Date(currentDate.date));
      }
    } else if (workshiftsData?.[0]?.start) {
      setDatePickerValue(new Date(workshiftsData[0].start));
    }
  }, []);

  const workshiftsList = useMemo(() => {
    if (!workshiftsData?.length) {
      return (
        <Box sx={styles.noWorkshift}>
          <Typography variant="body1">{t`No current workshift`}</Typography>
        </Box>
      );
    }

    return sortBy(workshiftsData as WorkshiftHistoryItem[], 'start').map(
      ({ id, start, end, driver_name }, idx) => {
        const onRowClick = () => {
          setHighlightedWorkshift(id);
        };

        return (
          <React.Fragment key={id}>
            <Box
              sx={{
                ...styles.workshiftRow,
                bgcolor:
                  id === highlightedWorkshift ? 'custom.blueLight3' : 'auto',
              }}
              onClick={onRowClick}
            >
              <Box sx={styles.driverName}>
                <Typography variant="body1">{driver_name}</Typography>
              </Box>
              <Box sx={styles.timeCheckBlock}>
                {isCurrentWorkshift(start, end) && (
                  <LightTooltip
                    title={t`You can't press APPLY button for current shift`}
                    arrow
                    placement="top"
                  >
                    <Chip
                      label="current"
                      color="primary"
                      size="small"
                      variant="outlined"
                      sx={{
                        margin: '0 4px 0 4px',
                        height: '24px',
                        width: '56px',
                        fontSize: '10px',
                      }}
                    />
                  </LightTooltip>
                )}
                <Box sx={styles.timeBlock}>
                  <LightTooltip
                    title={
                      isFirstDateMoreThenSecond(
                        format(datePickerValue, 'yyyy-MM-dd'),
                        format(new Date(start), 'yyyy-MM-dd'),
                      )
                        ? format(new Date(start), 'yyyy-MM-dd')
                        : ''
                    }
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant="h6"
                      color={
                        isFirstDateMoreThenSecond(
                          format(datePickerValue, 'yyyy-MM-dd'),
                          format(new Date(start), 'yyyy-MM-dd'),
                        )
                          ? 'custom.grey'
                          : 'custom.black'
                      }
                    >
                      {format(new Date(start), 'HH:mm')}
                    </Typography>
                  </LightTooltip>
                  <Typography variant="h6" m="0 4px 0 4px">
                    -
                  </Typography>
                  <LightTooltip
                    title={
                      isFirstDateMoreThenSecond(
                        format(new Date(end), 'yyyy-MM-dd'),
                        format(datePickerValue, 'yyyy-MM-dd'),
                      )
                        ? format(new Date(end), 'yyyy-MM-dd')
                        : ''
                    }
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant="h6"
                      color={
                        isFirstDateMoreThenSecond(
                          format(new Date(end), 'yyyy-MM-dd'),
                          format(datePickerValue, 'yyyy-MM-dd'),
                        )
                          ? 'custom.grey'
                          : 'custom.black'
                      }
                    >
                      {format(new Date(end), 'HH:mm')}
                    </Typography>
                  </LightTooltip>
                </Box>
                <Box sx={styles.checkBlock}>
                  {id === currentWorkshift && <CheckIcon color="primary" />}
                </Box>
              </Box>
            </Box>
            {idx < workshiftsData.length - 1 && <Box sx={styles.separator} />}
          </React.Fragment>
        );
      },
    );
  }, [workshiftsData, highlightedWorkshift, datePickerValue]);

  const handleChangeDatePicker = useCallback((val: Date | null) => {
    setDatePickerValue(val as Date);
    setHighlightedWorkshift(null);
  }, []);

  const handleSetCurrentShift = useCallback(() => {
    dispatch(removeSelectedWorkshiftId());
    onClosePopover();
  }, [onClosePopover]);

  const handleApplyPress = useCallback(() => {
    dispatch(setWorkshiftId(highlightedWorkshift as string));
    onClosePopover();
  }, [highlightedWorkshift]);

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={onClosePopover}>
        <CloseIcon />
      </IconButton>

      <Box sx={styles.title}>
        <Typography variant="h1">{t`History of work shifts`}</Typography>
      </Box>

      <Box sx={styles.dateBlock}>
        <DesktopDatePicker
          // label="Date desktop"
          inputFormat="yyyy-MM-dd"
          disabled={!availaleDates?.length}
          shouldDisableDate={shouldDisabledDate}
          value={datePickerValue}
          onChange={handleChangeDatePicker}
          renderInput={(params) => (
            <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
          )}
        />
      </Box>
      <Box sx={styles.workshiftsList}>{workshiftsList}</Box>
      <Box sx={styles.buttonsBlock}>
        <Button
          onClick={handleSetCurrentShift}
          sx={styles.button}
          variant="outlined"
        >
          {t`Current shift`}
        </Button>
        <Button
          disabled={!highlightedWorkshift || isHighlightedCurrent}
          sx={styles.button}
          variant="contained"
          onClick={handleApplyPress}
        >
          {t`Apply`}
        </Button>
      </Box>
    </Box>
  );
};
