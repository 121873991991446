import { Navigate, Route, Routes } from 'react-router-dom';
import {
  APP_ROUTES,
  CHANGE_PAGE_ROUTES,
  EXTRA_SECTION_TYPE,
} from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getIsAdminSelector,
  getIsLoggedInSelector,
} from 'store/selectors/auth';

import { AdminProtectedLayout } from 'components/common/AdminProtectedLayout';
import { ProtectedLayout } from 'components/common/ProtectedLayout';
import { AddAddressScreen } from 'components/screens/AddAddressScreen';
import { AddTerminalScreen } from 'components/screens/AddTerminalScreen';
import { AdminHomeScreen } from 'components/screens/AdminHomeScreen';
import { CreateCompanyScreen } from 'components/screens/CreateCompanyScreen';
import { CreateScreen } from 'components/screens/CreateScreen';
import { DedicatedChatScreen } from 'components/screens/DedicatedChatScreen';
import { EditCompanyScreen } from 'components/screens/EditCompanyScreen';
import { EditScreen } from 'components/screens/EditScreen';
import { EditSettingsScreen } from 'components/screens/EditSettingsScreen';
import { HomeScreen } from 'components/screens/HomeScreen';
import { LoginScreen } from 'components/screens/LoginScreen';
import { NotFoundScreen } from 'components/screens/NotFoundScreen';

import { AdditionsDataTabForm } from '../ChangeScreenForms/components/AdditionsDataTabForm';
import { DeliveryDataTabForm } from '../ChangeScreenForms/components/DeliveryDataTabForm';
import { OrderDetailsTabForm } from '../ChangeScreenForms/components/OrderDetailsTabForm';
import { ParcelDataTabForm } from '../ChangeScreenForms/components/ParcelDataTabForm';
import { PickUpDataTabForm } from '../ChangeScreenForms/components/PickUpDataTabForm';

export const RootRoutes = () => {
  const isLoggedIn = useAppSelector(getIsLoggedInSelector);
  const isAdmin = useAppSelector(getIsAdminSelector);

  return (
    <Routes>
      {isAdmin && (
        <Route element={<AdminProtectedLayout />}>
          <Route index element={<AdminHomeScreen />} />
          <Route
            path={APP_ROUTES.editCompany}
            element={<EditCompanyScreen />}
          />
          <Route
            path={APP_ROUTES.createCompany}
            element={<CreateCompanyScreen />}
          />
          <Route path={APP_ROUTES.notFound} element={<NotFoundScreen />} />
        </Route>
      )}
      {!isAdmin && (
        <Route element={<ProtectedLayout />}>
          <Route
            path={APP_ROUTES.dedicatedChat}
            element={<DedicatedChatScreen />}
          />
          <Route
            index
            element={<HomeScreen extraSection={EXTRA_SECTION_TYPE.map} />}
          />
          <Route
            path={APP_ROUTES.chat}
            element={<HomeScreen extraSection={EXTRA_SECTION_TYPE.chat} />}
          />

          <Route path={APP_ROUTES.edit} element={<EditScreen />}>
            <Route
              index
              element={
                <Navigate to={CHANGE_PAGE_ROUTES.orderDetails} replace />
              }
            />
            <Route
              path={CHANGE_PAGE_ROUTES.orderDetails}
              element={<OrderDetailsTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.pickUp}
              element={<PickUpDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.delivery}
              element={<DeliveryDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.parcelData}
              element={<ParcelDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.additions}
              element={<AdditionsDataTabForm />}
            />
          </Route>

          <Route path={APP_ROUTES.create} element={<CreateScreen />}>
            <Route
              index
              element={<Navigate to={CHANGE_PAGE_ROUTES.orderDetails} />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.orderDetails}
              element={<OrderDetailsTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.pickUp}
              element={<PickUpDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.delivery}
              element={<DeliveryDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.parcelData}
              element={<ParcelDataTabForm />}
            />
            <Route
              path={CHANGE_PAGE_ROUTES.additions}
              element={<AdditionsDataTabForm />}
            />
          </Route>

          <Route
            path={APP_ROUTES.addTerminal}
            element={<AddTerminalScreen />}
          />
          <Route path={APP_ROUTES.addAddress} element={<AddAddressScreen />} />
          <Route
            path={APP_ROUTES.editSettings}
            element={<EditSettingsScreen />}
          />
          <Route path={APP_ROUTES.notFound} element={<NotFoundScreen />} />
        </Route>
      )}

      {isLoggedIn ? (
        <Route
          path={APP_ROUTES.auth}
          element={<Navigate to={APP_ROUTES.root} replace />}
        />
      ) : (
        <Route path={APP_ROUTES.auth} element={<LoginScreen />} />
      )}

      <Route path="*" element={<Navigate to={APP_ROUTES.notFound} replace />} />
    </Routes>
  );
};
