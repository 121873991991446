import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    width: '100%',
    p: '16px',

    bgcolor: 'common.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderRadius: '6px',
  },
};
