import { createSelector } from '@reduxjs/toolkit';
import { sortBy, uniqBy } from 'lodash';
import { getUnreadMessagesList } from 'store/selectors/chat';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import { createTypedSelector } from 'store/utils';
import { Coordinates, Resource } from 'types/resource';

export const getResourcesListSelector = createTypedSelector(
  (state) => state.resource.resourcesList,
);

export const getResourceByIdSelector = (resourceId: string | null = null) =>
  createSelector(getResourcesListSelector, (resources) => {
    return resources.find(({ id }) => id === resourceId);
  });

export const getOnlyWithUnreadSelector = createTypedSelector(
  (state) => state.resource.onlyWithUnread,
);

export const getResourcesFilters = createTypedSelector(
  (state) => state.resource.resourcesFilters,
);

export const getResourcesGroups = createTypedSelector(
  (state) => state.resource.resourcesGroups,
);

export const getResourcesFiltersAndGroups = createTypedSelector(
  (state) => state.resource.resourcesFiltersAndGroups,
);

export const getResourcesColorsFilters = createTypedSelector(
  (state) => state.resource.resourcesColorsFilters,
);

export const getIsAnyResourcesFilterSetSelector = createTypedSelector(
  (state) =>
    !!state.resource.resourcesFilters.length ||
    !!state.resource.resourcesGroups.length ||
    !!state.resource.resourcesColorsFilters.length,
);

export const getFilteredResourcesListSelector = createSelector(
  [
    getResourcesListSelector,
    getResourcesFilters,
    getResourcesGroups,
    getResourcesColorsFilters,
    getOnlyWithUnreadSelector,
    getUnreadMessagesList,
    getOnlyCurrentWorkshiftSelector,
  ],
  (
    resourceList,
    filters,
    groups,
    colors,
    onlyWithUnread,
    unreadMessages,
    onlyCurrentWorkshift,
  ) => {
    const setFilterByUnread = (resources: Resource[]) => {
      if (!onlyWithUnread || !unreadMessages.length) {
        return resources;
      }

      if (onlyCurrentWorkshift) {
        return resources.filter((r) =>
          unreadMessages.find(
            (u) => u.resource_id === r.id && u.is_current_work_shift,
          ),
        );
      }

      return resources.filter((r) => {
        const workshiftsIds = r.work_shifts.map(({ id }) => id);

        return !!unreadMessages.find(
          (u) =>
            u.resource_id === r.id && workshiftsIds.includes(u.work_shift_id),
        );
      });
    };

    const filteredByFilter = filters.length
      ? resourceList.filter(({ number }) =>
          filters.some((filter) =>
            number.toLowerCase().startsWith(filter.toLowerCase()),
          ),
        )
      : [];

    const filteredByGroups = groups.length
      ? resourceList.filter(
          ({ groups_names }) =>
            groups_names &&
            groups_names.some((group) => groups.includes(group)),
        )
      : [];

    const filteredByGroupsAndFilters = sortBy(
      uniqBy([...filteredByFilter, ...filteredByGroups], 'id'),
      'number',
    );

    if (filteredByGroupsAndFilters.length) {
      return colors.length
        ? setFilterByUnread(
            filteredByGroupsAndFilters.filter(({ color }) =>
              colors.includes(color),
            ),
          )
        : setFilterByUnread(filteredByGroupsAndFilters);
    }

    const coloredResources = colors.length
      ? resourceList.filter(({ color }) => colors.includes(color))
      : resourceList;

    return setFilterByUnread(coloredResources);
  },
);

export const getIsCurrentResourceHasWorkshift = (resourceId: string | null) =>
  createTypedSelector((state) => {
    const foundResource = state.resource.resourcesList.find(
      ({ id }) => id === resourceId,
    );

    if (!foundResource) {
      return false;
    }

    return !!foundResource.work_shifts?.length;
  });

export const getSelectedResourceIdSelector = createTypedSelector(
  (state) => state.resource.selectedResourceId,
);

export const getResourceFilterSelector = createTypedSelector((state) =>
  state.resource.resourceFilter ? state.resource.resourceFilter : undefined,
);

export const getSelectedResourceNumberSelector = createTypedSelector(
  (state) =>
    state.resource.resourcesList.find(
      ({ id }) => state.resource.selectedResourceId === id,
    )?.number || '0',
);
export const getSelectedResourceTimeGapSelector = createTypedSelector(
  (state) =>
    state.resource.resourcesList.find(
      ({ id }) => state.resource.selectedResourceId === id,
    )?.time_gap || '0',
);

export const getResourcesCoordinates = createTypedSelector(
  (state) =>
    (state.resource.resourcesList
      .map((resource) => (resource?.coordinates ? resource.coordinates : null))
      .filter(Boolean) as Coordinates[]) || [],
);

export const getResourcesWithCoordinates = createTypedSelector((state) =>
  state.resource.resourcesList.filter((resource) => !!resource?.coordinates),
);
