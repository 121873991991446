import { FC, useMemo, useState } from 'react';
import { Box, List } from '@mui/material';
import { ReactComponent as Logo } from 'assets/icons/logoLarge.svg';
import { t } from 'ttag';

import { LocalizationMenu } from 'components/common/LocalizationMenu';
import { SettingsChapter } from 'components/screens/EditSettingsScreen/components/SettingsChapter';
import { Section } from 'components/ui/Section';

import { LoginScreenForm } from './components/LoginScreenForm';
import { styles } from './styles';

export const LoginScreen: FC = () => {
  const [requiredAdmin, setRequiredAdmin] = useState(false);

  const menuOptions = useMemo(() => [t`Login`, t`Admin`], []);

  const menuList = useMemo(
    () =>
      menuOptions.map((option, index) => {
        const currenMenuItem = requiredAdmin ? 1 : 0;

        return (
          <SettingsChapter
            key={`${option} ${index}`}
            isActive={currenMenuItem === index}
            label={option}
            onPress={() => setRequiredAdmin((prev) => !prev)}
          />
        );
      }),
    [menuOptions, requiredAdmin],
  );

  return (
    <Box sx={styles.container}>
      <LocalizationMenu sx={styles.localizationButton} />
      <Box>
        <Box sx={styles.logo}>
          <Logo />
        </Box>
        <List sx={styles.list}>{menuList}</List>
        <Section withoutlabelAndButtons>
          <LoginScreenForm requiredAdmin={requiredAdmin} />
        </Section>
      </Box>
    </Box>
  );
};
