import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  button: {
    color: 'primary.main',
    p: 0,
    paddingTop: '4px',
    fontSize: 0,
  },

  icon: {
    fontSize: '20px',
    cursor: 'pointer',
  },
};
