import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  form: {
    mt: '20px',
    mb: '32px',
  },

  content: {
    maxWidth: '1090px',
    mb: '32px',
  },

  gridItem: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
  },
};
