import { FC } from 'react';
import { List } from '@mui/material';
import { t } from 'ttag';

import { InnerSectionListItem } from 'components/common/InnerSectionListItem';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerCodesProps {
  orderNumber?: string | number | null;
  freightBill?: string | number | null;
}

export const DetailsSectionInnerCodes: FC<DetailsSectionInnerCodesProps> = ({
  orderNumber,
  freightBill,
}) => {
  const freightbillItem = freightBill && (
    <InnerSectionListItem label={t`Freightbill`} value={freightBill} />
  );

  const orderNumberItem = orderNumber && (
    <InnerSectionListItem label={t`Booking number`} value={orderNumber} />
  );

  return (
    <InnerSection label={t`Codes`} sx={styles.container}>
      <List sx={styles.list}>
        {orderNumberItem}
        {freightbillItem}
      </List>
    </InnerSection>
  );
};
