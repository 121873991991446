import { FC, useCallback, useMemo } from 'react';
import { IconButton, Skeleton } from '@mui/material';
import HereLogo from 'assets/images/hereSDKLogo.png';
import { throttle } from 'lodash';
import { useStartHereSDKOptimizationMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setCalcOptimization } from 'store/reducers/settingsSlice';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getIsCalcOptimization } from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface HereOptButtonProps {
  disabled?: boolean;
}
export const HereOptButton: FC<HereOptButtonProps> = ({ disabled = false }) => {
  const [startOptimization] = useStartHereSDKOptimizationMutation();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const isCalcOptimization = useAppSelector(getIsCalcOptimization);
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    if (selectedResourceId) {
      startOptimization(selectedResourceId);
      dispatch(setCalcOptimization('hereSDK'));
    }
  }, [selectedResourceId]);

  const tooltipTitle = useMemo(() => {
    if (disabled) {
      return t`You can make it enabled in resource settings`;
    }

    return isCalcOptimization === 'hereSDK'
      ? t`Here SDK is calculated ...`
      : t`Here SDK Optimization`;
  }, [disabled, isCalcOptimization]);

  return (
    <LightTooltip title={tooltipTitle} arrow placement="top">
      <span>
        <IconButton
          sx={styles.button}
          onClick={throttle(clickHandler, 200)}
          disabled={!!isCalcOptimization || disabled}
        >
          {isCalcOptimization === 'hereSDK' ? (
            <Skeleton>
              <img src={HereLogo} alt="Here SDK logo" width="24px" />
            </Skeleton>
          ) : (
            <img src={HereLogo} alt="Here SDK logo" width="24px" />
          )}
        </IconButton>
      </span>
    </LightTooltip>
  );
};
