import { COMPANY_ID_URL_PARAM } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const removeSelectedCompanyIdFromUrl = () =>
  removeValueFromUrl(COMPANY_ID_URL_PARAM);

export const setSelectedCompanyIdToUrl = (value: string) =>
  setValueToUrl(COMPANY_ID_URL_PARAM, value);

export const getSelectedCompanyIdFromUrl =
  getValueFromUrl(COMPANY_ID_URL_PARAM);
