import { FC, memo } from 'react';
import { ImageList } from '@mui/material';
import { createUniqueId } from 'utils/uniqIdUtils';

import { ParcelDataImagesListItem } from '../ParcelDataImagesListItem';

import { styles } from './styles';

interface ParcelDataImagesListProps {
  imageUrls: string[];
}

export const ParcelDataImagesList: FC<ParcelDataImagesListProps> = memo(
  ({ imageUrls }) => {
    const content = imageUrls.map((url, idx) => (
      <ParcelDataImagesListItem
        key={`img-${createUniqueId()}`}
        src={url}
        itemIndex={idx}
        photoUrls={imageUrls}
      />
    ));
    return (
      <ImageList cols={4} sx={styles.list}>
        {content}
      </ImageList>
    );
  },
);
