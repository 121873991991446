import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  BoxProps,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SxProps,
  Typography,
} from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useLogoutUserMutation, useManagerInfoQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { removeSelectedOrderId } from 'store/reducers/orderSlice';
import { removeSelectedResourceId } from 'store/reducers/resourceSlice';
import { t } from 'ttag';
import { getLocale, setLocale } from 'utils/i18nInit';
import { stringAvatar } from 'utils/stringUtils';

import { styles } from './styles';

interface LocalizationMenuProps extends BoxProps {
  isAdmin: boolean;
  menuStyles?: SxProps;
}

export const MainMenu: FC<LocalizationMenuProps> = ({ isAdmin, ...rest }) => {
  const locale = getLocale();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoutUser] = useLogoutUserMutation();

  const { data } = useManagerInfoQuery();

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuClick = useCallback(() => {
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const englishLabelStyle = useMemo(() => {
    if (locale === 'en') {
      return { ...styles.menuLabel, color: 'primary.main' };
    }

    return styles.menuLabel;
  }, []);

  const swedishLabelStyle = useMemo(() => {
    if (locale === 'sv') {
      return { ...styles.menuLabel, color: 'primary.main' };
    }

    return styles.menuLabel;
  }, []);

  const handleEnglishClick = useCallback(() => {
    if (locale !== 'en') {
      setLocale('en');
    }

    setAnchorEl(null);
  }, [locale]);

  const handleSwedishClick = useCallback(() => {
    if (locale !== 'sv') {
      setLocale('sv');
    }

    setAnchorEl(null);
  }, [locale]);

  const handleLogout = useCallback(() => {
    setAnchorEl(null);
    logoutUser();
  }, []);

  const handleAddCompanyPress = useCallback(() => {
    setAnchorEl(null);
    navigate(APP_ROUTES.createCompany);
  }, [navigate]);

  const handleAddAddressPress = useCallback(() => {
    setAnchorEl(null);
    dispatch(removeSelectedResourceId());
    dispatch(removeSelectedOrderId());
    navigate(APP_ROUTES.addAddress);
  }, [navigate, dispatch]);

  const handleAddTerminalPress = useCallback(() => {
    setAnchorEl(null);
    dispatch(removeSelectedResourceId());
    dispatch(removeSelectedOrderId());
    navigate(APP_ROUTES.addTerminal);
  }, [navigate, dispatch]);

  return (
    <Box {...rest}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
      >
        <MenuIcon color={anchorEl ? 'primary' : 'inherit'} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 192,
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={styles.menu}
      >
        <MenuItem sx={styles.userMenuItem}>
          <Box sx={styles.userContainer}>
            <Avatar sx={styles.avatar}>{stringAvatar(data?.name)}</Avatar>
            <Box>
              <Typography sx={styles.username}>{data?.name}</Typography>
              <Typography sx={styles.email}>{data?.email}</Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider sx={{ marginX: '16px' }} />

        {isAdmin ? (
          <MenuItem onClick={handleAddCompanyPress}>
            <ListItemText
              primary={t`Add company`}
              primaryTypographyProps={styles.menuLabel}
            />
          </MenuItem>
        ) : (
          <Box>
            <MenuItem onClick={handleAddTerminalPress}>
              <ListItemText
                primary={t`Add terminal`}
                primaryTypographyProps={styles.menuLabel}
              />
            </MenuItem>
            <MenuItem onClick={handleAddAddressPress}>
              <ListItemText
                primary={t`add address`}
                primaryTypographyProps={styles.menuLabel}
              />
            </MenuItem>
          </Box>
        )}

        <Divider sx={{ marginX: '16px' }} />

        <MenuItem onClick={handleMenuClick}>
          <ListItemText
            primary={t`Language`}
            primaryTypographyProps={styles.menuLabel}
          />
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <MenuItem onClick={handleEnglishClick}>
            <ListItemText
              primary={t`English`}
              primaryTypographyProps={englishLabelStyle}
            />
            {locale === 'en' && (
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
            )}
          </MenuItem>
          <MenuItem onClick={handleSwedishClick}>
            <ListItemText
              primary={t`Swedish`}
              primaryTypographyProps={swedishLabelStyle}
            />
            {locale === 'sv' && (
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
            )}
          </MenuItem>
        </Collapse>

        <Divider sx={{ marginX: '16px' }} />

        <MenuItem onClick={handleLogout}>
          <ListItemText
            primary={t`Log out`}
            primaryTypographyProps={{
              ...styles.menuLabel,
              color: 'custom.red',
            }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};
