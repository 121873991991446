import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    minWidth: '423px',
    width: '423px',
    maxWidth: '423px',
    ml: '20px',
    // flexGrow: 1,
  },

  halfContainer: {
    width: '423px',
    ml: '20px',
    flexGrow: 1,
    height: '50%',
  },

  warning: {
    mt: '10px',
    textAlign: 'center',
  },

  iconButton: {
    p: 0,
  },

  icon: {
    fontSize: '20px',
    color: 'primary.main',
  },
};
