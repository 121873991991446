import React, { FC, useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { Box } from '@mui/system';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeDedicatedChatGroupsAndFilters,
  removeDedicatedChatPriorityGroupsAndFilters,
  updateDedicatedChatPriorityFilters,
  updateDedicatedChatResourcesFilters,
  updateDedicatedChatResourcesFiltersAndGroups,
  updateDedicatedChatResourcesGroups,
} from 'store/reducers/chatSlice';
import { setToast } from 'store/reducers/settingsSlice';
import {
  getDedicatedChatFiltersAndGroups,
  getDedicatedChatPriorityFilters,
  getIsAnyDedicatedChatFilterSetSelector,
} from 'store/selectors/chat';
import { t } from 'ttag';
import { ChatPriority } from 'types/dedicatedChat';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

const prioritiesOptions = ['0', '1', '2'];

const chatPriorityColorMapper: Record<string, string> = {
  '0': 'custom.green',
  '1': 'custom.blue2',
  '2': 'custom.red',
};

const chatPriorityLabelMapper: Record<string, string> = {
  '0': t`Closed`,
  '1': t`Open`,
  '2': t`High priority`,
};

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const resourcesFiltersAndGroups = useAppSelector(
    getDedicatedChatFiltersAndGroups,
  );

  const isAnyFilters = useAppSelector(getIsAnyDedicatedChatFilterSetSelector);

  const chatPriority = useAppSelector(getDedicatedChatPriorityFilters);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupsAndFiltersInputValue, setGroupsAndFiltersInputValue] = useState<
    string[]
  >(resourcesFiltersAndGroups);
  const [priorityInputValue, setPriorityInputValue] =
    useState<string[]>(chatPriority);

  const isAnyDataInInputs = useMemo(() => {
    return (
      isAnyFilters ||
      groupsAndFiltersInputValue.length ||
      priorityInputValue.length
    );
  }, [isAnyFilters, groupsAndFiltersInputValue, priorityInputValue]);

  const { data } = useGetAllGroupsQuery({});

  const allGroups = useMemo(() => {
    return data ? data.map((group) => group.title) : [];
  }, [data]);

  const handleChange = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters and groups, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = groupsAndFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setGroupsAndFiltersInputValue(updatedValues);
    },
    [allGroups, groupsAndFiltersInputValue],
  );

  // @ts-ignore
  const handleColorsChange = useCallback((_, values: string[]) => {
    setPriorityInputValue(values);
  }, []);

  const handleApplyPress = useCallback(() => {
    let emptyCounter = 0;

    if (!groupsAndFiltersInputValue?.length) {
      dispatch(removeDedicatedChatGroupsAndFilters());
      emptyCounter++;
    }

    if (!priorityInputValue?.length) {
      dispatch(updateDedicatedChatPriorityFilters([]));
      emptyCounter++;
    }

    if (emptyCounter === 2) {
      return;
    }

    const filters: string[] = [];
    const groups: string[] = [];

    groupsAndFiltersInputValue.forEach((value) => {
      if (allGroups.includes(value)) {
        groups.push(value);

        return;
      }

      filters.push(value);
    });

    dispatch(updateDedicatedChatResourcesGroups(groups));
    dispatch(updateDedicatedChatResourcesFilters(filters));
    dispatch(
      updateDedicatedChatResourcesFiltersAndGroups(groupsAndFiltersInputValue),
    );

    dispatch(
      updateDedicatedChatPriorityFilters(
        priorityInputValue.map((v) => Number(v)) as ChatPriority[],
      ),
    );

    onClosePopover();
  }, [groupsAndFiltersInputValue, priorityInputValue, onClosePopover]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeDedicatedChatPriorityGroupsAndFilters());
    setGroupsAndFiltersInputValue([]);
    setPriorityInputValue([]);
    onClosePopover();
  }, [onClosePopover]);

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={onClosePopover}>
        <CloseIcon />
      </IconButton>

      <Box sx={styles.title}>
        <Typography
          variant="h1"
          sx={styles.popoverTitle}
        >{t`Resources, groups and chats priorities`}</Typography>
      </Box>

      <LightTooltip
        title={t`You can set up here groups and filters for resources in the settings screen. The maximum quantity of tags is five.`}
        arrow
        TransitionComponent={Zoom}
        leaveDelay={1000}
        placement="right"
      >
        <Autocomplete
          value={groupsAndFiltersInputValue}
          multiple
          id="tags-filled"
          onChange={handleChange}
          options={allGroups}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                size="small"
                sx={{ fontSize: '10px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // label={t`Groups and Filters`}
              placeholder={t`Search by resources or groups of resources`}
            />
          )}
          sx={styles.firstFilter}
        />
      </LightTooltip>
      <Autocomplete
        value={priorityInputValue}
        multiple
        freeSolo={false}
        id="colorred-cars-filters"
        onChange={handleColorsChange}
        options={prioritiesOptions}
        renderOption={(props, option) => (
          <li {...props} key={`Priority list item ${option}`}>
            <Typography
              sx={{
                color: chatPriorityColorMapper[option],
                textTransform: 'uppercase',
              }}
            >
              {chatPriorityLabelMapper[option]}
            </Typography>
          </li>
        )}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={chatPriorityLabelMapper[option]}
              {...getTagProps({ index })}
              size="small"
              sx={{
                textTransform: 'uppercase',
                color: chatPriorityColorMapper[option],
                fontSize: '10px',
                fontWeight: 600,
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={t`Choose chat priority`} />
        )}
        sx={styles.secondFilter}
      />

      <Button
        disabled={
          groupsAndFiltersInputValue.length === 0 &&
          priorityInputValue.length === 0
        }
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>
      <Button
        disabled={!isAnyDataInInputs}
        sx={styles.button}
        variant="contained"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
