import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  item: {
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    cursor: 'pointer',
    borderColor: 'custom.greyLight',
    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },
    '&:first-of-type': {
      borderRadius: '6px 6px 0 0',
    },
  },

  selected: {
    bgcolor: 'custom.blueLight',
    '&:hover': {
      bgcolor: 'custom.blueLight',
    },
  },

  icon: {
    mr: '16px',
    fontSize: '20px',
    color: 'custom.blue',
    display: 'flex',
    alignItems: 'center',
  },

  labelCircle: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    bgcolor: 'primary.main',
    margin: '3px',
  },

  content: {
    display: 'flex',
    flexDirection: 'row',
  },
};
