import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  item: {
    width: '100%',
    p: 0,
    pt: '16px',
    cursor: 'pointer',
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: '15px',
  },
  unread: {
    cursor: 'pointer',
    zIndex: 100,
  },
  divider: {
    mt: '16px',
  },
  expandButtonBlock: {
    display: 'flex',
    justifyContent: 'center',
  },
  parcels: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: 'custom.black',
  },
  parcelsBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '52px',
  },
  logoBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70px',
    height: '50px',
  },
  barcodeItem: {
    display: 'flex',
    width: '100%',
    height: '52px',
    alignItems: 'center',
  },
};
