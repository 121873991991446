import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  workshiftRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px 0 16px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  driverName: {
    ml: '24px',
  },
  timeCheckBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  timeBlock: {
    display: 'flex',
  },
  checkBlock: {
    display: 'flex',
    width: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    mr: '24px',
  },
  separator: {
    borderBottomWidth: '1px',
    borderBottomColor: 'custom.greyLight2',
    borderBottomStyle: 'solid',
    width: '100%',
    marginLeft: '24px',
  },
  title: {
    ml: '24px',
  },
  dateBlock: {
    mt: '24px',
    ml: '24px',
    width: '180px',
  },
  workshiftsList: {
    mt: '24px',
  },
  buttonsBlock: {
    display: 'flex',
    margin: '0 16px 0 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  button: {
    width: '100%',
  },
  close: {
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    mr: '24px',
  },

  divider: {
    borderWidth: '1px',
    borderColor: 'custom.greyLight2',
    borderStyle: 'solid',
    width: '100%',
    margin: '16px 0 16px 0',
    height: '1px',
  },

  switchersBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px 16px 24px',
  },
  switcherRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  switcherLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: 'custom.graphite',
  },

  infoBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 24px 16px 24px',
  },

  infoIcon: {
    color: 'custom.blue',
  },

  infoText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: 'custom.graphite',
    marginLeft: '8px',
  },
};
