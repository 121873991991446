import React, { FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as MoveToCurrentIcon } from 'assets/icons/move_to_current_workshift.svg';
import { useMoveShipmentToCurrentWorkshiftMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { removeSelectedOrderId } from 'store/reducers/orderSlice';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';

import { styles } from 'components/ui/ChooseWorkshiftButton/styles';
import { LightTooltip } from 'components/ui/LightTooltip';

interface MoveToCurrentWorkshiftButtonProps {
  resourceId: string;
  shipmentId: string;
  workshiftId: string;
}

export const MoveToCurrentWorkshiftButton: FC<MoveToCurrentWorkshiftButtonProps> =
  React.memo((props) => {
    const { resourceId, shipmentId, workshiftId } = props;
    const [moveToCurrentWorkshift, { isLoading }] =
      useMoveShipmentToCurrentWorkshiftMutation();
    const dispatch = useAppDispatch();

    const handleIconPress = useCallback(async () => {
      moveToCurrentWorkshift({ resourceId, shipmentId, workshiftId })
        .unwrap()
        .then(() => {
          dispatch(removeSelectedOrderId());

          dispatch(
            setToast({
              message: t`You have successfully moved shipment to the current work shift`,
              severity: 'success',
            }),
          );
        })
        .catch((e) => {
          dispatch(
            setToast({
              message: e?.data?.detail || t`Oops, something happened`,
              severity: 'error',
            }),
          );
        });
    }, [resourceId, shipmentId, workshiftId]);

    return (
      <LightTooltip
        title={t`You can move shipment to the current workshift`}
        arrow
        placement="top"
      >
        <span>
          <IconButton
            sx={styles.button}
            onClick={handleIconPress}
            disabled={isLoading}
          >
            <MoveToCurrentIcon />
          </IconButton>
        </span>
      </LightTooltip>
    );
  });
