import React, { FC, useCallback, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeResourcesColorsGroupsAndFilters,
  removeResourcesGroupsAndFilters,
  updateResourcesColorsFilters,
  updateResourcesFilters,
  updateResourcesFiltersAndGroups,
  updateResourcesGroups,
} from 'store/reducers/resourceSlice';
import { setToast } from 'store/reducers/settingsSlice';
import {
  getResourcesColorsFilters,
  getResourcesFiltersAndGroups,
} from 'store/selectors/resource';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import { t } from 'ttag';
import { RESOURCE_COLOR } from 'types/resource';
import {
  coloredFilterOptions,
  mappedColoredTitles,
} from 'utils/coloredFilterUtils';
import { resourceColorsMappedToMui } from 'utils/resourceUtils';

import { LightTooltip } from 'components/ui/LightTooltip';
import { ColoredResourcesTooltipContent } from 'components/ui/SetResourceFilterButton/components/ColoredResourcesTooltipContent';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}
const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const resourcesFiltersAndGroups = useAppSelector(
    getResourcesFiltersAndGroups,
  );

  const coloredResources = useAppSelector(getResourcesColorsFilters);
  const isOnlyCurrentWorkshiftSelector = useAppSelector(
    getOnlyCurrentWorkshiftSelector,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, setInputValue] = useState('');

  const { data } = useGetAllGroupsQuery({});

  const allGroups = useMemo(() => {
    return data ? data.map((group) => group.title) : [];
  }, [data]);

  const handleChange = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      if (!values?.length) {
        dispatch(removeResourcesGroupsAndFilters());

        return;
      }

      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters and groups, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = resourcesFiltersAndGroups.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      const filters: string[] = [];
      const groups: string[] = [];

      updatedValues.forEach((value) => {
        if (allGroups.includes(value)) {
          groups.push(value);

          return;
        }

        filters.push(value);
      });

      dispatch(updateResourcesGroups(groups));
      dispatch(updateResourcesFilters(filters));

      dispatch(updateResourcesFiltersAndGroups(updatedValues));
    },
    [allGroups, resourcesFiltersAndGroups],
  );

  // @ts-ignore
  const handleColorsChange = useCallback((_, values: string[]) => {
    if (!values?.length) {
      dispatch(updateResourcesColorsFilters([]));

      return;
    }

    dispatch(updateResourcesColorsFilters(values as RESOURCE_COLOR[]));
  }, []);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeResourcesColorsGroupsAndFilters());
    onClosePopover();
  }, [onClosePopover]);

  return (
    <Box sx={styles.container}>
      <IconButton sx={styles.close} onClick={onClosePopover}>
        <CloseIcon />
      </IconButton>

      <Box sx={styles.title}>
        <Typography
          variant="h1"
          sx={styles.popoverTitle}
        >{t`Resources, groups and statuses of resources`}</Typography>
      </Box>

      <LightTooltip
        title={t`You can set up here groups and filters for resources in the settings screen. The maximum quantity of tags is five.`}
        arrow
        TransitionComponent={Zoom}
        leaveDelay={1000}
        placement="right"
      >
        <Autocomplete
          value={resourcesFiltersAndGroups}
          multiple
          id="tags-filled"
          onChange={handleChange}
          options={allGroups}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                size="small"
                sx={{ fontSize: '10px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // label={t`Groups and Filters`}
              placeholder={t`Search by resources or groups of resources`}
            />
          )}
          sx={styles.firstFilter}
        />
      </LightTooltip>

      {isOnlyCurrentWorkshiftSelector && (
        <>
          <Autocomplete
            value={coloredResources}
            multiple
            freeSolo={false}
            id="colorred-cars-filters"
            onChange={handleColorsChange}
            options={coloredFilterOptions}
            inputValue={inputValue}
            // onInputChange={handleInputChange}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                <Box>
                  <CarIcon
                    fill={resourceColorsMappedToMui[option as RESOURCE_COLOR]}
                    style={{ width: '20px', height: '20px' }}
                  />
                </Box>
                <Typography ml="8px">
                  {mappedColoredTitles[option as RESOURCE_COLOR]}
                </Typography>
              </li>
            )}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  variant="outlined"
                  icon={
                    <CarIcon
                      fill={resourceColorsMappedToMui[option as RESOURCE_COLOR]}
                      style={{ width: '20px', height: '20px' }}
                    />
                  }
                  // label={option}
                  {...getTagProps({ index })}
                  size="small"
                  // sx={{ fontSize: '10px' }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                // label={t`Groups and Filters`}
                placeholder={t`Choose resources colors`}
              />
            )}
            sx={styles.secondFilter}
          />

          <Box sx={styles.infoBlock}>
            <LightTooltip
              title={<ColoredResourcesTooltipContent />}
              TransitionComponent={Zoom}
              leaveDelay={1000}
              placement="right-start"
              sx={{
                '.MuiTooltip-tooltip': {
                  maxWidth: 'none',
                  width: '560px',
                },
              }}
            >
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </LightTooltip>
            <Typography sx={styles.infoText}>{t`Info`}</Typography>
          </Box>
        </>
      )}

      <Button
        // disabled={!highlightedWorkshift || isHighlightedCurrent}
        sx={styles.button}
        variant="contained"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
