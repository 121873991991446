import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  logo: {
    mb: '42px',
    display: 'flex',
    justifyContent: 'center',
  },

  title: {
    pl: '16px',
    mb: '4px',
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
  },

  localizationButton: {
    position: 'absolute',
    top: '31px',
    right: '62px',
  },
  list: {
    ml: '16px',
    display: 'flex',
    alignItems: 'flex-end',
  },
};
