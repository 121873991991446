import { FC } from 'react';
import { Grid } from '@mui/material';

import { DeliveryDataTabAddressSection as DeliveryAddressSection } from '../DeliveryDataTabAddressSection';
import { DeliveryDataTabDetailsSection as DeliveryDetailsSection } from '../DeliveryDataTabDetailsSection';
import { DeliveryDataTabSenderSection as DeliverySenderSection } from '../DeliveryDataTabSenderSection';

export const DeliveryDataTabForm: FC = () => {
  return (
    <Grid container columns={3} spacing={2}>
      <Grid item mobile={2}>
        <Grid container columns={1} rowGap={2}>
          <Grid item mobile={1}>
            <DeliveryAddressSection />
          </Grid>
          <Grid item mobile={1}>
            <DeliverySenderSection />
          </Grid>
        </Grid>
      </Grid>
      <Grid item mobile={1}>
        <DeliveryDetailsSection />
      </Grid>
    </Grid>
  );
};
