import React, { FC, useEffect, useRef, useState } from 'react';
import {
  AlertColor,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { resetToast } from 'store/reducers/settingsSlice';

import { Alert } from '../Alert';

export interface SnackbarProps extends MuiSnackbarProps {
  severity: AlertColor;
  message: string;
}

export const Snackbar: FC<SnackbarProps> = ({ severity, message, ...rest }) => {
  const [open, setOpen] = useState(true);
  const timer = useRef<NodeJS.Timeout>();

  const dispatch = useAppDispatch();

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(resetToast());
    setOpen(false);
  };

  useEffect(() => {
    timer.current = setTimeout(() => {
      setOpen(false);
      dispatch(resetToast());
    }, 5000);

    return () => timer.current && clearTimeout(timer.current);
  }, []);

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      {...rest}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
