import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { GROUP_SECTION_SCREEN } from 'constants/common';

export interface GroupSettingContext {
  currentScreen: GROUP_SECTION_SCREEN;
  setCurrentScreen: Dispatch<SetStateAction<GROUP_SECTION_SCREEN>>;
  currentGroupId: string | null;
  setCurrentGroupId: Dispatch<SetStateAction<string | null>>;
}

export const GroupSettingContext = createContext<
  GroupSettingContext | undefined
>(
  undefined,
  //   {
  //   currentScreen: GROUP_SECTION_SCREEN.allGroups,
  //   // eslint-disable-next-line @typescript-eslint/no-empty-function
  //   setCurrentScreen: () => {},
  //   currentGroupId: null,
  //   // eslint-disable-next-line @typescript-eslint/no-empty-function
  //   setCurrentGroupId: () => {},
  // }
);

interface GroupSettingProviderProps {
  children: ReactNode;
}

const GroupSettingProvider: FC<GroupSettingProviderProps> = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState<GROUP_SECTION_SCREEN>(
    GROUP_SECTION_SCREEN.allGroups,
  );
  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null);

  return (
    <GroupSettingContext.Provider
      value={{
        currentScreen,
        setCurrentScreen,
        currentGroupId,
        setCurrentGroupId,
      }}
    >
      {children}
    </GroupSettingContext.Provider>
  );
};

export { GroupSettingProvider };
