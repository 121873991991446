import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { IconButton } from '@mui/material';
import { APP_ROUTES, CHANGE_PAGE_ROUTES } from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  setSelectedOrderId,
  setUnassignedPageNumberByShipmentId,
} from 'store/reducers/orderSlice';
import { updateIsPreviousScreenChat } from 'store/reducers/settingsSlice';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface EditButtonProps {
  orderId?: string;
  disabled?: boolean;
  shipmentId?: string;
}
export const EditButton: FC<EditButtonProps> = React.memo(
  ({ orderId, shipmentId, disabled = false }) => {
    const { navigateWithQueryParams } = useNavigateWithQueryParams();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();

    const handleClick = useCallback(() => {
      if (shipmentId) {
        dispatch(setUnassignedPageNumberByShipmentId(shipmentId));
      }

      if (orderId) {
        dispatch(setSelectedOrderId(orderId));
      }

      if (pathname.includes('chat')) {
        dispatch(updateIsPreviousScreenChat(true));
      }

      navigateWithQueryParams(
        `${APP_ROUTES.edit}/${CHANGE_PAGE_ROUTES.orderDetails}`,
      );
    }, [orderId, shipmentId, pathname]);

    if (disabled) {
      return (
        <LightTooltip
          title={t`You could set enable edit order in resource settings`}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              sx={styles.button}
              onClick={handleClick}
              disabled={disabled}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
          </span>
        </LightTooltip>
      );
    }

    return (
      <IconButton sx={styles.button} onClick={handleClick} disabled={disabled}>
        <BorderColorOutlinedIcon />
      </IconButton>
    );
  },
);
