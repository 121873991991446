import { t } from 'ttag';
import { ErrandDataProofTypes } from 'types/api';

const proofTypeRenderData: Record<ErrandDataProofTypes, string> = {
  [ErrandDataProofTypes.signOff]: t`Signature`,
  [ErrandDataProofTypes.driverSignOff]: t`Driver Sign Off`,
  [ErrandDataProofTypes.idControl]: t`ID Control`,
  [ErrandDataProofTypes.bankId]: t`Bank ID`,
  [ErrandDataProofTypes.bankIdStrict]: t`Bank ID Strict`,
  [ErrandDataProofTypes.leaveOutside]: t`In front of door`,
  [ErrandDataProofTypes.idSignature]: t`ID signature`,
  [ErrandDataProofTypes.idSignatureStrict]: t`ID signature strict`,
  [ErrandDataProofTypes.smartLockInsideTheBox]: t`Smart lock inside the box`,
  [ErrandDataProofTypes.smartLockGlue]: t`Smart lock glue`,
  [ErrandDataProofTypes.smartLockQlocx]: t`Smart lock qlocx`,
  [ErrandDataProofTypes.smartLockIboxen]: t`Smart lock iboxen`,
  [ErrandDataProofTypes.ageControl]: t`Age control`,
};
export const useProofTypeName = (
  proofType: ErrandDataProofTypes | null | undefined,
) => {
  return {
    proofTypeName: proofType ? proofTypeRenderData[proofType] : '',
  };
};
