import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  autocompleteItem: {
    '&&&': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      textTransform: 'initial',
    },
  },
};
