import React, { FC, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import { t } from 'ttag';
import { Driver } from 'types/api';

interface AllRoutesMapTooltipProps {
  driver?: Driver;
  onClose: () => void;
}

export const AllRoutesMapTooltip: FC<AllRoutesMapTooltipProps> = React.memo(
  (props: AllRoutesMapTooltipProps) => {
    const { driver, onClose } = props;

    const { firstName, lastName } = useMemo(() => {
      return {
        firstName: driver?.first_name || '',
        lastName: driver?.last_name || '',
      };
    }, [driver]);

    return (
      <>
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, top: 0 }}
        >
          <CloseIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
        <Typography
          variant="caption"
          color="custom.graphite"
          fontSize={14}
          fontWeight={400}
        >
          {t`Driver name: `}
        </Typography>
        <br />
        <Typography
          variant="caption"
          color="custom.black"
          fontSize={14}
          fontWeight={600}
        >
          {`${firstName} ${lastName}`}
        </Typography>
        <br />
        <Typography
          variant="caption"
          color="custom.graphite"
          fontSize={14}
          fontWeight={400}
        >
          {t`Driver phone number: `}
        </Typography>
        <br />

        <Typography
          variant="caption"
          color="custom.black"
          fontSize={14}
          fontWeight={600}
        >
          {driver?.phone}
        </Typography>
      </>
    );
  },
);
