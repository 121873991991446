import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  button: {
    color: 'primary.main',
    p: 0,
    fontSize: 0,
    marginTop: '4px',
  },

  icon: {
    fontSize: '20px',
  },
  popup: {
    width: '396px',
    padding: '24px 0 24px 0',
    borderRadius: '12px',
    borderWidth: '1px',
    borderColor: 'grey',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  },
};
