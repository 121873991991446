import { FC } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldDatePicker } from 'components/common/FieldDatePicker';
import { FieldTimePicker } from 'components/common/FieldTimePicker';

export const OrderDetailsTabTerminalDataSection: FC = () => {
  return (
    <ChangeScreenSection label={t`Terminal data`}>
      <Grid container columns={2} rowGap={4} spacing={2}>
        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="terminalBeLatest"
            label={t`Be at terminal latest date`}
            // maxDate={values.deliveryEarliest}
            disabled
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="terminalBeLatest"
            label={t`Be at terminal latest time`}
            disabled
          />
        </Grid>

        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="terminalLeaveLatest"
            label={t`Leave terminal latest date`}
            disabled
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="terminalLeaveLatest"
            label={t`Leave terminal latest time`}
            disabled
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
