import React, { useContext, useMemo } from 'react';
import { GROUP_SECTION_SCREEN } from 'constants/common';
import { GroupSettingContext } from 'context/GroupSetting/GroupSettingProvider';

import { AllGroupsList } from 'components/screens/EditSettingsScreen/components/AllGroupsList';
import { CreateEditGroup } from 'components/screens/EditSettingsScreen/components/CreateEditGroup';
import { GroupDetail } from 'components/screens/EditSettingsScreen/components/GroupDetail';
import { Section } from 'components/ui/Section';

import { styles } from './styles';

const GroupsSection = () => {
  // @ts-ignore
  const { currentScreen, currentGroupId } = useContext(GroupSettingContext);

  const renderCurrentScreen = useMemo(() => {
    switch (currentScreen) {
      case GROUP_SECTION_SCREEN.allGroups:
      default:
        return <AllGroupsList />;
      case GROUP_SECTION_SCREEN.createGroup:
        return <CreateEditGroup />;
      case GROUP_SECTION_SCREEN.groupDetail:
        return <GroupDetail />;
      case GROUP_SECTION_SCREEN.editGroup:
        return <CreateEditGroup />;
    }
  }, [currentScreen, currentGroupId]);

  return <Section sx={styles.container}>{renderCurrentScreen}</Section>;
};

export { GroupsSection };
