import React, { useCallback } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, Button } from '@mui/material';
import { UNASSIGNED_TASKS } from 'constants/common';
import { useSetTimeGapMutation } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getTimeGapButtonStatusByResourceId } from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export function TurnShirtGapTimer() {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const [setTimeGap] = useSetTimeGapMutation();
  const isTimeGapButtonBlocked = useAppSelector(
    getTimeGapButtonStatusByResourceId(selectedResourceId),
  );

  const handleButtonPress = useCallback(async () => {
    if (selectedResourceId && selectedResourceId !== UNASSIGNED_TASKS) {
      await setTimeGap({ resource_id: selectedResourceId, time_gap: 3000 });
    }
  }, [selectedResourceId]);

  return (
    <LightTooltip
      title={
        isTimeGapButtonBlocked
          ? t`Now Driver sends his position with 3 sec frequency`
          : t`Press to set temporary 3 sec frequency`
      }
      arrow
      placement="top"
    >
      <Box>
        <Button
          variant="contained"
          sx={styles.button}
          startIcon={
            !isTimeGapButtonBlocked ? <ScheduleIcon /> : <UpdateIcon />
          }
          onClick={handleButtonPress}
          disabled={isTimeGapButtonBlocked}
        >
          {isTimeGapButtonBlocked ? '3 sec' : 'Set 3 sec'}
        </Button>
      </Box>
    </LightTooltip>
  );
}
