export const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
  },

  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
    mb: '16px',
  },

  subtitle: {
    textTransform: 'uppercase',
    fontSize: '10px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
  },

  detailsUnderOrdersBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  section: {
    display: 'flex',
    width: '100%',
    // height: '208px',
  },

  input: {
    minHeight: '70px',
    '& .MuiFormHelperText-root': {
      ml: 0,
    },
  },

  bottomButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '120px',
    pt: '10px',
  },

  button: {
    width: '200px',
    height: '40px',
    marginTop: '24px',
  },

  cancelButton: {
    width: '200px',
    marginTop: '24px',
    bgcolor: 'custom.white',
    height: '40px',
  },
  list: {
    pt: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',
  },
  namedColumnItem: {
    borderColor: 'custom.grey2',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      backgroundColor: 'custom.grey3',
      minHeight: '40px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      position: 'sticky',
      top: '0px',
    },
    '&:not(:first-of-type)': {
      backgroundColor: 'custom.white',
      minHeight: '72px',
      borderRightWidth: '1px',
      borderLeftWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
    },
  },

  namedContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
    overflow: 'scroll',
  },

  rowItem: {
    minWidth: '72px',
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'custom.grey2',
    '&:nth-of-type(3)': {
      borderTopLeftRadius: '5px',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    '&:not(:first-of-type)': {
      borderRightWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderTopStyle: 'solid',
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
    },
    '&:first-of-type': {
      borderTopLeftRadius: '5px',
      borderWidth: '1px',
      borderStyle: 'solid',
      position: 'sticky',
      left: '0px',
      zIndex: 5,
    },

    '&:last-of-type': {
      borderTopRightRadius: '5px',
    },
  },

  columnItem: {
    minHeight: '72px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'custom.grey2',
    backgroundColor: 'custom.white',
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    '&:first-of-type': {
      backgroundColor: 'custom.grey3',
      minHeight: '40px',
      position: 'sticky',
      top: '0px',
      zIndex: 2,
    },
    '&:not(:last-of-type)': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
    },
  },

  checkBoxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '70vh',
  },
  subScreenContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // overflow: 'auto',
    marginBottom: '50px',
  },
  paginationLabel: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: 'custom.graphite',
    textTransform: 'none',
  },
  columnLabel: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '18px',
  },
};
