import React from 'react';
import { Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { t } from 'ttag';
import { DriverHistoricalPath } from 'types/api';

import { styles } from './styles';

interface HistoricalPointInfoProps {
  point: DriverHistoricalPath;
}

export const HistoricalPointInfo = (props: HistoricalPointInfoProps) => {
  const { point } = props;

  return (
    <Stack direction="column" sx={styles.container}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="caption"
          color="custom.graphite"
          fontSize={14}
          fontWeight={400}
        >
          {t`Arrived: `}
        </Typography>
        <br />
        <Typography
          variant="caption"
          color="custom.black"
          fontSize={14}
          fontWeight={600}
        >
          {format(new Date(point.created_at), 'HH:mm')}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="caption"
          color="custom.graphite"
          fontSize={14}
          fontWeight={400}
        >
          {t`Lived: `}
        </Typography>
        <Typography
          variant="caption"
          color="custom.black"
          fontSize={14}
          fontWeight={600}
        >
          {format(new Date(point.leaved_at), 'HH:mm')}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="caption"
          color="custom.graphite"
          fontSize={14}
          fontWeight={400}
        >
          {t`Speed: `}
        </Typography>
        <Typography
          variant="caption"
          color="custom.black"
          fontSize={14}
          fontWeight={600}
        >
          {`${(point.speed * 3.6).toFixed(2)} km/h`}
        </Typography>
      </Stack>
    </Stack>
  );
};
