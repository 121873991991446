import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  gridContainer: {
    justifyContent: 'space-between',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listItem: {
    p: '16px 4px',

    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',

    '&:last-of-type': {
      border: 'none',
      pb: 0,
    },
  },
  label: {
    mt: '4px',
  },
};
