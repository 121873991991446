import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as WaveIcon } from 'assets/icons/Waveform.svg';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateCurrentAudioId } from 'store/reducers/feedbackSlice';
import { getCurrentAudioId } from 'store/selectors/feedback';
import { createUniqueId } from 'utils/uniqIdUtils';

import { styles } from './styles';

interface AudioItemProps {
  audioUrl: string;
  itemId: number;
}

const AudioItem = memo((props: AudioItemProps) => {
  const { audioUrl, itemId } = props;
  const dispatch = useAppDispatch();
  const currentAudioId = useAppSelector(getCurrentAudioId);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0); // Total duration

  const isPlaying = useMemo(() => {
    return currentAudioId === itemId;
  }, [currentAudioId, itemId]);

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        dispatch(updateCurrentAudioId(null));
      } else {
        audioRef.current.play();
        dispatch(updateCurrentAudioId(itemId));
      }
    }
  }, [isPlaying, dispatch, itemId]);

  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, []);

  // Load duration once metadata is available
  const handleLoadedMetadata = useCallback(() => {
    if (audioRef.current) {
      setDuration(
        audioRef.current.duration !== Infinity ? audioRef.current.duration : 0,
      );
    }
  }, []);

  const handleAudioEnd = useCallback(() => {
    setCurrentTime(0);
    dispatch(updateCurrentAudioId(null));
  }, []);

  useEffect(() => {
    if (!isPlaying && currentTime && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [isPlaying, currentTime]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
      audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
      audioRef.current.addEventListener('ended', handleAudioEnd); // Handle when the audio ends
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
        );
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, [handleTimeUpdate, handleLoadedMetadata, handleAudioEnd]);

  // Format time as minutes:seconds
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Box sx={styles.itemContainer}>
      {/* Play/Pause Button */}
      <IconButton onClick={togglePlayPause} sx={{ color: 'primary.main' }}>
        {isPlaying ? (
          <StopCircleOutlinedIcon fontSize="large" />
        ) : (
          <PlayCircleOutlineIcon fontSize="large" />
        )}
      </IconButton>

      <WaveIcon />

      {/* Remaining Time (Duration - Current Time) */}
      <Typography variant="body2">
        {formatTime(Math.max(duration - currentTime, 0))}{' '}
        {/* Ensures it doesn't go below 0 */}
      </Typography>

      {/* Audio Element */}
      <audio ref={audioRef} src={audioUrl} preload="metadata" />
    </Box>
  );
});

interface FeedbackAudioListProps {
  audioUrls: string[];
}

export const FeedbackAudioList = memo((props: FeedbackAudioListProps) => {
  const { audioUrls } = props;

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}
    >
      {audioUrls.map((item, index) => (
        <AudioItem key={createUniqueId()} audioUrl={item} itemId={index} />
      ))}
    </Box>
  );
});
