import React, { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as GreenSwitcher } from 'assets/icons/greenSwitcher.svg';
import { t } from 'ttag';
import { Order, OrderTypes } from 'types/orders';

import { ParcelDataImagesList } from 'components/screens/HomeScreen/components/DetailsSectionInnerParcelDataItem/components/ParcelDataImagesList';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerPickupOrDeliveryPhotosProps {
  order: Order;
}
export const DetailsSectionInnerPickupOrDeliveryPhotos: FC<DetailsSectionInnerPickupOrDeliveryPhotosProps> =
  memo(({ order }) => {
    const sectionTitle =
      order.type === OrderTypes.DROP ? 'Delivery' : 'Pick up';

    const damagedGoods = order.proofData?.data?.is_damaged;
    const damagedGoodsItem = damagedGoods && (
      <Box sx={styles.damageBlock}>
        <GreenSwitcher />
        <Typography sx={styles.damageTitle}>{t`Damaged goods`}</Typography>
      </Box>
    );
    const photoUrls = order.proofData?.data?.order_confirmation_photo_urls;
    const photosItem = !!photoUrls?.length && (
      <ParcelDataImagesList imageUrls={photoUrls} />
    );

    const comment = order.proofData?.data?.order_confirmation_comment;
    const commentItem = comment && (
      <Typography sx={styles.commentItem}>{comment}</Typography>
    );

    return (
      <InnerSection label={t`${sectionTitle} photos`} sx={styles.container}>
        {damagedGoodsItem}
        {photosItem}
        {commentItem}
      </InnerSection>
    );
  });
