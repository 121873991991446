import { FC } from 'react';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import { Grid, IconButton, Link, Typography } from '@mui/material';
import { t } from 'ttag';

import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerRecipientProps {
  name?: string;
  phone?: string | null;
}

export const DetailsSectionInnerRecipient: FC<
  DetailsSectionInnerRecipientProps
> = ({ name, phone }) => {
  return (
    <InnerSection label={t`Contact information`} sx={styles.container}>
      <Grid container columns={12} sx={styles.gridContainer}>
        <Grid item mobile={9} sx={styles.gridItem}>
          <Typography variant="h3">{name}</Typography>
          <Typography sx={styles.phone}>{phone}</Typography>
        </Grid>
        {phone && (
          <Grid item mobile={2} sx={styles.gridItem}>
            <Link href={`tel:${phone}`}>
              <IconButton sx={styles.iconButton}>
                <PhoneIcon sx={styles.icon} />
              </IconButton>
            </Link>
          </Grid>
        )}
      </Grid>
    </InnerSection>
  );
};
