import { FC, useMemo, useState } from 'react';

import { FeedbackDetail } from './components/FeedbackDetail';
import { FeedbacksTable } from './components/FeedbacksTable';

export const Feedback: FC = () => {
  const [selectedFeedbackId, setSelectedFeedbackId] = useState<number | null>(
    null,
  );

  const content = useMemo(() => {
    if (!selectedFeedbackId) {
      return <FeedbacksTable setCurrentFeedbackId={setSelectedFeedbackId} />;
    }

    return (
      <FeedbackDetail
        feedbackId={selectedFeedbackId}
        setCurrentFeedbackId={setSelectedFeedbackId}
      />
    );
  }, [setSelectedFeedbackId, selectedFeedbackId]);

  return content;
};
