import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { ReactComponent as ChooseWorkshiftIcon } from 'assets/icons/choose_workshift_icon.svg';
import { UNASSIGNED_TASKS } from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { t } from 'ttag';

import { ChooseWorkshiftPopover } from 'components/ui/ChooseWorkshiftButton/components/ChooseWorkShiftPopover';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const ChooseWorkshiftButton: FC = () => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [shouldOpenPopover, setShouldOpenPopover] = useState(false);

  useEffect(() => {
    if (anchorEl) {
      setShouldOpenPopover(true);
    }
  }, [anchorEl]);

  const handleIconPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setShouldOpenPopover(false);
  }, []);

  const isDisabled = useMemo(
    () => selectedResourceId === UNASSIGNED_TASKS,
    [selectedResourceId],
  );

  return (
    <>
      <LightTooltip
        title={
          isDisabled
            ? t`There are no available workshifts`
            : t`You can choose workshift`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton
            sx={styles.button}
            onClick={handleIconPress}
            disabled={isDisabled}
          >
            <ChooseWorkshiftIcon />
          </IconButton>
        </span>
      </LightTooltip>
      {shouldOpenPopover && (
        <Popover
          open={Boolean(anchorEl)}
          PaperProps={{ sx: styles.popup }}
          elevation={12}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ChooseWorkshiftPopover onClosePopover={handleClose} />
        </Popover>
      )}
    </>
  );
};
