import { createTypedSelector } from 'store/utils';

export const getResourceOrderBy = createTypedSelector(
  (state) => state.deviceInfoReport.orderByResource,
);

export const getResourceOrder = createTypedSelector(
  (state) => state.deviceInfoReport.orderResource,
);

export const getResourcePage = createTypedSelector(
  (state) => state.deviceInfoReport.pageResource,
);

export const getResourceSize = createTypedSelector(
  (state) => state.deviceInfoReport.sizeResource,
);

export const getResourceNumberFilter = createTypedSelector(
  (state) => state.deviceInfoReport.resourceNumberFiltersResource,
);

export const getDriverOrderBy = createTypedSelector(
  (state) => state.deviceInfoReport.orderByDriver,
);

export const getDriverOrder = createTypedSelector(
  (state) => state.deviceInfoReport.orderDriver,
);

export const getDriverPage = createTypedSelector(
  (state) => state.deviceInfoReport.pageDriver,
);

export const getDriverSize = createTypedSelector(
  (state) => state.deviceInfoReport.sizeDriver,
);

export const getDriverNameFilter = createTypedSelector(
  (state) => state.deviceInfoReport.nameFiltersDriver,
);

export const getDriverDeviceFilter = createTypedSelector(
  (state) => state.deviceInfoReport.devicesFiltersDriver,
);

export const getDriverAndroidFilter = createTypedSelector(
  (state) => state.deviceInfoReport.androidFiltersDriver,
);

export const getDriverStartTime = createTypedSelector(
  (state) => state.deviceInfoReport.startDateDriver,
);

export const getDriverEndTime = createTypedSelector(
  (state) => state.deviceInfoReport.endDateDriver,
);
