import { FC, ReactNode, useMemo } from 'react';
import { Paper, PaperProps, SxProps } from '@mui/material';

import { styles } from './styles';

interface InnerSectionPaperProps extends PaperProps {
  children: ReactNode;
  customStyles?: SxProps;
}

export const InnerSectionPaper: FC<InnerSectionPaperProps> = ({
  children,
  customStyles,
  ...rest
}) => {
  const paperStyle = useMemo(() => {
    if (customStyles) {
      return { ...styles.container, ...customStyles };
    }

    return styles.container;
  }, [customStyles]);

  return (
    <Paper sx={paperStyle as SxProps} {...rest}>
      {children}
    </Paper>
  );
};
