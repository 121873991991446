export const styles = {
  list: {
    height: '100%',
    p: 0,
    overflowY: 'scroll',
    borderRadius: '6px',
  },
  collapsedItem: {
    flexDirection: 'row',
    height: '41px',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    cursor: 'pointer',
    borderColor: 'custom.greyLight',
    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },
    // '&:first-of-type': {
    //   borderRadius: '6px 6px 0 0',
    // },
  },
  timeCheckBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
