import { FC } from 'react';
import { Box, BoxProps, Paper, SxProps, Typography } from '@mui/material';

import { styles } from './styles';

interface InnerSectionProps extends BoxProps {
  children?: React.ReactNode;
  variant?: 'contained' | 'empty';
  customStyle?: SxProps;
  label?: string;
}

export const InnerSection: FC<InnerSectionProps> = ({
  children,
  variant = 'contained',
  label,
  customStyle = {},
  ...rest
}) => {
  const variantStyles =
    variant === 'contained' ? styles.variantContained : styles.variantEmpty;
  const containerStyles = {
    ...styles.container,
    ...variantStyles,
    ...customStyle,
  } as SxProps;
  return (
    <Box {...rest}>
      <Typography sx={styles.label} variant="h2">
        {label}
      </Typography>
      <Paper sx={containerStyles}>{children}</Paper>
    </Box>
  );
};
