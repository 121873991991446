import { useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useResourcesQuery, useResourcesV2Query } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getResourceFilterSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';

export const useSelectedResource = () => {
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const resourceFilter = useAppSelector(getResourceFilterSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const commonDate = useAppSelector(getCommonDateSelector);

  const { data: resourcesListV2 } = useResourcesV2Query(
    {
      resource_name: resourceFilter,
      date: commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd'),
    },
    { skip: onlyCurrentWorkshift, refetchOnMountOrArgChange: true },
  );

  const { data: resourcesListV1, isFetching } = useResourcesQuery(
    resourceFilter,
    { skip: !onlyCurrentWorkshift },
  );

  const resourcesList = useMemo(() => {
    if (onlyCurrentWorkshift) {
      return resourcesListV1;
    }

    return resourcesListV2;
  }, [onlyCurrentWorkshift, resourcesListV1, resourcesListV2]);

  const getSelectedResource = useCallback(() => {
    if (!resourcesList?.length) {
      return undefined;
    }

    const resource = resourcesList.find(
      (resource) => resource.id === selectedResourceId,
    );

    return resource ? resource : undefined;
  }, [selectedResourceId, resourcesList]);

  const selectedResource = useMemo(
    () => getSelectedResource(),
    [getSelectedResource],
  );

  return { selectedResource, isFetching };
};
