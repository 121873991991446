import { FC, useEffect } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import {
  AGE_CONTROL_OPTIONS,
  PROOF_TYPE_OPTIONS,
} from 'constants/editOrderOptions';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useIsManualOrderSetting } from 'hooks/useIsManualOrderSetting';
import { useOrdersList } from 'hooks/useOrdersList';
import { t } from 'ttag';
import { isProofTypeSupportsAgeControl } from 'utils/changeScreenUtils';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldDatePicker } from 'components/common/FieldDatePicker';
import { FieldInput } from 'components/common/FieldInput';
import { FieldTimePicker } from 'components/common/FieldTimePicker';

export const OrderDetailsTabDeliveryDataSection: FC = () => {
  const { formik, isCreateScreen } = useChangeScreenContext();
  const { isManualOrderSetting } = useIsManualOrderSetting();
  const { ordersList } = useOrdersList();

  const { values, errors, setFieldValue, touched } = formik;

  useEffect(() => {
    if (isCreateScreen && !isManualOrderSetting) {
      if (!ordersList || !ordersList?.length) {
        setFieldValue('deliveryOrderNumber', 2);
      } else {
        const arrayOrderNumbers = ordersList.map(
          (order) => order?.orderNumber || 0,
        );
        const maxOrderNumber = Math.max(...arrayOrderNumbers);
        setFieldValue('deliveryOrderNumber', maxOrderNumber + 2);
      }
    }
  }, [isCreateScreen, ordersList, isManualOrderSetting]);

  return (
    <ChangeScreenSection label={t`Delivery data`}>
      <Grid container columns={2} rowGap={4} spacing={2}>
        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="deliveryEarliest"
            label={t`Earliest date`}
            required
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="deliveryEarliest"
            label={t`Earliest time`}
            required
          />
        </Grid>

        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="deliveryLatest"
            label={t`Latest date`}
            required
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="deliveryLatest"
            label={t`Latest time`}
            required
          />
        </Grid>

        <Grid item mobile={1}>
          <Field
            component={FieldDatePicker}
            name="deliveryEta"
            label={t`ETA date`}
          />
        </Grid>
        <Grid item mobile={1}>
          <Field
            component={FieldTimePicker}
            name="deliveryEta"
            label={t`ETA time`}
          />
        </Grid>
        <Grid item mobile={2}>
          <Grid container columns={5} spacing={2}>
            <Grid item mobile={3}>
              <Autocomplete
                options={PROOF_TYPE_OPTIONS || null}
                onChange={(_, selectedOption) => {
                  const { value } = selectedOption || {};
                  setFieldValue('deliveryProofType', value);
                  if (
                    typeof value !== 'undefined' &&
                    !isProofTypeSupportsAgeControl(value)
                  ) {
                    setFieldValue('deliveryAgeControl', null);
                  }
                }}
                value={
                  PROOF_TYPE_OPTIONS.find(
                    (option) => option.value === values.deliveryProofType,
                  ) || null
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t`Proof of delivery method`}
                    name="deliveryProofType"
                    placeholder={t`Enter proof type name`}
                    error={
                      touched.deliveryProofType && !!errors.deliveryProofType
                    }
                    helperText={
                      touched.deliveryProofType && errors.deliveryProofType
                    }
                    required
                  />
                )}
              />
            </Grid>
            <Grid item mobile={2}>
              <Autocomplete
                options={AGE_CONTROL_OPTIONS || null}
                onChange={(_, selectedOption) => {
                  setFieldValue('deliveryAgeControl', selectedOption?.value);
                }}
                value={
                  AGE_CONTROL_OPTIONS.find(
                    (option) => option.value === values.deliveryAgeControl,
                  ) || null
                }
                fullWidth
                disabled={
                  !!values.deliveryProofType &&
                  !isProofTypeSupportsAgeControl(values.deliveryProofType)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t`Age control`}
                    name="deliveryAgeControl"
                    placeholder={t`Choose age`}
                    error={!!errors.deliveryAgeControl}
                    helperText={errors.deliveryAgeControl}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="deliveryOrderNumber"
            type="number"
            label={t`Order number`}
            placeholder={t`Enter order number`}
            fullWidth
            required
            disabled={!isManualOrderSetting}
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
