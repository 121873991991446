import React, { memo, useCallback } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'ttag';
import { FeedbackStatus } from 'types/feedback';

import { FeedbackStatusSelect } from 'components/common/FeedbackStatusSelect';

import { styles } from './styles';

interface FeedbackDetailHeaderProps {
  feedbackId: number;
  resourceNumber?: string;
  status?: FeedbackStatus;
  setCurrentFeedbackId: React.Dispatch<React.SetStateAction<number | null>>;
}
export const FeedbackDetailHeader = memo((props: FeedbackDetailHeaderProps) => {
  const { feedbackId, resourceNumber, setCurrentFeedbackId, status } = props;

  const handleBackPress = useCallback(() => {
    setCurrentFeedbackId(null);
  }, [setCurrentFeedbackId]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles.container}
    >
      <Box sx={styles.buttonBlock}>
        <IconButton onClick={handleBackPress}>
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h6" sx={styles.title}>
          {resourceNumber} ({t`Feedback reporting`})
        </Typography>
      </Box>

      <FeedbackStatusSelect feedbackId={feedbackId} status={status} />
    </Stack>
  );
});
