import { pick } from 'lodash';
import { createTypedSelector } from 'store/utils';

export const getMapSettingsSelector = createTypedSelector((state) =>
  pick(state.mapSettings, [
    'showResources',
    'showAllRoutes',
    'showHistoricalPath',
    'showETA',
    'showPOD',
  ]),
);

export const getMapSettingsDateSelector = createTypedSelector(
  (state) => state.mapSettings.settingsDate,
);

export const getShowUnreadInMapSelector = createTypedSelector(
  (state) => state.mapSettings.showUnreadInMap,
);

export const getLastMapCenterSelector = createTypedSelector(
  (state) => state.mapSettings.lastMapCenter,
);

export const getDetailedMapPointsSelector = createTypedSelector(
  (state) => state.mapSettings.detailedMapPoints,
);

export const getAllRoutesResourcesGroupsSelector = createTypedSelector(
  (state) => state.mapSettings.allRoutesResourcesGroups,
);

export const getAllRoutesResourcesGroupsAndFiltersSelector =
  createTypedSelector(
    (state) => state.mapSettings.allRoutesResourcesGroupsAndFilters,
  );

export const getDriverHistoricalPathSelector = createTypedSelector(
  (state) => state.mapSettings.driverHistoricalPath,
);

export const getAllRoutesResourcesFiltersSelector = createTypedSelector(
  (state) => state.mapSettings.allRoutesResourcesFilters,
);
export const getIsAnyFiltersOrGroupsSelector = createTypedSelector(
  (state) =>
    !!state.mapSettings.allRoutesResourcesFilters.length ||
    !!state.mapSettings.allRoutesResourcesGroups.length,
);
