import React, { FC, ReactElement, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { Box, ListItem, Typography } from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car.svg';
import { ReactComponent as GrabIcon } from 'assets/icons/grab_icon.svg';

import { styles } from './styles';

interface DraggableResourceItemProps {
  id: string;
  number: string;
  icon?: ReactElement;
}

const DraggableResourceItem: FC<DraggableResourceItemProps> = React.memo(
  (props) => {
    const { id, number, icon = <CarIcon /> } = props;

    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'resource',
      item: { id, number },
      end: () => {
        // const dropResult = monitor.getDropResult<ResourceForSettings>();
        //
        // if (item && dropResult) {
        //   dispatch(addItemToGroupingResourcesById(id));
        // }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }));

    const dragStyle = useMemo(() => {
      if (isDragging) {
        return {
          opacity: 0.4,
          cursor: 'grabbing !important',
        };
      }

      return {
        opacity: 1,
        cursor: 'grab',
      };
    }, [isDragging]);

    return (
      <ListItem sx={{ ...styles.item, ...dragStyle }} ref={drag}>
        <Box sx={styles.content}>
          <Box sx={styles.icon}>{icon}</Box>
          <Typography variant="subtitle1">{number}</Typography>
        </Box>
        <Box className="crab-icon-block" sx={styles.grabIconBlock}>
          <GrabIcon />
        </Box>
      </ListItem>
    );
  },
);

export { DraggableResourceItem };
