import { FC } from 'react';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { Grid, Typography } from '@mui/material';
import { t } from 'ttag';
import { Order, OrderTypes } from 'types/orders';

import { Time } from 'components/common/Time';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerDestination {
  order: Order;
}

export const DetailsSectionInnerDestination: FC<
  DetailsSectionInnerDestination
> = ({ order }) => {
  const totalColumns = 12;
  const iconColumns = 2;
  const timeColumns = order.time ? 4 : 0;
  const addressColumns = totalColumns - (iconColumns + timeColumns);

  return (
    <InnerSection
      label={
        order?.type === OrderTypes.DROP
          ? t`Delivery Destination`
          : t`Pickup Destination`
      }
    >
      <Grid container columns={totalColumns}>
        <Grid item mobile={iconColumns} sx={styles.gridItem}>
          <LocationIcon sx={styles.icon} />
        </Grid>
        <Grid item mobile={addressColumns} sx={styles.gridItem}>
          <Typography sx={{ overflowWrap: 'break-word' }}>
            {order.subtitle}
          </Typography>
          <Typography variant="h3" marginBottom="6px">
            {order.address.title}
          </Typography>
          <Typography>{order.address.subtitle}</Typography>
        </Grid>
        <Grid item mobile={timeColumns} sx={styles.gridItem}>
          <Time time={order.time} isOnlyPod={!!order?.data?.isManualCreated} />
        </Grid>
      </Grid>
    </InnerSection>
  );
};
