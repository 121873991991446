export const styles = {
  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
  },

  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '52px',
  },
};
