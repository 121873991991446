import { UNASSIGNED_PAGE_NUMBER_PARAM } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const getUnassignedPageNumberFromUrl = getValueFromUrl(
  UNASSIGNED_PAGE_NUMBER_PARAM,
);

export const removeUnassignedPageNumberFromUrl = () =>
  removeValueFromUrl(UNASSIGNED_PAGE_NUMBER_PARAM);

export const setUnassignedPageNumberToUrl = (value: string | undefined) => {
  return value
    ? setValueToUrl(UNASSIGNED_PAGE_NUMBER_PARAM, value)
    : removeValueFromUrl(UNASSIGNED_PAGE_NUMBER_PARAM);
};
