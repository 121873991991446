import { FC, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';

import { AllCompaniesReports } from 'components/screens/AdminHomeScreen/components/CompanyDetailSection/components/AllCompaniesReports';
import { CompanyDetail } from 'components/screens/AdminHomeScreen/components/CompanyDetailSection/components/CompanyDetail';
import { EditCompanyButton } from 'components/ui/EditCompanyButton';
import { Section } from 'components/ui/Section';

import { styles } from './styles';

export const CompanyDetailSection: FC = () => {
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);

  const content = useMemo(() => {
    if (!selectedCompanyId) {
      return <AllCompaniesReports />;
    }

    return <CompanyDetail />;
  }, [selectedCompanyId]);

  const sectionButtons = useMemo(() => {
    if (!selectedCompanyId) {
      return [];
    }

    return [<EditCompanyButton key="edit-company-button" />];
  }, [selectedCompanyId]);

  return (
    <Section buttons={sectionButtons} label={t`Detail`} sx={styles.container}>
      {content}
    </Section>
  );
};
