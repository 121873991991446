import { useMemo } from 'react';
import { MAP_POINT_TYPE } from 'constants/common';
import { format } from 'date-fns';
import { useMapAllRoutesQuery } from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getAllRoutesResourcesFiltersSelector,
  getAllRoutesResourcesGroupsSelector,
  getMapSettingsDateSelector,
  getMapSettingsSelector,
} from 'store/selectors/mapSettings';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { AllRoutesPointStatus } from 'types/api';
import { Coordinates } from 'types/resource';
import { generateDistinctColors } from 'utils/colorsUtils';
import { joinMapPointStatuses } from 'utils/mapPointUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

export interface AllRouteMapPoint {
  id: string;
  type?: MAP_POINT_TYPE;
  coords: Coordinates;
  errandIds: string[];
  resourceId: string;
  workshiftId: string;
  status: AllRoutesPointStatus;
  order: number;
  eta: string | null;
  pod: string | null;
}

export const useMapAllRouteData = () => {
  const settingsDate = useAppSelector(getMapSettingsDateSelector);
  const commonDate = useAppSelector(getCommonDateSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const mapSettings = useAppSelector(getMapSettingsSelector);
  const group = useAppSelector(getAllRoutesResourcesGroupsSelector);
  const resource_name = useAppSelector(getAllRoutesResourcesFiltersSelector);

  const preparedCommonDate = useMemo(() => {
    return commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd');
  }, [commonDate]);

  const { data: mapAllRoutesData } = useMapAllRoutesQuery(
    {
      date: onlyCurrentWorkshift ? settingsDate : preparedCommonDate,
      hide_historical: !mapSettings.showHistoricalPath,
      group,
      resource_name,
    },
    {
      pollingInterval: 60000,
    },
  );

  const allRoutesData = useMemo(() => {
    if (!mapAllRoutesData || !mapAllRoutesData?.length) {
      return [];
    }

    const availableColors = generateDistinctColors(mapAllRoutesData.length);

    return mapAllRoutesData.map((allRouteItem, index) => {
      const mapPoints: AllRouteMapPoint[] = allRouteItem.points.reduce(
        (accum: AllRouteMapPoint[], point, idx) => {
          const foundPintWithTheSameCoords = accum.find(
            (p) =>
              p.coords[0] === point.longitude && p.coords[1] === point.latitude,
          );

          if (!foundPintWithTheSameCoords) {
            return [
              ...accum,
              {
                id: createUniqueId(),
                type: MAP_POINT_TYPE.point,
                coords: [point.longitude, point.latitude],
                status: point.status,
                errandIds: point.errand_ids,
                resourceId: allRouteItem.resource_id,
                workshiftId: allRouteItem.work_shift_id,
                order: idx + 1,
                eta: point.eta,
                pod: point.pod_time,
              },
            ];
          }

          return accum.map((ap) => {
            if (
              ap.coords[0] === point.longitude &&
              ap.coords[1] === point.latitude
            ) {
              return {
                ...ap,
                status: joinMapPointStatuses(ap.status, point.status),
                errandIds: [...ap.errandIds, ...point.errand_ids],
              };
            }

            return ap;
          });
        },
        [],
      );

      const linePoints = allRouteItem.points.map((point) => [
        point.longitude,
        point.latitude,
      ]);

      return {
        mapPoints,
        linePoints,
        color: availableColors[index],
      };
    });
  }, [mapAllRoutesData]);

  return {
    allRoutesData,
  };
};
