import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';
interface CopyOrderToNewPopupProps {
  onCopyPress: () => void;
}

function CopyOrderToNewPopup({ onCopyPress }: CopyOrderToNewPopupProps) {
  return (
    <Box sx={styles.copyContainer} boxShadow={3} component={Grid}>
      <Box sx={styles.itemContainer} onClick={onCopyPress}>
        <Typography fontSize="16px">{t`Copy order to new`}</Typography>
      </Box>
    </Box>
  );
}

export default React.memo(CopyOrderToNewPopup);
