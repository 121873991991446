import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  imageContainer: {
    width: '55px',
    height: '55px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '& > img': {
      flexShrink: 0,
      minWidth: '100%',
      minHeight: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
};
