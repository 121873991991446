import { FC } from 'react';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { t } from 'ttag';
import { OrderAdditionsData } from 'types/orders';

import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface AdditionsSectionItemProps {
  label: string;
  value?: string;
}

const AdditionsSectionItem: FC<AdditionsSectionItemProps> = ({
  label,
  value,
}) => (
  <ListItem sx={styles.item}>
    <Grid container columns={30} sx={styles.itemContent}>
      <Grid item mobile={23}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item mobile={6}>
        <Typography variant="body2" sx={styles.itemValue}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  </ListItem>
);

export const DetailsSectionInnerAdditions: FC<OrderAdditionsData> = ({
  adr,
  tempered_transport: temperedTransport,
  on_call_courier: onCallCourier,
  time_slotted_lorry: timeSlottedLorry,
  small_addition_package_charge: smallAdditionPackageCharge,
  big_addition_package_charge: bigAdditionPackageCharge,
  new_pickup_address: newPickupAddress,
  new_delivery_address: newDeliveryAddress,
  extra_pallet_space: extraPalletSpace,
  flatbed_meter: flatbedMeter,
  pickup_waiting_time: pickWaitingTime,
  delivery_waiting_time: deliveryWaitingTime,
  unloading_time: unloadingTime,
  loading_time: loadingTime,
  installation,
  ferry_addition_cost: ferryAdditionCost,
}) => {
  const adrItem = !!adr && <AdditionsSectionItem label={t`ADR`} />;
  const temperedTransportItem = !!temperedTransport && (
    <AdditionsSectionItem label={t`Tempered transport`} />
  );
  const onCallCourierItem = !!onCallCourier && (
    <AdditionsSectionItem label={t`On call courier `} />
  );
  const timeSlottedLorryItem = !!timeSlottedLorry && (
    <AdditionsSectionItem label={t`Time slotted lorry per hour`} />
  );
  const smallAdditionPackageChargeItem = !!smallAdditionPackageCharge && (
    <AdditionsSectionItem label={t`Small addition package charge`} />
  );
  const bigAdditionPackageChargeItem = !!bigAdditionPackageCharge && (
    <AdditionsSectionItem label={t`Big addition package charge`} />
  );
  const newPickupAddressItem = !!newPickupAddress && (
    <AdditionsSectionItem label={t`New pickup address`} />
  );
  const newDeliveryAddressItem = !!newDeliveryAddress && (
    <AdditionsSectionItem label={t`New delivery address`} />
  );
  const extraPalletSpaceItem = !!extraPalletSpace && (
    <AdditionsSectionItem
      label={t`Extra pallet space`}
      value={t`${extraPalletSpace}pcs`}
    />
  );
  const flatbedMeterItem = !!flatbedMeter && (
    <AdditionsSectionItem
      label={t`Flatbed meter`}
      value={t`${flatbedMeter}m`}
    />
  );
  const pickWaitingTimeItem = !!pickWaitingTime && (
    <AdditionsSectionItem
      label={t`Waiting time pickup`}
      value={t`${pickWaitingTime}min`}
    />
  );
  const deliveryWaitingTimeItem = !!deliveryWaitingTime && (
    <AdditionsSectionItem
      label={t`Waiting time delivery`}
      value={t`${deliveryWaitingTime}min`}
    />
  );
  const unloadingTimeItem = !!unloadingTime && (
    <AdditionsSectionItem
      label={t`Unloading time`}
      value={t`${unloadingTime}min`}
    />
  );
  const loadingTimeItem = !!loadingTime && (
    <AdditionsSectionItem
      label={t`Loading time`}
      value={t`${loadingTime}min`}
    />
  );
  const installationItem = !!installation && (
    <AdditionsSectionItem
      label={t`Installation`}
      value={t`${installation}pcs`}
    />
  );
  const ferryAdditionCostItem = !!ferryAdditionCost && (
    <AdditionsSectionItem
      label={t`Ferry addition cost`}
      value={t`${ferryAdditionCost}pcs`}
    />
  );

  return (
    <InnerSection label={t`Additions`} sx={styles.container}>
      <List sx={styles.list}>
        {adrItem}
        {temperedTransportItem}
        {onCallCourierItem}
        {timeSlottedLorryItem}
        {smallAdditionPackageChargeItem}
        {bigAdditionPackageChargeItem}
        {newPickupAddressItem}
        {newDeliveryAddressItem}
        {extraPalletSpaceItem}
        {flatbedMeterItem}
        {pickWaitingTimeItem}
        {deliveryWaitingTimeItem}
        {loadingTimeItem}
        {unloadingTimeItem}
        {installationItem}
        {ferryAdditionCostItem}
      </List>
    </InnerSection>
  );
};
