import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, Chip, TextField, Zoom } from '@mui/material';
import { Box } from '@mui/system';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useSendGroupMessagesMutation } from 'store/api/chatApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SendGroupMessagePopover: FC<SetResourcesFiltersPopoverProps> = (
  props,
) => {
  const { onClosePopover } = props;
  const dispatch = useAppDispatch();

  const [sendGroupMessages, { isSuccess }] = useSendGroupMessagesMutation();
  const [groups, setGroups] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const [message, setMessage] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupsAndFiltersInputValue, setGroupsAndFiltersInputValue] = useState<
    string[]
  >([]);

  const { data } = useGetAllGroupsQuery({});

  const allGroups = useMemo(() => {
    return data ? data.map((group) => group.title) : [];
  }, [data]);

  const handleChange = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters and groups, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = groupsAndFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setGroupsAndFiltersInputValue(updatedValues);

      const tempFilters: string[] = [];
      const tempGroups: string[] = [];

      updatedValues.forEach((value) => {
        if (allGroups.includes(value)) {
          tempGroups.push(value);

          return;
        }

        tempFilters.push(value);
      });

      setFilters(tempFilters);
      setGroups(tempGroups);
    },
    [allGroups, groupsAndFiltersInputValue],
  );

  const handleApplyPress = useCallback(async () => {
    await sendGroupMessages({
      group: groups,
      resource_name: filters,
      content: message,
    });

    onClosePopover();
  }, [onClosePopover, groups, filters, message]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setToast({
          message: t`The group message was sent successfully`,
          severity: 'success',
        }),
      );
    }
  }, [isSuccess]);

  const handleResetAllPress = useCallback(() => {
    setGroups([]);
    setFilters([]);
    setGroupsAndFiltersInputValue([]);
  }, []);

  const handleMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.currentTarget.value.trim()) {
        setMessage(e.currentTarget.value.trim());
      }
    },
    [],
  );

  return (
    <Box sx={styles.container}>
      <LightTooltip
        title={t`You can set up here groups and filters for resources in the settings screen. The maximum quantity of tags is five.`}
        arrow
        TransitionComponent={Zoom}
        leaveDelay={1000}
        placement="right"
      >
        <Autocomplete
          value={groupsAndFiltersInputValue}
          multiple
          id="tags-filled"
          onChange={handleChange}
          options={allGroups}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
                size="small"
                sx={{ fontSize: '10px' }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              // label={t`Groups and Filters`}
              placeholder={t`Groups and Filters`}
            />
          )}
          sx={styles.firstFilter}
        />
      </LightTooltip>

      <TextField
        placeholder={t`Message`}
        rows={3}
        multiline
        fullWidth
        onChange={handleMessageChange}
        sx={styles.messageInput}
      />
      <Button
        // disabled={!isAnyFilters}
        sx={styles.clearButton}
        variant="outlined"
        onClick={handleResetAllPress}
      >
        {t`Clear members`}
      </Button>
      <Button
        disabled={groupsAndFiltersInputValue.length === 0 || !message}
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
        value={message}
      >
        {t`Send message`}
      </Button>
    </Box>
  );
};

export { SendGroupMessagePopover };
