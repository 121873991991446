import { WorkshiftHistoryItem } from 'types/api';

export function defineCurrentWorkshift<T extends WorkshiftHistoryItem>(
  workshifts: T[] | undefined,
): string | undefined {
  if (!workshifts || !workshifts.length) {
    return;
  }

  const nowDate = Date.now();
  const result = workshifts?.find(
    (r: T) => nowDate > Date.parse(r?.start) && nowDate < Date.parse(r?.end),
  );

  return result ? result?.id : undefined;
}

export const isCurrentWorkshift = (start: string, end: string) => {
  const nowDate = Date.now();

  return nowDate >= Date.parse(start) && nowDate <= Date.parse(end);
};
