import React, { FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as DisabledUnread } from 'assets/icons/unread_disable.svg';
import { ReactComponent as ShowUnread } from 'assets/icons/unread_enable.svg';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateShowUnreadInMap } from 'store/reducers/mapSettingsSlice';
import { getShowUnreadInMapSelector } from 'store/selectors/mapSettings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface EditButtonProps {
  orderId?: string;
  disabled?: boolean;
  shipmentId?: string;
}
export const ShowUnreadMessageInMapButton: FC<EditButtonProps> = React.memo(
  () => {
    const dispatch = useAppDispatch();
    const showUnreadInMap = useAppSelector(getShowUnreadInMapSelector);

    const handleClick = useCallback(() => {
      dispatch(updateShowUnreadInMap(!showUnreadInMap));
    }, [showUnreadInMap]);

    return (
      <LightTooltip
        title={
          showUnreadInMap
            ? t`Hide unread messages`
            : t`Show show unread messages`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton sx={styles.button} onClick={handleClick}>
            {showUnreadInMap && <ShowUnread />}
            {!showUnreadInMap && <DisabledUnread />}
          </IconButton>
        </span>
      </LightTooltip>
    );
  },
);
