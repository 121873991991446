import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  stack: {
    overflowY: 'hidden',
    maxHeight: '100%',
  },

  container: {
    p: '8px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  skeletonText: {
    width: '75%',
  },
};
