import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  modal: {
    position: 'relative',
    height: '63%',
    width: '63%',
    background: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    paddingX: '16px',
    paddingTop: '52px',
  },
  imageContainer: {
    width: '100%',
    height: '80%',

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },

  imageItem: {
    width: '8vh',
    height: '8vh',
    marginX: '6px',

    '&:hover': {
      cursor: 'pointer',
    },

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    mt: '16px',
  },

  close: {
    color: 'primary.main',
    position: 'absolute',
    right: '5px',
    top: '5px',
  },

  orangeBorder: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: 'primary.main',
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};
