import { FC } from 'react';
import { Grid } from '@mui/material';

import { AdditionsDataTabDeliveryTime } from '../AdditionsDataTabDeliveryTime';
import { AdditionsDataTabLoadingSpace } from '../AdditionsDataTabLoadingSpace';
import { AdditionsDataTabOtherData } from '../AdditionsDataTabOtherData';
import { AdditionsDataTabPickUpTime } from '../AdditionsDataTabPickUpTime';
import { AdditionsDataTabSwitchSection } from '../AdditionsDataTabSwitchSection';

export const AdditionsDataTabForm: FC = () => {
  return (
    <Grid container columns={3} spacing={2}>
      <Grid item mobile={1}>
        <AdditionsDataTabSwitchSection />
      </Grid>
      <Grid item mobile={2}>
        <Grid container columns={2} spacing={2}>
          <Grid item mobile={1}>
            <AdditionsDataTabPickUpTime />
          </Grid>
          <Grid item mobile={1}>
            <AdditionsDataTabDeliveryTime />
          </Grid>
          <Grid item mobile={1}>
            <AdditionsDataTabLoadingSpace />
          </Grid>
          <Grid item mobile={1}>
            <AdditionsDataTabOtherData />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
