import { FC, ReactNode } from 'react';
import { Grid, Icon, ListItem, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { StylesProps } from 'types/common';

import { styles } from './styles';

interface InnerSectionListItemProps {
  variant?: Variant;
  icon?: ReactNode;
  customIcon?: boolean;
  iconColor?: string;
  label?: string;
  value?: string | number | null;
  border?: boolean;
  style?: StylesProps;
}

export const InnerSectionListItem: FC<InnerSectionListItemProps> = ({
  icon,
  label,
  value,
  iconColor,
  customIcon = false,
  border = true,
  variant = 'h3',
  style,
}) => {
  const totalColumns = 12;
  const iconColumns = icon ? 2 : 0;
  const textColumns = totalColumns - iconColumns;
  const listItemStyle = border
    ? { ...styles.listItem, ...style }
    : { ...styles.listItem, border: 'none', ...style };

  return (
    <ListItem sx={listItemStyle}>
      <Grid container columns={totalColumns} sx={styles.gridContainer}>
        {icon && (
          <Grid item mobile={iconColumns} sx={styles.gridItem}>
            {customIcon ? icon : <Icon sx={{ color: iconColor }}>{icon}</Icon>}
          </Grid>
        )}
        <Grid item mobile={textColumns} sx={styles.gridItem}>
          <Typography variant={variant}>{value}</Typography>
          {!!label && <Typography sx={styles.label}>{label}</Typography>}
        </Grid>
      </Grid>
    </ListItem>
  );
};
