import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },
  list: {
    p: 0,
    '& > *:first-of-type': {
      pt: 0,
    },
    '& > *:last-of-type': {
      border: 'none',
      pb: 0,
    },
  },
};
