import { t } from 'ttag';

export const getRequiredFieldValidationError = (fieldName: string) => {
  return t`${fieldName} is a required field`;
};

export const getMaxLengthValidationError = (
  fieldName: string,
  maxLen: number,
) => {
  return t`${fieldName} field value should not be longer than ${maxLen} symbol(s)`;
};

export const getMinValueValidationError = (
  fieldName: string,
  minValue: number,
) => {
  return t`${fieldName} field value should be greater than ${minValue}`;
};

export const getMaxValueValidationError = (
  fieldName: string,
  maxValue: number,
) => {
  return t`${fieldName} field value should be lower than ${maxValue}`;
};
