import { FC, MouseEvent, useCallback, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { MapSettingsPopover } from './components/MapSettingsPopover';
import { styles } from './styles';

export const AllRoutesSettingsButton: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [shouldOpenPopover, setShouldOpenPopover] = useState(false);

  // useEffect(() => {
  //   if (anchorEl) {
  //     setShouldOpenPopover(true);
  //   }
  // }, [anchorEl]);

  const handleIconPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    // setShouldOpenPopover(false);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <LightTooltip
        title={t`You can change map settings`}
        arrow
        placement="top"
      >
        <span>
          <IconButton sx={styles.button} onClick={handleIconPress}>
            <TuneIcon />
          </IconButton>
        </span>
      </LightTooltip>
      {/* {shouldOpenPopover && ( */}
      <Popover
        id={id}
        open={open}
        PaperProps={{ sx: styles.popup }}
        // elevation={12}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: -20,
          horizontal: 'right',
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'left',
        // }}
      >
        {open && <MapSettingsPopover onClosePopover={handleClose} />}
      </Popover>
      {/* )} */}
    </>
  );
};
