import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  emptyContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  messageListContainer: {
    height: 'calc(100% - 80px)',
    p: 0,
    overflowY: 'scroll',
    backgroundColor: 'custom.greyBlue',
  },
  chatInputContainer: {
    height: '80px',
    backgroundColor: 'custom.greyLightBlue',
    p: '16px',
  },
};
