import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Driver } from 'types/api';

interface DriverState {
  driversList: Driver[];
}

const initialState: DriverState = {
  driversList: [],
};

export const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    setDriversList: (state, action: PayloadAction<Driver[]>) => {
      state.driversList = action.payload;
    },
  },
});

export const { setDriversList } = driverSlice.actions;

export const driverReducer = driverSlice.reducer;
