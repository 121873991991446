import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  label: {
    pl: '16px',
    mb: '4px',
    fontWeight: 600,
  },

  container: {
    borderRadius: '6px',
    boxShadow: 'none',
  },

  variantContained: {
    p: '16px',

    bgcolor: 'common.white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
  },

  variantEmpty: {
    bgcolor: 'transparent',
  },
};
