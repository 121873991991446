import { FC, useCallback } from 'react';
import {
  useCompanyByIdQuery,
  useEditCompanyByIdMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import { getSelectedCompanyIdSelector } from 'store/selectors/company';
import { t } from 'ttag';
import { WrappedErrorApi } from 'types/api';

import { UpdateCompanyScreenForms } from 'components/common/UpdateCompanyScreenForms';
import { AddCompanyValues } from 'components/common/UpdateCompanyScreenForms/UpdateCompanyScreenForms';

export const EditCompanyScreen: FC = () => {
  const [editCompany, { isLoading }] = useEditCompanyByIdMutation();
  const dispatch = useAppDispatch();
  const selectedCompanyId = useAppSelector(getSelectedCompanyIdSelector);
  const { data: companyDetail } = useCompanyByIdQuery(
    selectedCompanyId as string,
    {
      skip: !selectedCompanyId,
    },
  );
  const onEdit = useCallback(
    async (options: Partial<AddCompanyValues>): Promise<boolean> => {
      const res = await editCompany({ id: companyDetail?.id, ...options });

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              t`Something wrong in Edit Company!`,
            severity: 'error',
          }),
        );
        return false;
      }

      dispatch(
        setToast({
          message: t`You have successfully edited company`,
          severity: 'success',
        }),
      );

      return true;
    },
    [editCompany, companyDetail?.id],
  );

  return (
    <UpdateCompanyScreenForms
      edit
      loading={isLoading}
      onEdit={onEdit}
      companyDetail={companyDetail}
    />
  );
};
