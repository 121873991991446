import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    p: 0,
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 10,
      top: 0,
      bottom: 0,
      left: '34px',
      borderLeft: '1px dashed',
      borderColor: 'custom.greyLight',
    },
  },
};
