import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },
  separator: {
    width: '100%',
    // ml: '16px',
    borderColor: 'custom.greyLight2',
    borderStyle: 'solid',
    borderWidth: '1px',
    mt: '16px',
    mb: '16px',
    mr: '-16px',
  },
  signName: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bc: 'primary.main',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    bgcolor: 'primary.main',
    mr: '16px',
  },
  icon: {
    color: 'custom.white',
    fontSize: '16px',
  },
  mb16: {
    mb: '16px',
  },
};
