import { isEqual } from 'lodash';

import { usePrevious } from './usePrevious';

export const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val);
  if (Array.isArray(val)) {
    return val?.length !== prevVal?.length;
  }

  return !isEqual(prevVal, val);
};
