import React, { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuickResponsesQuery } from 'store/api/chatApiSlice';
import { QuickReply } from 'types/api';

import { styles } from './styles';
interface QuickRepliesProps {
  allReplies: number[];
}
export function BubbleQuickReply(props: QuickRepliesProps) {
  const { allReplies } = props;
  const { data: quickReplies } = useQuickResponsesQuery();

  const repliesList = useMemo(() => {
    if (!quickReplies) {
      return [];
    }

    return quickReplies.reduce((accum: QuickReply[], curr: QuickReply) => {
      if (allReplies.includes(curr.id)) {
        return [...accum, curr];
      }

      return accum;
    }, []);
  }, [quickReplies, allReplies]);

  const renderReply = useCallback((reply: QuickReply) => {
    const { id, emoji } = reply;

    // const replyStyle = isCurrentChosen ? { ...styles.reply, ...styles.borderedReply } : styles.reply;

    return (
      <Box key={id} sx={styles.reply}>
        <Typography fontSize="24px">
          {String.fromCodePoint(Number(emoji))}
        </Typography>
      </Box>
    );
  }, []);

  if (!repliesList?.length) {
    return null;
  }

  return (
    <Box sx={styles.repliesContainer}>
      {repliesList?.length && repliesList.map(renderReply)}
    </Box>
  );
}
