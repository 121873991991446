import { FC } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';

export const DeliveryDataTabSenderSection: FC = () => {
  const { terminalDeliveryType } = useEditScreenErrandTypes();

  return (
    <ChangeScreenSection label={t`Receiver`}>
      <Grid container spacing={2} rowGap={4}>
        {!terminalDeliveryType && (
          <Grid item mobile>
            <Field
              component={FieldInput}
              name="deliveryCompanyName"
              label={t`Company name`}
              placeholder={t`Enter company name`}
              fullWidth
              autoComplete="new-password"
              // required
            />
          </Grid>
        )}
        {terminalDeliveryType ? (
          <Grid item mobile>
            <Field
              component={FieldInput}
              name="deliveryTerminalName"
              label={t`Terminal name`}
              placeholder={t`Enter terminal name`}
              fullWidth
              autoComplete="new-password"
              // required
            />
          </Grid>
        ) : (
          <>
            <Grid item mobile>
              <Field
                component={FieldInput}
                name="deliveryNameOfSender"
                label={t`Name of receiver`}
                placeholder={t`Enter name of receiver`}
                fullWidth
                autoComplete="new-password"
                // required
              />
            </Grid>
            <Grid item mobile>
              <Field
                component={FieldInput}
                name="deliverySurnameOfSender"
                label={t`Surname of receiver`}
                placeholder={t`Enter surname of receiver`}
                fullWidth
                autoComplete="new-password"
                // required
              />
            </Grid>
          </>
        )}
        <Grid item mobile>
          <Field
            component={FieldInput}
            name="deliveryPhoneNumber"
            label={t`Phone number`}
            placeholder={t`Enter phone number`}
            fullWidth
            autoComplete="new-password"
            maxLength={20}
            // required
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
