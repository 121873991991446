import React, { useEffect, useMemo } from 'react';
import { useResourcesForGroupsWithPaginationQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeResourcesForSettings,
  updateCurrentGTPage,
  updateResourcesForSettings,
  updateTotalGRItems,
  updateTotalGRPages,
} from 'store/reducers/settingsSlice';
import {
  getCurrentGTPage,
  getSearchResourcesForSettingsByName,
} from 'store/selectors/settings';

import { ResourcesGroupSectionList } from 'components/screens/EditSettingsScreen/components/ResourcesGroupSectionList';
import { ResourcesForSettingFilterButton } from 'components/ui/ResourcesForSettingsFilterButton';
import { Section } from 'components/ui/Section';

import { styles } from './styles';

const ResourcesGroupSection = () => {
  const dispatch = useAppDispatch();
  const number = useAppSelector(getSearchResourcesForSettingsByName);
  const page = useAppSelector(getCurrentGTPage);

  const { data, isFetching } = useResourcesForGroupsWithPaginationQuery({
    number,
    page,
  });

  useEffect(() => {
    dispatch(updateCurrentGTPage(1));
    dispatch(removeResourcesForSettings());
  }, [number]);

  useEffect(() => {
    if (data) {
      if (data?.items?.length) {
        if (page === data?.page) {
          dispatch(updateResourcesForSettings(data.items));
        }
        dispatch(updateTotalGRPages(data.pages));
        dispatch(updateTotalGRItems(data.total));
      } else {
        dispatch(removeResourcesForSettings());
      }
    }
  }, [data, page]);

  const content = useMemo(() => {
    return <ResourcesGroupSectionList isFetching={isFetching} />;
  }, [isFetching]);

  return (
    <Section
      label=" "
      sx={styles.container}
      buttons={[
        <ResourcesForSettingFilterButton key="Resorces for settings button" />,
      ]}
    >
      {content}
    </Section>
  );
};

export { ResourcesGroupSection };
