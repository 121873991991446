import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
} from 'react';
import { SxProps, TextField, TextFieldProps } from '@mui/material';
import { FieldProps, getIn } from 'formik';

type Autocomplete = 'on' | 'off' | 'new-password';
type FieldInputProps = TextFieldProps &
  Partial<{
    selectOnFocus: boolean;
    autoComplete: Autocomplete;
    maxLength: number;
    isTrim: boolean;
  }>;

export const FieldInput: FC<FieldInputProps & FieldProps> = ({
  field,
  type,
  selectOnFocus,
  autoComplete = 'on',
  maxLength,
  isTrim = false,
  form: { errors, touched },
  ...props
}) => {
  const errorMessage = getIn(errors, field.name);
  const isTouched = !!getIn(touched, field.name);
  const value = field.value || field.value === 0 ? field.value : '';
  const fieldOnChange = field.onChange;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isTrim) {
        e.target.value = e.target.value.trim();
      }
      fieldOnChange(e);
    },
    [isTrim],
  );

  const preventEInNumberTextField = (event: KeyboardEvent<HTMLDivElement>) =>
    ['-', '+', 'e', 'E'].includes(event.key) &&
    type === 'number' &&
    event.preventDefault();

  const focusHandler = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => (selectOnFocus ? event.target.select() : null);

  const multilineStyle = useMemo(
    () =>
      props.multiline
        ? {
            '& .MuiFormHelperText-root': {
              position: 'static',
            },
          }
        : {},
    [props],
  );

  return (
    <TextField
      sx={multilineStyle as SxProps}
      fullWidth
      type={type}
      onFocus={focusHandler}
      {...field}
      {...props}
      value={value}
      onKeyDown={preventEInNumberTextField}
      onChange={handleChange}
      error={isTouched && !!errorMessage}
      helperText={isTouched && errorMessage}
      autoComplete={autoComplete}
      inputProps={{ maxLength }}
      // inputProps={{ shrink: true }}
    />
  );
};
