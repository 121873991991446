import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  checkBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  button: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderRadius: '4px',
    marginRight: '12px',
  },
};
