import { FC } from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  SxProps,
} from '@mui/material';

import { styles } from './styles';

export interface SwitchProps extends MuiSwitchProps {
  labelStyles?: SxProps;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  label?: FormControlLabelProps['label'];
}

export const Switch: FC<SwitchProps> = ({
  labelStyles,
  labelPlacement = 'start',
  label,
  ...rest
}) => {
  const switchStyles = labelStyles
    ? ({ ...styles.switch, ...labelStyles } as SxProps)
    : styles.switch;
  return (
    <FormControlLabel
      sx={switchStyles}
      labelPlacement={labelPlacement}
      control={<MuiSwitch {...rest} />}
      label={label}
    />
  );
};
