import { FC, ReactElement } from 'react';
import { Box, BoxProps, Paper, SxProps, Typography } from '@mui/material';

import { styles } from './styles';

export interface SectionWrapperProps extends BoxProps {
  children?: React.ReactNode;
  label?: string;
  emptyText?: string;
  innerStyles?: SxProps;
  labelStyles?: SxProps;
  buttons?: ReactElement[];
  withoutlabelAndButtons?: boolean;
}

interface EmptySectionProps {
  label?: string;
}

const EmptySection: FC<EmptySectionProps> = ({ label }) => {
  return (
    <Box sx={styles.emptySection}>
      <Typography sx={styles.emptySectionLabel}>{label}</Typography>
    </Box>
  );
};

export const Section: FC<SectionWrapperProps> = ({
  children,
  label,
  emptyText,
  innerStyles,
  labelStyles,
  buttons,
  withoutlabelAndButtons,
  ...rest
}) => {
  const paperStyles = innerStyles
    ? ({ ...styles.container, ...innerStyles } as SxProps)
    : styles.container;
  const titleStyles = labelStyles
    ? ({ ...styles.title, ...labelStyles } as SxProps)
    : styles.title;
  const sectionStyles = rest.sx
    ? ({ ...styles.section, ...rest.sx } as SxProps)
    : styles.section;
  const emptySection = emptyText && <EmptySection label={emptyText} />;

  const content = children || emptySection;
  return (
    <Box {...rest} sx={sectionStyles}>
      {!withoutlabelAndButtons && (
        <Box sx={styles.labelContainer}>
          {label && (
            <Typography sx={titleStyles} component="h1">
              {label}
            </Typography>
          )}

          <Box sx={styles.buttonsContainer}>
            {buttons && buttons.map((button) => button)}
          </Box>
        </Box>
      )}
      <Paper sx={paperStyles}>{content}</Paper>
    </Box>
  );
};
