import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldProps, getIn } from 'formik';

type FieldDatePickerProps = FieldProps &
  Partial<{
    required?: boolean;
    label?: string;
  }>;

export const FieldDatePicker = forwardRef<HTMLDivElement, FieldDatePickerProps>(
  function FieldDatePicker(
    {
      required = false,
      label,
      field,
      form: { errors, touched, setFieldValue, setFieldTouched },
      ...props
    },
    ref,
  ) {
    const { name } = field;
    const errorMessage = getIn(errors, name);
    const isTouched = !!getIn(touched, name);

    return (
      <DatePicker
        ref={ref}
        label={label}
        minDate={new Date('2000-01-01')}
        {...field}
        onChange={(value) => {
          setFieldValue(name, value, true);
          setFieldTouched(name, true);
        }}
        {...props}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            type="tel"
            error={isTouched && !!errorMessage}
            helperText={isTouched && (errorMessage as string)}
            fullWidth
            required={required}
          />
        )}
      />
    );
  },
);
