/* eslint-disable react/jsx-key */
import React, { FC, useCallback, useMemo } from 'react';
import { Autocomplete, Chip, TextField, Zoom } from '@mui/material';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeResourcesSettingsGroupsAndFilters,
  setToast,
  updateResourcesSettingsFilters,
  updateResourcesSettingsFiltersAndGroups,
  updateResourcesSettingsGroups,
} from 'store/reducers/settingsSlice';
import { getResourcesSettingsFiltersAndGroups } from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const ResourcesGroupsSearch: FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const resourcesSettingsFiltersAndGroups = useAppSelector(
    getResourcesSettingsFiltersAndGroups,
  );
  const { data } = useGetAllGroupsQuery({});

  const allGroups = useMemo(() => {
    return data ? data.map((group) => group.title) : [];
  }, [data]);

  const handleChange = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      if (!values?.length) {
        dispatch(removeResourcesSettingsGroupsAndFilters());

        return;
      }

      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters and groups, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = resourcesSettingsFiltersAndGroups.slice(
          0,
          -1,
        );
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      const filters: string[] = [];
      const groups: string[] = [];

      updatedValues.forEach((value) => {
        if (allGroups.includes(value)) {
          groups.push(value);

          return;
        }

        filters.push(value);
      });

      dispatch(updateResourcesSettingsGroups(groups));
      dispatch(updateResourcesSettingsFilters(filters));

      dispatch(updateResourcesSettingsFiltersAndGroups(updatedValues));
    },
    [allGroups, resourcesSettingsFiltersAndGroups],
  );
  return (
    <LightTooltip
      title={t`You can set up here groups and filters for resources in the settings screen. The maximum quantity of tags is five.`}
      arrow
      TransitionComponent={Zoom}
      leaveDelay={1000}
      placement="right"
    >
      <Autocomplete
        value={resourcesSettingsFiltersAndGroups}
        multiple
        id="tags-filled"
        onChange={handleChange}
        options={allGroups}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            // label={t`Groups and Filters`}
            placeholder={t`Search by resources or groups of resources`}
          />
        )}
        sx={styles.input}
      />
    </LightTooltip>
  );
});
