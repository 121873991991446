import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  layout: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  form: {
    mt: '10px',
    mb: '60px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    position: 'relative',
    overflowY: 'scroll',
  },
};
