import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  timeContainer: {
    textAlign: 'right',
  },

  time: {
    display: 'inline',
    color: 'custom.black',
  },

  delayTime: {
    textAlign: 'right',
    color: 'custom.green',
    fontSize: '12px',
    fontWeight: 'bold',
  },
};
