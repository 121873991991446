import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  section: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
  },

  labelContainer: {
    p: '0 16px',
    mb: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  buttonsContainer: {
    display: 'flex',
    height: '28px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    gap: '10px',
  },

  container: {
    flexGrow: 1,
    overflowY: 'hidden',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderRadius: '6px',
    boxShadow: 'none',
    bgcolor: 'inherit',
  },

  emptySection: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  emptySectionLabel: {
    fontWeight: 'bold',
    textAlign: 'center',
  },

  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};
