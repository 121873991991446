export const styles = {
  menu: { padding: '16px' },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '12px',
  },
  avatar: {
    bgColor: 'custom.greyLight3',
    width: '36px',
    height: '36px',
    marginRight: '12px',
  },
  username: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    color: 'custom.black',
    marginBottom: '4px',
    textTransform: 'none',
  },
  email: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    textTransform: 'none',
  },
  menuLabel: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
  userMenuItem: {
    textTransform: 'none',
    '&:hover': {
      bgcolor: 'custom.white',
    },
    cursor: 'unset',
  },
};
