import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  layout: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'custom.greyBackground',
  },
  content: {
    p: '16px 65px',
    flexGrow: 1,
    overflow: 'hidden',
  },
};
