import { SxProps } from '@mui/material';
import { AllRoutesPointStatus } from 'types/api';
import { OrderStatuses } from 'types/orders';

import { styles } from 'components/common/Map/styles';

const mapPoint: SxProps = {
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px',
  width: '24px',
  height: '24px',
  bgcolor: 'common.white',
  color: 'custom.black',
  borderWidth: '3px',
  borderColor: 'custom.black',
  borderStyle: 'solid',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.3)',
  },
};

export const pointStyle = (status: AllRoutesPointStatus) => {
  switch (status) {
    case 'fail':
      return {
        ...mapPoint,
        bgcolor: 'custom.red',
        color: 'custom.white',
        borderRadius: '0px',
      };
    case 'done':
      return {
        ...mapPoint,
        bgcolor: 'custom.green',
        color: 'custom.white',
        borderRadius: '12px',
      };
    case 'done-fail':
      return {
        ...mapPoint,
        bgcolor: 'custom.green',
        borderColor: 'custom.red',
        color: 'custom.white',
        borderRadius: '0px',
      };
    case 'done-fail-pending':
      return {
        ...mapPoint,
        bgcolor: 'custom.green',
        borderColor: 'custom.red',
        color: 'custom.yellow',
        borderRadius: '0px',
      };
    case 'done-pending':
      return {
        ...mapPoint,
        borderColor: 'custom.green',
        bgcolor: 'custom.grey',
        color: 'custom.black',
        borderRadius: '12px',
      };
    case 'fail-pending':
      return {
        ...styles.mapPoint,
        borderColor: 'custom.red',
        bgcolor: 'custom.grey',
        color: 'custom.black',
        borderRadius: '0px',
      };
    case 'pending':
    default:
      return mapPoint;
  }
};

export type MappingAllRoutesPoints = Record<AllRoutesPointStatus, string[]>;

const mappingAllRoutesPoint: MappingAllRoutesPoints = {
  done: ['done'],
  fail: ['fail'],
  pending: ['pending'],
  'done-fail': ['done', 'fail'],
  'done-fail-pending': ['done', 'fail', 'pending'],
  'done-pending': ['done', 'pending'],
  'fail-pending': ['fail', 'pending'],
};

const isEqualTypesArrays = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((item) => arr2.includes(item));
};

const mapPointTypeFromArray = (typeArray: string[]): AllRoutesPointStatus => {
  let result: AllRoutesPointStatus = 'pending';
  Object.entries(mappingAllRoutesPoint).some(([key, value]) => {
    if (isEqualTypesArrays(value, typeArray)) {
      result = key as AllRoutesPointStatus;
      return true;
    }

    return false;
  });

  return result;
};

export const joinMapPointStatuses = (
  type1: AllRoutesPointStatus,
  type2: AllRoutesPointStatus,
) => {
  const joinedTypes = [
    // @ts-ignore
    ...new Set([
      ...mappingAllRoutesPoint[type1],
      ...mappingAllRoutesPoint[type2],
    ]),
  ];

  return mapPointTypeFromArray(joinedTypes);
};

export const setPointStatusToOrderStatus = (status: AllRoutesPointStatus) => {
  switch (status) {
    case 'done':
      return OrderStatuses.DONE;
    case 'fail':
      return OrderStatuses.FAILED;
    case 'pending':
    default:
      return OrderStatuses.PENDING;
  }
};
