import { t } from 'ttag';
import { FeedbackFile, FeedbackFileType, FeedbackStatus } from 'types/feedback';

export type FeedbackHeadCellTitle =
  | 'resource_number'
  | 'android_version'
  | 'device_info'
  | 'created_at'
  | 'driver_name'
  | 'comment'
  | 'status'
  | 'audio'
  | 'video'
  | 'image'
  | 'location';

export type HeadCell = {
  id: FeedbackHeadCellTitle;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  isSorted: boolean;
};
export const feedbackHeadCells: readonly HeadCell[] = [
  {
    id: 'resource_number',
    numeric: false,
    disablePadding: true,
    label: t`RESOURCE`,
    isSorted: true,
  },
  {
    id: 'driver_name',
    numeric: false,
    disablePadding: false,
    label: t`DRIVER`,
    isSorted: true,
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: t`DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'device_info',
    numeric: false,
    disablePadding: false,
    label: t`DEVICE`,
    isSorted: true,
  },
  {
    id: 'android_version',
    numeric: false,
    disablePadding: true,
    label: t`ANDROID`,
    isSorted: true,
  },
  // {
  //   id: 'image',
  //   numeric: false,
  //   disablePadding: false,
  //   label: t`IMAGE`,
  // },
  // {
  //   id: 'video',
  //   numeric: false,
  //   disablePadding: false,
  //   label: t`VIDEO`,
  // },
  // {
  //   id: 'audio',
  //   numeric: false,
  //   disablePadding: false,
  //   label: t`AUDIO`,
  // },
  {
    id: 'comment',
    numeric: false,
    disablePadding: false,
    label: t`COMMENT`,
    isSorted: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: t`STATUS`,
    isSorted: true,
  },
];

export const iAnyFileOfType = (
  files: FeedbackFile[],
  type: FeedbackFileType,
): boolean => {
  return files.some((file) => file.file_type === type);
};

export const strWithoutMinus = (str: string) => {
  if (str.startsWith('-')) {
    return str.slice(1);
  }

  return str;
};

export const getStatusColor = (status: FeedbackStatus): string => {
  switch (status) {
    case 'Blocked':
      return 'custom.red';
    case 'Closed':
      return 'custom.green';
    case 'Progress':
      return 'custom.blue2';
    case 'New':
    default:
      return 'primary.main';
  }
};
