import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    mt: '24px',
  },
  qrCodeContainer: {
    display: 'flex',
    width: '255px',
    height: '255px',
    padding: '30px',
    bgcolor: 'custom.white',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '38px',
  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '385px',
  },
  cityBlock: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  qrWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '255px',
    ml: '64px',
  },

  regenerateButton: {
    mt: '16px',
    width: '100%',
  },

  submitButton: {
    mt: '24px',
    width: '200px',
  },

  rightBorder: {
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderRightColor: 'custom.greyLight',
  },

  topBorder: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: 'custom.greyLight',
  },

  zeroBorder: {
    borderWidth: '0px',
  },

  tablePaper: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
  },

  searchBlock: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    mb: '16px',
  },

  deviceIdBlock: {
    display: 'flex',
    alignItems: 'center',
  },
};
