import { memo, useMemo, useState } from 'react';
import { List } from '@mui/material';
import { DedicatedChat } from 'types/dedicatedChat';

import { DedicatedChatListItem } from 'components/screens/DedicatedChatScreen/components/DedicatedChatListItem/DedicatedChatListItem';
import { EmptyDedicatedChat } from 'components/screens/DedicatedChatScreen/components/EmptyDedicatedChat';

import { styles } from './styles';

interface DedicatedChatListProps {
  chatList: DedicatedChat[];
}

export const DedicatedChatList = memo((props: DedicatedChatListProps) => {
  const { chatList } = props;
  const [selectedChatId, setSelectedChatId] = useState<string>('');

  const renderChatList = useMemo(() => {
    if (!chatList || !chatList?.length) return null;

    return chatList?.map((chat) => {
      return (
        <DedicatedChatListItem
          key={chat.id}
          setSelectedChat={setSelectedChatId}
          isSelected={selectedChatId === chat.id}
          dedicatedChat={chat}
        />
      );
    });
  }, [chatList, selectedChatId]);

  if (!chatList || !chatList?.length) {
    return <EmptyDedicatedChat />;
  }

  return <List sx={styles.list}>{renderChatList}</List>;
});
