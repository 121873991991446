import React, { FC, useMemo } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Collapse, List } from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import { ReactComponent as TwoCarsIcon } from 'assets/icons/twoCarsDarkBlue.svg';
import { UNASSIGNED_TASKS } from 'constants/common';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import { Resource } from 'types/resource';
import { resourceColorsMappedToMui } from 'utils/resourceUtils';

import { ResourcesSectionListItem } from '../ResourcesSectionListItem';
import { WorkshiftList } from '../ResourcesSectionListItem/components/WorkshiftList';

import { styles } from './styles';

interface ResourcesSectionListProps {
  resourcesList: Resource[];
  selectedResourceId: string | null;
  setSelectedResourceId: (id: string) => void;
  selectAllResources: () => void;
}

export const ResourcesSectionList: FC<ResourcesSectionListProps> = ({
  resourcesList,
  selectedResourceId,
  setSelectedResourceId,
  selectAllResources,
}) => {
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);

  const resourcesListItemsV1 = useMemo(() => {
    return resourcesList.map((resource) => (
      <ResourcesSectionListItem
        icon={<CarIcon fill={resourceColorsMappedToMui[resource.color]} />}
        key={resource.id}
        id={resource.id}
        label={resource.number}
        selected={selectedResourceId === resource.id}
        onClick={setSelectedResourceId}
      />
    ));
  }, [resourcesList, selectedResourceId]);

  const resourcesListItemsV2 = useMemo(() => {
    return resourcesList.map((resource) => (
      <React.Fragment key={resource.id}>
        <ResourcesSectionListItem
          icon={<CarIcon fill={resourceColorsMappedToMui['deepGrey']} />}
          key={resource.id}
          id={resource.id}
          label={resource.number}
          selected={selectedResourceId === resource.id}
          onClick={setSelectedResourceId}
          expandIcon={selectedResourceId === resource.id}
          resource={resource}
        />
        <Collapse
          in={selectedResourceId === resource.id}
          timeout="auto"
          unmountOnExit
        >
          <WorkshiftList resource={resource} />
        </Collapse>
      </React.Fragment>
    ));
  }, [resourcesList, selectedResourceId]);

  return (
    <List sx={styles.list}>
      <ResourcesSectionListItem
        icon={<TwoCarsIcon fill="black" />}
        label="All resources"
        selected={!selectedResourceId}
        onClick={selectAllResources}
      />
      {onlyCurrentWorkshift ? resourcesListItemsV1 : resourcesListItemsV2}
      <ResourcesSectionListItem
        key={UNASSIGNED_TASKS}
        id={UNASSIGNED_TASKS}
        icon={<HelpOutlineIcon />}
        label="Unassigned"
        selected={!!selectedResourceId?.startsWith(UNASSIGNED_TASKS)}
        onClick={setSelectedResourceId}
      />
    </List>
  );
};
