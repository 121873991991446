import { memo, useCallback, useMemo } from 'react';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { ChatPriority } from 'types/dedicatedChat';
import { setValueToUrl } from 'utils/urlUtils';

import { styles } from './style';

interface UnreadMessagesProps {
  unread: number;
  priority?: ChatPriority;
  errandId?: string;
  customStyle?: SxProps;
  bgStyle?: SxProps;
  onIconClick?: () => void;
}
export const MapUnreadMessagesByPriority = memo(
  (props: UnreadMessagesProps) => {
    const { unread, errandId, priority, customStyle, bgStyle, onIconClick } =
      props;

    const dispatch = useAppDispatch();
    const { navigateWithQueryParams } = useNavigateWithQueryParams();

    const iconStyle = useMemo(() => {
      switch (priority) {
        case 0:
          return {
            color: 'custom.green',
          };
        case 1:
          return {
            color: 'custom.blue2',
          };
        case 2:
          return {
            color: 'custom.red',
          };
        case undefined:
        default:
          return {
            color: 'primary.main',
          };
      }
    }, [priority]);

    const handleIconClick = useCallback(() => {
      if (onIconClick) {
        onIconClick();

        return;
      }

      setValueToUrl('orderId', errandId as string);
      dispatch(setSelectedOrderId(errandId));
      navigateWithQueryParams(APP_ROUTES.chat);

      // let orderIdParam = '';
      // let dateParam = '';
      //
      // dispatch(setSelectedResourceId(resourceId));
      // dispatch(setWorkshiftId(workshiftId));
      // dispatch(setOnlyCurrentWorkshift(false));
      //
      // if (errandId) {
      //   dispatch(setSelectedOrderId(errandId));
      //   orderIdParam = `&orderId=${errandId}`;
      // }
      //
      // if (dedicatedChatDate) {
      //   dispatch(setCurrentCommonDate(dedicatedChatDate));
      //   dateParam = `&chosen_date=${dedicatedChatDate}`;
      // } else {
      //   dispatch(setCurrentCommonDate(undefined));
      // }
      //
      // dispatch(updateDedicatedChatDate(undefined));
      //
      // navigate(
      //   `/chat?only_current_workshift=false&workshiftId=${workshiftId}&resourceId=${resourceId}${orderIdParam}${dateParam}`,
      // );
    }, [
      onIconClick,
      errandId,
      // errandId, resourceId, workshiftId, dedicatedChatDate
    ]);

    const containerStyle = useMemo(() => {
      if (customStyle) {
        return {
          ...styles.container,
          ...customStyle,
        };
      }

      return styles.container;
    }, [customStyle]);

    const iconBoxStyle = useMemo(() => {
      return bgStyle ? bgStyle : {};
    }, [bgStyle]);

    return (
      <IconButton onClick={handleIconClick} sx={containerStyle as SxProps}>
        <Box sx={iconBoxStyle}>
          <ChatBubbleOutlineOutlinedIcon sx={iconStyle} />
        </Box>
        {!!unread && (
          <Box sx={styles.unreadContainer}>
            <Typography sx={styles.unread}>{unread}</Typography>
          </Box>
        )}
      </IconButton>
    );
  },
);
