export enum MESSAGE_EVENT_TYPE {
  driverPositionUpdate = 'driver_position_update',
  messageRead = 'message_read',
  newChatMessage = 'new_chat_message',
  shipmentStatusChanged = 'shipment_status_changed',
  routeOptimizationIsDone = 'route_optimization_is_done',
  packageDelete = 'package_delete',
  packageUpdate = 'package_update',
  newPackage = 'new_package',
  shipmentUpdate = 'shipment_update',
  resource_settings_changed = 'resource_settings_changed',
  global_settings_changed = 'global_settings_changed',
  new_quick_response = 'new_quick_response',
  new_group_created = 'new_group_created',
  group_deleted = 'group_deleted',
  group_changed = 'group_changed',
  resource_color_changed = 'resource_color_changed',
  resources_in_group_changed = 'resources_in_group_changed',
  new_subcontractor_created = 'new_subcontractor_created',
  subcontractor_deleted = 'subcontractor_deleted',
  subcontractor_changed = 'subcontractor_changed',
  block_time_gap_button = 'block_time_gap_button',
  unblock_time_gap_button = 'unblock_time_gap_button',
  proof_data_updated = 'proof_data_updated',
  new_group_chat_message = 'new_group_chat_message',
  all_messages_read = 'all_messages_read',
  chat_priority_update = 'chat_priority_update',
  terminal_scan = 'terminal_scan',
}
