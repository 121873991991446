export const styles = {
  title: {
    fontWeight: 600,
    color: 'custom.graphite',
  },
  borderedBox: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'hidden',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderRadius: '6px',
    boxShadow: 'none',
    bgcolor: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
