import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  wrapper: {
    height: '100px',
    padding: '20px 65px',
    position: 'static',

    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',
    bgcolor: 'transparent',
    color: 'custom.graphite',
    boxShadow: 'none',
  },

  content: {
    '&': {
      justifyContent: 'space-between',
      minHeight: '100%',
      height: '0',
    },
  },

  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
  },

  localizationButton: {
    pl: '8px',
  },

  input: {
    // width: '370px',
    // ml: '100px',
    bgcolor: 'custom.white',
  },
  searchButton: {
    ml: '12px',
    height: '43px',
  },

  inputIcon: {
    color: 'custom.greyLight',
  },

  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: '421px',
    marginRight: '24px',
  },

  logo: {
    width: '224px',
    justifyContent: 'flex-start',
    marginRight: '24px',
  },
};
