export const stringAvatar = (name?: string) => {
  if (!name) {
    return '';
  }

  if (name.split(' ').length === 1) {
    return name[0].toUpperCase();
  }

  return `${name.split(' ')?.[0]?.[0]?.toUpperCase()}${name
    .split(' ')?.[1]?.[0]
    ?.toUpperCase()}`;
};
