import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  workshiftRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '16px 0 16px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  driverName: {
    ml: '24px',
  },
  timeCheckBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  timeBlock: {
    display: 'flex',
  },
  checkBlock: {
    display: 'flex',
    width: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    mr: '24px',
  },
  separator: {
    borderBottomWidth: '1px',
    borderBottomColor: 'custom.greyLight2',
    borderBottomStyle: 'solid',
    width: '100%',
    marginLeft: '24px',
  },
  title: {
    ml: '24px',
  },
  dateBlock: {
    mt: '24px',
    ml: '24px',
    width: '180px',
  },
  workshiftsList: {
    mt: '24px',
  },
  buttonsBlock: {
    display: 'flex',
    margin: '16px 16px 0 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  button: {
    width: '175px',
  },
  close: {
    position: 'absolute',
    right: '-10px',
    top: '-10px',
    mr: '24px',
  },

  noWorkshift: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
};
