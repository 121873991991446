import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import cookies from 'browser-cookies';
import { APP_ROUTES, BASE_URL, LOGGED_IN_COOKIE } from 'constants/common';
import { logout } from 'store/reducers/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});

export const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if ((result.error?.data as any)?.error === 'token') {
    cookies.erase(LOGGED_IN_COOKIE);
    api.dispatch(logout());
    window.location.href = APP_ROUTES.root;
  }

  return result;
};
