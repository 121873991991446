import { diff } from 'deep-diff';
import { set } from 'lodash';

export const getNumberWithOrdinal = (num: string | number) => {
  if (typeof num === 'string') {
    return num;
  }

  const number = +num;
  const ordinals = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;
  return (
    number +
    (ordinals[(remainder - 20) % 10] || ordinals[remainder] || ordinals[0])
  );
};

export const removeUndefinedEntriesFromObject = (obj?: object | null) => {
  if (!obj) return null;

  const filteredArrayOfEntries = Object.entries(obj).filter((objectEntry) =>
    Boolean(objectEntry[1]),
  );

  return filteredArrayOfEntries.length
    ? Object.fromEntries(filteredArrayOfEntries)
    : null;
};

export const twoObjectsDifference = (lhs: object, rhs: object) => {
  const differenceTree = diff(lhs, rhs);
  const differenceObject = {};

  differenceTree?.forEach((treeItem) => {
    if (typeof treeItem.path === 'undefined') return null;

    if (treeItem.kind === 'E' || treeItem.kind === 'N') {
      set(differenceObject, treeItem.path?.join('.'), treeItem.rhs);
    }
  });

  return Object.entries(differenceObject).length ? differenceObject : null;
};
