import { FC } from 'react';
import { List } from '@mui/material';
import { Order } from 'types/orders';

import { OrdersSectionCollapsibleListItem } from '../OrdersSectionCollapsibleListItem';

import { styles } from './styles';

interface OrdersSectionCollapsibleListProps {
  ordersList: Order[];
  setSelectedOrderId: (orderId: string) => void;
  selectedOrderId?: string | null;
}

export const OrdersSectionCollapsibleList: FC<
  OrdersSectionCollapsibleListProps
> = ({ ordersList, setSelectedOrderId, selectedOrderId }) => {
  const orderComponentsList = ordersList.map((order, index) => (
    <OrdersSectionCollapsibleListItem
      onClick={setSelectedOrderId}
      index={index}
      key={order.id}
      order={order}
      selected={selectedOrderId === order.id}
    />
  ));

  return <List sx={styles.container}>{orderComponentsList}</List>;
};
