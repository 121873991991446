import React, { FC, useCallback, useState } from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, SxProps } from '@mui/material';
import { useDeleteGroupMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { removeGroupDetailResourcesById } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { GroupApiResponse } from 'types/api';

import { DialogModal } from 'components/ui/DialogModal';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface DeleteCompanyButtonProps {
  group: GroupApiResponse;
  buttonStyle?: SxProps;
}

export const DeleteGroupButton: FC<DeleteCompanyButtonProps> = React.memo(
  ({ group, buttonStyle = {} }) => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();

    const [deleteGroup] = useDeleteGroupMutation();

    const handleModalConfirm = useCallback(
      (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        dispatch(removeGroupDetailResourcesById(group.id));
        deleteGroup(group.id);
      },
      [deleteGroup, group],
    );

    return (
      <>
        <LightTooltip
          title={t`You can remove this group`}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              sx={{ ...styles.button, ...buttonStyle } as SxProps}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
            >
              <DeleteForeverOutlinedIcon sx={{ color: 'custom.graphite' }} />
            </IconButton>
          </span>
        </LightTooltip>

        <DialogModal
          open={openModal}
          onConfirm={handleModalConfirm}
          onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
            e.stopPropagation();
            setOpenModal(false);
          }}
          title={t`Are you sure you want to delete ${group.title} group?`}
          content=""
        />
      </>
    );
  },
);
