import { memo } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron_right_black.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron_up_orange.svg';

import { styles } from './styles';
interface OpenDedicatedChatButtonProps {
  open: boolean;
  onClick: () => void;
}
export const OpenDedicatedChatButton = memo(
  (props: OpenDedicatedChatButtonProps) => {
    const { open, onClick } = props;

    return (
      <IconButton sx={styles.button} onClick={onClick}>
        {open ? <ChevronUp /> : <ChevronRight />}
      </IconButton>
    );
  },
);
