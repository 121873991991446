import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  labelCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    bgcolor: 'primary.main',
  },

  horizontalPad: {
    width: '30px',
  },
};
