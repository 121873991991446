import { FC, MouseEvent, useCallback, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getIsAnyFiltersOrGroupsSelector } from 'store/selectors/mapSettings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { AllResourcesFiltersPopover } from './components/AllResourcesResourcesFiltersPopover';
import { styles } from './styles';

export const AllRoutesResourcesFilterButton: FC = () => {
  const resourceFilter = useAppSelector(getIsAnyFiltersOrGroupsSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleIconPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <LightTooltip
        title={
          !resourceFilter
            ? t`You can set a resource filter`
            : t`You can change a resource filter`
        }
        arrow
        placement="top"
      >
        <IconButton sx={styles.button} onClick={handleIconPress}>
          {resourceFilter ? (
            <FilterAltIcon sx={styles.icon} />
          ) : (
            <FilterAltOutlinedIcon sx={styles.icon} />
          )}
        </IconButton>
      </LightTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{ sx: styles.popup }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        style={{
          transform: 'translateX(-100px)',
        }}
      >
        {open && <AllResourcesFiltersPopover onClosePopover={handleClose} />}
      </Popover>
    </>
  );
};
