import React, { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';

interface QueryParamsLinkProps extends LinkProps {
  route?: string;
  children: ReactNode;
  onClick?: () => void;
  data?: any;
  withoutSaving?: boolean;
}

// eslint-disable-next-line react/display-name
export const QueryParamsLink: FC<QueryParamsLinkProps> = React.memo(
  ({
    route = APP_ROUTES.chat,
    children,
    onClick,
    withoutSaving = false,
    data,
    ...rest
  }) => {
    const { navigateWithQueryParams } = useNavigateWithQueryParams();
    const navigate = useNavigate();

    const clickHandler = useCallback((): void => {
      onClick?.();
      if (withoutSaving) {
        navigate(route);
      } else {
        navigateWithQueryParams(route, data);
      }
      navigateWithQueryParams(route, data);
    }, [route, navigateWithQueryParams, data, withoutSaving, navigate]);

    return (
      <Link {...rest} onClick={clickHandler} style={{ textDecoration: 'none' }}>
        {children}
      </Link>
    );
  },
);
