import { CHOSEN_DATE, ONLY_CURRENT_WORKSHIFT } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const getOnlyCurrentWorkshiftFromUrl =
  getValueFromUrl(ONLY_CURRENT_WORKSHIFT) === 'true';

export const removeOnlyCurrentWorkshiftFromUrl = () =>
  removeValueFromUrl(ONLY_CURRENT_WORKSHIFT);

export const setOnlyCurrentWorkshiftToUrl = (value: string) =>
  setValueToUrl(ONLY_CURRENT_WORKSHIFT, value);

export const getChosenDateFromUrl = getValueFromUrl(CHOSEN_DATE);

export const removeChosenDateFromUrl = () => removeValueFromUrl(CHOSEN_DATE);

export const setChosenDateToUrl = (value: string) =>
  setValueToUrl(CHOSEN_DATE, value);
