import React, { memo, useCallback, useMemo } from 'react';
import UpdateDisabledOutlinedIcon from '@mui/icons-material/UpdateDisabledOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import { Box, IconButton, List, Stack } from '@mui/material';
import { EXTRA_SECTION_TYPE, UNASSIGNED_TASKS } from 'constants/common';
import { useChatMessages } from 'hooks/useChatMessages';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import { useChatPriorityQuery } from 'store/api/chatApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setShowDoneFailShipment } from 'store/reducers/settingsSlice';
import { getSelectedOrderIdSelector } from 'store/selectors/order';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getIsShowDoneFailShipments } from 'store/selectors/settings';
import { getCurrentWorkshifIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { ShipmentStatuses } from 'types/api';

import { Chat } from 'components/common/Chat';
import { SettingsChapter } from 'components/screens/EditSettingsScreen/components/SettingsChapter';
import { PrioritySelect } from 'components/screens/HomeScreen/components/MapOrChatSection/components/PrioritySelect';
import { MapSection } from 'components/screens/HomeScreen/components/MapSection';
import { AllRoutesResourcesFilterButton } from 'components/ui/AllRoutesResourcesFilterButton';
import { AllRoutesSettingsButton } from 'components/ui/AllRoutesSettingsButton';
import { DetailedMapPointButton } from 'components/ui/DetailedMapPointsButton';
import { DriverHistoricalPathButton } from 'components/ui/DriverHistoricalPathButton';
import { LightTooltip } from 'components/ui/LightTooltip';
import { Section } from 'components/ui/Section';
import { ShowUnreadMessageInMapButton } from 'components/ui/ShowUnreadMessageInMapButton';

import { styles } from './styles';

interface MapOrChatSectionProps {
  type: EXTRA_SECTION_TYPE;
}

const menuOptions = [t`Map`, t`Chat`];

export const MapOrChatSection = memo((props: MapOrChatSectionProps) => {
  const { type } = props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const isShowDoneFail = useAppSelector(getIsShowDoneFailShipments);
  const dispatch = useAppDispatch();
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);
  const selectedOrderId = useAppSelector(getSelectedOrderIdSelector);

  const { selectedOrder } = useSelectedOrder();

  const { chatMessages } = useChatMessages(selectedOrder);

  const { data: chatPriority } = useChatPriorityQuery(
    // !selectedWorkshiftId ||
    //   (!selectedOrder && selectedOrderId) ||
    //   !chatMessages?.length
    //   ? skipToken
    {
      workshiftId: selectedWorkshiftId as string,
      shipmentId: selectedOrder?.shipmentId,
    },
    {
      skip:
        !selectedWorkshiftId ||
        (!selectedOrder && !!selectedOrderId) ||
        !chatMessages?.length,
      refetchOnMountOrArgChange: true,
    },
  );

  const currenMenuItem = useMemo(
    () => (type === EXTRA_SECTION_TYPE.map ? 0 : 1),
    [type],
  );

  const handleButtonPress = useCallback(() => {
    dispatch(setShowDoneFailShipment());
  }, []);

  const tooltipTitle = useMemo(() => {
    return isShowDoneFail
      ? t`Hide historical path of orders`
      : t`Show historical path of orders`;
  }, [isShowDoneFail]);

  const doneFailEnable = useMemo(() => {
    return (
      <LightTooltip
        title={tooltipTitle}
        arrow
        placement="top"
        key="done-faile-enable-button-tooltip"
      >
        <span>
          <IconButton
            key="done-faile-enable-button"
            sx={styles.iconButton}
            onClick={handleButtonPress}
          >
            {isShowDoneFail ? (
              <UpdateOutlinedIcon sx={styles.icon} />
            ) : (
              <UpdateDisabledOutlinedIcon sx={styles.icon} />
            )}
          </IconButton>
        </span>
      </LightTooltip>
    );
  }, [isShowDoneFail]);

  const mapButtons = useMemo(() => {
    if (type === EXTRA_SECTION_TYPE.chat && selectedResourceId) {
      if (chatMessages?.length) {
        return [
          <PrioritySelect
            key="CHAT PRIORITY SELECT BUTTON"
            work_shift_id={selectedWorkshiftId as string}
            priority={chatPriority}
            shipment_id={selectedOrder?.shipmentId}
          />,
        ];
      }

      return [];
    }
    return selectedResourceId
      ? [
          doneFailEnable,
          <DriverHistoricalPathButton key="driverHistoricalPathButton" />,
          <ShowUnreadMessageInMapButton key="Show Unread Message in the map button" />,
        ]
      : [
          <AllRoutesSettingsButton key="All Routes Settings Button" />,
          <AllRoutesResourcesFilterButton key="Filter button" />,
          <DetailedMapPointButton key="Detailed Map Point Buton" />,
        ];
  }, [
    doneFailEnable,
    selectedResourceId,
    type,
    chatPriority,
    selectedWorkshiftId,
    selectedOrder?.shipmentId,
    chatMessages?.length,
  ]);

  const isShowNewView = useMemo(
    () => selectedResourceId && selectedResourceId !== UNASSIGNED_TASKS,
    [selectedResourceId],
  );

  const sectionLabel = useMemo(() => {
    if (!isShowNewView) {
      return t`Map`;
    }

    if (type === EXTRA_SECTION_TYPE.map) {
      return '';
    }

    if (
      !selectedResourceId ||
      selectedResourceId === UNASSIGNED_TASKS ||
      selectedOrder?.shipmentStatus === ShipmentStatuses.ASSIGNED
    ) {
      return t`Chat`;
    }

    if (
      selectedResourceId &&
      (selectedOrder?.dropOptions?.length || !selectedOrder)
    ) {
      return t`General chat`;
    } else if (selectedResourceId && selectedOrder) {
      return t`${selectedOrder.address.title} Chat`;
    }

    return t`Chat`;
  }, [type, selectedResourceId, isShowNewView, selectedOrder]);

  const handleMenuItemClick = useCallback(
    (idx: number) => {
      if (idx === 0) {
        navigateWithQueryParams('/');
      } else {
        navigateWithQueryParams('/chat');
      }
    },
    [navigateWithQueryParams],
  );

  const menuList = useMemo(
    () =>
      menuOptions.map((option, index) => (
        <SettingsChapter
          key={`${option} ${index}`}
          isActive={currenMenuItem === index}
          label={option}
          onPress={() => handleMenuItemClick(index)}
        />
      )),
    [currenMenuItem, handleMenuItemClick],
  );

  const renderButtons = useMemo(() => {
    return mapButtons.reverse().map((element) => element);
  }, [type, mapButtons]);

  return (
    <Section
      label={sectionLabel}
      sx={styles.container}
      buttons={isShowNewView ? [] : mapButtons}
    >
      {isShowNewView ? (
        <>
          <List sx={styles.list}>
            <Stack direction="row">{menuList}</Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              {renderButtons}
            </Stack>
          </List>
          {currenMenuItem === 0 && <MapSection />}
          {currenMenuItem === 1 && (
            <Box sx={styles.chatContainer}>
              <Chat />
            </Box>
          )}
        </>
      ) : (
        <MapSection />
      )}
    </Section>
  );
});
