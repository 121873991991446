import { FC, FocusEvent, useState } from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FieldProps, getIn } from 'formik';
import { sortBy } from 'lodash';
import { SelectOption } from 'types/common';

import { CompanyImageOptions } from 'components/common/ChangeScreenForms/components/OrderDetailsTabPackageLogoSection/OrderDetailsTabPackageLogoSection';

import { styles } from './styles';

type FieldAutocompleteProps<T = SelectOption> = AutocompleteProps<
  T,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined
> & {
  // showSecondary?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  onSelect?: () => void;
  // TODO: Add typings
  creatableFilterFunction?: (options: any, params: any) => any;
};

export const FieldAutocomplete: FC<FieldAutocompleteProps & FieldProps> = ({
  field,
  form: { errors, touched, setFieldValue },
  name,
  label,
  placeholder,
  required,
  autoComplete = true,
  autoHighlight = true,
  selectOnFocus = true,
  fullWidth = true,
  creatableFilterFunction,
  onSelect,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  const errorMessage = getIn(errors, field.name);
  const isTouched = !!getIn(touched, field.name);
  const creatable = typeof creatableFilterFunction !== 'undefined';

  const focusHandler = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => (selectOnFocus ? event.target.select() : null);

  return (
    <Autocomplete
      inputValue={inputValue}
      clearOnBlur
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      includeInputInList={autoComplete ? true : props.includeInputInList}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disableClearable={required ? true : props.disableClearable}
      onChange={(_, selectedOption: any) => {
        const value =
          typeof selectedOption === 'string'
            ? selectedOption
            : selectedOption?.value || '';
        setFieldValue(field.name, value.trim());
        onSelect?.(value);
      }}
      renderOption={(props, option) => {
        const { img_url } = option as CompanyImageOptions;

        return (
          <MenuItem {...props} sx={styles.autocompleteItem}>
            <Typography>{option.label}</Typography>
            {img_url ? (
              <img
                src={img_url}
                // src={`https://best.mindconnect.se/driversync/v1/public/file/company-images?img_id=${option.img_id}&img_name=${option.img_name}`}
                alt="Company logo"
                width="70px"
              />
            ) : (
              <Typography>{option.secondaryLabel}</Typography>
            )}
          </MenuItem>
        );
      }}
      freeSolo={creatable ? true : props.freeSolo}
      filterOptions={
        creatable
          ? (options, params) => {
              const filtered = creatableFilterFunction?.(options, params);
              const { inputValue } = params;

              const isExisting = options.some(
                (option) =>
                  inputValue === option.value || inputValue === option.label,
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: inputValue,
                });
              }

              return filtered;
            }
          : props.filterOptions
      }
      value={
        props.options.find((option: any) => option.value === field.value) ||
        field.value ||
        null
      }
      {...props}
      options={sortBy(props.options, 'label') || null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          onFocus={focusHandler}
          placeholder={placeholder}
          required={required}
          error={isTouched && !!errorMessage}
          helperText={isTouched && errorMessage}
          // InputLabelProps={{
          //   shrink: true,
          // }}
        />
      )}
    />
  );
};
