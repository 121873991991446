import { t } from 'ttag';
import { RESOURCE_COLOR } from 'types/resource';

export const coloredFilterOptions = [
  'grey',
  'orange',
  'yellow',
  'blue',
  'green',
  'red',
];

export type MappedColorsTitle = Partial<Record<RESOURCE_COLOR, string>>;

export const mappedColoredTitles: MappedColorsTitle = {
  grey: t`no current workshift and not logged in`,
  orange: t`not workshift now and logged in and unfinished errands on last workshift`,
  yellow: t`not workshift now and logged in`,
  blue: t`not logged in and no workshift and unfinished errands in previous workshift`,
  green: t`workshift now and logged in`,
  red: t`workshift now and not logged in`,
};

export const coloredTitlesForTooltip = [
  {
    color: 'grey',
    title: t`Grey - no current workshift and not logged in`,
  },
  {
    color: 'green',
    title: t`Green - workshift now and logged in`,
  },
  {
    color: 'yellow',
    title: t`Yellow - not workshift now and logged in`,
  },
  {
    color: 'orange',
    title: t`Orange - not workshift now and logged in and unfinished errands on last workshift`,
  },
  {
    color: 'red',
    title: t`Red - workshift now and not logged in`,
  },
  {
    color: 'blue',
    title: t`Blue - not logged in and no workshift and unfinished errands in previous workshift`,
  },
];
