import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    minWidth: '224px',
    width: '224px',
    maxWidth: '224px',
  },

  warning: {
    mt: '10px',
    textAlign: 'center',
  },
};
