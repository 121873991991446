import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { isEmpty } from 'lodash';
import { t } from 'ttag';
import { SubcontractorDetail } from 'types/api';
import * as yup from 'yup';

import { Section } from 'components/ui/Section';

import { styles } from './styles';

const validationSchema = yup.object({
  name: yup
    .string()
    .max(50, t`Company name should not be longer than 50 symbols`)
    .min(3, t`Street should be at least 3 symbols`)
    .required(t`Company name is required`),
  email: yup
    .string()
    .email(t`The input value doesn't match the email pattern`)
    .max(50, t`Email should not be longer than 50 symbols`),
  country: yup
    .string()
    .required(t`Country is required`)
    .max(50, t`Country should not be longer than 50 symbols`)
    .min(3, t`Country should be at least 3 symbols`),
  city: yup
    .string()
    .required(t`City is required`)
    .max(50, t`City should not be longer than 50 symbols`)
    .min(3, t`City should be at least 3 symbols`),
});

export interface AddCompanyValues extends FormikValues {
  email: string;
  name: string;
  city: string;
  country: string;
}

interface UpdateCompanyScreenFormsProps {
  edit: boolean;
  companyDetail?: SubcontractorDetail;
  onCreate?: (options: {
    name: string;
    city: string;
    country: string;
    email?: string | undefined;
  }) => Promise<boolean>;
  onEdit?: (options: Partial<AddCompanyValues>) => Promise<boolean>;
  loading: boolean;
}

export const UpdateCompanyScreenForms: FC<UpdateCompanyScreenFormsProps> = (
  props,
) => {
  const { edit, companyDetail, onEdit, onCreate, loading = false } = props;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const submitFormHandler = useCallback(
    async (
      values: AddCompanyValues,
      helpers: FormikHelpers<AddCompanyValues>,
    ) => {
      helpers.setSubmitting(true);

      let res = false;

      if (edit) {
        res = (await onEdit?.(values)) as boolean;
      } else {
        res = (await onCreate?.(values)) as boolean;
      }

      await helpers.setSubmitting(false);

      if (!res) {
        return;
      }

      formik.resetForm();
      navigateWithQueryParams(APP_ROUTES.root);
    },
    [edit, onEdit, onCreate],
  );

  const formik = useFormik<AddCompanyValues>({
    initialValues: {
      country: companyDetail?.country || '',
      city: companyDetail?.city || '',
      email: companyDetail?.email || '',
      name: companyDetail?.name || '',
    },
    validationSchema,
    onSubmit: submitFormHandler,
  });

  useEffect(() => {
    if (companyDetail?.country) {
      formik.setFieldValue('country', companyDetail.country);
    }
    if (companyDetail?.city) {
      formik.setFieldValue('city', companyDetail.city);
    }
    if (companyDetail?.email) {
      formik.setFieldValue('email', companyDetail.email);
    }
    if (companyDetail?.name) {
      formik.setFieldValue('name', companyDetail.name);
    }
  }, [companyDetail]);

  useEffect(() => {
    formik.resetForm();

    return () => formik.resetForm();
  }, []);

  const isValid = useMemo(
    () => formik.isValid && !isEmpty(formik.touched),
    [formik],
  );
  return (
    <FormControl
      component="form"
      onSubmit={formik.handleSubmit}
      sx={styles.container}
    >
      <Typography sx={styles.title} component="h1">
        {edit ? t`Edit Company` : t`Add New Company`}
      </Typography>
      <Section
        sx={styles.section}
        label={t`Company`}
        labelStyles={styles.subtitle}
      >
        <Grid
          container
          columns={3}
          spacing={2}
          paddingX={2}
          paddingBottom={3}
          paddingTop={3}
        >
          <Grid item mobile={1}>
            <TextField
              required
              id="name"
              name="name"
              placeholder={t`Company name`}
              label={t`Company name`}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name &&
                (Boolean(formik.errors.name) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={1}>
            <TextField
              id="email"
              name="email"
              label={t`Email`}
              placeholder={t`Company email`}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.email &&
                (Boolean(formik.errors.email) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />

            {/* Fixed autocomplete issue*/}
            <div style={{ display: 'none' }}>
              <TextField autoComplete="on" />
            </div>
          </Grid>
        </Grid>

        <Grid container columns={3} spacing={2} paddingX={2} paddingBottom={3}>
          <Grid item mobile={1}>
            <TextField
              required
              id="country"
              name="country"
              placeholder={t`Country`}
              label={t`Country`}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.country &&
                (Boolean(formik.errors.country) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.country && formik.errors.country}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={1}>
            <TextField
              id="city"
              name="city"
              label={t`City`}
              placeholder={t`City`}
              required
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.city &&
                (Boolean(formik.errors.city) ||
                  Boolean(formik.errors.formErrorField))
              }
              helperText={formik.touched.city && formik.errors.city}
              fullWidth
              autoComplete="new-password"
              sx={styles.input}
            />

            {/* Fixed autocomplete issue*/}
            <div style={{ display: 'none' }}>
              <TextField autoComplete="on" />
            </div>
          </Grid>
        </Grid>
      </Section>
      <Box sx={styles.bottomButtonContainer}>
        <Button
          onClick={() => navigateWithQueryParams(APP_ROUTES.root)}
          sx={styles.cancelButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={styles.button}
          disabled={formik.isSubmitting || !isValid || loading}
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </Box>
    </FormControl>
  );
};
