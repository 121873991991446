import React, { FC, memo, useCallback, useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as AndroidIcon } from 'assets/icons/android_icon.svg';
import { format } from 'date-fns';
import { useDriversReportQuery } from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  updateDriverOrderBy,
  updateDriverPage,
  updateDriverSize,
} from 'store/reducers/deviceInfoReportSlice';
import {
  getDriverAndroidFilter,
  getDriverDeviceFilter,
  getDriverEndTime,
  getDriverNameFilter,
  getDriverOrder,
  getDriverOrderBy,
  getDriverPage,
  getDriverSize,
  getDriverStartTime,
} from 'store/selectors/deviseInfoReports';
import { t } from 'ttag';
import { SortOrder } from 'utils/tableUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

import {
  deviceDriversHeadCells,
  DeviceDriversHeadCellTitle,
} from 'components/screens/AdminHomeScreen/components/DeviceInfoReport/components/DeviceDriversTable/deviceDriversTableUtils';
import { CurrentResource } from 'components/screens/AdminHomeScreen/components/DeviceInfoReport/DeviceInfoReport';
import { DeviceInfoReportDriverFilterButton } from 'components/ui/DeviceInfoReportDriverFilterButton';

import { strWithoutMinus } from './deviceDriversTableUtils';
import { styles } from './styles';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: DeviceDriversHeadCellTitle,
  ) => void;
  order: SortOrder;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = memo((props: EnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: DeviceDriversHeadCellTitle) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const getBorderStyle = useCallback((isBorder: boolean): SxProps => {
    return isBorder ? styles.rightBorder : {};
  }, []);

  return (
    <TableHead>
      <TableRow>
        {deviceDriversHeadCells.map((headCell, idx) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              bgcolor: 'custom.grey3',
              ...getBorderStyle(idx < deviceDriversHeadCells.length - 1),
            }}
          >
            <TableSortLabel
              disabled={!headCell.isSorted}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.id === 'android_version' ? (
                <Box
                  ml={headCell.id && !headCell.numeric ? '20px' : '0px'}
                  pt="8px"
                >
                  <AndroidIcon />
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  ml={
                    orderBy !== headCell.id && !headCell.numeric
                      ? '16px'
                      : '0px'
                  }
                >
                  {headCell.label}
                </Typography>
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

interface DeviceDriversTableProps {
  setCurrentResource: React.Dispatch<
    React.SetStateAction<CurrentResource | null>
  >;
  currentResource: CurrentResource;
}
export const DeviceDriversTable: FC<DeviceDriversTableProps> = React.memo(
  ({ setCurrentResource, currentResource }) => {
    const dispatch = useAppDispatch();
    const orderBy = useAppSelector(getDriverOrderBy);
    const order = useAppSelector(getDriverOrder);
    const page = useAppSelector(getDriverPage);
    const size = useAppSelector(getDriverSize);
    const driverFilter = useAppSelector(getDriverNameFilter);
    const deviceFilter = useAppSelector(getDriverDeviceFilter);
    const androidFilter = useAppSelector(getDriverAndroidFilter);
    const startDate = useAppSelector(getDriverStartTime);
    const endDate = useAppSelector(getDriverEndTime);

    const { data } = useDriversReportQuery({
      order_by: orderBy,
      page,
      size,
      resource_id: currentResource.resourceId,
      android: androidFilter,
      device: deviceFilter,
      driver: driverFilter,
      from_date: startDate,
      to_date: endDate,
    });

    const rows = useMemo(() => {
      if (!data) {
        return [];
      }

      return data?.items?.length ? data.items : [];
    }, [data]);

    const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: DeviceDriversHeadCellTitle,
    ) => {
      // @ts-ignore
      const isAsc = orderBy === property && order === 'asc';
      // @ts-ignore
      dispatch(updateDriverOrderBy(isAsc ? `-${property}` : property));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
      dispatch(updateDriverPage(newPage + 1));
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      dispatch(updateDriverSize(parseInt(event.target.value, 10)));
      dispatch(updateDriverPage(1));
    };

    const handleBackPress = useCallback(() => {
      setCurrentResource(null);
    }, [setCurrentResource]);

    return (
      <Box
        sx={{ width: '100%', mt: '8px', flexGrow: 1, pl: '16px', pr: '16px' }}
      >
        <Box sx={styles.searchBlock}>
          <Box sx={styles.buttonBlock}>
            <IconButton onClick={handleBackPress}>
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h6" sx={styles.title}>
              {t`Resource`} {currentResource.resourceNumber}
            </Typography>
          </Box>
          <Box>
            <DeviceInfoReportDriverFilterButton />
            <Button
              sx={styles.button}
              variant="outlined"
              endIcon={<DownloadIcon color="primary" />}
            >
              {t`Export`}
            </Button>
          </Box>
        </Box>
        <Paper sx={styles.tablePaper}>
          <TableContainer sx={{ maxHeight: '550px' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                order={order}
                orderBy={strWithoutMinus(orderBy)}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.map((row, index) => {
                  // const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={() => setCurrentFeedbackId(row.id)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      // tabIndex={-1}
                      key={createUniqueId()}
                      // selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        align="left"
                        variant="body"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            textTransform: 'none',
                            width: '100px',
                          } as SxProps
                        }
                      >
                        <Box sx={styles.deviceIdBlock}>
                          <Typography
                            variant="body1"
                            ml="6px"
                            sx={{ lineHeight: '20px' }}
                          >
                            {row.driver_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '150px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.login_time
                            ? format(
                                new Date(row.login_time as string),
                                'yyyy-MM-dd HH:mm',
                              )
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '150px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.logout_time
                            ? format(
                                new Date(row.logout_time as string),
                                'yyyy-MM-dd HH:mm',
                              )
                            : ''}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '200px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px', textTransform: 'none' }}
                        >
                          {row.device_info}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={
                          {
                            ...styles.zeroBorder,
                            ...styles.rightBorder,
                            width: '50px',
                          } as SxProps
                        }
                      >
                        <Typography
                          variant="body1"
                          ml="6px"
                          sx={{ lineHeight: '20px' }}
                        >
                          {row.android_version}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && ( */}
                {/*   <TableRow */}
                {/*     style={{ */}
                {/*       height: 33 * emptyRows, */}
                {/*     }} */}
                {/*   > */}
                {/*     <TableCell colSpan={4} /> */}
                {/*   </TableRow> */}
                {/* )} */}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={styles.topBorder}
          />
        </Paper>
      </Box>
    );
  },
);
