import React, { FC, useCallback, useMemo, useState } from 'react';
import { Autocomplete, Button, Chip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeResourceFilters,
  updateResourceFilters,
  updateResourcePage,
  updateResourceSize,
} from 'store/reducers/deviceInfoReportSlice';
import { setToast } from 'store/reducers/settingsSlice';
import { getResourceNumberFilter } from 'store/selectors/deviseInfoReports';
import { t } from 'ttag';

import { styles } from './styles';

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const resourceFilter = useAppSelector(getResourceNumberFilter);
  const [resourceFiltersInputValue, setResourceFiltersInputValue] =
    useState<string[]>(resourceFilter);

  const isAnyFilters = useMemo(() => {
    return resourceFilter?.length;
  }, [resourceFilter]);

  const isAnyDataInInputs = useMemo(() => {
    return isAnyFilters || resourceFiltersInputValue.length;
  }, [isAnyFilters, resourceFiltersInputValue]);

  const handleChangeResources = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = resourceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setResourceFiltersInputValue(updatedValues);
    },
    [resourceFiltersInputValue],
  );

  const handleApplyPress = useCallback(() => {
    let emptyCounter = 0;

    if (!resourceFiltersInputValue?.length) {
      dispatch(updateResourceFilters([]));
      emptyCounter++;
    }

    if (emptyCounter === 1) {
      return;
    }

    dispatch(updateResourceFilters(resourceFiltersInputValue));
    dispatch(updateResourceSize(10));
    dispatch(updateResourcePage(1));

    onClosePopover();
  }, [resourceFiltersInputValue, onClosePopover]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeResourceFilters());
    setResourceFiltersInputValue([]);
    onClosePopover();
  }, [onClosePopover]);

  // @ts-ignore
  return (
    <Box sx={styles.container}>
      <Autocomplete
        value={resourceFiltersInputValue}
        multiple
        id="resources"
        onChange={handleChangeResources}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Resources`}
            // placeholder={t`Search by resources`}
          />
        )}
        // sx={styles.firstFilter}
      />

      <Button
        disabled={resourceFiltersInputValue.length === 0}
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>
      <Button
        disabled={!isAnyDataInInputs}
        sx={styles.button}
        variant="outlined"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
