import { FC, useMemo, useState } from 'react';

import { DeviceDriversTable } from 'components/screens/AdminHomeScreen/components/DeviceInfoReport/components/DeviceDriversTable';

import { DeviceResourceTable } from './components/DeviceResourceTable';

export type CurrentResource = {
  resourceId: string;
  resourceNumber: string;
};

export const DeviceInfoReport: FC = () => {
  const [selectedResource, setSelectedResource] =
    useState<CurrentResource | null>(null);

  const content = useMemo(() => {
    if (!selectedResource) {
      return <DeviceResourceTable setCurrentResource={setSelectedResource} />;
    }

    return (
      <DeviceDriversTable
        setCurrentResource={setSelectedResource}
        currentResource={selectedResource}
      />
    );
  }, [selectedResource, setSelectedResource]);

  return content;
};
