import { FC, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setAllRoutesSelectedOrder } from 'store/reducers/orderSlice';
import { updateShowDetailSection } from 'store/reducers/settingsSlice';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const CloseDetailButton: FC = () => {
  const dispatch = useAppDispatch();

  const handleButtonClick = useCallback(() => {
    dispatch(updateShowDetailSection(false));
    dispatch(setAllRoutesSelectedOrder(null));
  }, [dispatch]);

  return (
    <LightTooltip title={t`Close panel`} arrow placement="top">
      <span>
        <IconButton sx={styles.button} onClick={handleButtonClick}>
          <CloseIcon sx={styles.icon} />
        </IconButton>
      </span>
    </LightTooltip>
  );
};
