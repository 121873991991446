export const styles = {
  container: {
    position: 'relative',
  },

  circle: {
    position: 'absolute',
    minWidth: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: '0px',
    top: '0px',
    bgcolor: 'primary.main',
  },

  unreadContainer: {
    position: 'absolute',
    // left: '50%',
    // top: '50%',
    top: 9,
  },

  unread: {
    color: 'custom.black',
    fontSize: '10px',
    fontWeight: 500,
  },
};
