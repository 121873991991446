import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getSelectedOrderIdSelector } from 'store/selectors/order';
import { Order } from 'types/orders';

import { useOrdersList } from './useOrdersList';

export const useSelectedOrder = (orderId?: string | null) => {
  const selectedOrderId =
    orderId !== undefined
      ? orderId
      : useAppSelector(getSelectedOrderIdSelector);
  const { ordersList, isFetching } = useOrdersList();

  const getSelectedOrder = useCallback(() => {
    if (!ordersList?.length) {
      return null;
    }

    const order = ordersList.find(
      (order: Order) => order.id === selectedOrderId,
    );

    return order ? order : null;
  }, [selectedOrderId, ordersList]);

  const selectedOrder = useMemo(() => getSelectedOrder(), [getSelectedOrder]);

  return { selectedOrder, isFetching };
};
