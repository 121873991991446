import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';

import { ChangeScreenFormProps } from '../../types';

import { styles } from './styles';

export const AdditionsDataTabDeliveryTime: FC<ChangeScreenFormProps> = () => {
  return (
    <ChangeScreenSection label={t`Delivery time`}>
      <Grid container columns={1} rowGap={2}>
        <Grid container columns={1} spacing={2} rowGap={4}>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="deliveryWaitingTime"
              label={t`Waiting time delivery`}
              placeholder={t`Enter waiting time delivery`}
              type="number"
            />
            <Typography>{t`min`}</Typography>
          </Grid>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="deliveryUnloadingTime"
              label={t`Unloading time`}
              placeholder={t`Enter unloading time`}
              type="number"
            />
            <Typography>{t`min`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
