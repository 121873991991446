import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '316px',
    height: '96px',
  },

  addIconBlock: {
    borderRadius: '50%',
    bgcolor: 'primary.light',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  description: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: '8px',
    textAlign: 'center',
  },
};
