import { FC, useCallback } from 'react';
import GridViewIcon from '@mui/icons-material/GridView';
import { IconButton } from '@mui/material';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setDetailsUnderOrders } from 'store/reducers/settingsSlice';
import { getDetailsUnderOrdersSelector } from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const ChangeGridButton: FC = () => {
  const dispatch = useAppDispatch();
  const detailsUnderOrdersStatus = useAppSelector(
    getDetailsUnderOrdersSelector,
  );

  const handleIconPress = useCallback(() => {
    dispatch(setDetailsUnderOrders(!detailsUnderOrdersStatus));
  }, [detailsUnderOrdersStatus]);

  return (
    <LightTooltip
      title={t`You can change blocks’ positions`}
      arrow
      placement="top"
    >
      <IconButton sx={styles.button} onClick={handleIconPress}>
        <GridViewIcon sx={styles.icon} />
      </IconButton>
    </LightTooltip>
  );
};
