import { RESOURCE_ID_URL_PARAM } from 'constants/urlParams';
import {
  getValueFromUrl,
  removeValueFromUrl,
  setValueToUrl,
} from 'utils/urlUtils';

export const getSelectedResourceIdFromUrl = getValueFromUrl(
  RESOURCE_ID_URL_PARAM,
);

export const removeSelectedResourceIdFromUrl = () =>
  removeValueFromUrl(RESOURCE_ID_URL_PARAM);

export const setSelectedResourceIdToUrl = (value: string) =>
  setValueToUrl(RESOURCE_ID_URL_PARAM, value);
