import { createTheme } from '@mui/material';
import OpenSansRegular from 'assets/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'assets/fonts/OpenSans-SemiBold.ttf';

import { COLORS } from './variables';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

type CustomColors = Omit<
  typeof COLORS,
  'orangePrimary' | 'orangePrimaryLight' | 'orangeSecondary'
>;
//   white: string;
//   black: string;
//   graphite: string;
//   grey: string;
//   greyLight: string;
//   greyBlue: string;
//   greyLightBlue: string;
//   greyLightBlue2: string;
//   greyLightBlue3: string;
//   greyLight2: string;
//   greyBackground: string;
//   blue: string;
//   blueLight: string;
//   blueLight2: string;
//   blueLight3: string;
//   green: string;
//   yellow: string;
//   red: string;
//   red2: string;
//   gold: string;
//   brown: string;
//   main15: string;
//   grey2: string;
//   grey3: string;
//   green2: string;
//   green3: string;
//   darkBlue: string;
// }

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    custom: CustomColors;
  }

  interface PaletteOptions {
    custom?: CustomColors;
  }
}

type PrimaryPalette = {
  main: string;
  contrastText: string;
  light: string;
};

export const basicTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.orangePrimary,
      contrastText: COLORS.white,
      light: COLORS.orangePrimaryLight,
    },
    secondary: {
      main: COLORS.orangeSecondary,
      contrastText: COLORS.white,
    },
    custom: {
      white: COLORS.white,
      black: COLORS.black,
      yellow: COLORS.yellow,
      graphite: COLORS.graphite,
      grey: COLORS.grey,
      greyLight: COLORS.greyLight,
      greyLight2: COLORS.greyLight2,
      greyBlue: COLORS.greyBlue,
      greyLightBlue: COLORS.greyLightBlue,
      greyLightBlue2: COLORS.greyLightBlue2,
      greyLightBlue3: COLORS.greyLightBlue3,
      greyBackground: COLORS.greyBackground,
      green: COLORS.green,
      green2: COLORS.green2,
      green3: COLORS.green3,
      green4: COLORS.green4,
      blue: COLORS.blue,
      blue2: COLORS.blue2,
      blueLight: COLORS.blueLight,
      blueLight2: COLORS.blueLight2,
      blueLight3: COLORS.blueLight3,
      red: COLORS.red,
      red2: COLORS.red2,
      red3: COLORS.red3,
      gold: COLORS.gold,
      brown: COLORS.brown,
      main15: COLORS.main15,
      grey2: COLORS.grey2,
      grey3: COLORS.grey3,
      darkBlue: COLORS.darkBlue,
      greyLight3: COLORS.greyLight3,
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      laptop: 1200,
      desktop: 1680,
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    h3: { fontSize: '1rem', fontWeight: 'bold' },
    h2: {
      fontSize: '10px',
      fontWeight: 'bold',
      color: '#212121',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    h1: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#1C1B1F',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#212121',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '16px',
      color: '#666666',
    },
    body2: {
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: '#666666',
    },
    subtitle1: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '16px',
      color: '#000000',
    },
    h6: {
      fontSize: '12px',
      color: '#666666',
      fontWeight: '700',
      lineHeight: '18px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          src: local('Open Sans'), local('OpenSans-Regular'), url(${OpenSansRegular}) format('truetype');
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: 'Open Sans';
          src: local('Open Sans'), local('OpenSans-SemiBold'), url(${OpenSansSemiBold}) format('truetype');
          font-weight: 600;
          font-style: bold;
        }
        @keyframes unread_blinker {
          50% {
            color: #FF8200;
            border: 1px solid #FF8200;
            background: #FFFFFF;
          }
          100% {
            color: #FFFFFF;
            border: border: 1px solid #FF8200;
            background: #FF8200;
          }
        }
      `,
    },
  },
});

export const theme = createTheme(
  {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-root': { borderRadius: 6 },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: 0,

            '&.MuiOutlinedInput-root fieldset': {
              borderColor: basicTheme.palette.custom.greyLight,
            },
            '&.MuiOutlinedInput-root:hover fieldset': {
              borderColor: basicTheme.palette.primary.main,
            },
          },
          notchedOutline: {
            '&&&.MuiOutlinedInput-notchedOutline': { borderWidth: 1 },
          },
          input: {
            padding: '12px 16px',
            caretColor: basicTheme.palette.primary.main,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            borderRadius: 6,
            boxShadow: 'none',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: 'rgba(255, 130, 0, 0.04)',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            '&.MuiMenu-paper': {
              boxShadow: '0px 0px 3px 0px #00000029',
              borderRadius: '6px',
            },
          },
          list: {
            padding: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '0 16px',
            color: basicTheme.palette.custom.graphite,
            '&': {
              textTransform: 'uppercase',
              letterSpacing: '1.5px',
              fontWeight: 'bold',
              fontSize: '12px',
            },
            '&:hover': {
              backgroundColor: basicTheme.palette.primary.light,
            },
            '&.Mui-selected': {
              color: basicTheme.palette.primary.main,
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&.MuiAutocomplete-paper': {
              marginBottom: '12px',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            position: 'absolute',
            top: '45px',
            lineHeight: '14px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-root': {
              top: '-3px',
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginRight: '16px',
            marginLeft: 0,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              paddingTop: '6px',
              paddingBottom: '6px',
            },
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: '6px 4px 6px 6px',
            },
          },
        },
      },
    },
  },
  basicTheme,
);

export function getMuiColor(
  colorName: keyof CustomColors | keyof PrimaryPalette,
  type = 'custom',
) {
  // @ts-ignore
  return theme.palette[type][colorName];
}
