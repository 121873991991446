import { t } from 'ttag';
import { DriverDevice } from 'types/api';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type SortOrder = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export type HeadCell = {
  id: keyof DriverDevice;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
};
export const headCells: readonly HeadCell[] = [
  {
    id: 'device_id',
    numeric: true,
    disablePadding: true,
    label: t`DEVICE ID`,
  },
  {
    id: 'notes',
    numeric: false,
    disablePadding: false,
    label: t`NOTES`,
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: t`CREATED`,
  },
  {
    id: 'last_login',
    numeric: false,
    disablePadding: false,
    label: t`LAST USED`,
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: t`ACTIVE`,
  },
];
