import { ReactNode } from 'react';
import { Address, Message } from 'types/api';

export type PriorityName = 'closed' | 'open' | 'highPriority';
export type ChatPriority = 0 | 1 | 2;
export interface LatestDedicatedChatMessage {
  id: string;
  created_at: string;
  read: boolean;
  message: Message;
}

export interface DedicatedChatShipmentDetail {
  address: Pick<
    Address,
    'city' | 'postal_code' | 'street' | 'street_number'
  > | null;
  name: string | null;
  title: string | null;
}

export interface DedicatedChat {
  id: string;
  resource_number: string;
  resource_id: string;
  work_shift_id: string;
  shipment_id: string | null;
  shipment_detail: DedicatedChatShipmentDetail | null;
  start: string;
  end: string;
  unread: number;
  priority: ChatPriority;
  latest_message: LatestDedicatedChatMessage;
  latest_message_time?: string;
  eta: string | null;
  pod: string | null;
  margin: number | null;
  delivery_errand_id: string | null;
}

export const chatPriority: Record<PriorityName, ChatPriority> = {
  open: 1,
  closed: 0,
  highPriority: 2,
};

export type SortOrder = 'asc' | 'desc' | null;
export type ItemAlign = 'flex-start' | 'center' | 'flex-end';
export type HeaderFieldNames =
  | 'filter'
  | 'resource'
  | 'shift'
  | 'chat'
  | 'time'
  | 'message'
  | 'status';

export interface DedicatedChatHeaderItem {
  content: ReactNode;
  isOrdered: boolean;
  onClick?: () => void;
  order: SortOrder;
  itemSize: number;
  itemAlign: ItemAlign;
  fieldName: HeaderFieldNames;
}

export const sortedFields: Partial<HeaderFieldNames>[] = [
  'resource',
  'time',
  'status',
];

export const headerItemSizeMapper: Record<HeaderFieldNames, number> = {
  filter: 2,
  resource: 6,
  shift: 8,
  chat: 12,
  time: 6,
  message: 12,
  status: 6,
};

export const headerItemAlignMapper: Record<HeaderFieldNames, ItemAlign> = {
  filter: 'center',
  resource: 'flex-start',
  shift: 'flex-start',
  chat: 'flex-start',
  time: 'center',
  message: 'flex-start',
  status: 'center',
};
