import React, { memo, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';
import { format } from 'date-fns';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GraphData } from 'types/terminalScanChart';

import { styles } from 'components/common/ImageViewer/styles';

interface TerminalScansChartProps {
  chartData: GraphData[];
  allLoaded: boolean;
  errandsQty: number;
}

export const TerminalScansChart = memo((props: TerminalScansChartProps) => {
  const { chartData, allLoaded, errandsQty } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const maxYAxisValue = useMemo(() => {
    if (!errandsQty) {
      return 10;
    }

    return Math.ceil(errandsQty / 10) * 10;
  }, [errandsQty]);

  const data = useMemo(() => {
    if (!chartData) {
      return [];
    }

    return chartData.map(({ x, y }) => ({
      x: format(new Date(x), 'HH:mm'),
      parcels: y,
    }));
  }, [chartData]);

  const { lineColor, areaColor, dotBg } = useMemo(() => {
    if (allLoaded) {
      return {
        lineColor: '#42A146',
        areaColor: '#42A146',
        dotBg: '#FFFFFF',
      };
    }

    return {
      lineColor: '#FF8200',
      areaColor: '#FAEC30',
      dotBg: '#FAEC30',
    };
  }, [allLoaded]);

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          onClick={() => setIsOpenModal(true)}
          data={data}
          margin={{
            top: 0,
            right: 20,
            left: 0,
            bottom: 0,
          }}
          style={{ cursor: 'pointer' }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={areaColor} stopOpacity={1} />
              <stop offset="95%" stopColor={areaColor} stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <XAxis dataKey="x" />
          <YAxis dataKey="parcels" width={30} domain={[0, maxYAxisValue]} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Area
            type="natural"
            dataKey="parcels"
            stroke={lineColor}
            fill="url(#colorUv)"
            dot={{
              fill: dotBg,
              stroke: lineColor,
              strokeWidth: 1,
              r: 3,
              fillOpacity: 1,
            }}
            strokeWidth={3}
          />
          {/* <Line type="natural" dataKey="parcels" stroke={lineColor} dot={{ stroke: lineColor, strokeWidth: 2, r: 5 }} strokeWidth={3}/> */}
        </AreaChart>
      </ResponsiveContainer>

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        disableAutoFocus
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={styles.modal}>
          <IconButton sx={styles.close} onClick={() => setIsOpenModal(false)}>
            <CloseIcon />
          </IconButton>

          <ResponsiveContainer width="100%" height="90%">
            <AreaChart
              data={data}
              margin={{
                top: 0,
                right: 20,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={areaColor} stopOpacity={1} />
                  <stop offset="95%" stopColor={areaColor} stopOpacity={0.5} />
                </linearGradient>
              </defs>
              <XAxis dataKey="x" />
              <YAxis dataKey="parcels" width={30} domain={[0, maxYAxisValue]} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="natural"
                dataKey="parcels"
                stroke={lineColor}
                fill="url(#colorUv)"
                dot={{
                  fill: dotBg,
                  stroke: lineColor,
                  strokeWidth: 1,
                  r: 3,
                  fillOpacity: 1,
                }}
                strokeWidth={3}
              />
              {/* <Line type="natural" dataKey="parcels" stroke={lineColor} dot={{ stroke: lineColor, strokeWidth: 2, r: 5 }} strokeWidth={3}/> */}
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Modal>
    </>
  );
});
