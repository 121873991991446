import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { compareAsc, format } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  removeFeedbackFilters,
  updateFeedbackDevicesFilters,
  updateFeedbackDriversFilters,
  updateFeedbackEndDate,
  updateFeedbackPage,
  updateFeedbackResourcesFilters,
  updateFeedbackSize,
  updateFeedbackStartDate,
  updateFeedbackStatusesFilters,
  updateFeedbackVersionsFilters,
} from 'store/reducers/feedbackSlice';
import { setToast } from 'store/reducers/settingsSlice';
import {
  getFeedbackDevicesFilters,
  getFeedbackDriversFilterss,
  getfeedbackEndDate,
  getFeedbackResourcesFilters,
  getFeedbackStartDate,
  getFeedbackStatusesFilters,
  getFeedbackVersionsFilters,
} from 'store/selectors/feedback';
import { t } from 'ttag';
import { FeedbackStatus } from 'types/feedback';

import { styles } from './styles';

const prioritiesOptions = ['New', 'Progress', 'Blocked', 'Closed'];

const statusColorMapper: Record<FeedbackStatus, string> = {
  Closed: 'custom.green',
  Progress: 'custom.blue2',
  Blocked: 'custom.red',
  New: 'primary.main',
};

const statusLabelMapper: Record<FeedbackStatus, string> = {
  Closed: t`Closed`,
  Progress: t`In progress`,
  Blocked: t`Blocked`,
  New: t`New`,
};

interface SetResourcesFiltersPopoverProps {
  onClosePopover: () => void;
}

const SetResourcesFiltersPopover: FC<SetResourcesFiltersPopoverProps> = ({
  onClosePopover,
}) => {
  const dispatch = useAppDispatch();
  const resourceFilter = useAppSelector(getFeedbackResourcesFilters);
  const driverFilter = useAppSelector(getFeedbackDriversFilterss);
  const deviceFilter = useAppSelector(getFeedbackDevicesFilters);
  const androidFilter = useAppSelector(getFeedbackVersionsFilters);
  const statusFilter = useAppSelector(getFeedbackStatusesFilters);
  const startDate = useAppSelector(getFeedbackStartDate);
  const endDate = useAppSelector(getfeedbackEndDate);
  const [resourceFiltersInputValue, setResourceFiltersInputValue] =
    useState<string[]>(resourceFilter);
  const [driverFiltersInputValue, setDriverFiltersInputValue] =
    useState<string[]>(driverFilter);

  const [androidFiltersInputValue, setAndroidFiltersInputValue] =
    useState<string[]>(androidFilter);

  const [deviceFiltersInputValue, setDeviceFiltersInputValue] =
    useState<string[]>(deviceFilter);

  const [statusInputValue, setStatusInputValue] =
    useState<FeedbackStatus[]>(statusFilter);

  const [startDateInputValue, setStartDateInputValue] = useState<Date | null>(
    startDate ? new Date(startDate) : null,
  );
  const [endDateInputValue, setEndDateInputValue] = useState<Date | null>(
    endDate ? new Date(endDate) : null,
  );

  const isAnyFilters = useMemo(() => {
    return (
      resourceFilter?.length ||
      driverFilter?.length ||
      deviceFilter?.length ||
      androidFilter?.length ||
      statusFilter?.length ||
      endDate ||
      startDate
    );
  }, [
    resourceFilter,
    driverFilter,
    deviceFilter,
    androidFilter,
    statusFilter,
    startDate,
    endDate,
  ]);

  const handleChangeStartDatePicker = useCallback((val: Date | null) => {
    setStartDateInputValue(val);
  }, []);

  const handleChangeEndDatePicker = useCallback((val: Date | null) => {
    setEndDateInputValue(val);
  }, []);

  const isAnyDataInInputs = useMemo(() => {
    return (
      isAnyFilters ||
      resourceFiltersInputValue.length ||
      statusInputValue.length ||
      driverFiltersInputValue.length ||
      androidFiltersInputValue.length ||
      deviceFiltersInputValue.length ||
      startDateInputValue ||
      endDateInputValue
    );
  }, [
    isAnyFilters,
    resourceFiltersInputValue,
    statusInputValue,
    driverFiltersInputValue,
    startDateInputValue,
    endDateInputValue,
    androidFiltersInputValue,
    deviceFiltersInputValue,
  ]);

  const handleChangeResources = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = resourceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setResourceFiltersInputValue(updatedValues);
    },
    [resourceFiltersInputValue],
  );

  const handleChangeDrivers = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = driverFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setDriverFiltersInputValue(updatedValues);
    },
    [driverFiltersInputValue],
  );

  const handleChangeAndroid = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = androidFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setAndroidFiltersInputValue(updatedValues);
    },
    [androidFiltersInputValue],
  );

  const handleChangeDevices = useCallback(
    // @ts-ignore
    (_, values: string[]) => {
      let updatedValues = values;

      if (values.length > 5) {
        dispatch(
          setToast({
            message: t`You can set no more than 5 filters, so the last tag has been replaced with a new one`,
            severity: 'warning',
          }),
        );

        const lastValue = values.at(-1);
        const valuesWithoutLast = deviceFiltersInputValue.slice(0, -1);
        updatedValues = [...valuesWithoutLast, lastValue as string];
      }

      setDeviceFiltersInputValue(updatedValues);
    },
    [deviceFiltersInputValue],
  );

  // @ts-ignore
  const handleColorsChange = useCallback((_, values: string[]) => {
    setStatusInputValue(values as FeedbackStatus[]);
  }, []);

  const handleApplyPress = useCallback(() => {
    let emptyCounter = 0;

    if (!resourceFiltersInputValue?.length) {
      dispatch(updateFeedbackResourcesFilters([]));
      emptyCounter++;
    }

    if (!statusInputValue?.length) {
      dispatch(updateFeedbackStatusesFilters([]));
      emptyCounter++;
    }

    if (!driverFiltersInputValue?.length) {
      dispatch(updateFeedbackDriversFilters([]));
      emptyCounter++;
    }

    if (!deviceFiltersInputValue?.length) {
      dispatch(updateFeedbackDevicesFilters([]));
      emptyCounter++;
    }

    if (!androidFiltersInputValue?.length) {
      dispatch(updateFeedbackVersionsFilters([]));
      emptyCounter++;
    }

    if (!startDateInputValue) {
      dispatch(updateFeedbackStartDate(''));
      emptyCounter++;
    }

    if (!endDateInputValue) {
      dispatch(updateFeedbackEndDate(''));
      emptyCounter++;
    }

    if (emptyCounter === 7) {
      return;
    }

    dispatch(updateFeedbackResourcesFilters(resourceFiltersInputValue));
    dispatch(updateFeedbackStatusesFilters(statusInputValue));
    dispatch(updateFeedbackDriversFilters(driverFiltersInputValue));
    dispatch(updateFeedbackDevicesFilters(deviceFiltersInputValue));
    dispatch(updateFeedbackVersionsFilters(androidFiltersInputValue));
    dispatch(
      updateFeedbackStartDate(
        startDateInputValue
          ? format(startDateInputValue as Date, 'yyyy-MM-dd')
          : '',
      ),
    );
    dispatch(
      updateFeedbackEndDate(
        endDateInputValue
          ? format(endDateInputValue as Date, 'yyyy-MM-dd')
          : '',
      ),
    );
    dispatch(updateFeedbackSize(10));
    dispatch(updateFeedbackPage(1));

    onClosePopover();
  }, [
    resourceFiltersInputValue,
    statusInputValue,
    onClosePopover,
    startDateInputValue,
    endDateInputValue,
    driverFiltersInputValue,
    deviceFiltersInputValue,
    androidFiltersInputValue,
  ]);

  const handleResetAllPress = useCallback(() => {
    dispatch(removeFeedbackFilters());
    setResourceFiltersInputValue([]);
    setStatusInputValue([]);
    onClosePopover();
  }, [onClosePopover]);

  const shouldDisabledDate = useCallback(
    (d: Date) => {
      return (
        !startDateInputValue || compareAsc(startDateInputValue as Date, d) === 1
      );
    },
    [startDateInputValue],
  );

  // @ts-ignore
  return (
    <Box sx={styles.container}>
      <Autocomplete
        value={resourceFiltersInputValue}
        multiple
        id="resources"
        onChange={handleChangeResources}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Resources`}
            // placeholder={t`Search by resources`}
            // InputLabelProps={{ shrink: true }}
          />
        )}
        // sx={styles.firstFilter}
      />

      <Autocomplete
        value={driverFiltersInputValue}
        multiple
        id="drivers"
        onChange={handleChangeDrivers}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Driver's name`}
            // placeholder={t`Search by driver's name`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Autocomplete
        value={deviceFiltersInputValue}
        multiple
        id="devices"
        onChange={handleChangeDevices}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Device info`}
            // placeholder={t`Search by device info`}
          />
        )}
        // sx={styles.firstFilter}
      />

      <Autocomplete
        value={androidFiltersInputValue}
        multiple
        id="android"
        onChange={handleChangeAndroid}
        options={[]}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              size="small"
              sx={{ fontSize: '10px' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Android version`}
            // placeholder={t`Search by android version`}
          />
        )}
        sx={styles.firstFilter}
      />

      <Autocomplete
        value={statusInputValue}
        multiple
        freeSolo={false}
        id="colorred-cars-filters"
        onChange={handleColorsChange}
        options={prioritiesOptions}
        renderOption={(props, option) => (
          <li {...props} key={`Priority list item ${option}`}>
            <Typography
              sx={{
                color: statusColorMapper[option as FeedbackStatus],
                textTransform: 'uppercase',
              }}
            >
              {statusLabelMapper[option as FeedbackStatus]}
            </Typography>
          </li>
        )}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              variant="outlined"
              label={statusLabelMapper[option as FeedbackStatus]}
              {...getTagProps({ index })}
              size="small"
              sx={{
                textTransform: 'uppercase',
                color: statusColorMapper[option as FeedbackStatus],
                fontSize: '10px',
                fontWeight: 600,
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={t`Feedback status`}
            // placeholder={t`Choose feedback status`}
          />
        )}
        sx={styles.secondFilter}
      />

      <Box sx={styles.dateBlock}>
        <Box sx={{ width: '45%' }}>
          <DesktopDatePicker
            label={t`Start date`}
            inputFormat="yyyy-MM-dd"
            value={startDateInputValue}
            onChange={handleChangeStartDatePicker}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
            )}
          />
        </Box>

        <Box sx={{ width: '45%' }}>
          <DesktopDatePicker
            label={t`End date`}
            inputFormat="yyyy-MM-dd"
            disabled={!startDateInputValue}
            shouldDisableDate={shouldDisabledDate}
            value={endDateInputValue}
            onChange={handleChangeEndDatePicker}
            renderInput={(params) => (
              <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
            )}
          />
        </Box>
      </Box>
      <Button
        disabled={
          resourceFiltersInputValue.length === 0 &&
          statusInputValue.length === 0 &&
          driverFiltersInputValue.length === 0 &&
          !startDateInputValue &&
          !endDateInputValue &&
          androidFiltersInputValue.length === 0 &&
          deviceFiltersInputValue.length === 0
        }
        sx={styles.applyButton}
        variant="contained"
        onClick={handleApplyPress}
      >
        {t`Apply`}
      </Button>
      <Button
        disabled={!isAnyDataInInputs}
        sx={styles.button}
        variant="outlined"
        onClick={handleResetAllPress}
      >
        {t`Reset all`}
      </Button>
    </Box>
  );
};

export { SetResourcesFiltersPopover };
