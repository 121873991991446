import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Grid,
  ListItem,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import { alphabet, APP_ROUTES, CHANGE_PAGE_ROUTES } from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateShowDetailSection } from 'store/reducers/settingsSlice';
import { calculateUnreadMessagesForShipment } from 'store/selectors/chat';
import { getShowDetailSectionSelector } from 'store/selectors/settings';
import {
  getIsCurrentOrFutureWorkshiftSelector,
  getSelectedWorkshiftIdSelector,
} from 'store/selectors/workshift';
import { t } from 'ttag';
import { ErrandDataProofTypes } from 'types/api';
import { CopyOrderPopupCoords } from 'types/common';
import { Order } from 'types/orders';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { Time } from 'components/common/Time';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { CopyOrderToNewPopup } from 'components/ui/CopyOrderToNewPopup';
import { EditButton } from 'components/ui/EditButton';
import { MoveToCurrentWorkshiftButton } from 'components/ui/MoveToCurrentWorkshiftButton';

import { OrdersSectionListItemIcon } from '../OrdersSectionListItemIcon';

import { styles } from './styles';

interface OrdersSectionCollapsibleListItemProps {
  order: Order;
  index: number;
  selected: boolean;
  onClick: (orderId: string) => void;
}

export const OrdersSectionCollapsibleListItem: FC<
  OrdersSectionCollapsibleListItemProps
> = ({ order, selected, onClick, index }) => {
  const dispatch = useAppDispatch();
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const showDetailSection = useAppSelector(getShowDetailSectionSelector);
  const [popupCoords, setPopupCoords] = useState<CopyOrderPopupCoords | null>(
    null,
  );
  const unreadMessages = useAppSelector(
    calculateUnreadMessagesForShipment(order?.shipmentId),
  );
  const isCurrentOrFutureWorkshift = useAppSelector(
    getIsCurrentOrFutureWorkshiftSelector,
  );

  const isTempered = order?.bookingData?.tempered_transport;

  const clickHandler = useCallback(() => {
    if (!showDetailSection) {
      dispatch(updateShowDetailSection(true));
    }
    onClick(order.id);
  }, [showDetailSection, dispatch]);

  const contextHandler = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isCurrentOrFutureWorkshift) {
        setPopupCoords({ top: e.pageY, left: e.pageX });
      }
    },
    [isCurrentOrFutureWorkshift],
  );

  const handleClose = useCallback(() => {
    setPopupCoords(null);
  }, []);

  const handleCopyPress = useCallback(() => {
    navigateWithQueryParams(
      `${APP_ROUTES.create}/${CHANGE_PAGE_ROUTES.orderDetails}`,
      { orderId: order.id },
    );
    handleClose();
  }, [navigateWithQueryParams, order.id, handleClose]);

  const itemStyles = selected
    ? ({ ...styles.item, ...styles.selected } as SxProps)
    : styles.item;

  const open = Boolean(popupCoords);
  const id = open ? 'copy-to-new-order-popup' : undefined;

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: styles.popup }}
        anchorReference="anchorPosition"
        anchorPosition={popupCoords as CopyOrderPopupCoords}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CopyOrderToNewPopup onCopyPress={handleCopyPress} />
      </Popover>
      <ListItem
        key={order.id}
        sx={itemStyles}
        onClick={clickHandler}
        onContextMenu={contextHandler}
      >
        <OrdersSectionListItemIcon
          status={order.status}
          label={alphabet[index]}
          isGrouped={false}
          type={order.type}
          isNew={order.isNew}
          isCurrent={order?.isCurrent}
        />

        <Grid container columns={13} sx={styles.content}>
          <Grid item mobile={7} alignSelf="center">
            <Typography variant="subtitle1">
              {order?.data?.proofType === ErrandDataProofTypes.smartLockIboxen
                ? t`iBoxen delivery`
                : order?.isTerminalReturn
                ? t`Terminal delivery`
                : order.dataTitle || t`Home delivery`}
            </Typography>
            <Typography>{order.subtitle}</Typography>
          </Grid>

          <Grid item mobile={1} alignSelf="center" pl={1}>
            {isTempered && <img src={temperedGoodsImg} alt="Tempered goods" />}
          </Grid>

          <Grid item mobile={3} alignSelf="center" textAlign="end">
            {!order?.data?.isManualCreated && (
              <Time time={order.time} isCollapsible={false} />
            )}
          </Grid>

          <Grid item mobile={2} alignSelf="center" textAlign="center" pl={1}>
            {!!unreadMessages && (
              <QueryParamsLink sx={{ zIndex: 100 }} onClick={clickHandler}>
                <UnreadLabel qty={unreadMessages} />
              </QueryParamsLink>
            )}
          </Grid>
          {order.isEditable && isCurrentOrFutureWorkshift && (
            <Box sx={styles.editButton}>
              <EditButton orderId={order.id} />
            </Box>
          )}
          {order.isEditable && !isCurrentOrFutureWorkshift && (
            <Box sx={styles.moveButton}>
              <MoveToCurrentWorkshiftButton
                workshiftId={selectedWorkshiftId as string}
                shipmentId={order.shipmentId}
                resourceId={selectedWorkshiftId as string}
              />
            </Box>
          )}
        </Grid>
      </ListItem>
    </>
  );
};

export default React.memo(OrdersSectionCollapsibleListItem);
