export const styles = {
  container: {
    pl: '16px',
    pr: '16px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: 'custom.greyLight',
    width: '100%',
    height: '64px',
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: '12px',
    textTransform: 'uppercase',
    lineHeight: '16px',
    fontWeight: 700,
  },
};
