import { FC, useCallback, useEffect, useRef, useState } from 'react';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { getMuiColor } from 'theme';

interface CustomInputOnHoverProps {
  onPressOutside: (v: string) => void;
  value: string;
}

export const CustomInputOnHover: FC<CustomInputOnHoverProps> = ({
  onPressOutside,
  value: firstValue,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(firstValue || '');

  // Detect clicks outside of the text field to disable it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target as Node) &&
        !isDisabled
      ) {
        setIsDisabled(true);
        if (value !== firstValue) {
          onPressOutside(value);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [textFieldRef, isDisabled, value, onPressOutside, firstValue]);

  const toggleDisabledState = useCallback(() => {
    if (!isDisabled && value !== firstValue) {
      onPressOutside(value);
    }
    setIsDisabled(!isDisabled);
  }, [isDisabled, value, firstValue]);

  const handleBlur = useCallback(() => {
    setIsDisabled(true);
    if (value !== firstValue) {
      onPressOutside(value);
    }
  }, [onPressOutside, value, firstValue]);

  useEffect(() => {
    if (!isDisabled && inputRef.current) {
      const input = inputRef.current;
      input?.focus(); // Focus when enabled
      const length = input?.value?.length;
      input?.setSelectionRange(length, length);
    }
  }, [isDisabled]);

  return (
    <Tooltip
      title={isDisabled ? value : ''}
      placement="top"
      disableHoverListener={!isDisabled}
    >
      <Box
        ref={textFieldRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{ width: '100%', overflow: 'hidden' }}
      >
        <TextField
          inputRef={inputRef}
          disabled={isDisabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          fullWidth
          variant="standard"
          inputProps={{
            maxLength: 100,
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              >
                <IconButton
                  onClick={toggleDisabledState}
                  onMouseDown={(event) => event.preventDefault()} // Prevent the button from stealing focus from the TextField
                >
                  <BorderColorOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: getMuiColor('graphite'),
            },
            '& fieldset': { border: 'none' },
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        />
      </Box>
    </Tooltip>
  );
};
