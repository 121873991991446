const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },

  title: {
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
    mb: '16px',
  },

  subtitle: {
    textTransform: 'uppercase',
    fontSize: '10px',
    letterSpacing: '1.5px',
    fontWeight: 'bold',
    color: 'custom.graphite',
  },

  detailsUnderOrdersBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  section: {
    display: 'flex',
    width: '100%',
    // height: '208px',
  },

  input: {
    minHeight: '70px',
    '& .MuiFormHelperText-root': {
      ml: 0,
    },
  },

  bottomButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    pt: '10px',
  },

  button: {
    width: '200px',
  },

  cancelButton: {
    bgcolor: 'custom.white',
  },
};

export { styles };
