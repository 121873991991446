import React, { FC, memo, useCallback, useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Typography } from '@mui/material';
import { t } from 'ttag';
import { ErrandProofTypes } from 'types/api';
import { Order } from 'types/orders';

import { ParcelDataImagesList } from 'components/screens/HomeScreen/components/DetailsSectionInnerParcelDataItem/components/ParcelDataImagesList';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

export const reasonsMapper: Partial<Record<ErrandProofTypes, string>> = {
  can_not_find_location: t`Can not find the address / doorway`,
  can_not_enter_the_building: t`Can not enter the building`,
  can_not_find_the_door_of_the_address: t`Can not find recipient name / front door on the address`,
  nobody_home: t`Nobody answering the front door`,
  receiver_is_home_but_delivery_failed: t`Recipient is home but delivery failed`,
  receiver_does_not_want_to_receive_delivery: t`Receiver doesn't want to receive delivery`,
  delivery_denied_by_driver: t`Delivery denied by driver`,
  another_reason: t`Other reason`,
  no_phone_answer: t`Receiver does not answer the phone`,
  invalid_door_code: t`Door code does not work`,
  no_door_code: t`No door code`,
  no_intercom: t`No intercom`,
  no_intercom_answer: t`No answer through intercom`,
  skipped_call: t`Skipped contact the recipient`,
  skipped_chat: t`Skipped Contact the dispatcher`,
  success_call: t`Contacted the recipient`,
  have_called: t`Contacted the recipient`,
  success_chat: t`Contacted the dispatcher`,
  other_reason: t`Other reason`,
  no_id_verified: t`Identification could not be verified`,
  no_age_verified: t`Age was not be able to be verified`,
  not_appropriate: t`Not appropriate to deliver package to receiver`,
  damaged_parcel: t`Damaged parcel`,
};

interface DetailsSectionInnerFailProps {
  order: Order;
}
export const DetailsSectionInnerFail: FC<DetailsSectionInnerFailProps> = memo(
  ({ order }) => {
    const photoUrls = order.proofData?.data?.order_cancellation_photo_urls;
    const photosItem = !!photoUrls?.length && (
      <ParcelDataImagesList imageUrls={photoUrls} />
    );

    const comment = order.proofData?.data?.order_cancellation_comment;
    const commentItem = comment && (
      <Typography sx={styles.commentItem}>{comment}</Typography>
    );

    const firstReason = order?.proofData?.proof_type;
    const reasons = order?.proofData?.data?.reasons || [];

    const preparedReasons = useMemo(() => {
      const result = [firstReason as ErrandProofTypes, ...reasons];

      // if (result.at(-1) !== 'skipped_chat') {
      //   result.push('success_chat');
      // }

      return result.map((reason: ErrandProofTypes) => reasonsMapper[reason]);
    }, [firstReason, reasons]);

    const preparedReasonsLength = preparedReasons.length;

    const renderItem = useCallback(
      (label: ErrandProofTypes, index: number) => {
        return (
          <React.Fragment key={`Cancellation list Item ${index}`}>
            <Box sx={styles.itemBlock}>
              <Box sx={styles.circleBlock}>
                <DoneIcon sx={styles.icon} />
              </Box>

              <Typography variant="h2">{label}</Typography>
            </Box>
            {preparedReasonsLength > index + 1 ? (
              <Box sx={styles.mb16} />
            ) : (
              <Box sx={styles.separator} />
            )}
          </React.Fragment>
        );
      },
      [preparedReasonsLength],
    );

    return (
      <InnerSection
        label={t`Cancellation reason`}
        sx={styles.container}
        customStyle={{ pr: '0px' }}
      >
        {preparedReasons?.length &&
          (preparedReasons as ErrandProofTypes[]).map(renderItem)}
        {photosItem}
        {commentItem}
      </InnerSection>
    );
  },
);
