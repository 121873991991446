export const LOCALE_COOKIE = '__locale';

export enum APP_ROUTES {
  root = '/',
  auth = '/auth',
  chat = '/chat',
  edit = '/edit',
  create = '/create',
  addAddress = '/add-address',
  addTerminal = '/add-terminal',
  editSettings = '/edit-settings',
  notFound = '/not-found',
  editCompany = '/edit-company',
  createCompany = '/create-company',
  dedicatedChat = '/dedicated-chat',
}

export enum CHANGE_PAGE_ROUTES {
  orderDetails = 'order-details',
  pickUp = 'pick-up',
  delivery = 'delivery',
  parcelData = 'parcel-data',
  additions = 'additions',
}

export enum MAP_COMPONENT_TYPE {
  resources = 'resources',
  shipments = 'shipments',
}

export enum MAP_POINT_TYPE {
  resource = 'resource',
  point = 'point',
  last = 'last',
}

export enum EXTRA_SECTION_TYPE {
  map = 'map',
  chat = 'chat',
}

export enum HEADER_DROPDOWN_VALUE {
  logout = 'logout',
  addAddress = 'add address',
  addTerminal = 'add terminal',
  addCompany = 'add company',
}

export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const UNASSIGNED_TASKS = 'unassigned-tasks';

export enum LOCAL_STORAGE_KEYS {
  showDoneFail = 'SHOW_DONE_FAIL',
  showOrderNumber = 'showOrderNumber',
  adminSection = 'adminSection',
}

export const UNASSIGNED_PAGE_SIZE = 50;
export const RESOURCES_FOR_GROUPS_PAGE_SIZE = 50;
export const DEFAULT_TIME_GAP = 45000;

export const CLOSED_BY_APP = 'closed_by_app';
export const LOGGED_IN_COOKIE = 'logged_in';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
// export const BASE_URL = 'https://dev-api.cityflow.se/web-client';
export enum GROUP_SECTION_SCREEN {
  allGroups = 'allGroups',
  createGroup = 'createGroup',
  groupDetail = 'groupDetail',
  editGroup = 'editGroup',
}
