import React, { useEffect, useRef } from 'react';
import {
  AudioMessage,
  Avatar,
  FileMessage,
  LocationMessage,
  MeetingLink,
  MeetingMessage,
  PhotoMessage,
  ReplyMessage,
  SpotifyMessage,
  VideoMessage,
} from 'react-chat-elements';
import { IoIosDoneAll } from 'react-icons/io';
import {
  MdAccessTime,
  MdBlock,
  MdCheck,
  MdDelete,
  MdDoneAll,
  MdMessage,
} from 'react-icons/md';
import { RiShareForwardFill } from 'react-icons/ri';
import classNames from 'classnames';
import { ChatMessage } from 'types/chat';

import { BubbleQuickReply } from 'components/ui/BubbleQuickReply';

import 'react-chat-elements/dist/main.css';
import './MessageBox.css';

const MessageBox: React.FC<ChatMessage> = ({
  focus = false,
  notch = true,
  quickReplies = [],
  ...props
}) => {
  const prevProps = useRef(focus);
  const messageRef = useRef<HTMLDivElement>(null);

  const positionCls = classNames('rce-mbox', {
    'rce-mbox-right': props.position === 'right',
  });
  const thatAbsoluteTime =
    !/(text|video|file|meeting|audio)/g.test(props.type || 'text') &&
    !(props.type === 'location' && props.text);
  const dateText = props.date && props.dateString;

  useEffect(() => {
    if (prevProps.current !== focus && focus) {
      if (messageRef) {
        messageRef.current?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });

        props?.onMessageFocused?.(prevProps);
      }
    }
    prevProps.current = focus;
  }, [focus, prevProps]);

  return (
    <>
      <div
        ref={messageRef}
        className={classNames({ 'rce-container-mbox': true }, props.className)}
        onClick={props.onClick}
      >
        {/* {props.renderAddCmp instanceof Function && props.renderAddCmp()} */}
        {props.type === 'system' ? (
          // <SystemMessage {...props} focus={focus} notch={notch} />
          <div className="date-separator">{props.text}</div>
        ) : (
          <div
            className={classNames(
              { orange: props.position === 'right' },
              positionCls,
              { 'rce-mbox--clear-padding': thatAbsoluteTime },
              { 'rce-mbox--clear-notch': !notch },
              { 'message-focus': focus },
            )}
          >
            <div className="rce-mbox-body" onContextMenu={props.onContextMenu}>
              {!props.retracted && props.forwarded && (
                <div
                  className={classNames(
                    'rce-mbox-forward',
                    { 'rce-mbox-forward-right': props.position === 'left' },
                    { 'rce-mbox-forward-left': props.position === 'right' },
                  )}
                  onClick={props.onForwardClick}
                >
                  <RiShareForwardFill />
                </div>
              )}

              {!props.retracted && props.replyButton && (
                <div
                  className={
                    !props.forwarded
                      ? classNames(
                          'rce-mbox-forward',
                          {
                            'rce-mbox-forward-right': props.position === 'left',
                          },
                          {
                            'rce-mbox-forward-left': props.position === 'right',
                          },
                        )
                      : classNames(
                          'rce-mbox-forward',
                          {
                            'rce-mbox-reply-btn-right':
                              props.position === 'left',
                          },
                          {
                            'rce-mbox-reply-btn-left':
                              props.position === 'right',
                          },
                        )
                  }
                  onClick={props.onReplyClick}
                >
                  <MdMessage />
                </div>
              )}

              {!props.retracted && props.removeButton && (
                <div
                  className={
                    props.forwarded
                      ? classNames(
                          'rce-mbox-forward',
                          {
                            'rce-mbox-remove-right': props.position === 'left',
                          },
                          {
                            'rce-mbox-remove-left': props.position === 'right',
                          },
                        )
                      : classNames(
                          'rce-mbox-forward',
                          {
                            'rce-mbox-reply-btn-right':
                              props.position === 'left',
                          },
                          {
                            'rce-mbox-reply-btn-left':
                              props.position === 'right',
                          },
                        )
                  }
                  onClick={props.onRemoveMessageClick}
                >
                  <MdDelete />
                </div>
              )}

              {props.avatar && (
                <div
                  style={{
                    ...(props.titleColor && { color: props.titleColor }),
                  }}
                  onClick={props.onTitleClick}
                  className={classNames('rce-mbox-title', {
                    'rce-mbox-title--clear': props.type === 'text',
                  })}
                >
                  <Avatar letterItem={props.letterItem} src={props.avatar} />
                </div>
              )}

              {props.reply && (
                <ReplyMessage
                  onClick={props.onReplyMessageClick}
                  {...props.reply}
                />
              )}

              {props.type === 'text' && (
                <div
                  className={classNames(
                    'rce-mbox-text',
                    { 'white-color': props.position === 'right' },
                    {
                      'rce-mbox-text-retracted': props.retracted,
                      left: props.position === 'left',
                      right: props.position === 'right',
                    },
                  )}
                >
                  {props.retracted && <MdBlock />}
                  {props.text}
                </div>
              )}

              {props.type === 'location' && (
                <LocationMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'photo' && (
                <PhotoMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'video' && (
                <VideoMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'file' && (
                <FileMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'spotify' && (
                <SpotifyMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'meeting' && (
                <MeetingMessage focus={focus} notch={notch} {...props} />
              )}
              {props.type === 'audio' && (
                <AudioMessage focus={focus} notch={notch} {...props} />
              )}

              {props.type === 'meetingLink' && (
                <MeetingLink
                  focus={focus}
                  notch={notch}
                  {...props}
                  actionButtons={props?.actionButtons}
                />
              )}

              <div
                className={classNames(
                  'rce-mbox-time',
                  { 'white-color': props.position === 'right' },
                  { 'rce-mbox-time-block': thatAbsoluteTime },
                  { 'non-copiable': !props.copiableDate },
                )}
                data-text={props.copiableDate ? undefined : dateText}
              >
                {props.copiableDate && props.date && props.dateString}
                {props.status && props.position === 'right' && (
                  <span
                    className={classNames('rce-mbox-status', {
                      'white-color': props.position === 'right',
                    })}
                  >
                    {props.status === 'waiting' && <MdAccessTime />}

                    {props.status === 'sent' && <MdCheck />}

                    {props.status === 'received' && <IoIosDoneAll />}

                    {props.status === 'read' && (
                      <MdDoneAll
                        color={props.position === 'right' ? 'white' : '#4FC3F7'}
                      />
                    )}
                  </span>
                )}
              </div>
              <div style={{ display: 'flex', marginBottom: '-5px' }}>
                {/* <div style={{ height: '5px', marginBottom: '10px'}}> */}
                {!!quickReplies.length && (
                  <BubbleQuickReply allReplies={quickReplies} />
                )}
              </div>
            </div>

            {notch &&
              (props.position === 'right' ? (
                <svg
                  className={classNames('rce-mbox-right-notch', {
                    'message-focus': focus,
                  })}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 0v20L20 0" />
                </svg>
              ) : (
                <div>
                  <svg
                    className={classNames('rce-mbox-left-notch', {
                      'message-focus': focus,
                    })}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <filter id="filter1" x="0" y="0">
                        <feOffset
                          result="offOut"
                          in="SourceAlpha"
                          dx="-2"
                          dy="-5"
                        />
                        <feGaussianBlur
                          result="blurOut"
                          in="offOut"
                          stdDeviation="3"
                        />
                        <feBlend
                          in="SourceGraphic"
                          in2="blurOut"
                          mode="normal"
                        />
                      </filter>
                    </defs>
                    <path d="M20 0v20L0 0" filter="url(#filter1)" />
                  </svg>
                </div>
              ))}
          </div>
        )}
      </div>
      {props?.title && (
        <div
          className={classNames('footer-name', {
            'right-side': props.position === 'right',
          })}
        >
          {props.title}
        </div>
      )}
    </>
  );
};

export { MessageBox };
