import { FC, ReactNode } from 'react';
import { SxProps } from '@mui/material';

import { Section, SectionWrapperProps } from 'components/ui/Section';

import { styles } from './styles';

interface ChangeScreenSectionProps extends Omit<SectionWrapperProps, 'label'> {
  label?: string;
  variant?: 'outlined' | 'standard';
  children: ReactNode;
}

export const ChangeScreenSection: FC<ChangeScreenSectionProps> = ({
  children,
  variant = 'outlined',
  label = '',
  ...rest
}) => {
  const innerStyles =
    variant === 'standard'
      ? ({ ...styles.inner, borderWidth: 0 } as SxProps)
      : styles.inner;

  return (
    <Section
      label={label}
      labelStyles={styles.label}
      innerStyles={innerStyles}
      {...rest}
    >
      {children}
    </Section>
  );
};
