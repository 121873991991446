import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'store/api/customFetchBase';
import { setChatList, setUnreadMessages } from 'store/reducers/chatSlice';
import { removeSelectedResourceId } from 'store/reducers/resourceSlice';
import {
  AllRoutesApiResponse,
  Chat,
  ChatMessageStatusRequest,
  QuickReply,
  SendingMessage,
  UnreadMessagesByResource,
} from 'types/api';
import { ChatPriority, DedicatedChat } from 'types/dedicatedChat';

export const chatApiSlice = createApi({
  reducerPath: 'chatApi',
  baseQuery: customFetchBase,
  tagTypes: ['DedicatedChats', 'Priorities'],
  endpoints: (builder) => {
    return {
      updateQuickReplyByMessageId: builder.mutation<
        string,
        { messageId: string; quickReplies: number[] }
      >({
        query: ({ messageId, quickReplies }) => {
          const serializedReplies = quickReplies.map((id) => ({ id }));
          return {
            url: `/chat-message/${messageId}/quick_responses`,
            method: 'PUT',
            body: serializedReplies,
          };
        },
      }),

      chatByResourceId: builder.query<
        Chat[],
        { resourceId: string; workshiftId: string }
      >({
        query({ resourceId, workshiftId }) {
          return {
            url: `chat-message/by-resource/${resourceId}`,
            credentials: 'include',
            params: { work_shift_id: workshiftId },
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setChatList(data));
          } catch (error) {
            dispatch(removeSelectedResourceId());
            console.log(error);
          }
        },
      }),

      unreadMessagesByResource: builder.query<
        UnreadMessagesByResource[],
        { date?: string }
      >({
        query({ date }) {
          let param = '';
          if (date) {
            param = `?date=${date}`;
          }

          return {
            url: `chat-message/unreads-by-resource${param}`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setUnreadMessages(data));
          } catch (error) {
            dispatch(removeSelectedResourceId());
            console.log(error);
          }
        },
      }),

      sendChatMessage: builder.mutation<{ id: string }, SendingMessage>({
        query(data) {
          return {
            url: 'chat-message/',
            method: 'POST',
            body: data,
            credentials: 'include',
          };
        },
      }),

      setChatMessageStatus: builder.mutation<string, ChatMessageStatusRequest>({
        query(data) {
          return {
            url: 'chat-message/read',
            method: 'PATCH',
            body: data.messagesIds,
            credentials: 'include',
            // eslint-disable-next-line camelcase
            params: { resource_id: data.resource_id },
          };
        },
      }),

      quickResponses: builder.query<QuickReply[], void>({
        query: () => ({
          url: 'chat-message/quick_responses',
          credentials: 'include',
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      dedicatedChat: builder.query<
        DedicatedChat[],
        | {
            date?: string;
            group?: string[];
            resource_name?: string[];
            priority?: string[];
          }
        | undefined
      >({
        query: (args) => {
          if (
            !args ||
            Object.values(args).every((val) => !val || !val?.length)
          ) {
            return {
              url: 'dedicated-chat',
              credentials: 'include',
            };
          }

          const params = new URLSearchParams();

          if (args?.date) {
            params.append('date', args.date);
          }

          if (args?.group?.length) {
            args.group.forEach((group) => params.append('group', group));
          }

          if (args?.resource_name?.length) {
            args.resource_name.forEach((resource) =>
              params.append('resource_name', resource),
            );
          }

          if (args?.priority?.length) {
            args.priority.forEach((priority) =>
              params.append('priority', priority),
            );
          }

          return {
            url: 'dedicated-chat',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['DedicatedChats'],
      }),

      chatPriority: builder.query<
        ChatPriority,
        { workshiftId: string; shipmentId?: string }
      >({
        query({ shipmentId, workshiftId }) {
          const params = new URLSearchParams();
          params.append('work_shift_id', workshiftId);

          if (shipmentId) {
            params.append('shipment_id', shipmentId);
          }

          return {
            url: 'chat-message/priority',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['Priorities'],
      }),

      setChatPriority: builder.mutation<
        string,
        { work_shift_id: string; priority: string; shipment_id?: string }
      >({
        query({ work_shift_id, priority, shipment_id }) {
          const params = new URLSearchParams();
          params.append('work_shift_id', work_shift_id);
          params.append('priority', priority);
          if (shipment_id) {
            params.append('shipment_id', shipment_id);
          }

          return {
            url: 'dedicated-chat',
            method: 'PATCH',
            credentials: 'include',
            params,
          };
        },
        invalidatesTags: ['DedicatedChats'],
      }),

      setParticularChatPriority: builder.mutation<
        string,
        { work_shift_id: string; priority: string; shipment_id?: string }
      >({
        query({ work_shift_id, priority, shipment_id }) {
          const params = new URLSearchParams();
          params.append('work_shift_id', work_shift_id);
          params.append('priority', priority);
          if (shipment_id) {
            params.append('shipment_id', shipment_id);
          }

          return {
            url: 'dedicated-chat',
            method: 'PATCH',
            credentials: 'include',
            params,
          };
        },
        invalidatesTags: ['Priorities'],
      }),

      sendGroupMessages: builder.mutation<
        string,
        {
          group: string[];
          resource_name: string[];
          content: string;
          type?: string;
        }
      >({
        query({ group, resource_name, content, type = 'text' }) {
          return {
            url: 'dedicated-chat/send-messages/',
            method: 'POST',
            credentials: 'include',
            body: {
              group,
              resource_name,
              type,
              content,
            },
          };
        },
        invalidatesTags: ['DedicatedChats'],
      }),

      unreadMessagesForMapByResource: builder.query<
        AllRoutesApiResponse[],
        {
          date?: string;
          hide_historical?: boolean;
          group?: string[];
          resource_name: string | null;
        }
      >({
        query({ date, hide_historical = false, group = [], resource_name }) {
          const params = new URLSearchParams();
          params.append('hide_historical', hide_historical ? 'true' : 'false');

          if (date) {
            params.append('date', date);
          }
          if (group.length > 0) {
            group.forEach((g) => params.append('group', g));
          }

          if (resource_name) {
            params.append('resource_name', resource_name);
          }

          return {
            url: `shipment/all-resources?${params}`,
            credentials: 'include',
          };
        },

        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),
    };
  },
});

export const {
  useChatByResourceIdQuery,
  useQuickResponsesQuery,
  useSendChatMessageMutation,
  useSetChatMessageStatusMutation,
  useUnreadMessagesByResourceQuery,
  useUpdateQuickReplyByMessageIdMutation,
  useDedicatedChatQuery,
  useSetChatPriorityMutation,
  useSendGroupMessagesMutation,
  useChatPriorityQuery,
  useSetParticularChatPriorityMutation,
  useUnreadMessagesForMapByResourceQuery,
} = chatApiSlice;
