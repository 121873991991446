import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSelectedCompanyIdFromUrl,
  removeSelectedCompanyIdFromUrl,
  setSelectedCompanyIdToUrl,
} from 'store/helpers/companyHelpers';

interface CompanyState {
  selectedCompanyId: string | null;
  companyFilter: string;
  showFilters: boolean;
  countryFilter: string;
  cityFilter: string;
  searchFilter: string;
}

const initialState: CompanyState = {
  selectedCompanyId: getSelectedCompanyIdFromUrl || null,
  companyFilter: '',
  showFilters: false,
  countryFilter: '',
  cityFilter: '',
  searchFilter: '',
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompanyFilter: (state, action: PayloadAction<string>) => {
      state.companyFilter = action.payload;
    },
    setSelectedCompanyId: (state, action: PayloadAction<string>) => {
      state.selectedCompanyId = action.payload;
      setSelectedCompanyIdToUrl(action.payload);
    },
    setShowFilters: (state, action: PayloadAction<boolean>) => {
      state.showFilters = action.payload;
    },
    setCityFilters: (state, action: PayloadAction<string>) => {
      state.cityFilter = action.payload;
    },
    setCountryFilters: (state, action: PayloadAction<string>) => {
      state.countryFilter = action.payload;
    },
    setSearchFilters: (state, action: PayloadAction<string>) => {
      state.searchFilter = action.payload;
    },
    removeSelectedCompanyId: (state) => {
      state.selectedCompanyId = null;
      removeSelectedCompanyIdFromUrl();
    },
    removeAllFilters: (state) => {
      state.countryFilter = '';
      state.cityFilter = '';
      state.searchFilter = '';
    },
  },
});

export const {
  setSelectedCompanyId,
  removeSelectedCompanyId,
  setShowFilters,
  setCityFilters,
  setCountryFilters,
  setSearchFilters,
  removeAllFilters,
} = companySlice.actions;

export const companyReducer = companySlice.reducer;
