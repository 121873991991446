import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCAL_STORAGE_KEYS } from 'constants/common';
import { sortBy } from 'lodash';
import {
  getChosenDateFromUrl,
  getOnlyCurrentWorkshiftFromUrl,
  removeChosenDateFromUrl,
  removeOnlyCurrentWorkshiftFromUrl,
  setChosenDateToUrl,
  setOnlyCurrentWorkshiftToUrl,
} from 'store/helpers/settingsHelpers';
import {
  GroupApiResponse,
  GroupingResource,
  ResourceForSettings,
} from 'types/api';
import {
  CalcOptimization,
  ResourcesWithBlockedTimeGapButton,
  Toast,
} from 'types/settings';

interface SettingsState {
  detailsUnderOrders: boolean;
  showDetailSection: boolean;
  toast: Toast | null;
  isCalcOptimization: CalcOptimization;
  isShowDoneFailShipments: boolean;
  totalResourcesSettings: number;
  resourcesForSettings: ResourceForSettings[];
  showResourcesSettingsFilter: boolean;
  isResourcesSettingsFilterFilled: boolean;
  searchResourcesForSettingsByName: string;
  searchGroupsForSettingsByName: string;
  groupDetailResources: GroupApiResponse[];
  currentGroupDetailResources: GroupingResource[];

  resourcesSettingsGroups: string[];
  resourcesSettingsFilters: string[];
  resourcesSettingsFiltersAndGroups: string[];

  totalGRPages: number;
  totalGRItems: number;
  currentGTPage: number;

  resourcesWithBlockedTimeGapButton: ResourcesWithBlockedTimeGapButton;

  isPreviousScreenChat: boolean;
  isDedicatedChatScreen: boolean;
  isMapSectionActive: boolean;

  currentCommonDate?: string;
  isOnlyCurrentWorkshift: boolean;
}

const initialState: SettingsState = {
  detailsUnderOrders: false,
  showDetailSection: true,
  toast: null,
  isCalcOptimization: null,
  isShowDoneFailShipments:
    localStorage.getItem(LOCAL_STORAGE_KEYS.showDoneFail) === 'true',
  totalResourcesSettings: 0,
  resourcesForSettings: [],
  showResourcesSettingsFilter: false,
  isResourcesSettingsFilterFilled: false,
  searchResourcesForSettingsByName: '',
  searchGroupsForSettingsByName: '',
  groupDetailResources: [],
  currentGroupDetailResources: [],
  totalGRItems: 0,
  totalGRPages: 0,
  currentGTPage: 1,
  resourcesSettingsGroups: [],
  resourcesSettingsFilters: [],
  resourcesSettingsFiltersAndGroups: [],
  resourcesWithBlockedTimeGapButton: {},
  isPreviousScreenChat: false,
  isOnlyCurrentWorkshift: getOnlyCurrentWorkshiftFromUrl,
  currentCommonDate: getChosenDateFromUrl || undefined,
  isDedicatedChatScreen: false,
  isMapSectionActive: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentCommonDate: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.currentCommonDate = action.payload;
      if (action.payload) {
        setChosenDateToUrl(action.payload);
      } else {
        removeChosenDateFromUrl();
      }
    },

    setJustCurrentCommonDate: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.currentCommonDate = action.payload;
    },

    removeCurrentCommonDate: (state) => {
      state.currentCommonDate = undefined;
      removeChosenDateFromUrl();
    },

    removeJustCurrentCommonDate: (state) => {
      state.currentCommonDate = undefined;
    },

    setOnlyCurrentWorkshift: (state, action: PayloadAction<boolean>) => {
      state.isOnlyCurrentWorkshift = action.payload;
      setOnlyCurrentWorkshiftToUrl(action.payload ? 'true' : 'false');
    },

    setJustOnlyCurrentWorkshift: (state, action: PayloadAction<boolean>) => {
      state.isOnlyCurrentWorkshift = action.payload;
    },

    removeOnlyCurrentWorkshift: (state) => {
      state.currentCommonDate = undefined;
      removeOnlyCurrentWorkshiftFromUrl();
    },

    setBlockedTimeGapButtonByResourceId: (
      state,
      action: PayloadAction<string>,
    ) => {
      const resourceId = action.payload;
      state.resourcesWithBlockedTimeGapButton[resourceId] = true;
    },

    setUnblockedTimeGapButtonByResourceId: (
      state,
      action: PayloadAction<string>,
    ) => {
      const resourceId = action.payload;
      state.resourcesWithBlockedTimeGapButton[resourceId] = false;
    },

    updateIsDedicatedChatScreen: (state, action: PayloadAction<boolean>) => {
      state.isDedicatedChatScreen = action.payload;
    },

    updateResourcesSettingsGroups: (state, action: PayloadAction<string[]>) => {
      state.resourcesSettingsGroups = action.payload;
    },

    updateShowDetailSection: (state, action: PayloadAction<boolean>) => {
      state.showDetailSection = action.payload;
    },

    updateIsPreviousScreenChat: (state, action: PayloadAction<boolean>) => {
      state.isPreviousScreenChat = action.payload;
    },

    updateResourcesSettingsFiltersAndGroups: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.resourcesSettingsFiltersAndGroups = action.payload;
    },
    updateResourcesSettingsFilters: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.resourcesSettingsFilters = action.payload;
    },

    removeResourcesSettingsGroupsAndFilters: (state) => {
      state.resourcesSettingsGroups = [];
      state.resourcesSettingsFilters = [];
      state.resourcesSettingsFiltersAndGroups = [];
    },

    updateTotalGRItems: (state, action: PayloadAction<number>) => {
      state.totalGRItems = action.payload;
    },
    updateTotalGRPages: (state, action: PayloadAction<number>) => {
      state.totalGRPages = action.payload;
    },
    updateCurrentGTPage: (state, action: PayloadAction<number>) => {
      state.currentGTPage = action.payload;
    },

    setDetailsUnderOrders: (state, action: PayloadAction<boolean>) => {
      state.detailsUnderOrders = action.payload;
    },

    updateCurrentGroupDetailResources: (
      state,
      action: PayloadAction<GroupingResource[]>,
    ) => {
      state.currentGroupDetailResources = action.payload;
    },

    addItemCurrentGroupDetailResources: (
      state,
      action: PayloadAction<{ resource_id: string; number: string }>,
    ) => {
      const { resource_id, number } = action.payload;
      state.currentGroupDetailResources = sortBy(
        [
          ...state.currentGroupDetailResources,
          { resource_id, number, enabled: true },
        ],
        'number',
      );
    },

    removeItemCurrentGroupDetailResources: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.currentGroupDetailResources =
        state.currentGroupDetailResources.filter(
          (r) => r.resource_id !== action.payload,
        );
    },

    setResourceEnableById: (state, action: PayloadAction<string>) => {
      state.currentGroupDetailResources.forEach((r) => {
        if (r.resource_id === action.payload) {
          r.enabled = true;
        }
      });
    },

    setResourceDisableById: (state, action: PayloadAction<string>) => {
      state.currentGroupDetailResources.forEach((r) => {
        if (r.resource_id === action.payload) {
          r.enabled = false;
        }
      });
    },

    updateGroupDetailResources: (
      state,
      action: PayloadAction<GroupApiResponse[]>,
    ) => {
      state.groupDetailResources = action.payload;
    },
    updateIsMapSectionActive: (state, action: PayloadAction<boolean>) => {
      state.isMapSectionActive = action.payload;
    },

    addResourceToGroupDetailResourcesById: (
      state,
      action: PayloadAction<{ groupId: string; resourceId: string }>,
    ) => {
      const { groupId, resourceId } = action.payload;

      state.groupDetailResources.forEach((g) => {
        if (g.id === groupId) {
          g.resources_ids.push(resourceId);
        }
      });
    },

    removeGroupDetailResourcesById: (state, action: PayloadAction<string>) => {
      state.groupDetailResources = state.groupDetailResources.filter(
        (g) => g.id !== action.payload,
      );
    },

    updateSearchResourcesForSettingsByName: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.searchResourcesForSettingsByName = action.payload;
    },

    updateSearchGroupsForSettingsByName: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.searchGroupsForSettingsByName = action.payload;
    },

    updateShowResourcesSettingsFilter: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showResourcesSettingsFilter = action.payload;
    },

    setResourcesSettingsFilterFilled: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isResourcesSettingsFilterFilled = action.payload;
    },

    setShowDoneFailShipment: (state) => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.showDoneFail,
        `${!state.isShowDoneFailShipments}`,
      );
      state.isShowDoneFailShipments = !state.isShowDoneFailShipments;
    },

    setTotalResourcesSettings: (state, action: PayloadAction<number>) => {
      state.totalResourcesSettings = action.payload;
    },

    removeResourcesForSettings: (state) => {
      state.resourcesForSettings = [];
    },

    updateResourcesForSettings: (
      state,
      action: PayloadAction<ResourceForSettings[]>,
    ) => {
      state.resourcesForSettings.push(...action.payload);
    },

    removeItemFromResourcesForSettings: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.resourcesForSettings = state.resourcesForSettings.filter(
        (r) => r.id !== action.payload,
      );
    },

    setToast: (state, action: PayloadAction<Toast>) => {
      state.toast = action.payload;
    },
    resetToast: (state) => {
      state.toast = null;
    },
    setCalcOptimization: (state, action: PayloadAction<CalcOptimization>) => {
      state.isCalcOptimization = action.payload;
    },
    resetCalcOptimization: (state) => {
      state.isCalcOptimization = null;
    },
  },
});

export const {
  setDetailsUnderOrders,
  setToast,
  resetToast,
  resetCalcOptimization,
  setCalcOptimization,
  setShowDoneFailShipment,
  setTotalResourcesSettings,
  removeItemFromResourcesForSettings,
  updateResourcesForSettings,
  updateShowResourcesSettingsFilter,
  setResourcesSettingsFilterFilled,
  updateSearchResourcesForSettingsByName,
  addResourceToGroupDetailResourcesById,
  updateTotalGRPages,
  updateTotalGRItems,
  updateCurrentGTPage,
  removeResourcesForSettings,
  updateSearchGroupsForSettingsByName,
  setResourceDisableById,
  setResourceEnableById,
  updateCurrentGroupDetailResources,
  updateGroupDetailResources,
  addItemCurrentGroupDetailResources,
  removeItemCurrentGroupDetailResources,
  removeGroupDetailResourcesById,
  updateResourcesSettingsFilters,
  updateResourcesSettingsGroups,
  removeResourcesSettingsGroupsAndFilters,
  updateResourcesSettingsFiltersAndGroups,
  setBlockedTimeGapButtonByResourceId,
  setUnblockedTimeGapButtonByResourceId,
  updateIsPreviousScreenChat,
  updateShowDetailSection,
  setCurrentCommonDate,
  setOnlyCurrentWorkshift,
  setJustOnlyCurrentWorkshift,
  removeCurrentCommonDate,
  removeOnlyCurrentWorkshift,
  updateIsDedicatedChatScreen,
  setJustCurrentCommonDate,
  removeJustCurrentCommonDate,
  updateIsMapSectionActive,
} = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
