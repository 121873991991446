import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  button: {
    color: 'primary.main',
    p: 0,
    fontSize: 0,
    width: '56px',
    height: '48px',
    bgcolor: 'custom.white',
  },

  icon: {
    fontSize: '20px',
  },

  popup: {
    width: '456px',
    bgcolor: 'custom.white',
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: 'grey',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  },
};
