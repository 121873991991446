import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getFeedbackDevicesFilters,
  getFeedbackDriversFilterss,
  getfeedbackEndDate,
  getFeedbackResourcesFilters,
  getFeedbackStartDate,
  getFeedbackStatusesFilters,
  getFeedbackVersionsFilters,
} from 'store/selectors/feedback';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { SetResourcesFiltersPopover } from './components/SetResourcesFiltersPopover';
import { styles } from './styles';

export const FeedbackFilterButton: FC = () => {
  const resourceFilter = useAppSelector(getFeedbackResourcesFilters);
  const driverFilter = useAppSelector(getFeedbackDriversFilterss);
  const deviceFilter = useAppSelector(getFeedbackDevicesFilters);
  const androidFilter = useAppSelector(getFeedbackVersionsFilters);
  const statusFilter = useAppSelector(getFeedbackStatusesFilters);
  const startDate = useAppSelector(getFeedbackStartDate);
  const endDate = useAppSelector(getfeedbackEndDate);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isAnyFilters = useMemo(() => {
    return (
      resourceFilter?.length ||
      driverFilter?.length ||
      deviceFilter?.length ||
      androidFilter?.length ||
      statusFilter?.length ||
      endDate ||
      startDate
    );
  }, [
    resourceFilter,
    driverFilter,
    deviceFilter,
    androidFilter,
    statusFilter,
    startDate,
    endDate,
  ]);

  const handleIconPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <LightTooltip
        title={
          !isAnyFilters
            ? t`You can set a feedback filter`
            : t`You can change a feedback filter`
        }
        arrow
        placement="top"
      >
        <Button variant="outlined" sx={styles.button} onClick={handleIconPress}>
          {isAnyFilters ? (
            <FilterAltIcon sx={styles.icon} />
          ) : (
            <FilterAltOutlinedIcon sx={styles.icon} />
          )}
        </Button>
      </LightTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{ sx: styles.popup }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {open && <SetResourcesFiltersPopover onClosePopover={handleClose} />}
      </Popover>
    </>
  );
};
