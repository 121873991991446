import { t } from 'ttag';

export type DeviceDriversHeadCellTitle =
  | 'android_version'
  | 'device_info'
  | 'login_time'
  | 'driver_name'
  | 'logout_time';

export type HeadCell = {
  id: DeviceDriversHeadCellTitle;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  isSorted: boolean;
};
export const deviceDriversHeadCells: readonly HeadCell[] = [
  {
    id: 'driver_name',
    numeric: false,
    disablePadding: true,
    label: t`DRIVER`,
    isSorted: true,
  },
  {
    id: 'login_time',
    numeric: false,
    disablePadding: false,
    label: t`LOG IN DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'logout_time',
    numeric: false,
    disablePadding: false,
    label: t`LOG OUT DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'device_info',
    numeric: false,
    disablePadding: false,
    label: t`DEVICE`,
    isSorted: true,
  },
  {
    id: 'android_version',
    numeric: false,
    disablePadding: true,
    label: t`ANDROID`,
    isSorted: true,
  },
];

export const strWithoutMinus = (str: string) => {
  if (str.startsWith('-')) {
    return str.slice(1);
  }

  return str;
};
