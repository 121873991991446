import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';

import { styles } from './styles';

export const AdditionsDataTabPickUpTime: FC = () => {
  return (
    <ChangeScreenSection label={t`Pickup time`}>
      <Grid container columns={1} rowGap={2}>
        <Grid container columns={1} spacing={2} rowGap={4}>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="pickWaitingTime"
              label={t`Waiting time pickup`}
              placeholder={t`Enter waiting time pickup`}
              type="number"
            />
            <Typography>{t`min`}</Typography>
          </Grid>
          <Grid item mobile={1} sx={styles.gridItem}>
            <Field
              component={FieldInput}
              name="pickLoadingTime"
              label={t`Loading time`}
              placeholder={t`Enter loading time`}
              type="number"
            />
            <Typography>{t`min`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
