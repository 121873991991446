import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getToastInfo } from 'store/selectors/settings';

import { RootRoutes } from 'components/common/RootRoutes/RootRoutes';
import { Snackbar } from 'components/ui/Snackbar';

import 'mapbox-gl/dist/mapbox-gl.css';

export const App = () => {
  const toastInfo = useAppSelector(getToastInfo);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      <RootRoutes />
      {!!toastInfo?.message && (
        <Snackbar message={toastInfo.message} severity={toastInfo.severity} />
      )}
    </LocalizationProvider>
  );
};
