import { FC, useMemo } from 'react';
import IntercomIcon from '@mui/icons-material/DragIndicatorOutlined';
import ElevatorIcon from '@mui/icons-material/ElevatorOutlined';
import EnterCodeIcon from '@mui/icons-material/LockOpenOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import FloorIcon from '@mui/icons-material/StairsOutlined';
import WishIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { List } from '@mui/material';
import { ReactComponent as DoorBellIcon } from 'assets/icons/door_bell.svg';
import { ReactComponent as PackagingReturnIcon } from 'assets/icons/packagingReturn.svg';
import { ReactComponent as QuietTimeIcon } from 'assets/icons/quiet_time_active.svg';
import { t } from 'ttag';
import { getNumberWithOrdinal } from 'utils/common';

import { InnerSectionListItem } from 'components/common/InnerSectionListItem';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsInnerSectionProps {
  intercom?: string | null;
  enterCode?: string | null;
  elevator?: boolean | null;
  floor?: number | string | null;
  knockDoor?: boolean | null;
  packagingReturn?: boolean | null;
  ageControl?: number | null;
  proofTypeName?: string | null;
  quietPickup?: boolean | null;
  quietDelivery?: boolean | null;
  useDoorbell?: boolean | null;
}

export const DetailsSectionInnerDetails: FC<DetailsInnerSectionProps> = ({
  intercom,
  enterCode,
  floor,
  elevator,
  knockDoor,
  packagingReturn,
  ageControl,
  proofTypeName,
  quietPickup,
  quietDelivery,
  useDoorbell,
}) => {
  const floorNumber = useMemo(() => getNumberWithOrdinal(floor || 0), [floor]);

  const enterCodeItem = enterCode && (
    <InnerSectionListItem
      icon={<EnterCodeIcon />}
      iconColor="custom.brown"
      label={t`Door code`}
      value={enterCode}
    />
  );
  const intercomItem = intercom && (
    <InnerSectionListItem
      icon={<IntercomIcon />}
      iconColor="custom.blue"
      label={t`Intercom code`}
      value={intercom}
    />
  );
  const floorItem = floor && (
    <InnerSectionListItem
      icon={<FloorIcon />}
      iconColor="custom.green"
      label={t`Floor number`}
      value={t`${floorNumber} floor`}
    />
  );
  const elevatorItem = elevator && (
    <InnerSectionListItem
      icon={<ElevatorIcon />}
      iconColor="primary.main"
      label={t`The house has an elevator`}
      value={t`Elevator`}
    />
  );
  const knockDoorItem = knockDoor && (
    <InnerSectionListItem
      icon={<WishIcon />}
      iconColor="custom.gold"
      label={t`Client's wishes`}
      value={t`Knock on the door`}
    />
  );

  const quietPickupItem = quietPickup && (
    <InnerSectionListItem
      icon={<QuietTimeIcon />}
      label={t`Client's wishes`}
      value={t`Quiet pickup`}
    />
  );

  const quietDeliveryItem = quietDelivery && (
    <InnerSectionListItem
      icon={<QuietTimeIcon />}
      label={t`Client's wishes`}
      value={t`Quiet delivery`}
    />
  );

  const useDoorbellItem = useDoorbell && (
    <InnerSectionListItem
      icon={<DoorBellIcon />}
      label={t`Client's wishes`}
      value={t`Use door bell`}
    />
  );

  const packagingReturnItem = packagingReturn && (
    <InnerSectionListItem
      icon={<PackagingReturnIcon />}
      label={t`Extra action`}
      value={t`Packaging return`}
    />
  );

  const ageControlItem = ageControl && (
    <InnerSectionListItem
      icon={<WarningAmberOutlinedIcon />}
      iconColor="custom.red2"
      label={t`Age control`}
      value={t`${ageControl} years old`}
    />
  );

  const proofTypeItem = proofTypeName && (
    <InnerSectionListItem
      icon={<PersonOutlineOutlinedIcon />}
      iconColor="custom.blueLight2"
      label={t`Delivery method`}
      value={proofTypeName}
    />
  );

  return (
    <InnerSection label={t`Details`} sx={styles.container}>
      <List sx={styles.list}>
        {enterCodeItem}
        {intercomItem}
        {floorItem}
        {elevatorItem}
        {knockDoorItem}
        {useDoorbellItem}
        {quietPickupItem}
        {quietDeliveryItem}
        {proofTypeItem}
        {ageControlItem}
        {packagingReturnItem}
      </List>
    </InnerSection>
  );
};
