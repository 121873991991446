export const styles = {
  container: {
    margin: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '108px',
    borderWidth: '1px',
    borderColor: 'custom.greyLight',
    borderStyle: 'solid',
    borderRadius: '6px',
    bgcolor: 'custom.white',
    '&:hover': {
      boxShadow: '1px 1px 6px 1px rgba(0,0,0,0.38)',
    },

    marginBottom: '8px',
    overflow: 'hidden',
  },

  card: {
    display: 'flex',
    // margin: 0,
    // padding: 0,
    alignItems: 'center',
    height: '108px',
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
    '&[data-selected="true"]': {
      bgcolor: 'custom.greyLightBlue2',
    },
  },

  workshifTime: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'custom.black',
    lineHeight: '18px',
  },

  timeContent: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'custom.black',
    lineHeight: '20px',
  },

  messageText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'custom.black',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 3,
    //     maxHeight: '72px',
    //       display: '-webkit-box',
    //     '-webkit-box-orient': 'vertical',
    // '-webkit-line-clamp': 3,
    //     whiteSpace: 'normal',
  },

  chatContainer: {
    display: 'flex',
    flexGrow: 1,
    padding: 0,
    height: '500px',
    justifyContent: 'center',
  },

  chat: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },

  messageBlock: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
