import React, { FC, useCallback, useMemo } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ReactComponent as OverviewGrey } from 'assets/icons/overview_grey.svg';
import { ReactComponent as OverviewOrange } from 'assets/icons/overview_orange.svg';
import { format, isToday } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { removeSelectedOrderId } from 'store/reducers/orderSlice';
import { removeSelectedResourceId } from 'store/reducers/resourceSlice';
import {
  removeCurrentCommonDate,
  setCurrentCommonDate,
  setOnlyCurrentWorkshift,
} from 'store/reducers/settingsSlice';
import { removeSelectedWorkshiftId } from 'store/reducers/workshiftSlice';
import {
  getCommonDateSelector,
  getOnlyCurrentWorkshiftSelector,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const CommonDate: FC = () => {
  const commonDate = useAppSelector(getCommonDateSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const dispatch = useAppDispatch();

  const date = useMemo(() => {
    return commonDate ? new Date(commonDate) : new Date();
  }, [commonDate]);

  const handleChangeDatePicker = useCallback((val: Date | null) => {
    dispatch(
      setCurrentCommonDate(
        isToday(val as Date) ? undefined : format(val as Date, 'yyyy-MM-dd'),
      ),
    );
    dispatch(removeSelectedResourceId());
    dispatch(removeSelectedOrderId());
    dispatch(removeSelectedWorkshiftId());
  }, []);

  const handleIconPress = useCallback(() => {
    if (!onlyCurrentWorkshift) {
      dispatch(removeCurrentCommonDate());
    }
    dispatch(setOnlyCurrentWorkshift(!onlyCurrentWorkshift));
    dispatch(removeSelectedResourceId());
    dispatch(removeSelectedOrderId());
    dispatch(removeSelectedWorkshiftId());
  }, [onlyCurrentWorkshift]);

  return (
    <Box sx={styles.container}>
      <LightTooltip
        title={
          onlyCurrentWorkshift
            ? t`Use only current workshift`
            : t`Use historical workshifts by date`
        }
        arrow
        placement="top"
      >
        <span>
          <IconButton sx={styles.button} onClick={handleIconPress}>
            {onlyCurrentWorkshift ? <OverviewOrange /> : <OverviewGrey />}
          </IconButton>
        </span>
      </LightTooltip>
      <DesktopDatePicker
        // label="Date desktop"
        inputFormat="yyyy-MM-dd"
        disabled={onlyCurrentWorkshift}
        // shouldDisableDate={shouldDisabledDate}
        value={date}
        onChange={handleChangeDatePicker}
        renderInput={(params) => (
          <TextField {...params} onKeyDown={(e) => e.preventDefault()} />
        )}
      />
    </Box>
  );
};
