import React, { FC, useCallback, useState } from 'react';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, SxProps } from '@mui/material';
import { useRemoveResourcesFromGroupMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { removeItemCurrentGroupDetailResources } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { GroupDetailApiResponse, GroupingResource } from 'types/api';

import { DialogModal } from 'components/ui/DialogModal';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface DeleteCompanyButtonProps {
  group?: GroupDetailApiResponse;
  resource: GroupingResource;
  buttonStyle?: SxProps;
}

export const DeleteGroupResourceButton: FC<DeleteCompanyButtonProps> =
  React.memo(({ group, resource, buttonStyle = {} }) => {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(false);

    const [deleteResource] = useRemoveResourcesFromGroupMutation();

    const handleModalConfirm = useCallback(
      (e: React.MouseEvent<Element, MouseEvent>) => {
        e.stopPropagation();
        dispatch(removeItemCurrentGroupDetailResources(resource.resource_id));
        deleteResource({
          groupId: group?.id as string,
          resourcesIds: [resource.resource_id],
        });
      },
      [deleteResource, group, resource],
    );

    const title = group?.title || '';

    return (
      <>
        <LightTooltip
          title={t`You can remove this resource from group`}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              sx={{ ...styles.button, ...buttonStyle } as SxProps}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
            >
              <DeleteForeverOutlinedIcon sx={{ color: 'custom.graphite' }} />
            </IconButton>
          </span>
        </LightTooltip>

        <DialogModal
          open={openModal}
          onConfirm={handleModalConfirm}
          onClose={(e: React.MouseEvent<Element, MouseEvent>) => {
            e.stopPropagation();
            setOpenModal(false);
          }}
          title={t`Are you sure you want to delete "${resource.number}" from ${title}?`}
          content="You can add this resource again from the list of all resources"
        />
      </>
    );
  });
