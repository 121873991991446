import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Box, Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { format } from 'date-fns';
import {
  useChangeFeedbackStatusOrReplyMutation,
  useFeedbackByIdQuery,
} from 'store/api/adminApiSlice';
import { t } from 'ttag';
import { FeedbackStatus } from 'types/feedback';

import { FeedbackAudioList } from '../FeedbackAudioList';
import { FeedbackImagesList } from '../FeedbackImagesList';
import { FeedbackVideoList } from '../FeedbackVideoList';
import { FeedbackDetailHeader } from '../FeefbackDetailHeader';

import { styles } from './styles';

interface FeedbackDetailProps {
  feedbackId: number;
  setCurrentFeedbackId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const FeedbackDetail = memo((props: FeedbackDetailProps) => {
  const { feedbackId, setCurrentFeedbackId } = props;
  const [setFeedbackStatus] = useChangeFeedbackStatusOrReplyMutation();

  const { data } = useFeedbackByIdQuery({ feedbackId });
  const [reply, setReply] = useState<string | null | undefined>(
    data?.reply || '',
  );

  useEffect(() => {
    if (data?.reply) {
      setReply(data?.reply);
    }
  }, [data?.reply]);

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setReply(e.target.value);
    },
    [],
  );

  const handleCancelPress = useCallback(() => {
    setCurrentFeedbackId(null);
  }, []);

  const handleSavePress = useCallback(() => {
    if (!reply) {
      return;
    }

    setFeedbackStatus({
      feedbackId: String(feedbackId),
      status: data?.status as FeedbackStatus,
      reply: reply.trim(),
    });

    setCurrentFeedbackId(null);
  }, [reply, feedbackId, data?.status]);

  const handleLocationPress = useCallback(() => {
    const url = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
    window.open(url, '_blank');
  }, [data]);

  const images = useMemo(() => {
    if (!data?.files?.length) {
      return [];
    }

    return data.files
      .filter((file) => file.file_type === 'image')
      .map((file) => file.file_url);
  }, [data]);

  const videos = useMemo(() => {
    if (!data?.files?.length) {
      return [];
    }

    return data.files
      .filter((file) => file.file_type === 'video')
      .map((file) => file.file_url);
  }, [data]);

  const audios = useMemo(() => {
    if (!data?.files?.length) {
      return [];
    }

    return data.files
      .filter((file) => file.file_type === 'audio')
      .map((file) => file.file_url);
  }, [data]);

  return (
    <Stack direction="column" sx={styles.container}>
      <FeedbackDetailHeader
        feedbackId={feedbackId}
        resourceNumber={data?.resource_number}
        status={data?.status}
        setCurrentFeedbackId={setCurrentFeedbackId}
      />

      <Stack direction="column" sx={styles.contentBlock}>
        <Grid
          container
          columns={6}
          spacing={2}
          rowGap={2}
          padding={2}
          mt="24px"
        >
          <Grid item mobile={2}>
            <TextField
              id="resurceNumber"
              label={t`Resource number`}
              placeholder={t`Resource`}
              value={data?.resource_number || ''}
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={2}>
            <TextField
              id="Date"
              label={t`Date`}
              value={
                data?.created_at
                  ? format(new Date(data.created_at), 'dd.MM.yy')
                  : ''
              }
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={2}>
            <TextField
              id="Time"
              label={t`Time`}
              value={
                data?.created_at
                  ? format(new Date(data.created_at), 'hh:mm aa')
                  : ''
              }
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={styles.input}
            />
          </Grid>

          <Grid item mobile={3}>
            <TextField
              id="deviceInfo"
              label={t`Device info`}
              value={data?.device_info || ''}
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={3}>
            <TextField
              id="androidVersion"
              label={t`Android version`}
              value={data?.android_version || ''}
              sx={styles.input}
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item mobile={3}>
            <Box sx={styles.gpsBlock}>
              <TextField
                id="gpsLocation"
                label={t`GPS - location`}
                value={`${data?.latitude} - ${data?.latitude}`}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
              <IconButton sx={styles.gpsIcon} onClick={handleLocationPress}>
                <GpsFixedIcon color="primary" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item mobile={3}>
            <TextField
              id="driverName"
              label={t`Driver name`}
              value={data?.driver_name || ''}
              sx={styles.input}
              fullWidth
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        {!!images?.length && (
          <Box sx={styles.imageList}>
            <FeedbackImagesList imageUrls={images} />
          </Box>
        )}
        {!!videos?.length && (
          <Box sx={styles.imageList}>
            <FeedbackVideoList videoUrl={videos} />
          </Box>
        )}
        {!!audios?.length && (
          <Box sx={styles.imageList}>
            <FeedbackAudioList audioUrls={audios} />
          </Box>
        )}
        <Grid
          container
          columns={6}
          spacing={2}
          rowGap={2}
          padding={2}
          mt="24px"
        >
          <Grid item mobile={3}>
            <TextField
              id="comment"
              label={t`Comment`}
              value={data?.comment || ''}
              fullWidth
              disabled
              multiline
              InputLabelProps={{ shrink: true }}
              sx={styles.input}
            />
          </Grid>
          <Grid item mobile={3}>
            <TextField
              id="adminComment"
              label={t`Comment from admin`}
              value={reply}
              onChange={handleCommentChange}
              sx={styles.input}
              fullWidth
              multiline
              // InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Box sx={styles.buttonBlock}>
          <Button
            onClick={handleCancelPress}
            sx={styles.button}
            variant="outlined"
          >
            {t`Cancel`}
          </Button>
          <Button
            onClick={handleSavePress}
            variant="contained"
            sx={styles.button}
          >
            {t`Save`}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
});
