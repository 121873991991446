import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    ml: '20px',
    position: 'relative',
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  empty: {
    fontWeight: 600,
    alignSelf: 'center',
    justifySelf: 'center',
  },
  list: {
    pt: '20px',
    display: 'flex',
    pl: '20px',
    width: '100%',
    alignItems: 'flex-end',
    borderBottom: '1px solid',
    borderColor: 'custom.greyLight',
  },
  mainContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '16px',
  },
  bottomButtons: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    left: '16px',
    bottom: '16px',
  },
  contentBlock: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
};
