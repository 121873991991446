import { useMemo } from 'react';
import { UNASSIGNED_TASKS } from 'constants/common';
import {
  useShipmentsByResourceIdQuery,
  useTerminalGroupedErrandsByResourceIdQuery,
} from 'store/api/apiSlice';
import { AllRoutesSelectedOrder } from 'types/orders';
import { getOrders } from 'utils/orderUtils';

export const useAllRoutesSelectedOrder = (
  orderData: AllRoutesSelectedOrder | null,
) => {
  if (!orderData) {
    return { selectedOrder: null, isFetching: false };
  }

  const { workshiftId, orderId, resourceId, terminalId } = orderData;

  const { data: shipmentsList, isFetching: shipmentFetching } =
    useShipmentsByResourceIdQuery(
      {
        resourceId: resourceId as string,
        workshiftId: workshiftId,
      },
      {
        skip: !resourceId || resourceId === UNASSIGNED_TASKS,
      },
    );

  const { data: errandsList, isFetching: errandFetching } =
    useTerminalGroupedErrandsByResourceIdQuery(
      {
        resourceId: resourceId as string,
        workshiftId: workshiftId,
      },
      {
        skip: !resourceId || resourceId === UNASSIGNED_TASKS,
      },
    );

  let orderIdForSearch = orderId;

  if (terminalId) {
    errandsList?.some((errand) => {
      if (errand.id === orderId && errand?._meta?.terminal_group_number) {
        orderIdForSearch = `terminal-${errand._meta.terminal_group_number}-${terminalId}`;
      }
    });
  }

  const ordersList = useMemo(() => {
    if (!shipmentsList?.length) {
      return null;
    }

    return getOrders(shipmentsList, errandsList).filter((s) => s !== null);
  }, [shipmentsList, errandsList]);

  const isFetching = useMemo(
    () => shipmentFetching || errandFetching,
    [shipmentFetching, errandFetching],
  );
  const result = useMemo(() => {
    if (!ordersList) {
      return { selectedOrder: null, isFetching };
    }

    const foundOrder = ordersList.find(
      (order) => order.id === orderIdForSearch,
    );
    return { selectedOrder: foundOrder ? foundOrder : null, isFetching };
  }, [ordersList, isFetching, orderIdForSearch]);

  return result;
};
