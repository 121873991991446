import { FC } from 'react';
import { Grid } from '@mui/material';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';

import { OrderDetailsTabPackageLogoSection } from 'components/common/ChangeScreenForms/components/OrderDetailsTabPackageLogoSection';

import { OrderDetailsTabAdditionalDataSection } from '../OrderDetailsTabAdditionalDataSection';
import { OrderDetailsTabDeliveryDataSection } from '../OrderDetailsTabDeliveryDataSection';
import { OrderDetailsTabOrderDataSection } from '../OrderDetailsTabOrderDataSection';
import { OrderDetailsTabPickupDataSection } from '../OrderDetailsTabPickUpDataSection';
import { OrderDetailsTabStatusSection } from '../OrderDetailsTabStatusSection';
import { OrderDetailsTabTerminalDataSection } from '../OrderDetailsTabTerminalDataSection';

export const OrderDetailsTabForm: FC = () => {
  const { isCreateScreen } = useChangeScreenContext();
  const { terminalPickType } = useEditScreenErrandTypes();

  return (
    <Grid container columns={3} spacing={2}>
      <Grid item mobile={2}>
        <Grid container columns={1} rowGap={2}>
          <Grid item mobile={1}>
            <OrderDetailsTabOrderDataSection />
          </Grid>
          <Grid item mobile={1}>
            <Grid container columns={2} spacing={2}>
              <Grid item mobile={1}>
                <OrderDetailsTabPickupDataSection />
              </Grid>
              <Grid item mobile={1}>
                <OrderDetailsTabDeliveryDataSection />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item mobile={1}>
        <Grid container columns={1} rowGap={2}>
          {terminalPickType && !isCreateScreen && (
            <Grid item mobile={1}>
              <OrderDetailsTabTerminalDataSection />
            </Grid>
          )}
          <Grid item mobile={1}>
            <OrderDetailsTabStatusSection />
          </Grid>
          <Grid item mobile={1}>
            <OrderDetailsTabPackageLogoSection />
          </Grid>
          <Grid item mobile={1}>
            {!terminalPickType && <OrderDetailsTabAdditionalDataSection />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
