import React, { FC, useCallback } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { t } from 'ttag';

import { styles } from './styles';

interface DialogModalProps {
  open: boolean;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  onConfirm?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  title: string;
  content: string;
}

export const DialogModal: FC<DialogModalProps> = React.memo((props) => {
  const { open, onClose, title, content, onConfirm } = props;

  const handleConfirmClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      onConfirm?.(e);
      onClose(e);
    },
    [onClose, onConfirm],
  );

  return (
    <Dialog
      onClose={(e) => {
        e;
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <Box sx={styles.wrapper}>
        <Typography variant="h5" sx={styles.title}>
          {title}
        </Typography>

        <Typography variant="body1" sx={styles.content}>
          {content}
        </Typography>

        <Box sx={styles.separator} />
        <Box sx={styles.buttonBlock}>
          <Button sx={styles.button} variant="outlined" onClick={onClose}>
            {t`Cancel`}
          </Button>
          <Button
            sx={styles.button}
            variant="contained"
            onClick={handleConfirmClick}
          >
            {t`Confirm`}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
});
