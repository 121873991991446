import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { GROUP_SECTION_SCREEN } from 'constants/common';
import { GroupSettingContext } from 'context/GroupSetting/GroupSettingProvider';
import { useGetAllGroupsQuery } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  updateGroupDetailResources,
  updateSearchGroupsForSettingsByName,
} from 'store/reducers/settingsSlice';
import {
  getGroupDetailResources,
  getSearchGroupsForSettingsByName,
} from 'store/selectors/settings';
import { t } from 'ttag';

import { DroppableGroupItem } from 'components/screens/EditSettingsScreen/components/DroppableGroupItem';
import { ResourcesSectionSkeleton } from 'components/screens/HomeScreen/components/ResourcesSectionSkeleton';

import { styles } from './styles';

const AllGroupsList: FC = () => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(getSearchGroupsForSettingsByName);
  const { data: allGroups, isFetching } = useGetAllGroupsQuery(
    { title: search },
    { refetchOnMountOrArgChange: true },
  );
  const groups = useAppSelector(getGroupDetailResources);
  // @ts-ignore
  const { setCurrentScreen, setCurrentGroupId } =
    useContext(GroupSettingContext);

  useEffect(() => {
    if (allGroups?.length) {
      dispatch(updateGroupDetailResources(allGroups));
    } else {
      dispatch(updateGroupDetailResources([]));
    }
  }, [allGroups]);

  const handleSearchCloseClick = useCallback(() => {
    dispatch(updateSearchGroupsForSettingsByName(''));
  }, []);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      dispatch(updateSearchGroupsForSettingsByName(e.target.value));
    },
    [],
  );

  const content = useMemo(() => {
    if (isFetching) {
      return <ResourcesSectionSkeleton quantity={10} />;
    }

    if (!groups?.length) {
      return (
        <Typography sx={styles.noResources}>
          {t`There are no groups.`}
        </Typography>
      );
    }

    return groups.map((group) => {
      return <DroppableGroupItem key={group.id} group={group} />;
    });
  }, [groups, isFetching]);

  return (
    <>
      <Box sx={styles.filtersBlock}>
        <TextField
          sx={styles.input}
          // inputRef={inputRef}
          onChange={handleSearchChange}
          autoComplete="new-password"
          value={search}
          autoFocus
          label={t`Search`}
          placeholder={t`Search by the name of the group`}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: '16px', marginLeft: '16px' }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleSearchCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          type="text"
        />
        <Button
          sx={styles.createButton}
          variant="contained"
          onClick={() => {
            setCurrentScreen(GROUP_SECTION_SCREEN.createGroup);
            setCurrentGroupId(null);
          }}
        >
          {t`Create group`}
        </Button>
      </Box>
      <List sx={styles.list}>{content}</List>
    </>
  );
};

export { AllGroupsList };
