import { FormikErrors, FormikTouched } from 'formik';

import {
  ChangeScreenFormValues,
  ChangeScreenFormValuesKeys,
} from 'components/common/ChangeScreenForms/types';

interface ChangeScreenTabErrorsProps {
  errors: FormikErrors<ChangeScreenFormValues>;
  touched: FormikTouched<ChangeScreenFormValues>;
  keys: ChangeScreenFormValuesKeys;
}

const getDataTabErrors = (
  errors: FormikErrors<ChangeScreenFormValues>,
  touched: FormikTouched<ChangeScreenFormValues>,
  keys: string[],
) =>
  keys.reduce((acc, key) => {
    if (
      typeof errors === 'object' &&
      typeof touched === 'object' &&
      Object.keys(touched).length > Object.keys(errors).length
    ) {
      return (
        acc ||
        (Object.keys(errors).includes(key) &&
          Object.keys(touched).includes(key))
      );
    }
    return acc;
  }, false);

export const useChangeScreenTabErrors = ({
  keys,
  errors,
  touched,
}: ChangeScreenTabErrorsProps) => {
  const {
    orderDetailsTabFormValuesKeys,
    pickupDataTabFormValuesKeys,
    deliveryDataTabFormValuesKeys,
    parcelsDataTabFormValuesKeys,
    additionsDataTabFormValuesKeys,
  } = keys;

  return {
    orderDetailsTabErrors: getDataTabErrors(
      errors,
      touched,
      orderDetailsTabFormValuesKeys,
    ),
    pickupDataTabErrors: getDataTabErrors(
      errors,
      touched,
      pickupDataTabFormValuesKeys,
    ),
    deliveryDataTabErrors: getDataTabErrors(
      errors,
      touched,
      deliveryDataTabFormValuesKeys,
    ),
    parcelsDataTabErrors: getDataTabErrors(
      errors,
      touched,
      parcelsDataTabFormValuesKeys,
    ),
    additionsDataTabErrors: getDataTabErrors(
      errors,
      touched,
      additionsDataTabFormValuesKeys,
    ),
  };
};
