import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  list: {
    p: 0,
  },
};
