import React, { FC, memo, useCallback, useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Chip, ListItem, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setWorkshiftId } from 'store/reducers/workshiftSlice';
import { getUnreadMessagesByResourceIdV2 } from 'store/selectors/chat';
import { getCommonDateSelector } from 'store/selectors/settings';
import { getSelectedWorkshiftIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { WorkShift } from 'types/api';
import { Resource } from 'types/resource';
import { isFirstDateMoreThenSecond } from 'utils/timeUtils';
import {
  defineCurrentWorkshift,
  isCurrentWorkshift,
} from 'utils/workshiftUtil';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface WorkshiftProps {
  resource: Resource;
}

export const WorkshiftList: FC<WorkshiftProps> = memo(
  (props: WorkshiftProps) => {
    const { resource } = props;
    const commonDate = useAppSelector(getCommonDateSelector);
    const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
    const dispatch = useAppDispatch();
    const unreadMessages = useAppSelector(
      getUnreadMessagesByResourceIdV2(resource.id),
    );

    const handleUnreadPress = useCallback(() => {
      const currentWorkshift = defineCurrentWorkshift<WorkShift>(
        resource.work_shifts,
      );

      if (currentWorkshift && currentWorkshift !== selectedWorkshiftId) {
        dispatch(setWorkshiftId(currentWorkshift));
      }
    }, [selectedWorkshiftId, dispatch]);

    const handleWorkshiftPress = useCallback((id: string) => {
      dispatch(setWorkshiftId(id));
    }, []);

    const getUnreadQty = useCallback(
      (workshiftId: string) => {
        if (!unreadMessages?.length) {
          return 0;
        }

        const unreadByWirkshift = unreadMessages?.find(
          (message) => message.work_shift_id === workshiftId,
        );

        if (!unreadByWirkshift) {
          return 0;
        }

        return unreadByWirkshift?.count;
      },
      [unreadMessages],
    );

    const resources = useMemo(() => {
      if (!resource?.work_shifts?.length || !resource.work_shifts.length) {
        return null;
      }

      return resource.work_shifts.map((workshift) => {
        const { start, end, id } = workshift;
        const isCurrent = isCurrentWorkshift(start, end);

        return (
          <ListItem
            sx={styles.collapsedItem}
            key={workshift.id}
            onClick={() => handleWorkshiftPress(id)}
          >
            <Box sx={styles.timeCheckBlock}>
              {isCurrent ? (
                <LightTooltip
                  title={t`Current Workshift`}
                  arrow
                  placement="top"
                >
                  <Chip
                    label="current"
                    color="primary"
                    size="small"
                    variant="outlined"
                    sx={{
                      margin: '0 3px 0 0',
                      height: '24px',
                      width: '48px',
                      fontSize: '8px',
                    }}
                  />

                  {/* <HourglassEmptyIcon color="primary" /> */}
                </LightTooltip>
              ) : (
                <Box sx={{ width: '52px' }} />
              )}
              <Box sx={styles.timeBlock}>
                <LightTooltip
                  title={
                    isFirstDateMoreThenSecond(
                      format(
                        commonDate ? new Date(commonDate) : new Date(),
                        'yyyy-MM-dd',
                      ),
                      format(new Date(start), 'yyyy-MM-dd'),
                    )
                      ? format(new Date(start), 'yyyy-MM-dd')
                      : ''
                  }
                  arrow
                  placement="top"
                >
                  <Typography
                    variant="h6"
                    color={
                      isFirstDateMoreThenSecond(
                        format(
                          commonDate ? new Date(commonDate) : new Date(),
                          'yyyy-MM-dd',
                        ),
                        format(new Date(start), 'yyyy-MM-dd'),
                      )
                        ? 'custom.grey'
                        : 'custom.black'
                    }
                  >
                    {format(new Date(start), 'HH:mm')}
                  </Typography>
                </LightTooltip>
                <Typography variant="h6" m="0 4px 0 4px">
                  -
                </Typography>
                <LightTooltip
                  title={
                    isFirstDateMoreThenSecond(
                      format(new Date(end), 'yyyy-MM-dd'),
                      format(
                        commonDate ? new Date(commonDate) : new Date(),
                        'yyyy-MM-dd',
                      ),
                    )
                      ? format(new Date(end), 'yyyy-MM-dd')
                      : ''
                  }
                  arrow
                  placement="top"
                >
                  <Typography
                    variant="h6"
                    color={
                      isFirstDateMoreThenSecond(
                        format(new Date(end), 'yyyy-MM-dd'),
                        format(
                          commonDate ? new Date(commonDate) : new Date(),
                          'yyyy-MM-dd',
                        ),
                      )
                        ? 'custom.grey'
                        : 'custom.black'
                    }
                  >
                    {format(new Date(end), 'HH:mm')}
                  </Typography>
                </LightTooltip>
              </Box>
              <Box>
                {id === selectedWorkshiftId && (
                  <CheckIcon color="primary" fontSize="small" />
                )}
              </Box>
              {!!getUnreadQty(id) && (
                <QueryParamsLink
                  sx={{ zIndex: 100, marginLeft: '4px' }}
                  onClick={handleUnreadPress}
                >
                  <UnreadLabel qty={getUnreadQty(id)} />
                </QueryParamsLink>
              )}
            </Box>
          </ListItem>
        );
      });
    }, [
      selectedWorkshiftId,
      commonDate,
      resource,
      handleWorkshiftPress,
      handleUnreadPress,
      unreadMessages,
      getUnreadQty,
    ]);

    return <>{resources}</>;
  },
);
