import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UNASSIGNED_PAGE_SIZE } from 'constants/common';
import { uniq } from 'lodash';
import {
  getSelectedOrderIdFromUrl,
  removeSelectedOrderIdFromUrl,
  setSelectedOrderIdToUrl,
} from 'store/helpers/orderHelpers';
import {
  getUnassignedPageNumberFromUrl,
  removeUnassignedPageNumberFromUrl,
  setUnassignedPageNumberToUrl,
} from 'store/helpers/unassignedHelpers';
import { Shipment, UnassignedShipments } from 'types/api';
import { AllRoutesSelectedOrder } from 'types/orders';
import { findPageNumber } from 'utils/unassignedPageUtils';

interface OrderState {
  selectedOrderId: string | null | undefined;
  unassignedPageNumber: number;
  unassignedTotal: number;
  unassignedPages: number;
  unassignedShipments: Shipment[];
  viewedUnassignedPages: number[];
  searchedUnassignedShipmentId: string | null;
  allRoutesSelectedOrder: AllRoutesSelectedOrder | null;
}

const initialState: OrderState = {
  selectedOrderId: getSelectedOrderIdFromUrl || null,
  unassignedPageNumber: parseInt(getUnassignedPageNumberFromUrl || '1'),
  unassignedPages: 0,
  unassignedTotal: 0,
  unassignedShipments: [],
  viewedUnassignedPages: [parseInt(getUnassignedPageNumberFromUrl || '1')],
  searchedUnassignedShipmentId: null,
  allRoutesSelectedOrder: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setSelectedOrderId: (state, action: PayloadAction<string | undefined>) => {
      if (state.selectedOrderId !== action.payload) {
        state.selectedOrderId = action.payload;
        setSelectedOrderIdToUrl(action.payload);
      }
      if (state.allRoutesSelectedOrder) {
        state.allRoutesSelectedOrder = null;
      }
    },

    setJustSelectedOrderId: (
      state,
      action: PayloadAction<string | undefined | null>,
    ) => {
      state.selectedOrderId = action.payload;
    },

    setSearchedUnassignedShipmentId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.searchedUnassignedShipmentId = action.payload;
    },
    setAllRoutesSelectedOrder: (
      state,
      action: PayloadAction<AllRoutesSelectedOrder | null>,
    ) => {
      state.allRoutesSelectedOrder = action.payload;
    },
    setUnassignedPageNumber: (state, action: PayloadAction<number>) => {
      if (state.unassignedPageNumber !== action.payload) {
        const result = state.viewedUnassignedPages;

        if (result.some((p) => p !== action.payload)) {
          state.unassignedPageNumber = action.payload;
          setUnassignedPageNumberToUrl(action.payload.toFixed());
        }

        result.push(action.payload);
        state.viewedUnassignedPages = uniq(result);
      }
    },

    setUnassignedPageNumberByShipmentId: (
      state,
      action: PayloadAction<string>,
    ) => {
      const currentIndex = state.unassignedShipments.findIndex(
        (s) => s?.id === action.payload,
      );
      const foundPage = findPageNumber(
        currentIndex + 1,
        state.unassignedTotal,
        UNASSIGNED_PAGE_SIZE,
      );

      if (foundPage) {
        state.unassignedPageNumber = foundPage;
        setUnassignedPageNumberToUrl(foundPage.toFixed());
      }
    },

    setUnassignedShipment: (
      state,
      action: PayloadAction<UnassignedShipments>,
    ) => {
      const { total, items, pages, page } = action.payload;

      if (state.unassignedPageNumber !== page) {
        state.unassignedPageNumber = page;
      }

      if (total && total !== state.unassignedTotal && items.length) {
        const templateArray = new Array(total).fill(null);
        templateArray.splice(
          (page - 1) * UNASSIGNED_PAGE_SIZE,
          items.length,
          ...items,
        );
        state.unassignedShipments = templateArray;
      } else if (total === state.unassignedTotal && items.length) {
        state.unassignedShipments.splice(
          (page - 1) * UNASSIGNED_PAGE_SIZE,
          items.length,
          ...items,
        );
      }

      setUnassignedPageNumberToUrl(page.toFixed());
      state.unassignedPages = pages;
      state.unassignedTotal = total;
    },

    removeSelectedOrderId: (state) => {
      state.selectedOrderId = null;
      removeSelectedOrderIdFromUrl();
    },

    removeJustSelectedOrderId: (state) => {
      state.selectedOrderId = null;
    },
    clearUnassigned: (state) => {
      state.unassignedPageNumber = 1;
      state.unassignedPages = 0;
      state.unassignedTotal = 0;
      state.unassignedShipments = [];
      state.viewedUnassignedPages = [1];
      removeUnassignedPageNumberFromUrl();
    },
  },
});

export const {
  setSelectedOrderId,
  removeSelectedOrderId,
  setUnassignedShipment,
  setUnassignedPageNumber,
  setUnassignedPageNumberByShipmentId,
  clearUnassigned,
  setSearchedUnassignedShipmentId,
  setAllRoutesSelectedOrder,
  removeJustSelectedOrderId,
  setJustSelectedOrderId,
} = orderSlice.actions;

export const orderReducer = orderSlice.reducer;
