import React, { FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import { ReactComponent as ShowDetails } from 'assets/icons/disable_detail_icon.svg';
import { ReactComponent as DisabledDetails } from 'assets/icons/show_detail_icon.svg';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  setDetailedMapPoints,
  setMapSettings,
} from 'store/reducers/mapSettingsSlice';
import { getDetailedMapPointsSelector } from 'store/selectors/mapSettings';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

interface EditButtonProps {
  orderId?: string;
  disabled?: boolean;
  shipmentId?: string;
}
export const DetailedMapPointButton: FC<EditButtonProps> = React.memo(() => {
  const dispatch = useAppDispatch();
  const detailedMapPoints = useAppSelector(getDetailedMapPointsSelector);

  const handleClick = useCallback(() => {
    if (detailedMapPoints) {
      dispatch(
        setMapSettings({
          showPOD: false,
          showETA: false,
        }),
      );
    }
    dispatch(setDetailedMapPoints(!detailedMapPoints));
  }, [detailedMapPoints]);

  return (
    <LightTooltip
      title={
        detailedMapPoints
          ? t`Hide detailed map points`
          : t`Show detailed map points`
      }
      arrow
      placement="top"
    >
      <span>
        <IconButton sx={styles.button} onClick={handleClick}>
          {!detailedMapPoints && <ShowDetails />}
          {detailedMapPoints && <DisabledDetails />}
        </IconButton>
      </span>
    </LightTooltip>
  );
});
