import { FC, useMemo, useState } from 'react';
import { Box, List } from '@mui/material';
import { t } from 'ttag';

import { DeviceInfoReport } from 'components/screens/AdminHomeScreen/components/DeviceInfoReport';
import { Feedback } from 'components/screens/AdminHomeScreen/components/Feedback';
import { SettingsChapter } from 'components/screens/EditSettingsScreen/components/SettingsChapter';

import { styles } from './styles';

const menuOptions = [t`Feedback`, t`Device info`];

export const AllCompaniesReports: FC = () => {
  const [currentMenuItem, setCurrentMenuItem] = useState(0);

  const menuList = useMemo(
    () =>
      menuOptions.map((option, index) => (
        <SettingsChapter
          key={`${option} ${index}`}
          isActive={currentMenuItem === index}
          label={option}
          onPress={() => setCurrentMenuItem(index)}
        />
      )),
    [currentMenuItem],
  );

  const content = useMemo(() => {
    if (currentMenuItem === 1) {
      return <DeviceInfoReport />;
    }

    if (currentMenuItem === 0) {
      return <Feedback />;
    }
  }, [currentMenuItem]);

  return (
    <Box sx={styles.mainContentWrapper}>
      <List sx={styles.list}>{menuList}</List>
      <Box sx={styles.contentBlock}>{content}</Box>
    </Box>
  );
};
