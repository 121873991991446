import React, { FC, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as AscOrder } from 'assets/icons/asc_order_icon.svg';
import { ReactComponent as DescOrder } from 'assets/icons/desc_order_icon.svg';
import { ReactComponent as UnOrder } from 'assets/icons/unorder_icon.svg';
import { t } from 'ttag';
import {
  DedicatedChatHeaderItem,
  HeaderFieldNames,
  headerItemAlignMapper,
  headerItemSizeMapper,
  sortedFields,
  SortOrder,
} from 'types/dedicatedChat';

import { DedicatedChatFilterButton } from 'components/ui/DedicatedChatFilterButton';

import { styles } from './styles';

interface DedicatedChatHeaderProps {
  onOrderClick: (field: HeaderFieldNames) => void;
  orderedItems: Partial<Record<HeaderFieldNames, SortOrder>>;
}

export const DedicatedChatHeader: FC<DedicatedChatHeaderProps> = (props) => {
  const { onOrderClick, orderedItems } = props;

  const contentItems = useMemo(() => {
    return [
      <DedicatedChatFilterButton key="filter" />,
      <Typography sx={styles.title} component="h1" key="resource">
        {t`Resource`}
      </Typography>,
      <Typography sx={styles.title} component="h1" key="shift">
        {t`Shift`}
      </Typography>,
      <Typography sx={styles.title} component="h1" key="chat">
        {t`Chat`}
      </Typography>,
      <Typography sx={styles.title} component="h1" key="time">
        {t`Time`}
      </Typography>,
      <Typography sx={styles.title} component="h1" key="message">
        {t`Message`}
      </Typography>,
      <Typography sx={styles.title} component="h1" key="status">
        {t`Chat status`}
      </Typography>,
    ];
  }, []);

  const headerItems: DedicatedChatHeaderItem[] = useMemo(() => {
    return contentItems.map((content) => {
      const isOrdered = sortedFields.includes(content.key as HeaderFieldNames);
      const fieldName = content.key as HeaderFieldNames;

      return {
        content,
        fieldName,
        isOrdered,
        itemSize: headerItemSizeMapper[fieldName],
        itemAlign: headerItemAlignMapper[fieldName],
        order: isOrdered ? (orderedItems[fieldName] as SortOrder) : null,
        onClick: isOrdered
          ? () => {
              onOrderClick(fieldName);
            }
          : undefined,
      };
    });
  }, [contentItems, onOrderClick, orderedItems]);

  const renderHeader = useMemo(() => {
    return headerItems.map((item) => {
      const {
        content,
        isOrdered,
        itemSize,
        itemAlign,
        order,
        onClick,
        fieldName,
      } = item;
      return (
        <Grid
          key={`Header Item ${fieldName}`}
          item
          mobile={itemSize}
          sx={{
            display: 'flex',
            justifyContent: itemAlign,
            cursor: isOrdered ? 'pointer' : 'default',
            width: '100%',
            userSelect: 'none',
          }}
          onClick={onClick}
        >
          {order === 'asc' && <AscOrder />}
          {order === 'desc' && <DescOrder />}
          {isOrdered && order === null && <UnOrder />}
          {content}
        </Grid>
      );
    });
  }, [headerItems]);

  return (
    <Grid container columns={52} spacing={1} sx={styles.container}>
      {renderHeader}
    </Grid>
  );
};
