import { FC } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { t } from 'ttag';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';
import { Switch } from 'components/ui/Switch';

import { ChangeScreenFormProps } from '../../types';

import { styles } from './styles';

export const DeliveryDataTabDetailsSection: FC<ChangeScreenFormProps> = () => {
  const { formik } = useChangeScreenContext();

  const { values, handleChange } = formik;
  const { terminalDeliveryType, terminalPickType } = useEditScreenErrandTypes();

  const getContent = () => (
    <Grid container columns={1} spacing={2} rowGap={4}>
      {terminalDeliveryType ? (
        <Grid item mobile={1}>
          <Field
            component={FieldInput}
            name="deliveryCageCode"
            label={t`Cage code`}
            placeholder={t`Enter cage code`}
            fullWidth
            // required
            autoComplete="new-password"
          />
        </Grid>
      ) : (
        <>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="deliveryEnterCode"
              label={t`Door code`}
              placeholder={t`Enter door code`}
              fullWidth
              autoComplete="new-password"
            />
          </Grid>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="deliveryIntercomCode"
              label={t`Intercom code`}
              placeholder={t`Enter intercom code`}
              fullWidth
              autoComplete="new-password"
            />
          </Grid>
          <Grid item mobile={1}>
            <Field
              component={FieldInput}
              name="deliveryFloorNumber"
              label={t`Floor number`}
              placeholder={t`Enter floor number`}
              fullWidth
              autoComplete="new-password"
            />
          </Grid>
          <Grid
            container
            columns={1}
            spacing={2}
            rowGap={1.5}
            sx={{ ml: '8px' }}
          >
            <Grid item mobile={1}>
              <Switch
                name="deliveryElevator"
                label={t`The house has an elevator`}
                value={!!values.deliveryElevator}
                checked={!!values.deliveryElevator}
                onChange={handleChange}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="deliveryKnockOnTheDoor"
                label={t`Knock on the door`}
                value={!!values.deliveryKnockOnTheDoor}
                checked={!!values.deliveryKnockOnTheDoor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="deliveryUseDoorBell"
                label={t`Use doorbell`}
                value={!!values.deliveryUseDoorBell}
                checked={!!values.deliveryUseDoorBell}
                onChange={handleChange}
              />
            </Grid>
            <Grid item mobile={1} sx={styles.switchGridItem}>
              <Switch
                name="deliveryQuietDelivery"
                label={t`Quiet delivery`}
                value={!!values.deliveryQuietDelivery}
                checked={!!values.deliveryQuietDelivery}
                onChange={handleChange}
              />
            </Grid>

            {terminalPickType && (
              <Grid item mobile={1} sx={styles.switchGridItem}>
                <Switch
                  name="deliveryPackagingReturn"
                  label={t`Packaging return`}
                  value={!!values.deliveryPackagingReturn}
                  checked={!!values.deliveryPackagingReturn}
                  onChange={handleChange}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Grid item mobile={1} sx={styles.switchGridItem}>
        <Field
          component={FieldInput}
          name="deliveryInstruction"
          label={t`Delivery instruction`}
          placeholder={t`Enter instruction`}
          multiline
          maxRows={10}
          fullWidth
        />
      </Grid>
    </Grid>
  );

  return (
    <ChangeScreenSection label={t`Details`}>{getContent()}</ChangeScreenSection>
  );
};
