import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useDriverByResourceIdQuery } from 'store/api/apiSlice';
import { t } from 'ttag';

interface MapTooltipProps {
  resourceId?: string;
}

export const MapTooltip: FC<MapTooltipProps> = (props: MapTooltipProps) => {
  const { resourceId } = props;
  const { data: driver } = useDriverByResourceIdQuery(resourceId as string, {
    skip: !resourceId,
  });

  const firstName = driver?.first_name || '';
  const lastName = driver?.last_name || '';

  return (
    <>
      <Typography
        variant="caption"
        color="custom.graphite"
        fontSize={14}
        fontWeight={400}
      >
        {t`Driver name: `}
      </Typography>
      <br />
      <Typography
        variant="caption"
        color="custom.black"
        fontSize={14}
        fontWeight={600}
      >
        {`${firstName} ${lastName}`}
      </Typography>
      <br />
      <Typography
        variant="caption"
        color="custom.graphite"
        fontSize={14}
        fontWeight={400}
      >
        {t`Driver phone number: `}
      </Typography>
      <br />

      <Typography
        variant="caption"
        color="custom.black"
        fontSize={14}
        fontWeight={600}
      >
        {driver?.phone}
      </Typography>
    </>
  );
};
