import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    height: '100%',
    p: 0,
    // paddingTop: '200px',
    overflowY: 'scroll',
    borderRadius: '6px',
  },
  filtersBlock: {
    // position: 'absolute',
    width: '100%',
    height: '250px',
    padding: '16px',
    zIndex: 100,
    // bgcolor: 'white',
  },

  autocomplete: {
    bgcolor: 'custom.white',
  },

  input: {
    bgcolor: 'custom.white',
    '& .MuiInputBase-input': {
      // margin: 0,
      paddingLeft: 0,
    },
  },

  button: {
    width: '100%',
  },
};
