import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box } from '@mui/material';

import { GroupsSection } from 'components/screens/EditSettingsScreen/components/GroupsSection';
import { ResourcesGroupSection } from 'components/screens/EditSettingsScreen/components/ResourcesGroupSection';

import { styles } from './styles';

const ResourceGroupsTab = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={styles.container}>
        <ResourcesGroupSection />
        <GroupsSection />
      </Box>
    </DndProvider>
  );
};

export { ResourceGroupsTab };
