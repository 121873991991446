import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MapBox, {
  Layer,
  LayerProps,
  MapRef,
  Marker,
  Popup,
  Source,
} from 'react-map-gl';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import arrowImage from 'assets/images/resourceIconArrow.png';
import { MAP_COMPONENT_TYPE, UNASSIGNED_TASKS } from 'constants/common';
import { useHasChanged } from 'hooks/useHasChanged';
import { AllRouteMapPoint, useMapAllRouteData } from 'hooks/useMapAllRouteData';
import { useResourceRouteData } from 'hooks/useResourceRouteData';
import {
  useAllRoutesMapPointDetailsMutation,
  useDriverByResourceIdQuery,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getDetailedMapPointsSelector,
  getMapSettingsDateSelector,
  getMapSettingsSelector,
} from 'store/selectors/mapSettings';
import {
  getAllRoutesSelectedOrder,
  getSelectedOrderIdSelector,
} from 'store/selectors/order';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getDetailsUnderOrdersSelector,
  getShowDetailSectionSelector,
} from 'store/selectors/settings';
import { Coordinates, Resource } from 'types/resource';
import { getFontColorForBackground } from 'utils/colorsUtils';
import LngLatBounds from 'utils/lngLtdBounds';
import { pointStyle } from 'utils/mapPointUtils';
import { formatDateStringToHHmm } from 'utils/timeUtils';
import { createUniqueId } from 'utils/uniqIdUtils';

import { AllRoutesMapPointInfo } from 'components/common/Map/components/AllRoutesMapPointInfo';
import { AllRoutesMapTooltip } from 'components/common/Map/components/AllRoutesMapTooltip';

import { styles } from './styles';

import './MapForAllResources.css';

interface MapComponentProps {
  type?: MAP_COMPONENT_TYPE;
  resources?: Resource[];
}

// eslint-disable-next-line react/display-name
export const MapForAllResources: FC<MapComponentProps> = React.memo(
  (props: MapComponentProps) => {
    const { type = MAP_COMPONENT_TYPE.resources, resources = [] } = props;
    const mapRef = useRef<MapRef>(null);
    const timeOutInitial = useRef<NodeJS.Timeout>();
    const timeOutByDataChange = useRef<NodeJS.Timeout>();
    const [showPopup, setShowPopup] = useState(false);
    const [coords, setCoords] = useState<Coordinates | []>([]);
    const [pointClick, setPointClick] = useState<AllRouteMapPoint | null>(null);
    const [getMapPointDetails, { data: mapPointDetailResult }] =
      useAllRoutesMapPointDetailsMutation();
    const [resourceClick, setResourceClick] = useState(false);
    const showDetailSection = useAppSelector(getShowDetailSectionSelector);
    const { allRoutesData } = useMapAllRouteData();
    const [localSelectedResource, setSelectedResourceId] = useState<
      string | undefined
    >();
    const allRoutesSelectedOrder = useAppSelector(getAllRoutesSelectedOrder);
    const settingsDate = useAppSelector(getMapSettingsDateSelector);
    const commonDate = useAppSelector(getCommonDateSelector);
    const detailedMapPoints = useAppSelector(getDetailedMapPointsSelector);

    const { data: driver } = useDriverByResourceIdQuery(
      localSelectedResource as string,
      {
        skip: !localSelectedResource,
      },
    );
    const { mapPoints } = useResourceRouteData();
    const hasMapPointsChanged = useHasChanged(allRoutesData);
    const hasResourcesChanged = useHasChanged(resources);
    const currentOrder = useAppSelector(getSelectedOrderIdSelector);
    const detailsUnderOrdersStatus = useAppSelector(
      getDetailsUnderOrdersSelector,
    );
    const { showAllRoutes, showETA, showPOD, showResources } = useAppSelector(
      getMapSettingsSelector,
    );
    const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);

    const coordinates = useMemo(() => {
      const resourceCoords = resources.map((resource) => resource.coordinates);
      const pointsCoords = allRoutesData.reduce(
        (accum: Coordinates[], curr) => {
          curr.mapPoints.forEach((point) => accum.push(point.coords));
          return accum;
        },
        [],
      );

      return [...resourceCoords, ...pointsCoords];
    }, [resources, allRoutesData]);

    useEffect(() => {
      setShowPopup(false);
    }, [settingsDate, commonDate]);

    const resourcesMarkers = useMemo(() => {
      if (!resources.length) {
        return null;
      }

      return resources.map((resource, idx) => (
        <Marker
          key={`Vehicle} ${idx}`}
          onClick={() => {
            if (resource?.coordinates) {
              setCoords(resource.coordinates);
            }
            setPointClick(null);
            setResourceClick(true);
            setSelectedResourceId(resource.id);
            setShowPopup(true);
          }}
          longitude={(resource?.coordinates && resource.coordinates[0]) || 0}
          latitude={(resource?.coordinates && resource.coordinates[1]) || 0}
        >
          <Grid
            sx={{
              ...styles.markerWrapper,
              transform: `rotate(${resource?.details?.dir || 0}deg)`,
            }}
          >
            <img src={arrowImage} alt="Source Arrow" width={20} />
            <Box sx={styles.resourceCircle} />
            <Typography
              variant="caption"
              color="common.white"
              fontSize={12}
              fontWeight={600}
              letterSpacing={1.1}
              textAlign="center"
              sx={{
                ...styles.resourceNumber,
                transform: `rotate(-${resource?.details?.dir || 0}deg)`,
              }}
            >
              {resource.number}
            </Typography>
          </Grid>
        </Marker>
      ));
    }, [resources, resources.length]);

    const poperStyle = (point: AllRouteMapPoint): SxProps => {
      switch (point.status) {
        case 'fail':
          return {
            ...styles.poper,
            bgcolor: 'custom.red3',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-8px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '14px solid transparent',
              borderRight: '14px solid transparent',
              borderBottom: '14px solid ',
              borderBottomColor: 'custom.red3',
            },
          };
        case 'done':
          return {
            ...styles.poper,
            bgcolor: 'custom.green4',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-8px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '14px solid transparent',
              borderRight: '14px solid transparent',
              borderBottom: '14px solid ',
              borderBottomColor: 'custom.green4',
            },
          };
        case 'pending':
        default:
          return styles.poper;
      }
    };

    const handleMapPointClick = useCallback(
      (point: AllRouteMapPoint) => {
        if (showPopup) {
          setShowPopup(false);
        }
        setResourceClick(false);
        setPointClick(point);
        setCoords(point.coords);
        setShowPopup(true);

        getMapPointDetails({
          work_shift_id: point.workshiftId,
          errands_ids: point.errandIds,
        });
      },
      [showDetailSection, showPopup, getMapPointDetails],
    );

    const renderMapPoints = useCallback(
      (mapPoints: AllRouteMapPoint[]) => {
        if (!mapPoints.length) {
          return null;
        }

        return mapPoints.map((point) => {
          const showPoper =
            (point.status === 'done' ||
              point.status === 'fail' ||
              point.status === 'pending') &&
            ((point.eta && showETA) || (point.pod && showPOD));

          return (
            <Marker
              key={createUniqueId()}
              longitude={point.coords[0]}
              latitude={point.coords[1]}
            >
              <Grid
                sx={{
                  ...pointStyle(point.status),
                  transform: `scale(${
                    point?.errandIds?.some(
                      (e) => e === allRoutesSelectedOrder?.orderId,
                    )
                      ? '1.5'
                      : '1'
                  })`,
                }}
                onClick={() => handleMapPointClick(point)}
              >
                {showPoper && (
                  <Box sx={poperStyle(point)}>
                    {point.eta && point.eta !== 'None' && showETA && (
                      <Typography sx={styles.podEtaTitle}>
                        ETA {formatDateStringToHHmm(point.eta)}
                      </Typography>
                    )}
                    {point.pod && showPOD && (
                      <Typography sx={styles.podEtaTitle}>
                        POD {formatDateStringToHHmm(point.pod)}
                      </Typography>
                    )}
                  </Box>
                )}
                <Typography
                  variant="caption"
                  color="inherit"
                  fontSize={12}
                  fontWeight={600}
                  letterSpacing={1.1}
                  textAlign="center"
                >
                  {point?.order || 0}
                </Typography>
              </Grid>
            </Marker>
          );
        });
      },
      [showETA, showPOD, allRoutesSelectedOrder, handleMapPointClick],
    );

    const allRoutesRender = useMemo(() => {
      if (!allRoutesData.length) {
        return null;
      }

      return allRoutesData.map((route) => {
        return renderMapPoints(route.mapPoints);
      });
    }, [allRoutesData, renderMapPoints]);

    const linesRender = useMemo(() => {
      if (!allRoutesData.length) {
        return null;
      }

      const geojson = {
        type: 'FeatureCollection',
        features: allRoutesData.map((route) => ({
          type: 'Feature',
          properties: {
            color: route.color, // Ensure each line has a color property
          },
          geometry: {
            type: 'LineString',
            coordinates: route.linePoints,
          },
        })),
      };

      const layerStyle: LayerProps = {
        id: 'route-line-id',
        type: 'line',
        paint: {
          'line-width': 3,
          'line-color': ['get', 'color'], // Use the 'color' property from each feature for line-color
        },
      };

      return (
        <Source id="my-data" type="geojson" data={geojson as any}>
          <Layer {...layerStyle} />
        </Source>
      );
    }, [allRoutesData]);

    const mapPointsForGeoJSON = useMemo(() => {
      return allRoutesData.flatMap((route) => {
        return route.mapPoints.map((point, index) => {
          return {
            type: 'Feature',
            properties: {
              color: route.color, // Use the same color as the line or define new one
              fontColor: getFontColorForBackground(route.color),
              number: `${index + 1}`,
            },
            geometry: {
              type: 'Point',
              coordinates: point.coords,
            },
          };
        });
      });
    }, [allRoutesData]);

    const renderOnlyPoints = useMemo(() => {
      if (!allRoutesData.length) {
        return null;
      }

      const geojsonPoints = {
        type: 'FeatureCollection',
        features: mapPointsForGeoJSON,
      };

      const pointLayerStyle: LayerProps = {
        id: 'route-point-id',
        type: 'circle',
        paint: {
          'circle-radius': 8,
          'circle-color': ['get', 'color'],
        },
      };

      return (
        <Source id="my-points-data" type="geojson" data={geojsonPoints as any}>
          <Layer {...pointLayerStyle} />
        </Source>
      );
    }, [mapPointsForGeoJSON]);

    const renderOnlyNumbers = useMemo(() => {
      if (!allRoutesData.length) {
        return null;
      }

      const geojsonNumbers = {
        type: 'FeatureCollection',
        features: mapPointsForGeoJSON,
      };

      const numberLayerStyle: LayerProps = {
        id: 'route-number-id',
        type: 'symbol',
        source: 'points',
        layout: {
          'text-field': ['get', 'number'], // Access the 'number' property in the properties of each feature
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-anchor': 'center',
          'text-size': 10,
        },
        paint: {
          'text-color': ['get', 'fontColor'],
        },
      };

      return (
        <Source
          id="my-numbers-data"
          type="geojson"
          data={geojsonNumbers as any}
        >
          <Layer {...numberLayerStyle} />
        </Source>
      );
    }, [mapPointsForGeoJSON]);

    const fitBoundsMap = useCallback(() => {
      // @ts-ignore
      let preparedCoords = coordinates?.map(([lng = 0, lat = 0]) => {
        if (!lng && !lat) {
          return [18.0707, 59.324];
        }

        return [lng, lat];
      });

      if (type === MAP_COMPONENT_TYPE.shipments) {
        // preparedCoords = linePoints;
        preparedCoords = mapPoints.map(
          (m) => [m.coords?.[0] || 18.0707, m.coords[1]] || 59.324,
        );
      }

      const bounds = preparedCoords.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new LngLatBounds(preparedCoords[0], preparedCoords[0]));

      console.log(bounds);

      if (bounds && mapRef.current) {
        mapRef.current?.setPadding({
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        });
        mapRef.current?.fitBounds(
          [
            bounds?._ne?.lng || 18.0707,
            bounds?._ne?.lat || 59.324,
            bounds?._sw?.lng || 18.0708,
            bounds?._sw?.lat || 59.325,
          ],
          {
            maxZoom: 12,
          },
        );
      }
    }, [coordinates, mapPoints, type]);

    useEffect(() => {
      if (resources.length || allRoutesData.length) {
        timeOutInitial.current = setTimeout(() => fitBoundsMap(), 500);
      }
    }, [resources.length || allRoutesData.length]);

    useEffect(() => {
      if (!hasResourcesChanged || !resources.length) return;

      if (!hasMapPointsChanged || !mapPoints.length) return;

      timeOutByDataChange.current = setTimeout(() => fitBoundsMap(), 500);
    }, [
      hasMapPointsChanged,
      mapPoints,
      hasResourcesChanged,
      resources,
      fitBoundsMap,
    ]);

    useEffect(
      () => () => {
        timeOutInitial.current && clearTimeout(timeOutInitial.current);
        timeOutByDataChange.current &&
          clearTimeout(timeOutByDataChange.current);
      },
      [],
    );

    useEffect(() => {
      mapRef.current?.resize();
    }, [
      currentOrder,
      detailsUnderOrdersStatus,
      selectedResourceId,
      showDetailSection,
    ]);

    return (
      <MapBox
        // mapLib={import('mapbox-gl')}
        ref={mapRef}
        initialViewState={{
          longitude: 18.070768,
          latitude: 59.324806,
          zoom: 12,
        }}
        style={{ width: '100%', position: 'relative' }}
        mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
      >
        {selectedResourceId !== UNASSIGNED_TASKS && (
          <>
            {showResources && resourcesMarkers}
            {showAllRoutes && detailedMapPoints && allRoutesRender}
            {showAllRoutes && linesRender}
            {showAllRoutes && !detailedMapPoints && renderOnlyPoints}
            {showAllRoutes && !detailedMapPoints && renderOnlyNumbers}
          </>
        )}
        {showPopup && (
          <Popup
            closeOnClick={false}
            key={createUniqueId()}
            longitude={coords[0] || 0}
            latitude={coords[1] || 0}
            anchor="bottom"
            offset={14}
            closeButton={false}
            onClose={() => setShowPopup(false)}
            style={{ maxWidth: '403px' }}
            className={pointClick ? 'map-point-details' : ''}
          >
            <>
              {pointClick && (
                <AllRoutesMapPointInfo
                  point={mapPointDetailResult}
                  closePopup={() => setShowPopup(false)}
                />
              )}
              {resourceClick && localSelectedResource && (
                <AllRoutesMapTooltip
                  driver={driver}
                  onClose={() => setShowPopup(false)}
                />
              )}
            </>
          </Popup>
        )}
      </MapBox>
    );
  },
);
