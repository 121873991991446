import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  label: {
    fontSize: '10px',
  },
  inner: {
    p: '20px',
    pb: '40px',
  },
};
