import React, { FC, useCallback, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { debounce, sortBy } from 'lodash';
import {
  useGetAddressByStreetAndCityNamesQuery,
  useGetStreetsGroupedByCityQuery,
} from 'store/api/apiSlice';
import { t } from 'ttag';

import { ChangeScreenFormProps } from '../ChangeScreenForms/types';

export interface AddressSearchProps extends ChangeScreenFormProps {
  type: 'pick' | 'delivery';
}

export const AddressSearch: FC<AddressSearchProps> = ({ type }) => {
  const { formik } = useChangeScreenContext();

  const { handleChange, errors, setValues, values } = formik;

  const [inputValue, setInputValue] = useState('');
  const [streetCityValue, setStreetCityValue] = useState<any>(null);
  const [addressValue, setAddressValue] = useState<any>(null);
  const [streetName, setStreetName] = useState(streetCityValue?.street);

  const value = useMemo(() => {
    if (addressValue) {
      return [streetCityValue, addressValue];
    } else if (streetCityValue) {
      return [streetCityValue];
    }
    return [];
  }, [streetCityValue, addressValue]);

  const { street, city } = streetCityValue || {};

  const { data: addressOptions } = useGetAddressByStreetAndCityNamesQuery(
    { street, city },
    {
      skip: !streetName || !city,
    },
  );
  const { data: streetOptions } = useGetStreetsGroupedByCityQuery(streetName, {
    skip: !streetName || city,
  });

  const options = useMemo(() => {
    if (addressValue) {
      return [];
    }
    return sortBy(city ? addressOptions : streetOptions, ['street', 'city']);
  }, [addressOptions, streetOptions, city, addressValue]);

  const getPlaceholder = () => {
    if (addressValue) {
      return '';
    }
    return city ? t`Enter street number` : t`Enter street name`;
  };

  const autocompleteChangeHandler = (_event: any, newValue: any[]) => {
    const { city, street } = newValue[0] || {};
    const newAddressValue = newValue[1] || {};
    const streetNumber = newAddressValue.street_number;

    if (city && street) {
      setStreetCityValue({ street, city });
    } else {
      setStreetCityValue(null);
      setStreetName('');
    }

    if (streetNumber) {
      setAddressValue({ streetNumber });
      const modifiedValues: any = {};
      (modifiedValues[`${type}AddressId`] = newAddressValue.id),
        (modifiedValues[`${type}City`] = newAddressValue.city),
        (modifiedValues[`${type}Country`] = newAddressValue.country),
        (modifiedValues[`${type}Latitude`] = newAddressValue.latitude),
        (modifiedValues[`${type}Longitude`] = newAddressValue.longitude),
        (modifiedValues[`${type}PostalCode`] = newAddressValue.postal_code),
        (modifiedValues[`${type}State`] = newAddressValue.state),
        (modifiedValues[`${type}Street`] = newAddressValue.street),
        (modifiedValues[`${type}StreetNumber`] = newAddressValue.street_number),
        setValues({
          ...values,
          ...modifiedValues,
        });
    } else {
      setAddressValue(null);
    }
  };

  const searchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      const { value: newValue } = event.target;

      if (!streetCityValue) {
        setStreetName(newValue);
      }
    },
    [streetCityValue],
  );

  const debouncedSearchHandler = useMemo(
    () => debounce(searchHandler, 100),
    [searchHandler],
  );

  return (
    <Autocomplete
      multiple
      options={options || []}
      value={value}
      onChange={autocompleteChangeHandler}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(value.length === 2 ? '' : newInputValue);
      }}
      getOptionLabel={(option: any) => {
        if (option?.street_number) {
          return option.street_number;
        } else if (option?.streetNumber) {
          return option.streetNumber;
        } else if (option?.street && option.city) {
          return option?.street_number || `${option.street}, ${option.city}`;
        }
        return option;
      }}
      disablePortal
      fullWidth
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={t`Search`}
          name="search"
          placeholder={getPlaceholder()}
          error={!!errors.pickSearch}
          helperText={errors.pickSearch}
          onChange={debouncedSearchHandler}
          // InputLabelProps={{
          //   shrink: true,
          // }}
        />
      )}
    />
  );
};
