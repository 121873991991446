import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSelectedWorkshiftIdFromUrl,
  removeSelectedWorkshiftIdFromUrl,
  setSelectedWorkshiftIdToUrl,
} from 'store/helpers/workshiftHelpers';

interface WorkshiftState {
  selectedWorkshiftId: string | null;
  selectedWorkshiftDate: string | null;
}

const initialState: WorkshiftState = {
  selectedWorkshiftId: getSelectedWorkshiftIdFromUrl || null,
  selectedWorkshiftDate: null,
};

export const workshiftSlice = createSlice({
  name: 'workshift',
  initialState,
  reducers: {
    setWorkshiftId: (state, action: PayloadAction<string>) => {
      if (state.selectedWorkshiftId !== action.payload) {
        state.selectedWorkshiftId = action.payload;
        setSelectedWorkshiftIdToUrl(action.payload);
      }
    },

    setJustWorkshiftId: (state, action: PayloadAction<string | null>) => {
      state.selectedWorkshiftId = action.payload;
    },
    setWorkshiftDate: (state, action: PayloadAction<string>) => {
      if (state.selectedWorkshiftDate !== action.payload) {
        state.selectedWorkshiftDate = action.payload;
      }
    },
    removeSelectedWorkshiftId: (state) => {
      state.selectedWorkshiftId = null;
      state.selectedWorkshiftDate = null;
      removeSelectedWorkshiftIdFromUrl();
    },
    removeJustSelectedWorkshiftId: (state) => {
      state.selectedWorkshiftId = null;
      state.selectedWorkshiftDate = null;
    },
  },
});

export const {
  setWorkshiftId,
  setWorkshiftDate,
  removeSelectedWorkshiftId,
  removeJustSelectedWorkshiftId,
  setJustWorkshiftId,
} = workshiftSlice.actions;

export const workshiftReducer = workshiftSlice.reducer;
