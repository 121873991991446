import { FC } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

import { styles } from './styles';

interface ResourcesSectionSkeletonProps {
  quantity?: number;
}

export const ResourcesSectionSkeleton: FC<ResourcesSectionSkeletonProps> = ({
  quantity,
}) => {
  const content = Array.from(Array(quantity)).map((_e, index) => (
    <Box sx={styles.container} key={index}>
      <Skeleton variant="circular" width={25} height={25} />
      <Skeleton variant="text" sx={styles.skeletonText} />
    </Box>
  ));
  return <Stack sx={styles.stack}>{content}</Stack>;
};
