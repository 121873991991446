import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  repliesContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 0 5px 0',
  },
  reply: {
    '&:not(:last-of-type)': {
      mr: '5px',
    },
  },

  borderedReply: {
    borderRadius: '6px',
    borderColor: 'custom.greyBlue',
    bgcolor: 'custom.greyLightBlue',
  },
};
