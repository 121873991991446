import React, { FC } from 'react';
import { ListItem, SxProps, Typography } from '@mui/material';

import { styles } from './styles';

interface SettingsChapterProps {
  isActive: boolean;
  label: string;
  onPress: () => void;
}

export const SettingsChapter: FC<SettingsChapterProps> = (props) => {
  const { isActive, label, onPress } = props;

  const activeItemStyles = isActive
    ? ({ ...styles.listItem, ...styles.listItemActive } as SxProps)
    : styles.listItem;

  return (
    <ListItem sx={activeItemStyles}>
      <Typography component="a" sx={styles.link} onClick={onPress}>
        <Typography component="span" variant="body2" sx={styles.linkTitle}>
          {label}
        </Typography>
      </Typography>
    </ListItem>
  );
};
