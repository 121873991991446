import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    minWidth: '313px',
    maxWidth: '423px',
    ml: '20px',
    flexGrow: 1,
  },

  halfContainer: {
    width: '423px',
    ml: '20px',
    flexGrow: 1,
    height: '50%',
    pt: '20px',
  },

  inner: {
    p: '16px',
    overflowY: 'scroll',
    bgcolor: 'custom.greyLightBlue',
  },
};
