import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { styles } from './styles';

interface InnerSectionTextItemProps {
  label: string | number;
  value: string | number;
}

export const InnerSectionTextItem: FC<InnerSectionTextItemProps> = ({
  label,
  value,
}) => {
  return (
    <Grid container columns={10} sx={styles.container}>
      <Grid mobile={6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid mobile={4}>
        <Typography sx={styles.valueText}>{value}</Typography>
      </Grid>
    </Grid>
  );
};
