import { FC } from 'react';
import { List } from '@mui/material';
import { t } from 'ttag';
import { OrderPackage } from 'types/orders';

import { InnerSection } from 'components/ui/InnerSection';

import { DetailsSectionInnerParcelDataItem } from '../DetailsSectionInnerParcelDataItem';

import { styles } from './styles';

interface DetailsSectionInnerParcelDataProps {
  parcels: OrderPackage[];
}

export const DetailsSectionInnerParcelData: FC<
  DetailsSectionInnerParcelDataProps
> = ({ parcels }) => {
  const content = parcels.map((parcel, idx) => {
    return (
      <DetailsSectionInnerParcelDataItem
        key={parcel.id}
        number={idx + 1}
        {...parcel}
      />
    );
  });
  return (
    <InnerSection label={t`Parcel data`} sx={styles.container}>
      <List sx={styles.list}>{content}</List>
    </InnerSection>
  );
};
