import { FC } from 'react';
import { Box } from '@mui/material';
import { t } from 'ttag';
import { OrderDeliveryData, OrderPackage } from 'types/orders';

import { InnerSectionTextItem } from 'components/common/InnerSectionTextItem';
import { InnerSection } from 'components/ui/InnerSection';

import { styles } from './styles';

interface DetailsSectionInnerDeliveryDataProps extends OrderDeliveryData {
  packages: OrderPackage[];
}

interface ReduceReturnType {
  totalWeight: number;
  totalVolume: number;
}

export const DetailsSectionInnerDeliveryData: FC<
  DetailsSectionInnerDeliveryDataProps
> = ({
  packages,
  goods_type: goodsType,
  general_parcel_type: generalParcelType,
  goods_marking: goodsMarking,
  pallet_location: palletLocation,
}) => {
  const numberOfPackages = packages?.length || 0;
  const { totalWeight, totalVolume } = packages
    ? packages.reduce<ReduceReturnType>(
        (acc, current) => {
          const {
            width = 0,
            height = 0,
            length = 0,
            weight = 0,
          } = current.data;
          const volume = Number(width) * Number(height) * Number(length);
          return {
            totalWeight: +(acc?.totalWeight + Number(weight)).toFixed(3),
            totalVolume: +(acc?.totalVolume + volume).toFixed(3),
          };
        },
        { totalWeight: 0, totalVolume: 0 } as ReduceReturnType,
      )
    : { totalWeight: 0, totalVolume: 0 };

  const goodsTypeItem = goodsType && (
    <InnerSectionTextItem label={t`Goods type`} value={goodsType} />
  );
  const generalParcelTypeItem = generalParcelType && (
    <InnerSectionTextItem
      label={t`General parcel type`}
      value={generalParcelType}
    />
  );
  const goodsMarkingItem = goodsMarking && (
    <InnerSectionTextItem label={t`Goods marking`} value={goodsMarking} />
  );
  const palletLocationItem = palletLocation && (
    <InnerSectionTextItem label={t`Pallet location`} value={palletLocation} />
  );
  const numberOfPackagesItem = (
    <InnerSectionTextItem
      label={t`Number of packages`}
      value={numberOfPackages}
    />
  );
  const totalWeightItem = !!totalWeight && (
    <InnerSectionTextItem
      label={t`Total weight`}
      value={t`${totalWeight} kg`}
    />
  );
  const totalVolumeItem = !!totalVolume && (
    <Box sx={styles.innerContainer}>
      <InnerSectionTextItem
        label={t`Total volume`}
        value={t`${totalVolume} m${'\u{000B3}'}`}
      />
    </Box>
  );

  const isTotalSectionExist = Boolean(totalWeightItem && totalVolumeItem);

  return (
    <InnerSection label={t`Delivery data`} sx={styles.container}>
      <Box sx={styles.innerContainer}>
        {goodsTypeItem}
        {generalParcelTypeItem}
        {goodsMarkingItem}
        {numberOfPackagesItem}
      </Box>
      {isTotalSectionExist && (
        <Box sx={styles.innerContainer}>
          {totalWeightItem}
          {totalVolumeItem}
        </Box>
      )}
      {palletLocationItem}
    </InnerSection>
  );
};
