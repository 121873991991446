import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, List, Typography } from '@mui/material';
import { GROUP_SECTION_SCREEN } from 'constants/common';
import { GroupSettingContext } from 'context/GroupSetting/GroupSettingProvider';
import {
  useAddResourcesToGroupMutation,
  useGetGroupByIdQuery,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  addItemCurrentGroupDetailResources,
  setToast,
  updateCurrentGroupDetailResources,
} from 'store/reducers/settingsSlice';
import { getCurrentGroupDetailResources } from 'store/selectors/settings';
import { t } from 'ttag';
import { ResourceForSettings, WrappedErrorApi } from 'types/api';

import { DroppableGroupDetailResourceItem } from 'components/screens/EditSettingsScreen/components/DroppableGroupDetailResourceItem';
import { EmptyDnDList } from 'components/screens/EditSettingsScreen/components/EmtyDnDList';

import { styles } from './styles';

const GroupDetail: FC = () => {
  const dispatch = useAppDispatch();
  const currentGroupDetailResources = useAppSelector(
    getCurrentGroupDetailResources,
  );

  // @ts-ignore
  const { currentGroupId, setCurrentGroupId, setCurrentScreen } =
    useContext(GroupSettingContext);
  // @ts-ignore
  const { data } = useGetGroupByIdQuery(currentGroupId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      if (data?.resources?.length) {
        dispatch(updateCurrentGroupDetailResources(data.resources));
      } else {
        dispatch(updateCurrentGroupDetailResources([]));
      }
    }
  }, [data]);

  const [addResourceMutation] = useAddResourcesToGroupMutation();

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'resource',
      drop: (item) => {
        addResourceToGroup({ id: item.id, resourceName: item.number });
      },
      canDrop: (item: ResourceForSettings) => {
        const founded = data?.resources.find(
          ({ resource_id }) => resource_id === item.id,
        );

        if (founded) {
          return false;
        }

        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [data?.resources],
  );

  const addResourceToGroup = useCallback(
    async ({ id, resourceName }: { id: string; resourceName: string }) => {
      if (!data?.id) {
        return;
      }

      dispatch(
        addItemCurrentGroupDetailResources({
          resource_id: id,
          number: resourceName,
        }),
      );
      const res = await addResourceMutation({
        groupId: data.id,
        resourcesIds: [id],
      });

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              t`Something wrong when adding resource!`,
            severity: 'error',
          }),
        );
      }
    },
    [data, addResourceMutation],
  );

  const content = useMemo(() => {
    // if (isFetching) {
    //   return <ResourcesSectionSkeleton quantity={10} />;
    // }

    if (!currentGroupDetailResources?.length) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <EmptyDnDList />
        </Box>
        // <Typography sx={styles.noResources}>
        //   {t`There are no resources.`}
        // </Typography>
      );
    }

    return currentGroupDetailResources.map((resource) => {
      return (
        <DroppableGroupDetailResourceItem
          key={resource.resource_id}
          group={data}
          resource={resource}
        />
      );
    });
  }, [currentGroupDetailResources, data]);

  const handleHeaderClick = useCallback(() => {
    setCurrentGroupId(null);
    setCurrentScreen(GROUP_SECTION_SCREEN.allGroups);
  }, []);

  const backgroundStyle = useMemo(() => {
    const isActive = canDrop && isOver;
    let bc = {};

    if (isActive) {
      bc = { backgroundColor: 'custom.green2' };
    } else if (canDrop) {
      bc = { backgroundColor: 'custom.green3' };
    }

    return bc;
  }, [canDrop, isOver]);

  return (
    <>
      <Box sx={styles.headerWrapper}>
        <Box sx={styles.headerBlock}>
          <Box sx={styles.pressableBlock} onClick={handleHeaderClick}>
            <ArrowBackIcon />
            <Typography sx={styles.headerName}>{data?.title}</Typography>
          </Box>
        </Box>
        <Box sx={styles.headerDescriptionBlock}>
          <Typography variant="h5" sx={{ color: 'custom.black' }}>
            {t`Description`}
          </Typography>
          <Typography sx={styles.descriptionContent}>
            {data?.description}
          </Typography>
        </Box>
      </Box>

      <List ref={drop} sx={{ ...styles.list, ...backgroundStyle }}>
        {content}
      </List>
    </>
  );
};

export { GroupDetail };
