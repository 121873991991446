import { FC } from 'react';
import { Box, Icon, IconProps, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import { OrderStatuses, OrderTypes } from 'types/orders';

import { styles } from './styles';

interface OrdersSectionListItemIconProps {
  status: OrderStatuses;
  type: OrderTypes;
  label: string | number | null;
  isNew: boolean;
  isGrouped: boolean;
  isCurrent: boolean | undefined;
}

interface CollapsibleIconProps extends IconProps {
  label: string | number | null;
}

interface TextIconProps {
  label: string | number | null;
}

const CollapsibleIcon: FC<CollapsibleIconProps> = ({ label, ...rest }) => {
  return <Icon {...rest}>{label}</Icon>;
};

const TextIcon: FC<TextIconProps> = ({ label }) => (
  <Typography sx={styles.iconTextContent}>{label}</Typography>
);

export const OrdersSectionListItemIcon: FC<OrdersSectionListItemIconProps> = ({
  status,
  type,
  label,
  isGrouped,
  isCurrent,
}) => {
  const iconLabel = label || '?';
  const getIconData = (status: OrderStatuses, current: boolean | undefined) => {
    switch (status) {
      case OrderStatuses.DONE:
        return {
          color: 'custom.green',
          children: <TextIcon label={iconLabel} />,
          // children: <Done sx={styles.iconSvgContent} />,
        };
      case OrderStatuses.FAILED:
        return {
          color: 'custom.red',
          children: <TextIcon label={iconLabel} />,
          // children: <Close sx={styles.iconSvgContent} />,
        };
      default:
        if (current) {
          return {
            color: 'primary.main',
            children: <TextIcon label={iconLabel} />,
          };
        }
        return {
          color: 'custom.greyLight',
          children: <TextIcon label={iconLabel} />,
        };
    }
  };

  const { color: iconColor, children } = getIconData(status, isCurrent);

  const iconComponent = isGrouped ? (
    <CollapsibleIcon
      sx={{ ...styles.collapsibleIcon, bgcolor: iconColor }}
      label={iconLabel}
    />
  ) : (
    <Icon sx={{ ...styles.icon, bgcolor: iconColor }}>{children}</Icon>
  );

  return (
    <Box sx={{ ...styles.iconContainer, color: iconColor }}>
      {type === OrderTypes.PICK && (
        <SvgIcon sx={styles.leftTriangle} component={TriangleIcon} />
      )}
      {iconComponent}
      {type === OrderTypes.DROP && (
        <SvgIcon sx={styles.rightTriangle} component={TriangleIcon} />
      )}
    </Box>
  );
};
