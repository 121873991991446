import { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, List, ListItem, SxProps } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { removeJustSelectedOrderId } from 'store/reducers/orderSlice';
import { removeJustSelectedResourceId } from 'store/reducers/resourceSlice';
import { removeJustCurrentCommonDate } from 'store/reducers/settingsSlice';
import { removeJustSelectedWorkshiftId } from 'store/reducers/workshiftSlice';
import { t } from 'ttag';

import { styles } from './styles';

const links = [
  { route: APP_ROUTES.root, label: t`Resources` },
  { route: APP_ROUTES.dedicatedChat, label: t`Dedicated chat` },
];

export const HeaderNavigation: FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getLinkStyles = useCallback(
    (route: string): SxProps =>
      pathname === route
        ? ({ ...styles.link, ...styles.active } as SxProps)
        : styles.link,
    [pathname],
  );

  const handleItemClick = useCallback(
    (route: APP_ROUTES) => {
      dispatch(removeJustSelectedResourceId());
      dispatch(removeJustSelectedOrderId());
      dispatch(removeJustSelectedWorkshiftId());
      dispatch(removeJustCurrentCommonDate());
      navigate(route);
    },
    [navigate],
  );

  return (
    <List sx={styles.list}>
      {links.map((link) => (
        <ListItem
          sx={styles.listItem}
          key={link.route}
          onClick={() => handleItemClick(link.route)}
        >
          {/* <QueryParamsLink withoutSaving sx={getLinkStyles(link.route)} route={link.route}> */}
          <Link sx={getLinkStyles(link.route)}>{link.label}</Link>
          {/* </QueryParamsLink> */}
        </ListItem>
      ))}
    </List>
  );
};
