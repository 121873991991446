import { createSelector } from '@reduxjs/toolkit';
import { createTypedSelector } from 'store/utils';

export const getDetailsUnderOrdersSelector = createTypedSelector(
  (state) => state.settings.detailsUnderOrders,
);

export const getCommonDateSelector = createTypedSelector(
  (state) => state.settings.currentCommonDate,
);

export const getOnlyCurrentWorkshiftSelector = createTypedSelector(
  (state) => state.settings.isOnlyCurrentWorkshift,
);

export const getShowDetailSectionSelector = createTypedSelector(
  (state) => state.settings.showDetailSection,
);

export const getToastInfo = createTypedSelector(
  (state) => state.settings.toast,
);

export const getIsPreviousScreenChat = createTypedSelector(
  (state) => state.settings.isPreviousScreenChat,
);

export const getIsDedicatedChatScreen = createTypedSelector(
  (state) => state.settings.isDedicatedChatScreen,
);

export const getIsMapSectionActive = createTypedSelector(
  (state) => state.settings.isMapSectionActive,
);

export const getIsCalcOptimization = createTypedSelector(
  (state) => state.settings.isCalcOptimization,
);

export const getIsShowDoneFailShipments = createTypedSelector(
  (state) => state.settings.isShowDoneFailShipments,
);

export const getTotalResourcesSettings = createTypedSelector(
  (state) => state.settings.totalResourcesSettings,
);

export const getCurrentGroupDetailResources = createTypedSelector(
  (state) => state.settings.currentGroupDetailResources,
);

export const getResourcesForSettings = createTypedSelector((state) => {
  return state.settings.resourcesForSettings;
});

export const getShowResourcesSettingsFilter = createTypedSelector(
  (state) => state.settings.showResourcesSettingsFilter,
);

export const getSearchResourcesForSettingsByName = createTypedSelector(
  (state) => state.settings.searchResourcesForSettingsByName,
);

export const getSearchGroupsForSettingsByName = createTypedSelector(
  (state) => state.settings.searchGroupsForSettingsByName,
);

export const getTotalGRItems = createTypedSelector(
  (state) => state.settings.totalGRItems,
);

export const getTotalGRPages = createTypedSelector(
  (state) => state.settings.totalGRPages,
);

export const getCurrentGTPage = createTypedSelector(
  (state) => state.settings.currentGTPage,
);

export const getGroupDetailResources = createTypedSelector(
  (state) => state.settings.groupDetailResources,
);

export const getResourcesSettingsGroups = createTypedSelector(
  (state) => state.settings.resourcesSettingsGroups,
);

export const getResourcesSettingsFilters = createTypedSelector(
  (state) => state.settings.resourcesSettingsFilters,
);

export const getResourcesSettingsFiltersAndGroups = createTypedSelector(
  (state) => state.settings.resourcesSettingsFiltersAndGroups,
);

export const getResourcesWithBlockedTimeGapButton = createTypedSelector(
  (state) => state.settings.resourcesWithBlockedTimeGapButton,
);

export const getTimeGapButtonStatusByResourceId = (
  resourceId: string | null = null,
) =>
  createSelector(getResourcesWithBlockedTimeGapButton, (resource) => {
    if (!resourceId) {
      return false;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (resource.hasOwnProperty(resourceId)) {
      return resource[resourceId];
    }

    return false;
  });
