import { FC } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { t } from 'ttag';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { LightTooltip } from 'components/ui/LightTooltip';

import { styles } from './styles';

export const OpenSettingsButton: FC = () => {
  return (
    <QueryParamsLink route={APP_ROUTES.editSettings}>
      <LightTooltip title={t`Resource settings`} arrow placement="top">
        <span>
          <IconButton sx={styles.button}>
            <SettingsOutlinedIcon sx={styles.icon} />
          </IconButton>
        </span>
      </LightTooltip>
    </QueryParamsLink>
  );
};
