import React, { FC, useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material';
import { ReactComponent as CarIcon } from 'assets/icons/car_without_color.svg';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import { ERRAND_SERVICE_OPTIONS } from 'constants/editOrderOptions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import {
  removeSelectedOrderId,
  setAllRoutesSelectedOrder,
} from 'store/reducers/orderSlice';
import { setSelectedResourceId } from 'store/reducers/resourceSlice';
import { updateShowDetailSection } from 'store/reducers/settingsSlice';
import { setWorkshiftId } from 'store/reducers/workshiftSlice';
import { t } from 'ttag';
import {
  AllRoutesMapPointDetailsApi,
  AllRoutesMapPointErrand,
  ErrandProofTypes,
} from 'types/api';
import { AllRoutesSelectedOrder, OrderTime, OrderTypes } from 'types/orders';
import { RESOURCE_COLOR } from 'types/resource';
import { setPointStatusToOrderStatus } from 'utils/mapPointUtils';
import { resourceColorsMappedToMui } from 'utils/resourceUtils';
import {
  dateStringToNumber,
  formatDateStringToHHmm,
  twoDateStringsDifference,
} from 'utils/timeUtils';

import { Time } from 'components/common/Time';
import { reasonsMapper } from 'components/screens/HomeScreen/components/DetailsSectionInnerFail/DetailsSectionInnerFail';
import { OrdersSectionListItemIcon } from 'components/screens/HomeScreen/components/OrdersSectionListItemIcon';

import { styles } from './styles';

interface MapPointIfoProps {
  point?: AllRoutesMapPointDetailsApi;
  closePopup: () => void;
}

export interface AllRoutesErrandForRender extends AllRoutesMapPointErrand {
  workshiftId: string;
  resourceId: string;
  isTerminal: boolean;
  terminal_id: string | null;
  terminal_name: string | null;
}
export const AllRoutesMapPointInfo: FC<MapPointIfoProps> = React.memo(
  (props) => {
    const { point, closePopup } = props;

    const prepearedErrands: AllRoutesErrandForRender[] = useMemo(() => {
      if (!point || !point?.errands || !point?.errands.length) {
        return [];
      }

      return point.errands.map((errand) => ({
        ...errand,
        workshiftId: point.work_shift_id,
        resourceId: point.resource_id,
        isTerminal: point.is_terminal,
        terminal_id:
          point?.terminal_id && point?.terminal_id !== 'None'
            ? point?.terminal_id
            : null,
        terminal_name: point?.terminal_name ? point?.terminal_name : null,
      }));
    }, []);
    // const { popupSubtitle, popupTitle, popupData } = point;
    const dispatch = useAppDispatch();

    const handleGoPress = useCallback(() => {
      if (!point) {
        return;
      }
      dispatch(setSelectedResourceId(point.resource_id));
      dispatch(setWorkshiftId(point.work_shift_id));
      dispatch(removeSelectedOrderId());
      dispatch(setAllRoutesSelectedOrder(null));
      dispatch(updateShowDetailSection(true));
    }, [point]);

    const handleClick = useCallback(
      (order: AllRoutesErrandForRender) => {
        closePopup();
        const orderData: AllRoutesSelectedOrder = {
          orderId: order.id,
          workshiftId: order.workshiftId,
          resourceId: order.resourceId,
          terminalId:
            order.service === 'Terminal pickup' ? order.terminal_id : null,
        };
        dispatch(setAllRoutesSelectedOrder(orderData));
        dispatch(updateShowDetailSection(true));
      },
      [closePopup, dispatch],
    );

    const errandsList = useMemo(() => {
      return prepearedErrands.map((errand, idx) => {
        const date = new Date();

        let difference = errand?.eta
          ? twoDateStringsDifference(errand?.latest_date as string, errand?.eta)
          : dateStringToNumber(date.toString());

        if (errand.status === 'done' || errand.status === 'fail') {
          difference = errand?.latest_date
            ? twoDateStringsDifference(
                errand?.latest_date,
                errand?.pod_time as string,
              )
            : dateStringToNumber(errand?.pod_time as string);
        }
        const time: OrderTime = {
          difference,
          eta: errand?.eta ? formatDateStringToHHmm(errand?.eta) : null,
          latest: errand?.latest_date
            ? new Date(errand.latest_date)
            : undefined,
          earliest: errand.earliest_date
            ? new Date(errand.earliest_date)
            : undefined,
          fullEta: errand?.eta,
          pod: formatDateStringToHHmm(errand?.pod_time),
        };

        return (
          <React.Fragment key={errand.id}>
            <Box sx={styles.divider24} />
            <ListItem
              sx={styles.errandItemBlock}
              key={errand.id}
              onClick={() => handleClick(errand)}
              // ref={itemRef as any}
            >
              <OrdersSectionListItemIcon
                status={setPointStatusToOrderStatus(errand.status)}
                label=" "
                isGrouped={false}
                type={
                  errand.type === 'pickup' ? OrderTypes.PICK : OrderTypes.DROP
                }
                isNew={false}
                isCurrent={false}
              />

              <Grid container columns={14} sx={styles.errandBlockContent}>
                <Grid item mobile={8} alignSelf="center">
                  {errand?.title && !errand?.terminal_id && (
                    <Typography sx={{ overflowWrap: 'break-word' }}>
                      {
                        ERRAND_SERVICE_OPTIONS.find(
                          (option) => option.value === errand?.title,
                        )?.label
                      }
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    {errand?.terminal_name ||
                      errand?.name ||
                      errand?.company_name}
                  </Typography>
                  {errand?.proof_type && (
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'custom.red' }}
                    >
                      {reasonsMapper[errand.proof_type as ErrandProofTypes]}
                    </Typography>
                  )}

                  {/* <Typography variant="h3">{order.address.title}</Typography> */}
                  {/* <Typography sx={styles.addressSubtitle}> */}
                  {/*   {order.address.subtitle} */}
                  {/* </Typography> */}
                  {/* {orderHint} */}
                </Grid>

                <Grid item mobile={1} alignSelf="center" pl={1}>
                  {errand.tempered_transport && (
                    <img src={temperedGoodsImg} alt="Tempered goods" />
                  )}
                </Grid>

                <Grid item mobile={3} alignSelf="center">
                  <Time
                    time={time}
                    // isOnlyPod={!!order?.data?.isManualCreated}
                  />
                </Grid>

                {/* <Grid item mobile={2} alignSelf="center" textAlign="center" pl={1}> */}
                {/*   {(!!unreadMessages || isAnyUnreadMessages) && ( */}
                {/*     <QueryParamsLink sx={{ zIndex: 100 }} onClick={clickHandler}> */}
                {/*       <UnreadLabel qty={isAnyUnreadMessages ? 0 : unreadMessages} /> */}
                {/*     </QueryParamsLink> */}
                {/*   )} */}
                {/* </Grid> */}
                {/* {order.isEditable && !selectedWorkshiftId && ( */}
                {/*   <Box sx={styles.editButton}> */}
                {/*     <EditButton orderId={order.id} shipmentId={order.shipmentId} /> */}
                {/*   </Box> */}
                {/* )} */}
                {/* {order.isEditable && selectedWorkshiftId && ( */}
                {/*   <Box sx={styles.moveButton}> */}
                {/*     <MoveToCurrentWorkshiftButton */}
                {/*       workshiftId={selectedWorkshiftId} */}
                {/*       shipmentId={order.shipmentId} */}
                {/*       resourceId={selectedWorkshiftId} */}
                {/*     /> */}
                {/*   </Box> */}
                {/* )} */}
                {/* TODO: Not for now */}
                {/* <Grid item mobile={1} sx={styles.reorderButton}>
          <ReorderIcon />
        </Grid> */}
              </Grid>
            </ListItem>

            {prepearedErrands.length === idx + 1 && <Box sx={styles.divider} />}
          </React.Fragment>
        );
      });
    }, [prepearedErrands, handleClick]);

    if (!point) return null;

    return (
      <Box sx={styles.wrapper}>
        <Box sx={styles.header}>
          <Box sx={styles.resourceInfo}>
            <CarIcon
              fill={
                resourceColorsMappedToMui[
                  point?.resource_color as RESOURCE_COLOR
                ]
              }
              style={{ width: '20px', height: '20px' }}
            />
            <Typography ml="16px" variant="h5">
              {point?.resource_number}
            </Typography>
          </Box>
          <IconButton onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container columns={12} sx={styles.locationBlock}>
          <Grid item mobile={2} sx={styles.locationItem}>
            <LocationIcon sx={styles.locationIcon} />
          </Grid>
          <Grid item mobile={10} sx={styles.locationItem}>
            <Typography variant="h3" marginBottom="6px">
              {`${point?.address?.street}, ${point?.address?.street_number}`}
            </Typography>
            <Typography>{`${point?.address?.postal_code}, ${point?.address?.city}`}</Typography>
          </Grid>
        </Grid>
        <Box sx={styles.ordersList}>{errandsList}</Box>
        <Box sx={styles.buttonsBlock}>
          <Button
            onClick={handleGoPress}
            sx={styles.button}
            variant="contained"
          >
            {t`Go to resource`}
          </Button>
        </Box>
      </Box>
    );
  },
);
