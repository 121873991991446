import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  list: { p: 0 },

  item: {
    p: 0,
    pt: '4px',
    '&:first-of-type': {
      pt: 0,
    },
  },

  itemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  itemValue: {
    textAlign: 'right',
    color: 'custom.green',
    textTransform: 'none',
  },
};
