import { FC, useCallback } from 'react';
import { useAddCompanyMutation } from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { WrappedErrorApi } from 'types/api';

import { UpdateCompanyScreenForms } from 'components/common/UpdateCompanyScreenForms';

export const CreateCompanyScreen: FC = () => {
  const [addCompany, { isLoading }] = useAddCompanyMutation();
  const dispatch = useAppDispatch();

  const onCreate = useCallback(
    async (options: {
      name: string;
      city: string;
      country: string;
      email?: string;
    }): Promise<boolean> => {
      const res = await addCompany(options);

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              t`Something wrong in Add Company!`,
            severity: 'error',
          }),
        );
        return false;
      }

      dispatch(
        setToast({
          message: t`You have successfully saved company`,
          severity: 'success',
        }),
      );

      return true;
    },
    [addCompany],
  );

  return (
    <UpdateCompanyScreenForms
      edit={false}
      loading={isLoading}
      onCreate={onCreate}
    />
  );
};
