import { createBrowserHistory } from 'history';
import { isArray, isEmpty } from 'lodash';

export const updateHistory = (searchParams: URLSearchParams) => {
  const browserHistory = createBrowserHistory();
  const url = searchParams.toString();
  browserHistory.push({
    search: `${url}`,
  });
};

export const getValueFromUrl = (queryParameter: string) => {
  const browserHistory = createBrowserHistory();
  const { search } = browserHistory.location;
  const searchParams = new URLSearchParams(search);

  return searchParams.get(queryParameter);
};

export const setValueToUrl = (queryParameter: string, value: string) => {
  const browserHistory = createBrowserHistory();
  const { search } = browserHistory.location;
  const searchParams = new URLSearchParams(search);

  searchParams.set(queryParameter, value);

  const url = searchParams.toString();
  browserHistory.push({
    search: `${url}`,
  });
};

export const removeValueFromUrl = (queryParameter: string) => {
  const browserHistory = createBrowserHistory();
  const { search } = browserHistory.location;
  const searchParams = new URLSearchParams(search);

  searchParams.delete(queryParameter);

  const url = searchParams.toString();
  browserHistory.push({
    search: `${url}`,
  });
};

type SearchParamsObject = Record<
  string,
  string | string[] | undefined | number
>;

const isEmptyArray = (arr: any) => isArray(arr) && !arr.length;

export const formSearchParamsStr = (searchParams: SearchParamsObject) => {
  const searchParamsWithoutEmptyFields = Object.entries(searchParams).reduce(
    (accum, [key, value]) => {
      if (!value || isEmptyArray(value)) {
        return accum;
      }

      return { ...accum, [key]: value };
    },
    {},
  );

  if (isEmpty(searchParamsWithoutEmptyFields)) {
    return '';
  }

  const queryString = Object.keys(searchParamsWithoutEmptyFields)
    .map((key) => {
      if (
        typeof searchParamsWithoutEmptyFields[key] === 'string' ||
        typeof searchParamsWithoutEmptyFields[key] === 'number'
      ) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          searchParamsWithoutEmptyFields[key] as string,
        )}`;
      }

      return (searchParamsWithoutEmptyFields[key] as string[])
        .map(
          (value: string) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        )
        .join('&');
    })
    .join('&');

  return `${queryString}`;
};
