import { createTypedSelector } from 'store/utils';

export const getSelectedCompanyIdSelector = createTypedSelector(
  (state) => state.company.selectedCompanyId,
);

export const getShowFiltersSelector = createTypedSelector(
  (state) => state.company.showFilters,
);

export const getSearchFilterSelector = createTypedSelector(
  (state) => state.company.searchFilter,
);

export const getCityFilterSelector = createTypedSelector(
  (state) => state.company.cityFilter,
);

export const getCountryFilterSelector = createTypedSelector(
  (state) => state.company.countryFilter,
);

export const getIsAnyFilterFilled = createTypedSelector(
  (state) =>
    !!state.company.searchFilter ||
    !!state.company.countryFilter ||
    !!state.company.cityFilter,
);
