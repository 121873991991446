import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

interface MapSettingsState {
  showResources: boolean;
  showAllRoutes: boolean;
  showHistoricalPath: boolean;
  showETA: boolean;
  showPOD: boolean;
  settingsDate: string;
  allRoutesResourcesFilters: string[];
  allRoutesResourcesGroups: string[];
  allRoutesResourcesGroupsAndFilters: string[];
  detailedMapPoints: boolean;
  driverHistoricalPath: boolean;
  lastMapCenter: {
    latitude: number;
    longitude: number;
  };
  showUnreadInMap: boolean;
}

const initialState: MapSettingsState = {
  showResources: true,
  showAllRoutes: true,
  showHistoricalPath: true,
  showETA: false,
  showPOD: false,
  settingsDate: format(Date.now(), 'yyyy-MM-dd'),
  allRoutesResourcesFilters: [],
  allRoutesResourcesGroups: [],
  allRoutesResourcesGroupsAndFilters: [],
  detailedMapPoints: false,
  driverHistoricalPath: false,
  lastMapCenter: {
    longitude: 18.070768,
    latitude: 59.324806,
  },
  showUnreadInMap: true,
};

export type KeyOfMapSettingsState = keyof Pick<
  MapSettingsState,
  | 'showResources'
  | 'showAllRoutes'
  | 'showETA'
  | 'showPOD'
  | 'showHistoricalPath'
>;
export type MapSettings = Partial<Record<KeyOfMapSettingsState, boolean>>;

export const mapSettingsSlice = createSlice({
  name: 'mapSettings',
  initialState,
  reducers: {
    setMapSettings: (state, action: PayloadAction<MapSettings>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key as KeyOfMapSettingsState] = value;
      });
    },
    setSettingsDate: (state, action: PayloadAction<string>) => {
      state.settingsDate = action.payload;
    },
    setAllRoutesResourcesFilters: (state, action: PayloadAction<string[]>) => {
      state.allRoutesResourcesFilters = action.payload;
    },
    setAllRoutesResourcesGroups: (state, action: PayloadAction<string[]>) => {
      state.allRoutesResourcesGroups = action.payload;
    },
    setDetailedMapPoints: (state, action: PayloadAction<boolean>) => {
      state.detailedMapPoints = action.payload;
    },
    setDriverHistoricalPath: (state, action: PayloadAction<boolean>) => {
      state.driverHistoricalPath = action.payload;
    },
    setAllRoutesResourcesGroupsAndFilters: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.allRoutesResourcesGroupsAndFilters = action.payload;
    },
    removeAllRoutesResourcesGroupsAndFilters: (state) => {
      state.allRoutesResourcesFilters = [];
      state.allRoutesResourcesGroups = [];
      state.allRoutesResourcesGroupsAndFilters = [];
    },
    updateLastMapCenter: (
      state,
      action: PayloadAction<{
        latitude: number;
        longitude: number;
      }>,
    ) => {
      state.lastMapCenter = action.payload;
    },
    updateShowUnreadInMap: (state, action: PayloadAction<boolean>) => {
      state.showUnreadInMap = action.payload;
    },
  },
});

export const {
  setSettingsDate,
  setMapSettings,
  setAllRoutesResourcesFilters,
  setAllRoutesResourcesGroups,
  setAllRoutesResourcesGroupsAndFilters,
  removeAllRoutesResourcesGroupsAndFilters,
  setDetailedMapPoints,
  setDriverHistoricalPath,
  updateLastMapCenter,
  updateShowUnreadInMap,
} = mapSettingsSlice.actions;

export const mapSettingsReducer = mapSettingsSlice.reducer;
