import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES, UNASSIGNED_TASKS } from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { useSelectedOrder } from 'hooks/useSelectedOrder';
import { useSelectedResource } from 'hooks/useSelectedResource';
import { useSelectedShipment } from 'hooks/useSelectedShipment';
import { omit } from 'lodash';
import {
  useCreateBookingMutation,
  useCreateErrandMutation,
  useCreateShipmentMutation,
  useShipmentsByResourceIdQuery,
  useUpdatePackagesMutation,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setToast } from 'store/reducers/settingsSlice';
import { getSelectedWorkshiftIdSelector } from 'store/selectors/workshift';
import { t } from 'ttag';
import { BookingPackages, ShipmentStatuses } from 'types/api';
import { ChangeScreenFormOnSubmitFunctionParams } from 'types/changeScreenTypes';
import { setNewShipmentStatus } from 'utils/orderUtils';

import { ChangeScreenForms } from 'components/common/ChangeScreenForms';

import { LoadingScreen } from '../LoadingScreen';

export const CreateScreen: FC = () => {
  const location = useLocation();
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);

  const { selectedShipment, isFetching: isShipmentFetching } =
    useSelectedShipment(location.state?.orderId);
  const { selectedResource, isFetching } = useSelectedResource();
  const { selectedOrder, isFetching: isOrderFetching } = useSelectedOrder(
    location.state?.orderId,
  );
  const { refetch } = useShipmentsByResourceIdQuery(
    {
      resourceId: selectedResource?.id as string,
      workshiftId: selectedWorkshiftId,
    },
    {
      skip:
        !selectedResource ||
        !selectedResource.id ||
        selectedResource.id === UNASSIGNED_TASKS,
    },
  );

  const [createPickErrand, { data: createdPickErrand }] =
    useCreateErrandMutation();
  const [createDeliveryErrand, { data: createdDeliveryErrand }] =
    useCreateErrandMutation();
  const [createBooking, { data: createdBooking }] = useCreateBookingMutation();
  const [createPackages] = useUpdatePackagesMutation();
  const [createShipment] = useCreateShipmentMutation();

  const [packages, setPackages] = useState<BookingPackages>();
  const [shipmentStatus, setShipmentStatus] = useState<ShipmentStatuses>();
  const [workShiftId, setWorkShiftId] = useState<string>();

  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const dispatch = useDispatch();

  const submitFormHandler = ({
    changedPickErrand,
    changedDeliveryErrand,
    changedBooking,
    changedPackages,
    changedShipmentStatus,
    changedWorkShiftId,
  }: ChangeScreenFormOnSubmitFunctionParams) => {
    createPickErrand(changedPickErrand);
    createDeliveryErrand(changedDeliveryErrand);
    createBooking({ ...changedBooking });
    setShipmentStatus(changedShipmentStatus);
    setWorkShiftId(changedWorkShiftId);
    setPackages(changedPackages);
  };

  const createShipmentHandler = useCallback(async () => {
    if (
      createdPickErrand &&
      createdDeliveryErrand &&
      createdBooking &&
      shipmentStatus &&
      workShiftId
    ) {
      await createShipment({
        pickup_errand_id: createdPickErrand.id,
        delivery_errand_id: createdDeliveryErrand.id,
        booking_id: createdBooking.id,
        status: setNewShipmentStatus(shipmentStatus) as ShipmentStatuses,
        work_shift_id:
          shipmentStatus === ShipmentStatuses.UNASSIGNED ? null : workShiftId,
      });

      if (packages) {
        const preparedPackages = packages.map((p) =>
          omit(p, ['id', 'booking_id']),
        );

        await createPackages({
          packages: preparedPackages,
          bookingId: createdBooking.id,
        });
      }

      dispatch(
        setToast({
          message: t`You have successfully edited the order`,
          severity: 'success',
        }),
      );

      await refetch();

      navigateWithQueryParams(APP_ROUTES.root);
    }
  }, [
    createdPickErrand,
    createdDeliveryErrand,
    createdBooking,
    shipmentStatus,
    workShiftId,
    createShipment,
  ]);

  useEffect(() => {
    createShipmentHandler();
  }, [createShipmentHandler]);

  if (isFetching || isOrderFetching || isShipmentFetching) {
    return <LoadingScreen />;
  }

  return (
    <ChangeScreenForms
      label={t`Create screen`}
      resource={selectedResource}
      shipment={location.state?.orderId && selectedShipment && selectedShipment}
      order={location.state?.orderId && selectedOrder && selectedOrder}
      onSubmit={submitFormHandler}
      isCreateScreen
      createScreenState={location.state ? location.state : undefined}
    />
  );
};
