import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '16px',
  },

  gridContainer: {
    justifyContent: 'space-between',
  },

  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  icon: {
    color: 'primary.main',
  },

  phone: {
    mt: '4px',
  },

  iconButton: {
    width: '40px',
    height: '40px',
    border: '1px solid',
    borderColor: 'custom.greyLight',
  },
};
