import { createSelector } from '@reduxjs/toolkit';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';

import { createTypedSelector } from '../utils';

export const getAllChatMessages = createTypedSelector(
  (state) => state.chat.chatList,
);

export const calculateUnreadMessagesForShipment = (
  shipmentId: string | null = null,
) =>
  createSelector(getAllChatMessages, (messages) =>
    messages?.reduce((accum, curr) => {
      if (
        curr?.message?.sender === 'driver' &&
        !curr?.read &&
        curr?.message?.shipment_id === shipmentId
      ) {
        return accum + 1;
      }

      return accum;
    }, 0),
  );

export const getUnreadMessagesList = createTypedSelector(
  (state) => state.chat.unreadMessages,
);

export const getUnreadMessagesByResourceId = (resourceId?: string) =>
  createSelector(
    getUnreadMessagesList,
    (unreadMessages) =>
      unreadMessages?.find((message) => message.resource_id === resourceId)
        ?.count,
  );

export const getUnreadMessagesByResourceIdV2 = (resourceId?: string) =>
  createSelector(getUnreadMessagesList, (unreadMessages) =>
    unreadMessages?.filter((message) => message.resource_id === resourceId),
  );

export const getUnreadMessagesByWorkshiftId = (workshiftId: string | null) =>
  createSelector(
    getUnreadMessagesList,
    (unreadMessages) =>
      unreadMessages?.find((message) => message.work_shift_id === workshiftId)
        ?.count,
  );

export const unreadMessagesSelector = createTypedSelector((state) => {
  return state.chat.unreadMessages;
});

export const isAnyUnreadMessageSelector = createSelector(
  [unreadMessagesSelector, getOnlyCurrentWorkshiftSelector],
  (unreads, isOnlyCurrentWorkshift) => {
    if (isOnlyCurrentWorkshift) {
      return unreads.some((m) => !!m.count && m.is_current_work_shift);
    }

    return unreads.some((m) => !!m.count);
  },
);

export const isAnyUnreadInGroup = (shipmentIds: string[]) =>
  createSelector(
    getAllChatMessages,
    (messages) =>
      !!messages?.some(
        ({ message, read }) =>
          shipmentIds.includes(message?.shipment_id) &&
          message?.sender === 'driver' &&
          !read,
      ),
  );

export const getDedicatedChatResourcesFilters = createTypedSelector(
  (state) => state.chat.dedicatedChatResourcesFilters,
);

export const getDedicatedChatResourcesGroups = createTypedSelector(
  (state) => state.chat.dedicatedChatResourcesGroups,
);

export const getDedicatedChatFiltersAndGroups = createTypedSelector(
  (state) => state.chat.dedicatedChatResourcesFiltersAndGroups,
);

export const getDedicatedChatPriorityFilters = createTypedSelector((state) => {
  const priorities = state.chat.dedicatedChatPriorityFilters;

  if (!priorities || !priorities.length) {
    return [];
  }

  return priorities.map((priority) => String(priority));
});

export const getIsAnyDedicatedChatFilterSetSelector = createTypedSelector(
  (state) =>
    !!state.chat.dedicatedChatResourcesGroups.length ||
    !!state.chat.dedicatedChatResourcesFilters.length ||
    !!state.chat.dedicatedChatPriorityFilters.length,
);

export const getDedicatedChatDateSelector = createTypedSelector(
  (state) => state.chat.dedicatedChatDate,
);
