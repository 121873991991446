import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    display: 'flex',
    height: '98%',
    width: '424px',
    position: 'relative',
  },
  detailsUnderOrdersBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};
