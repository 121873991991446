import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    height: '100%',
    p: 0,
    mt: '-1px',
    overflowY: 'scroll',
    borderRadius: '6px',
  },
};
