import { createTypedSelector } from 'store/utils';

export const getFeedbackOrderBy = createTypedSelector(
  (state) => state.feedback.orderBy,
);

export const getFeedbackOrder = createTypedSelector(
  (state) => state.feedback.order,
);
export const getFeedbackPage = createTypedSelector(
  (state) => state.feedback.page,
);
export const getFeedbackSize = createTypedSelector(
  (state) => state.feedback.size,
);
export const getCurrentAudioId = createTypedSelector(
  (state) => state.feedback.currentAudioId,
);
export const getFeedbackResourcesFilters = createTypedSelector(
  (state) => state.feedback.feedbackResourcesFilters,
);
export const getFeedbackDriversFilterss = createTypedSelector(
  (state) => state.feedback.feedbackDriversFilters,
);
export const getFeedbackVersionsFilters = createTypedSelector(
  (state) => state.feedback.feedbackVersionsFilters,
);
export const getFeedbackDevicesFilters = createTypedSelector(
  (state) => state.feedback.feedbackDevicesFilters,
);
export const getFeedbackStatusesFilters = createTypedSelector(
  (state) => state.feedback.feedbackStatusesFilters,
);
export const getFeedbackStartDate = createTypedSelector(
  (state) => state.feedback.feedbackStartDate,
);
export const getfeedbackEndDate = createTypedSelector(
  (state) => state.feedback.feedbackEndDate,
);
