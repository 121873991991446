import { FC, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
} from '@mui/material';

import { styles } from './styles';

export type DropdownMenuOptionValue = string | number;

interface DropdownMenuProps {
  selectedOptionValue?: DropdownMenuOptionValue;
  options: { value: DropdownMenuOptionValue; label: string }[];
  onSelect: (value: DropdownMenuOptionValue) => void;
  iconButtonContent?: React.ReactNode;
  textButtonContent?: string;
  menuStyles?: SxProps;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  selectedOptionValue,
  options,
  onSelect,
  iconButtonContent,
  textButtonContent,
  menuStyles,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const menuOpenHandler = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const menuCloseHandler = (event: any) => {
    setAnchorEl(null);
    const { value } = event.currentTarget.dataset;
    if (value) onSelect(value);
  };

  const buttonStyles = open
    ? ({ ...styles.button, ...styles.active } as SxProps)
    : styles.button;

  return (
    <Box sx={styles.container}>
      {iconButtonContent && !textButtonContent && (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={menuOpenHandler}
          sx={buttonStyles}
        >
          {iconButtonContent}
        </IconButton>
      )}
      {textButtonContent && (
        <Button
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={menuOpenHandler}
          sx={buttonStyles}
          startIcon={iconButtonContent}
        >
          {textButtonContent}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={menuCloseHandler}
        PaperProps={{
          style: {
            left: '50%',
            transform: 'translateX(-40%)',
          },
        }}
        sx={menuStyles}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedOptionValue}
            data-value={option.value}
            onClick={menuCloseHandler}
            sx={styles.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
