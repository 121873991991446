import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  getIsAnyResourcesFilterSetSelector,
  getOnlyWithUnreadSelector,
} from 'store/selectors/resource';
import { t } from 'ttag';

import { LightTooltip } from 'components/ui/LightTooltip';
import { SetResourcesFiltersPopover } from 'components/ui/SetResourceFilterButton/components/SetResourcesFiltersPopover';

import { styles } from './styles';

export const SetResourceFilterButton: FC = () => {
  const resourceFilter = useAppSelector(getIsAnyResourcesFilterSetSelector);
  const onlyWithUnread = useAppSelector(getOnlyWithUnreadSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleIconPress = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const title = useMemo(() => {
    if (onlyWithUnread) {
      return t`You can't set a filter by resources due to setting a filter by unread messages`;
    }

    return !resourceFilter
      ? t`You can set a resource filter`
      : t`You can change a resource filter`;
  }, [onlyWithUnread, resourceFilter]);

  return (
    <>
      <LightTooltip title={title} arrow placement="top">
        <span>
          <IconButton
            sx={styles.button}
            onClick={handleIconPress}
            disabled={onlyWithUnread}
          >
            {resourceFilter ? (
              <FilterAltIcon sx={styles.icon} />
            ) : (
              <FilterAltOutlinedIcon sx={styles.icon} />
            )}
          </IconButton>
        </span>
      </LightTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{ sx: styles.popup }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {open && <SetResourcesFiltersPopover onClosePopover={handleClose} />}
      </Popover>
    </>
  );
};
