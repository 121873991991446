import React, { FC, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal } from '@mui/material';
import { StylesProps } from 'types/common';

import { styles } from './styles';

// import './imageViewer.css';

interface ImageViewerProps {
  currentImageIndex: number;
  photoUrls: string[];
  open: boolean;
  onClose: () => void;
}

export const ImageViewer: FC<ImageViewerProps> = (props: ImageViewerProps) => {
  const { currentImageIndex, open, onClose, photoUrls } = props;

  const [currentIndex, setCurrentIndex] = useState(currentImageIndex || 0);

  useEffect(() => {
    setCurrentIndex(currentImageIndex);
  }, [open, currentImageIndex]);

  const handleImageClick = useCallback((idx: number) => {
    setCurrentIndex(idx);
  }, []);

  const renderItem = (item: string, index: number) => {
    return (
      <Box
        key={`img-item-${index}`}
        sx={
          {
            ...styles.imageItem,
            ...(index === currentIndex ? styles.orangeBorder : {}),
          } as StylesProps
        }
        onClick={() => handleImageClick(index)}
      >
        <img src={item} alt="item of List" />
      </Box>
    );
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={styles.modal}>
        <IconButton sx={styles.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Box sx={styles.imageContainer}>
          <img src={photoUrls[currentIndex]} alt="Chosen Image" />
        </Box>

        <Box sx={styles.listContainer}>
          {!!photoUrls.length && photoUrls.map(renderItem)}
        </Box>
      </Box>
    </Modal>
  );
};
