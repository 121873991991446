import {
  differenceInMinutes,
  format,
  millisecondsToHours,
  millisecondsToMinutes,
} from 'date-fns';
import { t } from 'ttag';

export const toISOStringWithTimezone = (date: Date) => {
  return (
    `${date.toISOString().split(/.\d{3}Z/)[0]}+00:00` || date.toISOString()
  );
};

export const twoDateStringsDifference = (date1: string, date2: string) =>
  dateStringToNumber(date1) - dateStringToNumber(date2);

export const dateStringToNumber = (date?: string) => {
  if (!date) return 0;
  return Date.parse(date);
};

export const formatDateStringToHHmm = (dateString: string | undefined) => {
  if (!dateString || dateString === 'None') {
    return undefined;
  }

  return format(new Date(dateString), 'HH:mm');
};

export const formatMillisecondsToTimestamp = (milliseconds: number) => {
  const absMilliseconds = Math.abs(milliseconds);
  const isNotEmpty =
    millisecondsToHours(absMilliseconds) ||
    millisecondsToMinutes(absMilliseconds);
  const hours = millisecondsToHours(absMilliseconds)
    ? `${millisecondsToHours(absMilliseconds)}${t`h`} `
    : '';
  const minutes = isNotEmpty
    ? `${millisecondsToMinutes(absMilliseconds) % 60}${t`min`}`
    : '0 min';

  return `${hours}${minutes}`;
};

export const combineDateAndTimeFromTwoIsoStrings = (
  dateIsoString: string,
  timeIsoString: string,
) => {
  const stringDivider = 'T';
  const time = timeIsoString.split(stringDivider)[1];
  const date = dateIsoString.split(stringDivider)[0];
  return `${date}${stringDivider}${time}`;
};

export const getTheLargerOfTheTwoDates = (
  date1: Date | null,
  date2: Date | null,
) => {
  if (date1 === null || date2 === null) return null;
  return date1.getTime() > date2.getTime() ? date1 : date2;
};

export const getMidnightDate = (date?: Date) => {
  date ??= new Date();
  return date.setHours(0, 0, 0, 0);
};

export const isFirstDateMoreThenSecond = (d1: string, d2: string): boolean => {
  // @ts-ignore
  return Date.parse(new Date(d1)) > Date.parse(new Date(d2));
};

export const removeZ = (tString: string) => {
  if (typeof tString !== 'string') {
    return tString;
  }

  return tString.replace('Z', '');
};

export function getDifferenceInHoursAndMinutes(date1?: string, date2?: string) {
  if (!date1 || !date2) {
    return '0min';
  }

  const totalMinutes = differenceInMinutes(new Date(date1), new Date(date2));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}min`;
  } else {
    return `${minutes}min`;
  }
}
