export const styles = {
  container: {
    position: 'relative',
  },

  circle: {
    position: 'absolute',
    minWidth: '12px',
    height: '12px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: '0px',
    top: '0px',
    bgcolor: 'primary.main',
  },

  unread: {
    color: 'custom.white',
    fontSize: '8px',
    fontWeight: 600,
  },
};
