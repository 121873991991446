import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  list: {
    height: '100%',
    p: 0,
    overflowY: 'scroll',
    borderRadius: '6px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    cursor: 'grab',
    borderColor: 'custom.greyLight',
    '&:hover': {
      bgcolor: 'custom.blueLight3',
    },
    '&:first-of-type': {
      borderRadius: '6px 6px 0 0',
    },

    '&:hover .crab-icon-block': {
      display: 'block',
    },
  },

  selected: {
    bgcolor: 'custom.blueLight',
    '&:hover': {
      bgcolor: 'custom.blueLight',
    },
  },

  icon: {
    mr: '16px',
    fontSize: '20px',
    color: 'custom.blue',
    display: 'flex',
    alignItems: 'center',
  },

  labelCircle: {
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    bgcolor: 'primary.main',
    margin: '3px',
  },
  filtersBlock: {
    // position: 'absolute',
    width: '100%',
    height: '70px',
    padding: '16px',
    zIndex: 100,
    // bgcolor: 'white',
  },
  noResources: {
    marginTop: '24px',
    textAlign: 'center',
  },
  grabIconBlock: {
    display: 'none',
  },
};
