import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  container: {
    mt: '5px',
    '&:first-of-type': {
      mt: 0,
    },
  },

  valueText: {
    textAlign: 'right',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
};
