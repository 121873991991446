import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat, UnreadMessagesByResource } from 'types/api';
import { ChatPriority } from 'types/dedicatedChat';

interface ChatState {
  chatList: Chat[];
  unreadMessages: UnreadMessagesByResource[];
  dedicatedChatResourcesGroups: string[];
  dedicatedChatResourcesFilters: string[];
  dedicatedChatResourcesFiltersAndGroups: string[];
  dedicatedChatPriorityFilters: ChatPriority[];
  dedicatedChatDate?: string;
}

const initialState: ChatState = {
  chatList: [],
  unreadMessages: [],
  dedicatedChatResourcesGroups: [],
  dedicatedChatResourcesFilters: [],
  dedicatedChatResourcesFiltersAndGroups: [],
  dedicatedChatPriorityFilters: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateDedicatedChatResourcesGroups: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.dedicatedChatResourcesGroups = action.payload;
    },

    updateDedicatedChatDate: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.dedicatedChatDate = action.payload;
    },

    updateDedicatedChatResourcesFilters: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.dedicatedChatResourcesFilters = action.payload;
    },

    updateDedicatedChatResourcesFiltersAndGroups: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.dedicatedChatResourcesFiltersAndGroups = action.payload;
    },

    updateDedicatedChatPriorityFilters: (
      state,
      action: PayloadAction<ChatPriority[]>,
    ) => {
      state.dedicatedChatPriorityFilters = action.payload;
    },

    removeDedicatedChatGroupsAndFilters: (state) => {
      state.dedicatedChatResourcesGroups = [];
      state.dedicatedChatResourcesFilters = [];
      state.dedicatedChatResourcesFiltersAndGroups = [];
    },

    removeDedicatedChatPriorityGroupsAndFilters: (state) => {
      state.dedicatedChatResourcesGroups = [];
      state.dedicatedChatResourcesFilters = [];
      state.dedicatedChatResourcesFiltersAndGroups = [];
      state.dedicatedChatPriorityFilters = [];
    },

    setChatList: (state, action: PayloadAction<Chat[]>) => {
      state.chatList = action.payload;
    },

    updateQuickReplyByMessageId: (
      state,
      action: PayloadAction<{ messageId: string; quickReplies: number[] }>,
    ) => {
      const { messageId, quickReplies } = action.payload;
      const searchedMessage = state.chatList.find(
        (message) => message.id === messageId,
      );

      if (searchedMessage) {
        const quick_responses = quickReplies.map((id) => ({ id }));
        state.chatList = state.chatList.map((message) => {
          if (message.id === messageId) {
            return { ...message, quick_responses };
          }

          return message;
        });
      }
    },
    setUnreadMessages: (
      state,
      action: PayloadAction<UnreadMessagesByResource[]>,
    ) => {
      state.unreadMessages = action.payload;
    },
  },
});

export const {
  setChatList,
  setUnreadMessages,
  updateQuickReplyByMessageId,
  removeDedicatedChatGroupsAndFilters,
  removeDedicatedChatPriorityGroupsAndFilters,
  updateDedicatedChatPriorityFilters,
  updateDedicatedChatResourcesFilters,
  updateDedicatedChatResourcesFiltersAndGroups,
  updateDedicatedChatResourcesGroups,
  updateDedicatedChatDate,
} = chatSlice.actions;

export const chatReducer = chatSlice.reducer;
