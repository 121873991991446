import { MessageType } from 'react-chat-elements';
import { format, isSameDay } from 'date-fns';
import { orderBy } from 'lodash';

import { useAppSelector } from '../store/hooks/useAppSelector';
import { getAllChatMessages } from '../store/selectors/chat';
import { Order } from '../types/orders';

type UpdatedMessageType = MessageType & { quickReplies: number[] };

export const useChatMessages = (shipment: Order | undefined | null) => {
  const shipmentId =
    shipment?.id.startsWith('group-') || shipment?.id.startsWith('terminal-')
      ? null
      : shipment?.shipmentId
      ? shipment.shipmentId
      : null;

  const messages = useAppSelector(getAllChatMessages);

  const filteredMessage = messages.filter(
    (m) => m?.message?.shipment_id === shipmentId || null,
  );

  const mappedMessages = filteredMessage.map((m): UpdatedMessageType => {
    const status =
      m?.message?.sender === 'driver' ? 'read' : m?.read ? 'read' : 'sent';
    const isManager = m?.message?.sender === 'manager';
    const quickReplies = m.quick_responses?.map((q) => q.id) || [];

    return {
      type: 'text',
      id: m.id,
      text: m?.message?.content,
      position: isManager ? 'right' : 'left',
      title: isManager ? m?.message?.manager_name : '',
      focus: true,
      date: new Date(m?.created_at),
      dateString: format(new Date(m?.created_at), 'HH:mm'),
      forwarded: false,
      replyButton: false,
      removeButton: false,
      status,
      notch: true,
      copiableDate: true,
      retracted: false,
      className: '',
      reply: undefined,
      avatar: undefined,
      titleColor: 'black',
      quickReplies,
    };
  });

  const messagesWithDateSeparators = orderBy(
    mappedMessages,
    'date',
    'asc',
  ).reduce<Partial<MessageType>[]>((accum, curr, idx) => {
    if (idx === 0) {
      return [
        {
          type: 'system',
          text: format(curr.date, 'eee, dd MMMM'),
          className: '',
        },
        curr,
      ];
    }

    if (!isSameDay(accum?.[accum.length - 1]?.date as Date, curr.date)) {
      return [
        ...accum,
        {
          type: 'system',
          text: format(curr.date, 'eee, dd MMMM'),
        },
        curr,
      ];
    }

    return [...accum, curr];
  }, []);

  const managerMessagesIds = filteredMessage
    .filter((m) => m?.message?.sender === 'driver' && !m?.read)
    .map((m) => m.id);

  return {
    chatMessages: messagesWithDateSeparators,
    managerMessagesIds,
  };
};
